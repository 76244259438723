import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { Dots as DotsIcon } from 'components/icons';
import { Menu } from 'components/ui';

const Wrapper = styled.div`
  position: relative;
`;

const Control = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const Icon = styled(DotsIcon)``;

@withTheme
@observer
class UserActions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @observable isOpened = false;

  get userActions() {
    const { t } = this.props;

    return [
      {
        label: t('User.Action.Change'),
        value: 'change'
      },
      {
        label: t('User.Action.Block'),
        value: 'block'
      },
      {
        label: t('User.Action.Remove'),
        value: 'remove'
      }
    ];
  }

  handleToggleOpen = () => {
    this.isOpened = !this.isOpened;
  }

  handleClose = () => {
    if (this.isOpened) {
      this.isOpened = false;
    }
  }

  handleChange = (item) => {
    const { onChange } = this.props;
    this.isOpened = false;

    onChange(item);
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Control onClick={this.handleToggleOpen}>
          <Icon />
        </Control>

        <Menu
          isOpened={this.isOpened}
          onClose={this.handleClose}
          onChange={this.handleChange}
        >
          {this.userActions.map((item, index) => (
            <Menu.Item
              key={index}
              value={item}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(UserActions))``;
