import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { display, typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex')}
`;

const Item = styled.div`
  ${typography(13, 19)}
  ${({ theme }) => css`
    color: ${theme.colors.text.disabled};
  `}

  :not(:last-child)::after {
    content: "·";
    margin: 0 6px;
  }
`;

@withTheme
class HotelCategories extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    theme: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { items, theme, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {items.map((item, index) => (
          <Item
            key={index}
            theme={theme}
          >
            {item}
          </Item>
        ))}
      </Wrapper>
    );
  }
}

export default styled(HotelCategories)``;
