import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { withTranslation } from 'react-i18next';
import { RequestInfo } from 'pages/Orders/components';

import { Button } from 'components/forms';

import DropdownMenu from 'components/v2/ui/DropdownMenu';

const DropdownContainer = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${Button} + ${Button} {
    margin-left: 35px;
  }
`;

@inject('showState')
@withRouter
@observer
class SecondaryActions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @observable isRequestInfo = false;

  @observable requestInfo = { title: 'Title', actionText: 'actionText', bodyText: 'bodyText' };

  @action
  closeRequestInfo = () => {
    this.isRequestInfo = false;
    this.requestInfo = { title: null, actionText: '', bodyText: '' };
  }

  @action
  openRequestInfo = (attributes) => {
    this.requestInfo = attributes;
    this.isRequestInfo = true;
  }

  handleSyncWithOneC = async (e) => {
    e.preventDefault();

    const { t, showState } = this.props;

    const requestInfo = {
      title: t('SyncWithOneC.RequestInfo.Title'),
      actionText: t('SyncWithOneC.RequestInfo.Action'),
      bodyText: t('SyncWithOneC.RequestInfo.Text')
    };

    await showState.syncOnPlatform();
    this.openRequestInfo(requestInfo);
  }

  handleDownload = (e) => {
    e.preventDefault();

    const { showState } = this.props;
    return showState.downloadReport();
  }

  handleRecalc = (e) => {
    e.preventDefault();

    const { showState } = this.props;
    return showState.recalc();
  }

  @computed get canSyncWithOneC() {
    const { showState: { permissions } } = this.props;
    return permissions.sync_on_platform;
  }

  @computed get canHistory() {
    const { showState: { permissions } } = this.props;
    return permissions.history;
  }

  @computed get isDisabled() {
    const { showState } = this.props;
    return showState.isPending;
  }

  @computed get menu() {
    const { t } = this.props;

    return [
      {
        label: t('Order.History'),
        disabled: this.isDisabled || !this.canHistory
      },
      {
        label: t('Order.Recalc'),
        disabled: this.isDisabled || !this.canHistory,
        onClick: this.handleRecalc
      }
    ];
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Button
          compact
          disabled={this.isDisabled || !this.canSyncWithOneC}
          onClick={this.handleSyncWithOneC}
        >
          {t('Order.SyncWithOneC')}
        </Button>

        <Button
          compact
          disabled={this.isDisabled}
          onClick={this.handleDownload}
        >
          {t('Order.UploadXLS')}
        </Button>

        <DropdownContainer>
          <DropdownMenu
            menu={this.menu}
          />
        </DropdownContainer>

        {this.isRequestInfo && (
          <RequestInfo
            {...this.requestInfo}
            handleClose={this.closeRequestInfo}
          >
            Info
          </RequestInfo>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(SecondaryActions))``;
