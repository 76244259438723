import React from 'react';
import { Star } from 'components/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  color: #4a515c;
  white-space: nowrap;
  ${typography(14, 16, 700)};
`;

class Rating extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    rating: PropTypes.number
  };

  static defaultProps = {
    className: '',
    rating: 0
  };

  render() {
    const { rating, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Star /> {rating}
      </Wrapper>
    );
  }
}

export default styled(Rating)``;
