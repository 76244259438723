import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  display: table-cell;
  padding: 12px 0;

  :not(:first-child) {
    padding-left: 8px;
  }

  :not(:last-child) {
    padding-right: 8px;
  }
`;

const Label = styled.div`
  ${({ theme }) => css`
    ${typography(12, 14)}
    color: ${theme.colors.text.secondary};
  `}
`;

const Value = styled.div`
  ${({ theme }) => css`
    margin: 4px 0;
    ${typography(14, 18)}
    color: ${theme.colors.text.primary};
  `}
`;

@withTheme
class Cell extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { label, value, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Label>
          {label}
        </Label>

        <Value>
          {value}
        </Value>
      </Wrapper>
    );
  }
}

export default styled(Cell)``;
