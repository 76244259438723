import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { observable } from 'mobx';
import { inject } from 'mobx-react';

import Button from 'components/forms/Button';
import { OrderCancelDialog } from 'pages/Orders/components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${Button} + ${Button} {
    margin-left: 8px;
  }
`;

@inject('ordersStore')
@withRouter
@observer
class Actions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired,
    ordersStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @observable isOrderCancelDialogOpened = false;

  handleEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { order: { id, provider_state, hotel }, history } = this.props;

    switch (provider_state.code) {
      case 'created':
        hotel
          ? history.push(`/orders/${id}/edit`)
          : history.push(`/orders/${id}/search`);

        break;

      case 'booked':
        history.push(`/orders/${id}/confirm`);
        break;

      default:
        history.push(`/orders/${id}`);
    }
  }

  handleCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.isOrderCancelDialogOpened = true;
  }

  handleOrderCancelPromptClose = () => {
    const { ordersStore } = this.props;
    this.isOrderCancelDialogOpened = false;
    ordersStore.fetch();
  }

  render() {
    const { order: { id }, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Button
          href={`/orders/${id}`}
          onClick={this.handleEdit}
          rounded
          outline
          size='small'
        >
          {t('UI.Button.Edit')}
        </Button>

        <Button
          rounded
          outline
          size='small'
          variant='danger'
          onClick={this.handleCancel}
        >
          {t('Order.Cancel')}
        </Button>

        <OrderCancelDialog
          isOpened={this.isOrderCancelDialogOpened}
          onClose={this.handleOrderCancelPromptClose}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Actions))``;
