import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { SearchQueryProvider } from 'hooks/useQuery';

import { Add, List, Edit, Show, Details, Search } from './containers';

@withRouter
@observer
class Orders extends Component {
  render() {
    return (
      <SearchQueryProvider>
        <Switch>
          <Route
            exact
            path='/orders/new'
            component={Add}
          />

          <Route
            exact
            path='/orders/:id/edit/:reservation_id?'
            component={(props) => <Edit {...props} />}
          />

          <Route
            exact
            path='/orders'
            component={List}
          />

          <Route
            exact
            path='/orders/:id/search'
            component={Search}
          />

          <Route
            exact
            path='/orders/:id/details'
            component={Details}
          />

          <Route
            exact
            path='/orders/:id'
            component={Show}
          />
        </Switch>
      </SearchQueryProvider>
    );
  }
}

export default Orders;
