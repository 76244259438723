import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Tab } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'align-items', 'space-between')}
    border-bottom: 1px solid ${theme.colors.popup.primary};
  `}

  ${Tab} + ${Tab} {
    margin-left: 40px;
  }
`;

@withTheme
class Tabs extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string,
        active: PropTypes.bool,
        label: PropTypes.string
      })
    ),
    theme: PropTypes.object
  };

  static defaultProps = {
    tabs: [],
    className: ''
  };

  render() {
    const { tabs, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {tabs.map(({ label, to, active, onClick }, key) => (
          <Tab
            key={key}
            to={to}
            active={active ? 'active' : undefined}
            onClick={onClick}
          >
            {label}
          </Tab>
        ))}
      </Wrapper>
    );
  }
}

export default styled(Tabs)``;
