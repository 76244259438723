import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import orderState from 'utils/orderState';

import { OrderState } from 'pages/Orders/components';

const Wrapper = styled.div`
`;

@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      order: { provider_state, platform_state },
      className
    } = this.props;

    const state = orderState({ provider_state, platform_state });

    return (
      <Wrapper className={className}>
        <OrderState
          direction='row'
          prefixed
          actioned
          state={state}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
