import instance from 'connection/instance';
import { t } from 'utils/localization';

const documentMapper = ({ hit, locale }) => {
  const {
    document: {
      [`label_${locale}`]: label,
      [`description_${locale}`]: description,
      place_id,
      scope,
      slug,
      location
    }
  } = hit;

  return { place_id, label, description, scope, slug, location };
};

const filterBy = ({ locale }) => {
  return locale === 'ru'
    // eslint-disable-next-line no-use-before-define
    ? { filter_by: 'scope:[city, region, country]' }
    // eslint-disable-next-line no-use-before-define
    : { filter_by: 'scope:[city, region, country], is_crimea:false' };
};

async function fetchRegions(chars) {
  const headers = {
    // eslint-disable-next-line no-use-before-define
    'X-TYPESENSE-API-KEY': process.env.REACT_APP_TYPESENSE_API_KEY
  };

  const params = {
    q: chars.toLowerCase(),
    // eslint-disable-next-line no-use-before-define
    query_by: 'index_strings',
    // eslint-disable-next-line no-use-before-define
    sort_by: '_text_match:desc',
    num_typos: 1,
    ...filterBy({ locale: 'ru' })
  };

  const { data: response } = await instance.get(
    // eslint-disable-next-line quotes
    process.env.REACT_APP_TYPESENSE_API_REGIONS,
    { params, headers }
  );

  const data = response.hits
    .map(hit => documentMapper({ hit, locale: 'ru' }));

  const title = t('Filters.Places.Regions.Title');

  return { title, data };
}

export default fetchRegions;
