import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

const Label = styled.div`
  margin-right: 6px;
  color: #4A515C;
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
`;

const Value = styled.div`
  color: #4A515C;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;

  ${({ alert }) => alert && css`
    color: #E68686;
  `}
`;

const PaymentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nnowrap;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 42px;
`;

@withTranslation()
@inject('bookingForm')
@observer
class PaymentInfo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    bookingForm: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingForm, t, ...rest } = this.props;

    const { bank_transfer, cash } = bookingForm.payment;

    return (
      <Wrapper {...rest}>
        {bank_transfer && (
          <PaymentItem>
            <Label>
              {t('Order.OrderInfo.Insurance')}:
            </Label>
            <Value>
              {t('UI.Price', bank_transfer)}
            </Value>
          </PaymentItem>
        )}

        {cash && (
          <PaymentItem>
            <Label>
              {t('Order.OrderInfo.Cash')}:
            </Label>
            <Value alert>
              {t('UI.Price', cash)}
            </Value>
          </PaymentItem>
        )}
      </Wrapper>
    );
  }
}

export default styled(PaymentInfo)``;
