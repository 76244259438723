import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, observable, action, reaction } from 'mobx';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Pen as PenIcon,
  Search as SearchIcon,
  Success as SuccessIcon,
  Error as ErrorIcon
} from 'components/icons';

import { Spinner, Input, InputBuffer, Action, Switch, Wrapper } from '../components';

@withTranslation()
@inject('store', 'form')
@observer
class EditMode extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    focused: PropTypes.bool,
    store: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleEditMode: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.inputEl = React.createRef();
    this.bufferEl = React.createRef();
  }

  componentDidMount() {
    this.addHandler();
    this.selectAndFocus();
  }

  componentWillUnmount() {
    this.removeHandler();
  }

  // search, success, pending, error
  @observable.ref status = {
    code: 'edit',
    messages: []
  };

  @action
  setStatus({ code, messages } = { messages: [] }) {
    this.status = { code, messages };
  }

  @action
  unsetStatus() {
    this.status = { code: 'unknow', messages: [] };
  }

  @computed get isSearch() {
    const { code } = this.status;
    return code === 'search';
  }

  @computed get isSuccess() {
    const { code } = this.status;
    return code === 'success';
  }

  @computed get isPending() {
    const { code } = this.status;
    return code === 'pending';
  }

  @computed get isError() {
    const { code } = this.status;
    return code === 'error';
  }

  @computed get isEdit() {
    const { code } = this.status;
    return code === 'edit';
  }

  addHandler() {
    this._changeValue = reaction(
      () => this.value,
      (value) => this.setInputWidth(),
      { fireImmediately: true }
    );
  }

  removeHandler() {
    this._changeValue();
  }

  setInputWidth = () => {
    const { inputEl, bufferEl } = this;
    const width = bufferEl.current.clientWidth;

    inputEl.current.style.width = `${width}px`;
  }

  selectAndFocus() {
    const { inputEl } = this;

    inputEl.current.focus();
    inputEl.current.select();
  }

  handleSearch = async (e) => {
    const { form, store } = this.props;

    const chars = form.$('chars').value;
    if (chars < 3) return;

    this.setStatus({ code: 'pending' });

    try {
      await store.fetch({ chars });
      await this.applyContract();
      await this.validateContract();
      this.setStatus({ code: 'success' });
      await this.submitForm();
    } catch (error) {
      this.handleError(error);
    }
  }

  handleChange = (e) => {
    const { form } = this.props;
    const { value } = e.target;

    form.$('chars').set(value);
    this.setStatus({ code: 'search' });
  }

  handleError = (error) => {
    const { data: { messages } } = error.response;
    this.setStatus({ code: 'error', messages });

    setTimeout(() => this.setStatus({ code: 'search' }), 1000);
  }

  applyContract = () => {
    const { form, store } = this.props;
    const contract = store.data.toJSON();

    form.setContract(contract);
    return Promise.resolve(form);
  }

  validateContract = () => {
    const { form } = this.props;
    form.validate();
  }

  submitForm = () => {
    const { form } = this.props;

    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setStatus({ code: 'edit' });
        form.submit();
      }, 1000);
    });

    return promise;
  }

  handleKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    e.preventDefault();

    if (this.isSearch) this.handleSearch(e);
  }

  render() {
    const { store, form, handleEditMode, className, ...rest } = this.props;

    const { inputEl, handleChange, handleSearch, handleKeyDown } = this;

    return (
      <Wrapper
        {...rest}
        bordered
        switched
        className={className}
      >
        <Input
          {...rest}
          ref={inputEl}
          onKeyDown={handleKeyDown}
          {...form.$('chars').bind({ onChange: handleChange })}
        />

        <InputBuffer
          as='div'
          ref={this.bufferEl}
          {...rest}
        >
          {form.$('chars').value}
        </InputBuffer>

        {this.isSearch && (
          <Action
            {...rest}
            onClick={handleSearch}
          >
            <SearchIcon />
          </Action>
        )}

        {this.isPending && (
          <Action
            {...rest}
          >
            <Spinner {...rest} />
          </Action>
        )}

        {this.isSuccess && (
          <Action
            {...rest}
          >
            <SuccessIcon />
          </Action>
        )}

        {this.isError && (
          <Action
            {...rest}
          >
            <ErrorIcon />
          </Action>
        )}

        {this.isEdit && (
          <Action
            {...rest}
            onClick={(e) => handleEditMode('view')}
          >
            <PenIcon />
          </Action>
        )}

        <Switch
          mode='insurant'
          onChange={handleEditMode}
        />
      </Wrapper>
    );
  }
}

export default styled(EditMode)``;
