import React from 'react';
import { Badge, StopSales, DepartureClose, ArrivalClose, DepartureArrivalClose } from 'components/atoms';
import PropTypes from 'prop-types';
import { typography } from 'theme/mixins';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import moment from 'utils/moment';

const Date = styled.div`
  ${typography(13, 17, 500)};

  .DayPicker-Day--selected & {
    color: #fff;
  }

  .DayPicker-Day--disabled & {
    color: #a6a6a6;
  }

  .DayPicker-Day--selected.DayPicker-Day--disabled & {
    color: #fff;
  }
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 2px;
  box-sizing: border-box;
`;

const CellEnabled = styled(Cell)`
  background-color: #ebf8fd;

  &:hover {
    background-color: #e4f1f6;
  }

  .DayPicker-Day--selected & {
    background-color: #5ab2d6;
  }
`;

const CellDisabled = styled(Cell)`
  background-color: #e9f0f5;

  .DayPicker-Day--selected & {
    background-color: #E68686;
  }
`;

const RestrictionsContainer = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 18px;
  height: 18px;
  z-index: 10;
`;

const CellRestrictionDisabled = styled(Cell)`
  background-color: #F4F8FA;

  .DayPicker-Day--selected & {
    background-color: #FAE2E2;

    div {
      color: #E26161 !important;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 57px;
  height: 54px;

  ${Cell} {
    flex-grow: 1;
  }

  ${Badge} {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -3px;
    right: -3px;
    line-height: 15px;
    vertical-align: middle;
    text-align: center;
    border: 2px solid white;
    border-radius: 6px;
    background-color: #5AB2D6;

    ${props => (props.count < 1
      ? css`
          background: #9cb4ca;
        `
      : css`
         background: #5ab2d6;
        `
    )};
  }

  ${StopSales} {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -3px;
    right: -3px;
  }

  ${StopSales} + ${Badge} {
    display: none;
  }
`;

@observer
class Package extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    day: PropTypes.object.isRequired,
    price: PropTypes.number,
    available: PropTypes.number,
    stop_sales: PropTypes.bool
  }

  static defaultProps = {
    price: -1,
    available: -1,
    className: ''
  }

  get hasAvailability() {
    const { available } = this.props;
    return available > 0;
  }

  get hasStopSales() {
    const { stop_sales } = this.props;
    return stop_sales;
  }

  get disabledByRestrictions() {
    const { restrictions } = this.props;

    const {
      closed_by_date_restrictions: {
        closed_to_arrival,
        closed_to_departure
      }
    } = restrictions;

    return closed_to_arrival || closed_to_departure;
  }

  get hasPrice() {
    const { price } = this.props;
    return price > 0;
  }

  get isDisabled() {
    const { available, price, stop_sales } = this.props;

    return price <= 0 || available <= 0 || stop_sales;
  }

  get CellComponent() {
    if (this.isDisabled) return CellDisabled;

    if (this.disabledByRestrictions) return CellRestrictionDisabled;

    return CellEnabled;
  }

  render() {
    const { available, day, price, stop_sales, restrictions, className } = this.props;
    const {
      closed_by_date_restrictions: {
        closed_to_arrival,
        closed_to_departure
      },
      length_of_stay_restrictions: {
        max_length_of_stay,
        min_length_of_stay
      }
    } = restrictions;

    const dayNumber = moment(day).format('D');
    const isDisabled = price <= 0 || available <= 0 || stop_sales;

    return (
      <Wrapper
        count={available}
        className={className}
      >
        {this.hasStopSales && (
          <StopSales />
        )}

        {!this.hasStopSales && this.disabledByRestrictions && (
          <RestrictionsContainer>
            {closed_to_arrival && !closed_to_departure && <ArrivalClose min={min_length_of_stay} max={max_length_of_stay} />}

            {closed_to_departure && !closed_to_arrival && <DepartureClose min={min_length_of_stay} max={max_length_of_stay} />}

            {closed_to_departure && closed_to_arrival && <DepartureArrivalClose min={min_length_of_stay} max={max_length_of_stay} />}
          </RestrictionsContainer>
        )}

        {this.hasAvailability && (
          <Badge count={available} />
        )}

        <Cell as={this.CellComponent}>
          <Date>{dayNumber}</Date>
        </Cell>
      </Wrapper>
    );
  }
}

export default styled(Package)``;
