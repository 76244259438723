import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { HotelCardPreview, HotelCardListItem } from './components';

class HotelCard extends React.PureComponent {
  static propTypes = {
    kind: PropTypes.oneOf([
      'list-item',
      'preview'
    ]),
    className: PropTypes.string,
    hotel: PropTypes.object
  };

  static defaultProps = {
    kind: 'preview',
    className: ''
  };

  render() {
    const { kind, ...rest } = this.props;

    if (kind === 'preview') {
      return (
        <HotelCardPreview {...rest} />
      );
    }

    if (kind === 'list-item') {
      return (
        <HotelCardListItem {...rest} />
      );
    }

    return null;
  }
}

export default styled(HotelCard)``;
