import { t } from 'utils/localization';

const fields = [
  'email',
  'first_name',
  'last_name',
  'middle_name',
  'phone',
  'role'
];

const placeholders = {
  email: t('Profile.Email.Label'),
  first_name: t('Profile.FirstName.Label'),
  last_name: t('Profile.LastName.Label'),
  middle_name: t('Profile.MiddleName.Label'),
  phone: t('Profile.Phone.Label'),
  role: t('Profile.Role.Label')
};

const rules = {
  first_name: 'required|string|min:2|max:32',
  last_name: 'required|string|min:2|max:32',
  middle_name: 'required|string|min:2|max:32',
  email: 'required|string|min:5|max:100',
  phone: 'required|string|min:10|max:12'
};

const rolesItems = [
  {
    value: 'admin',
    label: t('User.Roles.Administrator')
  },
  {
    value: 'manager',
    label: t('User.Roles.Manager')
  },
  {
    value: 'analyst',
    label: t('User.Roles.Analyst')
  }
];

const extra = {
  role: rolesItems
};

export default {
  fields,
  placeholders,
  rules,
  extra
};
