import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import styled, { css } from 'styled-components';
import getImage from 'utils/getImage';

import { ImageSetPropType } from 'types/customPropTypes';

import ImageComponent from 'components/ui/Image';
import { Status, SlideControl } from './components';

const Wrapper = styled.div`
  position: relative;

  .image-gallery-thumbnail {
    width: 66px;
  }

  .image-gallery-thumbnails-wrapper.bottom {
    margin-top: 20px;
  }
`;

const SlideS = styled(ImageComponent)`
  width: 100%;
  height: 336px;
  font-size: 64px;
  border-radius: 4px;
  object-fit: cover;
`;

const ThumbS = styled(ImageComponent)`
  height: 40px;
  border-radius: 3px;
  object-fit: cover;
`;

const StatusS = styled(Status)`
  position: absolute;
  top: 296px;
  left: 50%;
  transform: translateX(-50%);
`;

const SlideControlS = styled(SlideControl)`
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  ${({ direction }) => direction && css`
    ${direction === 'left' && css`
      left: 16px;
    `}

    ${direction === 'right' && css`
      right: 16px;
    `}
  `}
`;

class CarouselGallery extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(ImageSetPropType)
  };

  static defaultProps = {
    className: ''
  };

  state = {
    currentSlide: 0
  };

  handleCurrentSlideSet = (currentSlide) => {
    this.setState({ currentSlide });
  }

  renderItem(image) {
    const mainImageSize = { width: 960, height: 456 };
    const { src } = this.getImgPath(image, mainImageSize);

    return (
      <SlideS src={src} />
    );
  }

  renderThumbInner(image) {
    const thumbSize = { width: 233, height: 155 };
    const { src } = this.getImgPath(image, thumbSize);

    return (
      <ThumbS src={src} />
    );
  }

  renderLeftNav(onClick, disabled) {
    return (
      <SlideControlS
        direction='left'
        disabled={disabled}
        onClick={onClick}
      />
    );
  }

  renderRightNav(onClick, disabled) {
    return (
      <SlideControlS
        direction='right'
        disabled={disabled}
        onClick={onClick}
      />
    );
  }

  getImgPath(item, size) {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  }

  render() {
    const { items, ...rest } = this.props;
    const { currentSlide } = this.state;

    const galleryItems = items.map(item => ({
      renderItem: () => this.renderItem(item),
      renderThumbInner: () => this.renderThumbInner(item)
    }));

    return (
      <Wrapper {...rest}>
        <ImageGallery
          items={galleryItems}
          renderLeftNav={this.renderLeftNav}
          renderRightNav={this.renderRightNav}
          showFullscreenButton={false}
          showPlayButton={false}
          onSlide={this.handleCurrentSlideSet}
        />

        <StatusS>
          {`${currentSlide + 1} / ${items.length}`}
        </StatusS>
      </Wrapper>
    );
  }
}

export default styled(CarouselGallery)``;
