import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import OrderInfo from './OrderInfo';

const Wrapper = styled.div`
  width: inherit;
  height: auto;
  padding: 18px 0px 28px;
`;

@observer
class Body extends React.Component {
  static propTypes = {
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { className } = this.props;

    return (
      <Wrapper className={className}>
        <OrderInfo />
      </Wrapper>
    );
  }
}

export default styled(Body)``;
