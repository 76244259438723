import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Text } from 'components/atoms';

import { display, typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'top', 'space-between')};
`;

const Type = styled(Text)`
  ${typography(14, 18, 700)}
`;

const Amount = styled(Text)`
  ${typography(14, 18, 700)}
  white-space: nowrap;
`;

class Room extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    reservation: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, reservation, ...rest } = this.props;
    const { price, room_type } = reservation;

    return (
      <Wrapper {...rest}>
        <Type>
          {t(`Order.RoomType.Count.${room_type.beds}`)}
          {' '}
          {room_type.name.toLowerCase()}
        </Type>

        <Amount>
          {t('UI.Price', { price })}
        </Amount>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Room))``;
