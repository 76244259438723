import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import Qs from 'qs';
import { NavLink, withRouter } from 'react-router-dom';
import { typography } from 'theme/mixins';
import getImage from 'utils/getImage';

import { Image as ImageComponent } from 'components/ui';

const Title = styled.div`
  color: #4a515c;
  margin-bottom: 12px;
  ${typography(16, 18, 500)};
`;

const Description = styled.div`
  color: #6b707b;
  ${typography(12, 15)};
`;

const Time = styled(Description)``;

const Address = styled(Description)`
  margin-bottom: 12px;
`;

const TimeWrapper = styled(Description)`
  display: flex;
  flex-direction: column;

  ${Time} + ${Time} {
    margin-top: 2px;
  }
`;

const Phone = styled(Description)`
  margin: 16px 0 12px;
`;

const HotelUrl = styled(NavLink)`
  color: #3c6f9d;
  text-decoration: none;
  ${typography(13, 20, 500)};
`;

const Image = styled(ImageComponent)`
  width: 100%;
  height: auto;
  min-height: 146px !important;
  font-size: 32px;
  border-radius: 4px 4px 0 0;
`;

const Header = styled.div`
`;

const Body = styled.div`
  padding: 12px 16px 12px 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
`;

@withRouter
@observer
class HotelCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history,
    match: ReactRouterPropTypes.match,
    location: PropTypes.object.isRequired,
    hotel: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get hotelUrl() {
    const { location: { pathname }, hotel } = this.props;

    let queryParams = { hotel_id: hotel.id };
    queryParams = Qs.stringify(queryParams);

    return [pathname, queryParams].join('?');
  }

  @computed get hotelPhone() {
    const { contacts } = this.props.hotel;

    const phone = contacts
      .find(contact => contact.type === 'phone');

    return phone ? phone.value : null;
  }

  getImgPath(item, size) {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  }

  render() {
    const { hotel, t, ...rest } = this.props;
    const thumbSize = { width: 233, height: 155 };
    const { src } = this.getImgPath(hotel.images[0], thumbSize);

    return (
      <Wrapper {...rest}>
        <Header>
          {hotel.images.length > 0 &&
            <Image
              src={src}
              alt={hotel.name}
            />
          }
        </Header>

        <Body>
          <Title>
            {hotel.name}
          </Title>

          <Address>
            {hotel.address.location}
          </Address>

          <TimeWrapper>
            <Time>
              {t('Hotel.One.CheckIn', {
                time: hotel.check_in_time
              })}
            </Time>
            <Time>
              {t('Hotel.One.CheckOut', {
                time: hotel.check_out_time
              })}
            </Time>
          </TimeWrapper>

          <Phone>
            {this.hotelPhone}
          </Phone>

          <HotelUrl to={this.hotelUrl}>
            {t('Hotel.One.More')}
          </HotelUrl>
        </Body>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(HotelCard))``;
