import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Label = styled.div`
  color: #979BA0;
  font-size: 13px;
  line-height: 16px;
`;

const Value = styled.div`
  color: #3AA6D2;
  font-size: 13px;
  line-height: 16px;
  text-decoration: underline;
  text-decoration-style: dashed;
  cursor: pointer;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  ${Label} + ${Value} {
    margin-left: 4px;
  }
`;

function Switch({ mode, onChange, className }) {
  const { t } = useTranslation();

  const handleClick = (e) => {
    onChange(mode);
  };

  return (
    <Wrapper className={className}>
      <Label>
        {t('Contract.Switch.Label')}
      </Label>

      <Value onClick={handleClick}>
        {t('Contract.Switch.Mode', { mode })}
      </Value>
    </Wrapper>
  );
}

Switch.defaultProps = {
  onSelect: () => {}
};

Switch.propTypes = {
  className: PropTypes.string,
  mode: PropTypes.oneOf(['contract', 'insurant']),
  onChange: PropTypes.func
};

export default styled(Switch)``;
