import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

const Cell = styled.div`
  font-size: 14px;
  line-height: 16px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 16px;

  ${({ alert }) => alert && css`
    color: #E68686;
  `}

  ${({ bold }) => bold && css`
    font-weight: 500;
  `}
`;

const Rate = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 18px;
`;

const Contract = styled.div`
  color: #979BA0;
  font-size: 14px;
  line-height: 18px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & + & {
    margin-top: 6px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

@inject('showState')
@observer
class Slot extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired,
    slot: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { slot, t, ...rest } = this.props;

    const {
      contract,
      rate: { description },
      payment: { total, cash }
    } = slot;

    return (
      <Wrapper {...rest}>
        <Row>
          <Cell>
            <Rate>
              {description}
            </Rate>
            <Contract>
              {contract
                ? t('Contract.Value', contract)
                : t('Contract.Empty')
              }
            </Contract>
          </Cell>
          <Cell>
            <Text bold>
              {t('UI.Price', total)}
            </Text>
          </Cell>
        </Row>

        {cash && (
          <Row>
            <Cell>
              <Text alert>
                {t('Order.OrderInfo.CashSurcharge')}
              </Text>
            </Cell>
            <Cell>
              <Text alert bold>
                {t('UI.Price', cash)}
              </Text>
            </Cell>
          </Row>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Slot))``;
