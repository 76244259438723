function tabUniqId(name) {
  const tabID = (sessionStorage.tabID && sessionStorage.closedLastTab !== '2')
    ? sessionStorage.tabID
    : sessionStorage.tabID = Math.random();

  sessionStorage.closedLastTab = '2';

  window.onbeforeunload = () => {
    sessionStorage.closedLastTab = '1';
  };

  return [name, tabID].join('-');
}

export default tabUniqId;
