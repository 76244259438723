import BaseState from 'forms/BaseState';

import { fields } from '.';

class ContractKppForm extends BaseState {
  setup() {
    return fields;
  }
}

export default ContractKppForm;
