import { t } from 'utils/localization';

function orderState({ platform_state, provider_state }) {
  const { code: pr_code } = provider_state;
  const { code: pl_code, message } = platform_state;
  const code = pr_code;

  if (pr_code === 'confirmed' && pl_code === 'success') {
    return { code, message, variant: 'confirmed' };
  }

  if (pr_code === 'confirmed' && pl_code === 'warning') {
    return { code, message, variant: 'warning' };
  }

  if (pr_code === 'confirmed' && pl_code === 'error') {
    return { code, message, variant: 'error' };
  }

  if (pr_code === 'booked') {
    return { code, message: t('ProviderState.Booked.Message'), variant: 'booked' };
  }

  return { code, variant: code };
}

export default orderState;
