import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';
import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${typography(12, 16, 500)}
  letter-spacing: 1px;
  text-transform: uppercase;
`;

class CapitalText extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, className } = this.props;

    return (
      <Wrapper className={className}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(CapitalText)``;
