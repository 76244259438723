import _cloneDeep from 'lodash/cloneDeep';
import _sortBy from 'lodash/sortBy';
import _compact from 'lodash/compact';

const SELECTED_TYPE = 'cash';

/* eslint-disable array-callback-return */
function convertToNight(prices) {
  const data = prices.map((curr, i) => {
    if (i + 1 === prices.length) return;

    const nxt = prices[i + 1];

    const payment_method =
      (
        curr.payment_method.type === nxt.payment_method.type &&
        curr.payment_method.type === SELECTED_TYPE
      )
        ? { type: SELECTED_TYPE }
        : { type: 'bank_transfer' };

    const price = curr.price;
    const night = [curr.day, nxt.day];

    return { night, price, payment_method };
  });

  return _compact(data);
}
/* eslint-enable array-callback-return */

function corceSelectedDays(items) {
  const data = new Map();
  let selected = 0;

  items.forEach((curr, i) => {
    const prv = i - 1 >= 0
      ? items[i - 1]
      : null;

    const nxt = i + 1 <= items.length
      ? items[i + 1]
      : null;

    if (!prv && curr.payment_method.type === SELECTED_TYPE) {
      data.set(nxt.day, { ...nxt, payment_method: { type: SELECTED_TYPE } });
    }

    if (!nxt && curr.payment_method.type === SELECTED_TYPE) {
      data.set(prv.day, { ...prv, payment_method: { type: SELECTED_TYPE } });
    }

    if (curr.payment_method.type === SELECTED_TYPE) {
      selected += 1;
    }

    if (curr.payment_method.type !== SELECTED_TYPE && selected === 1) {
      data.set(curr.day, { ...curr, payment_method: { type: SELECTED_TYPE } });
    }

    if (nxt && nxt.payment_method.type !== SELECTED_TYPE) {
      selected = 0;
    }

    if (!data.has(curr.day)) data.set(curr.day, curr);
  });

  return Array.from(data.values());
}

function pricesToNights(prices) {
  let items = _cloneDeep(prices);
  items = _sortBy(items, 'day');
  items = corceSelectedDays(items);
  items = _sortBy(items, 'day');
  items = convertToNight(items);

  return items;
}

export default pricesToNights;
