import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { observable, computed } from 'mobx';
import { observer, inject } from 'mobx-react';

import {
  ReservationListItem,
  Total,
  ReservationRemoveDialog as RemoveDialog
} from 'pages/Orders/components';

const Wrapper = styled.div`
  ${ReservationListItem} {
    margin-bottom: 20px;

    :not(:first-child) {
      margin-top: 20px;
    }
  }

  ${Total} {
    margin-top: 20px;
  }
`;

@inject('bookingCart', 'orderStore')
@withRouter
@observer
class Center extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingCart: PropTypes.object,
    orderStore: PropTypes.object,
    history: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @observable.ref removeReservation = undefined;

  @computed get isOpenned() {
    return !!this.removeReservation;
  }

  handleEdit = (reservation) => {
    const { bookingCart: { order: { id } }, history } = this.props;
    history.push(`/orders/${id}/edit/${reservation.id}`);
  }

  handleRemove = (reservation) => {
    this.removeReservation = reservation;
  }

  handleOnClose = () => {
    this.removeReservation = undefined;
  }

  handleSubmit = () => {
    const { history, orderStore } = this.props;
    const { id } = orderStore.data.toJSON();

    orderStore.confirm({ id })
      .then(() => history.push('/orders'));
  };

  render() {
    const {
      bookingCart: {
        order,
        total,
        reservationGroup
      },
      orderStore,
      ...rest
    } = this.props;

    const listItems = reservationGroup.reservations.map(reservation => (
      <ReservationListItem
        key={reservation.id}
        order={order}
        reservation={reservation}
        onEdit={this.handleEdit}
        onRemove={this.handleRemove}
      />
    ));

    return (
      <Wrapper {...rest}>
        {listItems}

        <Total
          total={total}
          hasSubmitButton
          loading={orderStore.isPending}
          onSubmit={this.handleSubmit}
        />

        {this.isOpenned && (
          <RemoveDialog
            isOpened
            onClose={this.handleOnClose}
            reservation={this.removeReservation}
          />
        )}
      </Wrapper>
    );
  }
}

export default styled(Center)``;
