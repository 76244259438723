import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';

import { ProviderState } from 'components/ui';

const Wrapper = styled.div`
  padding: 9px 0;
`;

@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get code() {
    const { order: { provider_state: { code } } } = this.props;
    return code;
  }

  @computed get variant() {
    const { code } = this;

    switch (code) {
      case 'created':
        return 'secondary';

      case 'booked':
        return 'primary';

      case 'confirmed':
        return 'success';

      case 'cancelled':
        return 'danger';

      default:
        return 'secondary';
    }
  }

  render() {
    const { className } = this.props;

    const { code, variant } = this;

    return (
      <Wrapper className={className}>
        <ProviderState
          code={code}
          variant={variant}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
