import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/ui/Modal';
import { DialogHotel } from 'pages/Orders/components';

class HotelInfoDialog extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    isOpened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func
  }

  handleClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  }

  render() {
    const { id, isOpened, onClick } = this.props;

    return (
      <Modal
        opened={isOpened}
        onClose={this.handleClose}
      >
        <DialogHotel id={id} showHotelButton onClick={onClick} />
      </Modal>
    );
  }
}

export default HotelInfoDialog;
