import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { SubTitle } from 'components/ui';
import { Date } from 'components/ui/Date';
import { BlockShadow } from 'components/atoms';
import { AccountTable } from 'pages/Settings/components';

const Wrapper = styled(BlockShadow)`
  ${SubTitle} {
    margin-top: 8px;
    text-transform: uppercase;
  }

  ${AccountTable} {
    margin-top: 24px;
  }

  ${AccountTable.Cell} {
    width: 20%;
  }
`;

class Contract extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    contract: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      contract: {
        created_at,
        end_at,
        format,
        legal_entity,
        contract_code,
        start_at,
        type
      },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <SubTitle>
          {contract_code} — {legal_entity}
        </SubTitle>

        <AccountTable>
          <AccountTable.Row isHighlighted>
            <AccountTable.Cell
              label={t('Settings.Contracts.List.SignedAt')}
              value={(
                <Date>
                  {created_at}
                </Date>
              )}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.List.StartAt')}
              value={(
                <Date>
                  {start_at}
                </Date>
              )}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.List.EndAt')}
              value={(
                <Date>
                  {end_at}
                </Date>
              )}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.List.ContractType')}
              value={type}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.List.ContractFormat')}
              value={format}
            />
          </AccountTable.Row>
        </AccountTable>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Contract))``;
