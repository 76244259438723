import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';

import Tariffs from '../../Tariffs';

const Header = styled.div`
  background-color: #E8ECF1;
  margin-right: -20px;
  margin-left: -10px;
  padding: 8px 20px 8px 10px;
  border-radius: 3px;

  color: #6B707B;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Header} + ${Tariffs} {
    margin-top: 15px;
  }
`;

@inject('bookingState')
@observer
class RoomType extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired,
    room_type: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { hotel, room_type, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Header>
          {room_type.name}
        </Header>

        <Tariffs
          hotel={hotel}
          room_type={room_type}
        />
      </Wrapper>
    );
  }
}

export default styled(RoomType)``;
