import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import HotelCard from 'components/ui/HotelCard';
import { Line } from 'src/components/ui';
import { display, typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'flex-start', 'space-between')};
  flex-wrap: wrap;
  width: 960px;
  margin-bottom: 48px;
`;

const SubTitle = styled.div`
  margin-top: 32px;
  ${typography(20, 24, 700)};
`;

const HotelCardS = styled(HotelCard)`
  width: calc(50% - 10px);
  margin: 10px 0;
`;

@inject('hotelsStore', 'searchState')
@observer
class FavoriteHotels extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    favoriteHotelsStore: PropTypes.object,
    searchState: PropTypes.object,
    hotelsStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isFetching() {
    const { hotelsStore } = this.props;
    return hotelsStore.isPending;
  }

  @computed get hotelsList() {
    const { hotelsStore } = this.props;
    return hotelsStore.data.toJSON();
  }

  handleFavoriteHotelSubmit(hotel) {
    const { searchState } = this.props;
    searchState.$('hotel').update(hotel);
    searchState.submit();
  }

  render() {
    const { searchState, t, ...rest } = this.props;

    if (!this.isFetching && this.hotelsList.length > 0) {
      return (
        <Wrapper {...rest}>
          <SubTitle>
            {t('Orders.Hotels.FavoriteHotels')}
          </SubTitle>

          <Line />

          {this.hotelsList.map((hotel, id) => (
            <HotelCardS
              key={id}
              hotel={hotel}
              onClick={() => this.handleFavoriteHotelSubmit(hotel)}
            />
          ))}
        </Wrapper>
      );
    }

    return null;
  }
}

export default styled(withTranslation()(FavoriteHotels))``;
