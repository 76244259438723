import React from 'react';
import { observer } from 'mobx-react';
import { Switch, Route, withRouter } from 'react-router-dom';

import { Contacts, ContractsLegal, ContractsList, General, Users } from './containers';

@withRouter
@observer
class Settings extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path='/settings'
          component={General}
        />

        <Route
          exact
          path='/settings/users'
          component={Users}
        />

        <Route
          exact
          path='/settings/contacts'
          component={Contacts}
        />

        <Route
          exact
          path='/settings/contracts'
          component={ContractsLegal}
        />

        <Route
          exact
          path='/settings/contracts/list'
          component={ContractsList}
        />
      </Switch>
    );
  }
}

export default Settings;
