import cleanDeep from 'clean-deep';

function pickFilterAttrs(attrs) {
  const {
    chars,
    accommodation_features,
    amenities,
    location_types,
    meal_options,
    treatment_factors,
    treatment_profiles,
    hotel_types,
    price,
    promo
  } = attrs;

  const filter = {
    chars,
    accommodation_features,
    amenities,
    location_types,
    meal_options,
    treatment_factors,
    treatment_profiles,
    hotel_types,
    price,
    promo
  };

  return { filter };
}

function pickSortAttrs(attrs) {
  const { sort_by, direction } = attrs;
  const sort = { sort_by, direction };

  return { sort };
}

function pickPaginateAttrs(attrs) {
  const { page, limit } = attrs;
  const paginate = { page, limit };

  return { paginate };
}

function pickTravellersAttrs(attrs) {
  const travellers = attrs.map(t => {
    const { age_group, age } = t;
    return { age_group, age };
  });

  return { travellers };
}

function pickAttrs(query) {
  const {
    check_in,
    check_out,
    address,
    hotel_ids,
    travellers,
    filter,
    sort,
    rating,
    paginate
  } = query;

  return {
    check_in,
    rating,
    check_out,
    address,
    hotel_ids,
    ...pickTravellersAttrs(travellers),
    ...pickFilterAttrs(filter),
    ...pickSortAttrs(sort),
    ...pickPaginateAttrs(paginate)
  };
}

function cleanQuery(query) {
  const options = {
    emptyArrays: false
  };

  let value = pickAttrs(query);
  value = cleanDeep(value, options);

  return value;
}

export default cleanQuery;
