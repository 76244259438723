import React from 'react';
import { withTranslation } from 'react-i18next';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.article``;

@withTranslation()
@inject('hotelsInfo')
@observer
class Specializations extends React.Component {
  @computed get specializations() {
    const { hotelsInfo } = this.props;
    const { specializations } = hotelsInfo && hotelsInfo.data && hotelsInfo.data.toJSON();
    return specializations;
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <p>
          {t('Hotel.Details.Specializations.Caption')}
        </p>
        <ul>
          {this.specializations.map(({ code, name }) => (
            <li key={code}>
              {name}
            </li>
          ))}
        </ul>
      </Wrapper>
    );
  }
}

Specializations.propTypes = {
  className: PropTypes.string,
  hotelsInfo: PropTypes.object,
  t: PropTypes.func
};

Specializations.defaultProps = {
  className: ''
};

export default styled(Specializations)``;
