import { types, applySnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';
import cleanDeep from 'clean-deep';

export const Address = types.model('Address', {
  location: types.string,
  coordinates: types.array(types.number)
});

const User = types.model('User', {
  id: types.identifier,
  first_name: types.string,
  last_name: types.string,
  middle_name: types.string,
  email: types.string,
  phone: types.string,
  role: types.string
});

const LegalEntity = types.model('LegalEntity', {
  id: types.identifier,
  accounting_code: types.string,
  bank: types.string,
  bank_code: types.string,
  correspondent_account: types.string,
  legal_address: types.maybeNull(Address),
  name: types.string,
  payment_account: types.string,
  phone: types.string,
  position: types.string,
  reg_number: types.string,
  real_address: types.maybeNull(Address),
  signatory: types.string,
  tin: types.string
});

const Contract = types.model('Contract', {
  id: types.identifier,
  created_at: types.string,
  end_at: types.string,
  format: types.string,
  legal_entity: types.string,
  contract_code: types.string,
  start_at: types.string,
  type: types.string
});

const GeneralSettings = types.model('GeneralSettings', {
  autofill: types.number,
  extra_charge: types.number
});

const ContractsSettings = types.model('ContractsSettings', {
  contracts: types.optional(types.array(Contract), []),
  legal_entities: types.optional(types.array(LegalEntity), [])
});

const Settings = types.model('Settings', {
  general: GeneralSettings,
  contracts: ContractsSettings,
  users: types.optional(types.array(User), [])
});

const SettingsStore = types
  .model('SettingsStore', {
    data: types.maybeNull(Settings),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })

  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    }
  }))

  .actions(self => ({
    fetch() {
      self.setState('pending');

      return instance.get('/api/settings')
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    create(values = {}) {
      self.setState('pending');

      const data = cleanDeep(values, { emptyArrays: false });

      return instance.post('/api/orders', { data })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    update(values = {}) {
      self.setState('pending');

      const { id, ...data } = cleanDeep(values, { emptyArrays: false });

      return instance.put(`/api/orders/${id}`, { data })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(self, data);
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    clear() {
      applySnapshot(self, {});
    }
  }));

export default SettingsStore;
