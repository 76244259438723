import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
`;

class Navigatioon extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        Navigation
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Navigatioon))``;
