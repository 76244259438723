import { types } from 'mobx-state-tree';

import { Insurant } from 'stores/models';

const GroupByTariff = types.model('GroupByTariff', {
  name: types.string,
  description: types.string,
  insurant: Insurant
});

export default GroupByTariff;
