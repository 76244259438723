import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Edit, Delete } from 'components/icons';
import { Cancel } from 'pages/Orders/containers';

const Description = styled.div`
  color: #3AA6D2;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
`;

const PlatformState = styled.div`
  color: #979BA0;
  font-size: 13px;
  line-height: 15px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

const Control = styled.div`
  cursor: pointer;

  & + & {
    margin-left: 12px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;

  ${PlatformState} + ${Controls} {
    margin-left: 20px;
  }
`;

const Room = styled.div`
  color: #4A515C;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

const Amount = styled.div`
  color: #4A515C;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Top} + ${Bottom} {
    margin-top: 12px;
  }
`;

@inject('showState')
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired,
    reservation: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleEdit = (e) => {
    const { reservation, showState } = this.props;
    showState.editReservation(reservation);
  }

  handleCancel = (e) => {
    const { reservation, showState } = this.props;
    return showState.cancelReservation(reservation);
  }

  render() {
    const { reservation, t, ...rest } = this.props;

    const { price, state, tariff, room_type, dates_of_stay, seats_count, duration } = reservation;

    return (
      <Wrapper {...rest}>
        <Top>
          <Description>
            {dates_of_stay}
            &nbsp;&nbsp;•&nbsp;&nbsp;
            {t('Orders.Guests', { count: seats_count })}
          </Description>

          <Actions>
            {false && (
              <PlatformState>
                Синхронизирован
              </PlatformState>
            )}

            {state !== 'cancelled' && (
              <Controls>
                <Control
                  onClick={this.handleEdit}
                >
                  <Edit />
                </Control>

                <Control>
                  <Cancel
                    title={t('Order.Reservation.Remove.Caption')}
                    acceptText={t('Order.Reservation.Remove.Accept')}
                    declineText={t('Order.Reservation.Remove.Cancel')}
                    onAccept={this.handleCancel}
                    onDecline={() => {}}
                  >
                    <Delete />
                  </Cancel>
                </Control>
              </Controls>
            )}
          </Actions>
        </Top>

        <Bottom>
          <Room>
            {tariff.name}
            <br />
            {room_type.name}
          </Room>

          <Amount>
            {t('UI.Price', { price })}
            &nbsp;/&nbsp;
            {t('UI.Duration', { ...duration })}
          </Amount>
        </Bottom>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Header))``;
