import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Description from './Description';
import OrderAndChat from './OrderAndChat';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  ${Description} {
    flex-grow: 1;
  }

  ${OrderAndChat} {
    width: 370px;
  }

  ${Description} + ${OrderAndChat} {
    margin-left: 20px;
  }
`;

@inject('bookingState')
@observer
class Bottom extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingState: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { bookingState, ...rest } = this.props;
    const { hotel, room_type, tariff, hasOrder } = bookingState;

    return (
      <Wrapper {...rest}>
        {(hotel && room_type && tariff) && (
          <>
            <Description
              hotel={hotel}
              room_type={room_type}
              tariff={tariff}
            />
          </>
        )}

        {hasOrder && (
          <OrderAndChat />
        )}
      </Wrapper>
    );
  }
}

export default styled(Bottom)``;
