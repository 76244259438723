import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { display, flexChildActualWidth, transition } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', null, 'space-between')}
  width: 100%;
  height: 144px;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.colors.light};
  `}

  ${transition()};
`;

const Details = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 16px 8px 16px 16px;
`;

const Visual = styled.div`
  ${flexChildActualWidth(144)}
  ${display('flex', 'flex-end')}
  padding: 16px 16px 16px 8px;
`;

const AddressStub = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.passive.primary};

    ::before {
      content: "";
      display: block;
      height: 14px;
      margin: 5px 0;
      background: currentColor;
    }

    ::before {
      width: 75%;
    }
  `}
`;

const TitleStub = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.passive.primary};

    ::before,
    ::after {
      content: "";
      display: block;
      height: ${theme.typography.h3.fontSize}px;
      margin: calc(${theme.typography.h3.lineHeight}px - ${theme.typography.h3.fontSize}px / 2) 0;
      background: currentColor;
    }

    ::before {
      width: 83%;
    }

    ::after {
      width: 71%;
    }
  `}
`;

const PhotoStub = styled.div`
  width: 112px;
  height: 72px;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.colors.passive.primary};
  `}
`;

@withTheme
class HotelCardStubPreview extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Details>
          <TitleStub />
          <AddressStub />
        </Details>
        <Visual>
          <PhotoStub />
        </Visual>
      </Wrapper>
    );
  }
}

export default styled(HotelCardStubPreview)``;
