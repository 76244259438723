import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  padding: 12px 0;
`;

const Item = styled.div`
  ${typography(14, 24)}
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
  `}
`;

@withTheme
class HotelSpecializations extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.string),
    theme: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { items, theme, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {items.map((item, index) => (
          <Item
            key={index}
            theme={theme}
          >
            {item}
          </Item>
        ))}
      </Wrapper>
    );
  }
}

export default styled(HotelSpecializations)``;
