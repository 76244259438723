import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import noPhotoSvgPath from 'assets/icons/no-photo.svg';

const Image = styled.img.attrs(({ src }) => ({
  src,
  width: 112,
  height: 72
}))`
  width: 112px;
  height: 72px;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.25s;

  ${({ isDisplay }) => isDisplay && css`
    opacity: 1;
  `}
`;

const Empty = styled.div`
  width: 112px;
  height: 72px;
  background: url(${noPhotoSvgPath}) no-repeat center center;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.25s;

  ${({ isDisplay }) => isDisplay && css`
    opacity: 1;
  `}
`;

const Wrapper = styled.div`
  height: 72px;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.colors.passive.primary};
  `}
`;

@withTheme
class HotelPhoto extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    theme: PropTypes.object,
    src: PropTypes.string
  };

  static defaultProps = {
    className: ''
  }

  state = {
    isImageDisplay: false,
    hasError: false
  };

  handleImageLoad = () => {
    this.setState({ isImageDisplay: true });
  }

  handleImageError = () => {
    this.setState({
      isImageDisplay: true,
      hasError: true
    });
  }

  render() {
    const { theme, src, ...rest } = this.props;
    const { hasError, isImageDisplay } = this.state;

    return (
      <Wrapper {...rest}>
        {hasError
          ? (
            <Empty
              isDisplay={isImageDisplay}
              theme={theme}
            />
          )
          : (
            <Image
              isDisplay={isImageDisplay}
              src={src}
              onError={this.handleImageError}
              onLoad={this.handleImageLoad}
            />
          )}
      </Wrapper>
    );
  }
}

export default styled(HotelPhoto)``;
