import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import Qs from 'qs';
import { Marker as PointElement, Popup, Tooltip } from 'react-leaflet';
import L from 'leaflet';

import Bubble from './Bubble';

const Wrapper = styled.div`
  width: max-content;
  height: max-content;
`;

function Marker({
  position,
  item,
  history,
  location,
  hotel,
  isActive,
  children,
  onHover,
  className,
  ...rest
}) {
  const handleModalOpen = (e) => {
    const qs = Qs.parse(location.search, { ignoreQueryPrefix: true });
    qs.hotel_id = item.id;

    history.push(`${location.pathname}?${Qs.stringify(qs)}`);
  };

  const icon = L.divIcon({
    iconSize: [32, 32],
    iconAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    className: isActive ? 'active-icon' : ''
  });

  return (
    <Wrapper {...rest}>
      <PointElement
        position={position}
        draggable={false}
        icon={icon}
      >
        <Tooltip
          permanent={false}
          direction='top'
          offset={[0, -30]}
        >
          <Bubble
            anchorPointY='bottom'
            hotel={item}
          />
        </Tooltip>

        <Popup
          onOpen={handleModalOpen}
        />
      </PointElement>
    </Wrapper>
  );
}

Marker.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  item: PropTypes.object.isRequired,
  position: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  location: PropTypes.object,
  hotel: PropTypes.object,
  onHover: PropTypes.func,
  history: PropTypes.object
};

Marker.defaultProps = {
  isActive: false
};

export default styled(withRouter(Marker))``;
