import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useTreatmentProfile } from 'stores/FiltersStore1/TreatmentProfileStore';
import { useTranslation } from 'react-i18next';

import { Heart } from 'components/icons';

import List, { Empty } from './List';

const Input = styled.input`
  width: 100%;
  height: 42px;
  cursor: pointer;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  padding-left: 44px;
  padding-right: 15px;
  font-size: 13px;
  box-sizing: border-box;
  line-height: 15px;
  background: #ffffff;
  box-sizing: border-box;
  font-family: inherit;
  transition: all 0.25s;

  &:focus {
    color: #4a515c;
    border-color: #abb3bf;
    box-shadow: none;
    outline: 0;

    &::placeholder {
      color: #d6d9df;
    }
  }

  ${({ hasSelected }) => hasSelected && css`
    padding-right: 36px;
  `}
`;

const InputContainer = styled.div`
  position: relative;
`;

const Popover = styled.div`
  width: auto;
  min-width: 100%;
  padding: 16px 18px 20px;
  background: #FFFFFF;
  border: 1px solid #5ab2d6;
  border-radius: 4px;
  box-shadow: -2px 2px 10px 0 rgba(102, 116, 166, 0.2);
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  position: relative;

  ${Popover} {
    position: absolute;
    top: 46px;
    left: 0;
    z-index: 9999;
  }
  
  ${Heart} {
    position: absolute;
    font-size: 18px;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
`;

function Targets({ value: initialValue, onSelect, className }) {
  const { t } = useTranslation();

  const [active, setActive] = useState(false);

  const inputRef = useRef();
  const [chars, setChars] = useState('');

  const [{ data, doFetch }] = useTreatmentProfile();

  const isFetched = useRef();

  useEffect(() => {
    if (isFetched.current) {
      return;
    }

    if (data.length > 0) {
      isFetched.current = true;
    }
  }, [data]);

  useEffect(() => {
    if (!isFetched.current) {
      return;
    }

    if (!initialValue) {
      setChars('');
      return;
    }

    const selected = data
      .find(item => item.tag === initialValue);

    if (selected) {
      setChars(selected.short_name);
    }
  }, [initialValue, isFetched.current]);

  const handleFocus = (e) => {
    inputRef.current.select();
    setActive(true);
  };

  const handleBlur = (e) => {
    setActive(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setChars(value);

    const data = value.length > 0 ? { chars: value } : {};
    doFetch({ data });
  };

  const handleSelect = (item) => {
    const { tag, short_name } = item;
    setChars(short_name);
    onSelect(tag);
  };

  return (
    <Wrapper className={className}>
      <InputContainer isActive={active}>
        <Heart />

        <Input
          ref={inputRef}
          value={chars}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={t('Filters.Disease.Placeholder')}
          // eslint-disable-next-line no-use-before-define
          autoСomplete='off'
        />
      </InputContainer>

      {active && (
        <Popover>
          {data.length > 0
            ? (
              <List
                title={t('Common.TreatmentProfiles')}
                items={data}
                value={chars}
                onSelect={handleSelect}
              />
            ) : (
              <Empty
                title={t('Filters.Disease.List.Empty')}
              />
            )}
        </Popover>
      )}
    </Wrapper>
  );
}

Targets.propTypes = {
  value: PropTypes.string,
  onSelect: PropTypes.func,
  className: PropTypes.string
};

Targets.defaultProps = {
  value: null,
  onSelect: () => { }
};

export default styled(Targets)``;
