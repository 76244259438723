import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Close, YellowAttentionIcon, RedAttentionIcon } from 'components/icons';

const MessageWrapper = styled.div`
  display: flex;
  max-width: 294px;
  padding: 16px;
  border-radius: 4px;
  background: ${(props) => (props.showYellowAttention ? '#fdf8f1' : '#FDF1F1')};
  margin-right: 20px;
  margin-bottom: ${(props) => (!props.showYellowAttention && '20px')};;
`;

const Message = styled.div`
  color: ${(props) => (props.showYellowAttention ? '#eeb868' : '#E26161')};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 8px;
  max-width: 300px;
`;

const AttentionIconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

const CloseIconWrapper = styled.div`
  width: 12px;
  height: 12px;
  cursor: pointer;
`;
export const Attention = ({ count }) => {
  const MIN_ORDER_NUMBERS = Number(process.env.REACT_APP_MIN_ORDER_NUMBERS);
  const MAX_ORDER_NUMBERS = Number(process.env.REACT_APP_MAX_ORDER_NUMBERS);

  const [show, setShow] = useState(MIN_ORDER_NUMBERS);
  const showYellowAttention =
    count >= MIN_ORDER_NUMBERS && count <= MAX_ORDER_NUMBERS - 1;
  const showRedAttention = count >= MAX_ORDER_NUMBERS;

  const msg =
    showYellowAttention &&
    `В заказ можно добавить не более ${MAX_ORDER_NUMBERS} номеров. Добавлено ${count} из ${MAX_ORDER_NUMBERS}.`;
  const msg2 =
    showRedAttention &&
    `Вы добавили максимальное количество номеров: ${MAX_ORDER_NUMBERS} из ${MAX_ORDER_NUMBERS}.
Для оставшихся номеров оформите отдельный заказ.`;

  return (
    ((showYellowAttention && show) || (showRedAttention && show)) && (
      <MessageWrapper showYellowAttention={showYellowAttention}>
        <AttentionIconWrapper>
          {showYellowAttention ? <YellowAttentionIcon /> : <RedAttentionIcon />}
        </AttentionIconWrapper>
        <Message showYellowAttention={showYellowAttention}>
          {showYellowAttention ? msg : msg2}
        </Message>
        <CloseIconWrapper onClick={() => setShow(false)}>
          <Close />
        </CloseIconWrapper>
      </MessageWrapper>
    )
  );
};

Attention.propTypes = {
  count: PropTypes.number
};
