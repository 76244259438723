import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, observable, action, reaction } from 'mobx';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Pen as PenIcon,
  Search as SearchIcon,
  Success as SuccessIcon,
  Error as ErrorIcon
} from 'components/icons';

import { Spinner, Input, InputBuffer, Action, Popover, Switch, Wrapper } from '../components';

import { InsurantStore } from '../stores';

@withTranslation()
@inject('form')
@observer
class InsurantMode extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    focused: PropTypes.bool,
    store: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    handleEditMode: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.inputEl = React.createRef();
    this.bufferEl = React.createRef();

    this.store = InsurantStore.create();
  }

  componentDidMount() {
    this.addHandler();
    this.selectAndFocus();
  }

  componentWillUnmount() {
    this.removeHandler();
  }

  // Popover status
  @observable isOpened = false;

  @observable.ref insurants = [];

  @action
  setInsurants(value) {
    this.insurants = value;
  }

  @action setIsOpend(value) {
    this.isOpened = value;
  }

  // search, success, pending, error
  @observable.ref status = {
    code: 'edit',
    messages: []
  };

  @action
  setStatus({ code, messages } = { messages: [] }) {
    this.status = { code, messages };
  }

  @action
  unsetStatus() {
    this.status = { code: 'unknow', messages: [] };
  }

  @computed get isSearch() {
    const { code } = this.status;
    return code === 'search';
  }

  @computed get isSuccess() {
    const { code } = this.status;
    return code === 'success';
  }

  @computed get isPending() {
    const { code } = this.status;
    return code === 'pending';
  }

  @computed get isError() {
    const { code } = this.status;
    return code === 'error';
  }

  @computed get isEdit() {
    const { code } = this.status;
    return code === 'edit';
  }

  addHandler() {
    this._changeValue = reaction(
      () => this.value,
      (value) => this.setInputWidth(),
      { fireImmediately: true }
    );
  }

  removeHandler() {
    this._changeValue();
  }

  setInputWidth = () => {
    const { inputEl, bufferEl } = this;
    const width = bufferEl.current.clientWidth;

    inputEl.current.style.width = `${width}px`;
  }

  selectAndFocus() {
    const { inputEl } = this;

    inputEl.current.focus();
    inputEl.current.select();
  }

  handleSelect = async (contract) => {
    this.setIsOpend(false);
    this.setStatus({ code: 'pending' });
    await this.applyContract(contract);
    await this.validateContract();
    await this.submitForm();
    this.setStatus({ code: 'success' });
  }

  handleChange = async (e) => {
    const { form } = this.props;
    const { value } = e.target;

    form.$('chars').set(value);

    if (value.length < 3) {
      this.setStatus({ code: 'search' });
      this.setIsOpend(false);
      return;
    }

    try {
      this.setStatus({ code: 'pending' });
      await this.store.fetch({ chars: value });
      this.setInsurants(this.store.data.toJSON());
      this.setStatus({ code: 'search' });
      this.setIsOpend(true);
    } catch (error) {
      this.setIsOpend(false);
    }
  }

  handleError = (error) => {
    const { data: { messages } } = error.response;
    this.setStatus({ code: 'error', messages });

    setTimeout(() => this.setStatus({ code: 'search' }), 1000);
  }

  applyContract = (contract) => {
    const { form } = this.props;

    form.setContract(contract);
    return Promise.resolve(form);
  }

  validateContract = () => {
    const { form } = this.props;
    form.validate();
  }

  submitForm = () => {
    const { form } = this.props;

    const promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.setStatus({ code: 'edit' });
        form.submit();
      }, 1000);
    });

    return promise;
  }

  render() {
    const { store, form, handleEditMode, className, ...rest } = this.props;

    const { inputEl, handleChange, handleSearch, handleSelect, isOpened, insurants } = this;

    return (
      <Wrapper
        {...rest}
        bordered
        switched
        className={className}
      >
        <Input
          {...rest}
          ref={inputEl}
          {...form.$('chars').bind({ onChange: handleChange, placeholder: 'Страхователь' })}
        />

        <InputBuffer
          as='div'
          ref={this.bufferEl}
          {...rest}
        >
          {form.$('chars').value}
        </InputBuffer>

        {this.isSearch && (
          <Action
            {...rest}
            onClick={handleSearch}
          >
            <SearchIcon />
          </Action>
        )}

        {this.isPending && (
          <Action
            {...rest}
          >
            <Spinner {...rest} />
          </Action>
        )}

        {this.isSuccess && (
          <Action
            {...rest}
          >
            <SuccessIcon />
          </Action>
        )}

        {this.isError && (
          <Action
            {...rest}
          >
            <ErrorIcon />
          </Action>
        )}

        {this.isEdit && (
          <Action
            {...rest}
            onClick={(e) => handleEditMode('view')}
          >
            <PenIcon />
          </Action>
        )}

        {isOpened && (
          <Popover
            insurants={insurants}
            onSelect={handleSelect}
          />
        )}

        <Switch
          mode='edit'
          onChange={handleEditMode}
        />
      </Wrapper>
    );
  }
}

export default styled(InsurantMode)``;
