import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { typography } from 'theme/mixins';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div``;

const Title = styled.div`
  ${typography(12, 18)}
`;

const Content = styled.div`
  ${typography(13, 20)}
`;

@withTheme
class OrderTableCellHead extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    content: ReactComponentChildrenPropType,
    title: ReactComponentChildrenPropType,
    theme: PropTypes.object
  };

  render() {
    const { title, content, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {title && (
          <Title>
            {title}
          </Title>
        )}

        {content && (
          <Content>
            {content}
          </Content>
        )}
      </Wrapper>
    );
  }
}

export default styled(OrderTableCellHead)``;
