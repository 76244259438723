import React from 'react';
import { withTranslation } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Logo, PlusPageBlueIcon } from 'components/icons';
import Button from 'components/forms/Button';
import { Menu } from 'components/molecules';

import { flexCenterBetween, flexCenter, display } from 'theme/mixins';

import { UserBar } from './components';

import Tooltip from 'components/v2/ui/Tooltip';

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonText = styled.div`
  white-space: nowrap;
  line-height: 16px;
`;

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${flexCenterBetween()};
    height: 72px;
    background: ${theme.colors.active.secondary};
    color: ${theme.colors.light};
  `}

  ${Button} {
    min-width: 182px;
  }

  ${Menu} {
    flex-grow: 1;
  }

  ${UserBar} {
    margin-left: 24px;
  }

  ${PlusPageBlueIcon} {
    font-size: 24px;
    margin-right: 12px;
    cursor: pointer;
  }

  z-index: 9999;
`;

const InnerS = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center', 'space-between')};
    width: 100%;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;
  `}
`;

const InnerWrapper = styled.div`
  ${display('flex', 'center', 'space-between')};
  width: 100%;
  margin: 0 auto;
`;

const LogoWrapper = styled.div`
  ${flexCenter()};
  margin: 0 20px 0 0;
  font-size: 40px;
`;

@withRouter
@withTheme
class Header extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    showRequestButton: PropTypes.bool,
    location: ReactRouterPropTypes.location,
    menuItems: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    ),
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { location, t, showRequestButton, ...rest } = this.props;
    const navigation = [
      {
        id: '1',
        isActive: location.pathname === '/orders',
        title: t('Pages.Orders'),
        to: '/orders'
      },
      {
        id: '2',
        isActive: location.pathname === '/contracts',
        title: t('Pages.Contacts'),
        to: '/contracts'
      }
      // {
      //   id: '2',
      //   isActive: location.pathname === '/reports',
      //   title: t('Pages.Reports'),
      //   to: '/reports'
      // },
      // {
      //   id: '3',
      //   isActive: location.pathname === '/statistics',
      //   title: t('Pages.Statistics'),
      //   to: '/statistics'
      // },
      // {
      //   id: '4',
      //   isActive: location.pathname === '/finances',
      //   title: t('Pages.Finances'),
      //   to: '/finances'
      // },
      // {
      //   id: '5',
      //   isActive: location.pathname === '/settings',
      //   title: t('Pages.Settings'),
      //   to: '/settings'
      // }
    ];

    const handleGoForm = () => {
      window.open('https://forms.yandex.ru/u/612f9b6d4a429e6a0f4755bb/');
    };

    return (
      <Wrapper {...rest}>
        <InnerS>
          <InnerWrapper>
            <LogoWrapper>
              <NavLink to='/'>
                <Logo />
              </NavLink>
            </LogoWrapper>
            <Menu items={navigation} />
          </InnerWrapper>

          {showRequestButton && (
            <Tooltip
              placement='bottom'
              overlay={t('Header.GoForm')}
            >
              <Button
                rounded
                variant='whiteground'
                onClick={() => handleGoForm()}
              >
                <ButtonContent>
                  <PlusPageBlueIcon />

                  <ButtonText>
                    {t('Order.Actions.CreateOrder')}
                  </ButtonText>
                </ButtonContent>
              </Button>
            </Tooltip>
          )}

          <UserBar />
        </InnerS>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Header))``;
