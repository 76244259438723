import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

import { display, typography } from 'theme/mixins';

const Message = styled.div`
  ${({ position, theme }) => css`
    position: absolute;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%);
    width: 196px;
    padding: 8px;
    background: ${theme.colors.text.secondary};
    border: 1px solid ${theme.colors.text.primary};
    border-radius: 4px;
    color: ${theme.colors.light};
    ${typography(12, 16)}
    pointer-events: none;
    opacity: 0;

    ${position === 'top' && css`
      bottom: 0;
      transition:
        bottom 0.25s,
        opacity 0.25s;
    `}

    ${position === 'bottom' && css`
      top: 0;
      transition:
        top 0.25s,
        opacity 0.25s;
    `}
  `}
`;

const Container = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition:
    top 0.25s,
    bottom 0.25s;
`;

const Wrapper = styled.div`
  ${({ position }) => css`
    ${display('flex')}
    flex-direction: column;
    position: relative;
    cursor: help;
    
    :hover {
      z-index: 1;

      ${Container} {
        ${position === 'top' && css`
          top: 0;
        `}

        ${position === 'bottom' && css`
          bottom: 0;
        `}
      }

      ${Message} {
        opacity: 1;

        ${position === 'top' && css`
          bottom: 8px;
        `}

        ${position === 'bottom' && css`
          top: 8px;
        `}
      }
    }
  `}
`;

const Title = styled.div`
  padding: 4px 0;
  font-weight: 700;
`;

const Content = styled.div`
  padding: 4px 0;
`;

@observer
class Tooltip extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    title: PropTypes.string,
    content: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  messageRef = React.createRef();

  @observable position = 'top';

  componentDidMount() {
    window.addEventListener('resize', this.handlePositionChange);
    window.addEventListener('scroll', this.handlePositionChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handlePositionChange);
    window.removeEventListener('scroll', this.handlePositionChange);
  }

  handlePositionChange = () => {
    const { height, top } = this.messageRef.current.getBoundingClientRect();
    this.position = top - height > 0 ? 'top' : 'bottom';
  }

  render() {
    const { children, content, title, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        position={this.position}
      >
        {children}

        <Container>
          <Message
            ref={this.messageRef}
            position={this.position}
          >
            {title && (
              <Title>
                {title}
              </Title>
            )}

            {content && (
              <Content>
                {content}
              </Content>
            )}
          </Message>
        </Container>
      </Wrapper>
    );
  }
}

export default styled(Tooltip)``;
