import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { computed } from 'mobx';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.colors.text.primary};
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
  `}
`;

class Rating extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    rating: PropTypes.number
  }

  static defaultProps = {
    rating: 0
  }

  @computed get rating() {
    const { rating } = this.props;

    return rating.toLocaleString('ru-RU');
  }

  render() {
    const { className } = this.props;

    return (
      <Wrapper className={className}>
        {this.rating}
      </Wrapper>
    );
  }
}

export default styled(Rating)``;
