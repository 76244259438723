import { types, applySnapshot, getSnapshot } from 'mobx-state-tree';
import instance from 'connection/instance';

const Insurer = types.model('insurant', {
  begin_date: types.string,
  end_date: types.string,
  id: types.string,
  insured_inn: types.string,
  insured_kpp: types.string,
  insured_name: types.string,
  number: types.string,
  platform_id: types.string
});

const InsurersStore = types
  .model('InsurersStore', {
    data: types.optional(types.array(Insurer), []),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })
  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    },

    get selectOptions() {
      return getSnapshot(self.data);
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState('pending');

      return instance.get('/api/filters/insurants', { params })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(self, data);
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    }
  }));

export default InsurersStore;
