import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { typography } from 'theme/mixins';
import { BlockShadow, Block, Text } from 'components/atoms';

const Wrapper = styled(BlockShadow)`
  padding: 20px 16px;

  ${Text} {
    color: #4a515c;
    ${typography(16, 20, 700)};
  }

  ${Block} {
    margin-top: 20px;

    + ${Block} {
      margin-top: 18px;
    }

    ${Text} {
      ${typography(14, 16, 500)};

      + ${Text} {
        margin-top: 8px;
        ${typography(14, 16)};
      }
    }
  }
`;

@inject('orderStore')
@observer
class PaymentInfo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    orderStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get order() {
    const { orderStore } = this.props;
    return orderStore.data.toJSON();
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Text>
          {t('Order.PaymentInfo.Title')}
        </Text>

        <Block>
          <Text>
            {t('Order.PaymentInfo.TotalCost')}
          </Text>

          <Text>
            {t('UI.Price', { price: this.order.price })}
          </Text>
        </Block>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentInfo))``;
