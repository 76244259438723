import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { display, transition } from 'theme/mixins';

import { Rating } from 'components/atoms';
import {
  HotelPhoto,
  HotelPrice,
  HotelTitle,
  HotelCategories,
  HotelSpecializations
} from './components';

const Wrapper = styled(Link).attrs(({ link }) => ({ to: link }))`
  ${display('flex', null, 'space-between')}
  width: 100%;
  height: 240px;
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.2);

  ${({ theme }) => css`
    background: ${theme.colors.light};

    :hover {
      box-shadow: 0 2px 6px 0 ${theme.colors.button.primary};
    }
  `}

  ${transition()};
`;

const Details = styled.div`
  flex-grow: 1;
  ${display('flex', null, 'space-between')}
  flex-direction: column;
  padding: 16px 20px;
`;

const Controls = styled.div`
  flex-grow: 1;
  ${display('flex', 'align-items', 'space-between')}
`;

class HotelCardListItem extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      hotel, ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <HotelPhoto src={hotel.thumb} />

        <Details>
          <HotelTitle>
            <span>
              {hotel.name}
            </span>

            <Rating rating={hotel.rating} />
          </HotelTitle>

          <HotelCategories items={hotel.categories} />

          <HotelSpecializations items={hotel.specializations} />

          <Controls>
            <HotelPrice value={hotel.price} />
          </Controls>
        </Details>
      </Wrapper>
    );
  }
}

export default styled(HotelCardListItem)``;
