import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled, { css, withTheme } from 'styled-components';

import { FilterBar, Tabs, Top, Placeholder, Body } from '.';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: ${theme.pageContainer.width}px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;
  `}
`;

const OrdersTabs = styled(Tabs)`
  margin: 29px 0 32px;
`;

const OrdersFilterBar = styled(FilterBar)`
  margin-bottom: 12px;
`;

@withRouter
@withTheme
@inject('filtersState', 'ordersStore')
@observer
class Orders extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history,
    filtersState: PropTypes.object.isRequired,
    ordersStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get pagination() {
    const { ordersStore } = this.props;
    const { page, total } = ordersStore.meta.paginate;

    return { total, current: Number(page) };
  }

  @computed get orders() {
    const { ordersStore } = this.props;
    return toJS(ordersStore.data);
  }

  handleLoadMore = (page) => {
    const { ordersStore, filtersState } = this.props;
    const filter = filtersState.values();

    ordersStore.fetchMore({ page, filter });
  }

  render() {
    const { ordersStore, filtersState, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Top />

        {false && <OrdersTabs />}

        <OrdersFilterBar
          filters={filtersState.$('filters')}
        />

        {
          this.orders.length > 0
            ? (
              <Body
                orders={this.orders}
                pagination={this.pagination}
                onLoadMore={this.handleLoadMore}
              />
            )

            : (
              <Placeholder />
            )
        }
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Orders))``;
