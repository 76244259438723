import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reaction, computed, observable } from 'mobx';
import { Provider, observer, inject } from 'mobx-react';
import styled from 'styled-components';

import { Header, Messages, Loader, Control } from './components';

import { ChatForm } from './forms';

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
  box-sizing: border-box;

  ${Body} {
    flex-grow: 1;
  }

  ${Header} {
    flex-shrink: 0;
  }
`;

@inject('chatStore')
@observer
class Conversations extends Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired,
    chatStore: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props);

    // Initialize form
    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.chatForm = new ChatForm({}, { hooks });
  }

  componentDidMount() {
    const { chatStore } = this.props;

    this.connectionHandler = reaction(
      () => chatStore.isConnected,
      (isConnected) => isConnected && this.connectToRoom(),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this.connectionHandler();
    this.disconnectFromRoom();
  }

  @observable conference = undefined;

  @computed get isConnected() {
    return this.conference &&
      this.conference.isJoined;
  }

  connectToRoom = async () => {
    const { order, chatStore } = this.props;

    const conference = await chatStore.createConference(order);
    conference.connectToRoom();
    this.conference = conference;
  }

  disconnectFromRoom = () => {
    if (this.isConnected) {
      this.conference.disconnectFromRoom();
    }
  }

  handleSuccess = (form) => {
    const { conference, chatForm } = this;
    const values = chatForm.values();

    conference.sendMessage(values);
    chatForm.clear();
  }

  render() {
    const { className } = this.props;

    return (
      <Provider chatForm={this.chatForm}>
        <Wrapper className={className}>
          <Header />

          <Body>
            {this.isConnected
              ? <Messages conversation={this.conference} />
              : <Loader />
            }
          </Body>

          {true && (
            <Control conversation={this.conference} />
          )}
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(Conversations)``;
