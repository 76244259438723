import { reaction, computed } from 'mobx';
import BaseState from 'forms/BaseState';

import fields from './fields';

import { Field } from 'mobx-react-form';
import { ContractField, TravellerField } from 'forms/Fields';

class SearchForm extends BaseState {
  constructor(...props) {
    super(...props);

    reaction(
      () => this.$('hotel.address').value,
      address => this.setAddress(address)
    );
  }

  setAddress = (address = undefined) => {
    if (address) {
      this.$('address').set(address);
    }
  }

  @computed get requestParams() {
    const { check_in, check_out, address, filter, sort } = this.values();

    const travellers = [
      { age_group: 'adult', age: 0 }
    ];

    const data = {
      check_in,
      check_out,
      sort,
      filter,
      travellers
    };

    if (address && address.location) {
      data.address = address;
    }

    return data;
  }

  setup() {
    return fields;
  }

  options() {
    return {
      showErrorsOnClear: true,
      showErrorsOnChange: false,
      showErrorsOnBlur: false,
      validateOnChange: false,
      validateOnChangeAfterSubmit: true
    };
  }

  makeField(props) {
    const re = /^travellers.\d+$/;

    if (re.exec(props.path)) {
      return new TravellerField(props);
    }
    if (props.path === 'contract') {
      return new ContractField(props);
    }

    return new Field(props);
  }
}

export default SearchForm;
