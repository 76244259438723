import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { setDisplayName } from 'recompose';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { Button, Input, Checkbox, Field } from 'components/forms';
import { Form } from 'components/ui';
import { typography } from 'theme/mixins';

const RememberField = styled(Field)`
  display: inline-flex;
  align-items: center;
  flex-direction: row-reverse;

  label {
    color: #4a515c;
    ${typography(14, 16)};
  }
`;

const FieldRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  ${Field} {
    label {
      color: #4a515c;
      padding-bottom: 11px;
      display: inline-block;
      text-transform: uppercase;
      ${typography(12, 15, 700)};
    }
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${Button} + ${RememberField} {
    margin-left: 50px;
  }
`;

const Wrapper = styled.div`
`;

const Title = styled.div`
  color: #9cb4ca;
  text-align: center;
  margin-bottom: 59px;
  ${typography(40, 48, 700)};
`;

const ForgetWrapper = styled.div`
  position: relative;
`;

const Forget = styled(NavLink)`
  top: 0;
  right: 0;
  color: #3c6f9d;
  position: absolute;
  ${typography(14, 16)};
  text-decoration: none;
`;

@setDisplayName('LoginForm')
@inject('loginState')
@observer
class LoginForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    loginState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { loginState, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Title>Вход</Title>

        <Form form={loginState}>
          <FieldRow>
            <Field
              field={loginState.$('email')}
              component={Input}
            />
          </FieldRow>

          <FieldRow>
            <ForgetWrapper>
              <Forget to='/forget'>Забыли пароль?</Forget>
            </ForgetWrapper>

            <Field
              field={loginState.$('password')}
              component={Input}
            />
          </FieldRow>

          <Actions>
            <Button type='submit'>
              Войти
            </Button>

            <RememberField
              field={loginState.$('remember')}
              component={Checkbox}
            />
          </Actions>
        </Form>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(LoginForm))``;
