import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled, { css, withTheme } from 'styled-components';
import { typography, display, flexCenter } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex')};
  display: inline-flex;
  background: #9cb4ca;
`;

const Item = styled(NavLink)`
  ${({ isActive, theme }) => css`
    ${flexCenter()};
    width: 142px;
    height: 71px;
    color: ${theme.colors.light};
    text-decoration: none;
    ${typography(14, 17, 500)};

    ${isActive() && css`
      background: rgba(255, 255, 255, 0.15);
      cursor: default;
    `};

    ${!isActive() && css`
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    `}
  `}
`;

@withTheme
class Menu extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired
      })
    ),
    theme: PropTypes.object
  };

  static defaultProps = {
    className: '',
    items: []
  };

  render() {
    const { items, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {items.map(({
          title, isActive, to = '#', ...rest
        }, key) => (
          <Item
            {...rest}
            key={key}
            as={isActive && 'div'}
            to={to}
            isActive={() => isActive}
          >
            {title}
          </Item>
        ))}
      </Wrapper>
    );
  }
}

export default styled(Menu)``;
