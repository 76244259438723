import React from 'react';
import { observable, computed } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import moment from 'utils/moment';

import { Calendar as CalendarIcon } from 'components/icons';
import { Input } from 'components/forms';
import { Dropdown } from 'components/molecules';
import { Calendar } from 'components/organisms';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${Input} {
      background: ${theme.colors.light};
    }
  `}
`;

const Container = styled(Dropdown)`
  ${({ theme }) => css`
    &&& {
      position: relative;
      margin-top: 4px;
      border: 1px solid ${theme.colors.active.primary};
      border-radius: 4px;
    }
  `}
`;

@withTheme
@observer
class DateRangePicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    checkIn: PropTypes.object.isRequired,
    checkOut: PropTypes.object.isRequired,
    format: PropTypes.string,
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    format: 'D MMM, YYYY'
  };

  @observable isOpened = false

  @observable today = moment().toDate();

  @computed get rangeValue() {
    const { checkIn, checkOut } = this.props;

    return [
      moment(checkIn.value).toDate(),
      moment(checkOut.value).toDate()
    ];
  }

  @computed get rangeString() {
    const { checkIn, checkOut, format } = this.props;

    return [checkIn, checkOut]
      .map(date => moment(date.value))
      .filter(date => date.isValid())
      .map(date => date.format(format))
      .join(' – ');
  }

  setCheckIn = value => {
    const { checkIn } = this.props;

    if (value) {
      checkIn.set(value);
    }

    else {
      checkIn.clear();
    }
  }

  setCheckOut = value => {
    const { checkOut } = this.props;

    if (value) {
      checkOut.set(value);
    }

    else {
      checkOut.clear();
    }
  }

  open = () => {
    this.isOpened = true;
  }

  close = () => {
    this.isOpened = false;
  }

  handleChange = (_, value) => {
    const [from, to] = value;

    this.setCheckIn(from);
    this.setCheckOut(to);

    if (from && to) {
      const { onChange } = this.props;
      this.close();

      if (onChange) {
        onChange([from, to]);
      }
    }
  };

  handleCloseRequest = () => {
    if (!this.preventClose) this.close();

    this.preventClose = false;
  };

  renderAnchor = () => {
    const { autoFocus } = this.props;

    return (
      <Input
        autoFocus={autoFocus}
        value={this.rangeString}
        onFocus={() => {
          if (!this.isOpened) this.open();
        }}
        onMouseDown={() => {
          if (!this.isOpened) {
            this.open();
            this.preventClose = true;
          }
        }}
        iconLeft={<CalendarIcon />}
      />
    );
  };

  render() {
    const { checkIn, checkOut, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Container
          anchor={this.renderAnchor()}
          isOpened={this.isOpened}
          onRequestClose={this.handleCloseRequest}
        >
          <Calendar
            onChange={this.handleChange}
            today={this.today}
            range
            value={this.rangeValue}
            visibleMonths={2}
          />
        </Container>
      </Wrapper>
    );
  }
}

export default styled(DateRangePicker)``;
