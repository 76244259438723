import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Content, Control, Description, Title } from '.';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  ${Description}:not(:first-child) {
    margin-left: 16px;
  }
`;

class Unit extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired
  };

  static defaultProps = {
    className: ''
  };

  static Content = Content;

  static Control = Control;

  static Description = Description;

  static Title = Title;

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Unit)``;
