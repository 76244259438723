import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Button } from 'components/forms';
import { OrderOptions, Travellers } from 'pages/Orders/components';
import { display } from 'theme/mixins';

const Wrapper = styled.div`
  width: 720px;
  margin: 32px auto;
`;

const TravellersControls = styled.div`
  ${display('flex', 'center', 'flex-end')}
  margin-top: 32px;
`;

@inject('orderStore', 'searchState')
@observer
class AddTravellers extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isOpened: PropTypes.bool,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    orderStore: PropTypes.object,
    searchState: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get order() {
    const { orderStore } = this.props;
    return orderStore.data.toJSON();
  }

  handleSubmit = () => {
    const { searchState } = this.props;
    searchState.submit();
  }

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  render() {
    const { isOpened, searchState, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <OrderOptions>
          <OrderOptions.Header
            order={this.order}
            mode='travellers'
          />

          <OrderOptions.Content>
            <Travellers
              travellers={searchState.$('travellers')}
            />

            <TravellersControls>
              <Button
                rounded
                onClick={this.handleSubmit}
              >
                {t('Order.Next')}
              </Button>
            </TravellersControls>
          </OrderOptions.Content>
        </OrderOptions>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(AddTravellers))``;
