import cleanQuery from './cleanQuery';
import mergeQuery from '../mergeQuery';

import moment from 'utils/moment';

const DEFAULT_QUERY = {
  check_in: moment().add(7, 'days').format('YYYY-MM-DD'),
  check_out: moment().add(14, 'days').format('YYYY-MM-DD'),
  address: null,
  hotel_ids: [],
  travellers: [
    { age_group: 'adult', age: 0 }
  ],
  filter: {
    chars: null,
    accommodation_features: [],
    amenities: [],
    location_types: [],
    meal_options: [],
    treatment_factors: [],
    treatment_profiles: [],
    hotel_types: [],
    price: {
      min: 100,
      max: 100000
    }
  },
  sort: {
    sort_by: 'rating',
    direction: -1
  },
  rating: 0,
  paginate: {
    page: 1,
    limit: 50
  }
};

function updateQuery(current, patch) {
  let value = mergeQuery(current, patch);
  value = mergeQuery(DEFAULT_QUERY, value);
  value = cleanQuery(value);
  return value;
}

export default updateQuery;
