import { observable, computed } from 'mobx';
import store from 'store';
import instance from 'connection/instance';

import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import _debounce from 'lodash/debounce';

import { tabUniqId } from 'utils';

import { Strophe, $pres } from 'strophe.js';
import 'strophejs-plugin-pubsub';
import 'strophejs-plugin-muc';
import 'strophejs-plugin-roster';
import 'strophejs-plugin-rsm';
import 'strophejs-plugin-mam';

import Conference from './Conference';

const BOSH_LOCATION = process.env.REACT_APP_BOSH_LOCATION;
const XMPP_DOMAIN = process.env.REACT_APP_XMPP_DOMAIN;
const PRESENCE_INTERVAL = 25 * 1000; // 25 sec
const RECONNECT_INTERVAL = 30 * 1000; // 30 sec
const RESOURCE = tabUniqId('web');

class ChatStore {
  // CurrentUser
  @observable user = undefined;

  // Conference
  conferences = [];

  // Chat statuses
  @observable status = null

  @computed get isConnected() {
    return this.status === Strophe.Status.CONNECTED;
  }

  @computed get isConnecting() {
    return this.status === Strophe.Status.CONNECTING;
  }

  @computed get isDisconnected() {
    return this.status === Strophe.Status.DISCONNECTED;
  }

  @computed get isDisconnecting() {
    return this.status === Strophe.Status.DISCONNECTING;
  }

  @computed get isConnfail() {
    return this.status === Strophe.Status.CONNFAIL;
  }

  constructor() {
    this.connection = new Strophe.Connection(
      BOSH_LOCATION, { sync: false, keepalive: true }
    );
  }

  connect() {
    // Initialize user for connection
    this.initializeCurrentUser();

    if (!this.user) {
      console.log('Not user');
      return false;
    }

    // Try connect
    const options = [
      [this.user.jid, RESOURCE].join('/'),
      this.user.password,
      this.changeStatusHandler
    ];

    this.connection.connect(...options);
  }

  disconnect() {
    this.connection.disconnect();
    this.connection.flush();
    this.connection.reset();
  }

  reconnect = () => {
    console.log('try reconnect');
    if (this.isConnected) return true;
    this.connect();
  }

  tryReconnect = _debounce(this.reconnect, RECONNECT_INTERVAL);

  initializeCurrentUser() {
    const data = store.get('authStore');

    const {
      data: { email, first_name, last_name },
      token
    } = data;

    if (email && token) {
      const user = {
        jid: [email.split('@')[0], XMPP_DOMAIN].join('@'),
        nickname: [last_name, first_name].join(' '),
        password: token
      };

      this.user = user;
    }
  }

  changeStatusHandler = (status) => {
    this.status = status;

    switch (status) {
      case Strophe.Status.CONNECTED:
        this.addHandlers();
        console.log('connected');
        break;

      case Strophe.Status.CONNECTING:
        console.log('connecting');
        break;

      case Strophe.Status.DISCONNECTED:
        this.removeHandlers();
        console.log('disconnected');
        break;

      case Strophe.Status.DISCONNECTING:
        console.log('disconnected');
        break;

      case Strophe.Status.CONNFAIL:
        this.tryReconnect();
        console.log('fail');
        break;

      default:
        console.log('unknow connection status');
        this.disconnect();
    }
  }

  addHandlers() {
    this.sendPresenceHandler = setInterval(() => {
      this.sendPresence();
    }, PRESENCE_INTERVAL);
  }

  removeHandlers() {
    if (this.sendPresenceHandler) {
      clearInterval(this.sendPresenceHandler);
      this.sendPresenceHandler = undefined;
    }
  }

  async getConference(conference_id) {
    console.log('Get conference');

    try {
      // Find conference
      const conference = this.findConferenceById(conference_id);
      if (conference) return conference;

      // Fetch and add it to local collection
      return await this.fetchConference(conference_id);
    } catch (e) {
      console.log(e);
    }
  }

  createConference({ id, conference: attributes }) {
    let conference = _merge(attributes, {
      id,
      connection: this.connection,
      user: _pick(this.user, ['jid', 'nickname'])
    });

    conference = new Conference(conference);
    this.conferences.push(conference);

    return conference;
  }

  findConferenceById(conference_id) {
    return this.conferences
      .find(c => c.id === conference_id);
  }

  fetchConference(conference_id) {
    const promise = new Promise((resolve, reject) => {
      instance.get(`/api/orders/${conference_id}/conference`)
        .then(response => {
          const value = this.addConferenceToCollection(
            conference_id, response
          );

          resolve(value);
        })
        .catch(error => reject(error));
    });

    return promise;
  }

  addConferenceToCollection = (conference_id, response) => {
    let { conference } = response.data;

    conference = _merge(conference, {
      id: conference_id,
      connection: this.connection,
      user: _pick(this.user, ['jid', 'nickname'])
    });

    conference = new Conference(conference);
    this.conferences.push(conference);

    return conference;
  }

  sendPresence = () => {
    const presence = $pres();
    this.connection.sendPresence(presence);
  }
}

export default ChatStore;
