import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Loader from 'rambler-ui/Loader';

import { shortName } from 'utils';

import ManagerForm from './ManagerForm';

import useClickOutside from 'hooks/useClickOutside';

import { UserBlueIcon, Pen as PenIcon, Edit as EditIcon } from 'components/icons';

const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 1px solid #3AA6D2;
  font-size: 16px;
  line-height: 28px;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
`;

const ArrowContainer = styled.div`
  margin-left: 14px;
  cursor: pointer;
  width: 13px;
  font-size: 13px;
`;

const Manager = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${Avatar} + ${Manager}, ${Avatar} + ${ManagerForm} {
    margin-left: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30%;
`;

function Creator({ className, order: { creator }, isLoading, isDisabled, onChange, ...rest }) {
  const [opened, setOpened] = useState();

  const wrapperRef = useRef();

  useClickOutside(wrapperRef, () => setOpened(false));

  const handleToggle = e => {
    e.stopPropagation();
    setOpened(!opened);
  };

  const handleConfirm = (value) => {
    onChange(value);
  };

  return (
    <Wrapper {...rest}>
      <Content ref={wrapperRef}>
        <Avatar>
          <UserBlueIcon />
        </Avatar>

        {!opened
          ? (
            <Manager>
              {shortName(creator)}
            </Manager>
          )
          : (
            <ManagerForm
              onConfirm={handleConfirm}
              onClose={() => setOpened(false)}
            />
          )}

        {!isDisabled && (
          <ArrowContainer>
            {opened && (
              <PenIcon onClick={handleToggle} />
            )}

            {!opened && !isLoading && (
              <EditIcon onClick={handleToggle} />
            )}

            {isLoading && <Loader loading spinnerColor='#3AA6D2' />}
          </ArrowContainer>
        )}
      </Content>
    </Wrapper>
  );
}

Creator.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func
};

Creator.defaultProps = {
  className: '',
  isLoading: false,
  isDisabled: true
};

export default styled(Creator)``;
