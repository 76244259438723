import { types, destroy } from 'mobx-state-tree';

import Notification from './Notification';

const NotificationsList = types
  .model('NotificationsList', {
    notifications: types.optional(types.array(Notification), [])
  })
  .actions(self => ({
    add(notification) {
      self.notifications.push(notification);
    },

    remove(notification) {
      destroy(notification);
    }
  }));

export default NotificationsList;
