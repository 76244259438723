import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Cell } from '..';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  ${({ isHighlighted, theme }) => css`
    display: table-row;

    ${isHighlighted && css`
      background-color: ${theme.colors.passive.secondary};

      ${Cell} {
        :first-child {
          padding-left: 16px;
        }

        :last-child {
          padding-right: 16px;
        }
      }

      :not(:last-child) {
        ${Cell} {
          position: relative;

          ::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: ${theme.colors.popup.primary};
            opacity: 0.5;
          }

          :first-child::after {
            left: auto;
            right: 0;
            width: calc(100% - 16px);
          }

          :last-child::after {
            width: calc(100% - 16px);
          }
        }
      }
    `}
  `}
`;

@withTheme
class Row extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    isHighlighted: PropTypes.bool
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Row)``;
