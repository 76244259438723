import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { QueryWrapper as Places } from 'components/forms/Places';

function AddressField({ className, query, order, handleUpdate }) {
  return (
    <Places
      className={className}
      value={query.address?.label || order?.address?.location}
      chars={query.address?.location}
      onSelect={(values, hotel_ids) => handleUpdate({ address: values, hotel_ids })}
    />
  );
}

AddressField.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func,
  order: PropTypes.object
};

export default styled(AddressField)``;
