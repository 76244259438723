import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import { ChooseRooms } from '.';

import OrderStore from 'stores/OrdersStore/One';

@withRouter
@observer
class RoomType extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: ReactRouterPropTypes.match.isRequired
  };

  constructor(props) {
    super(props);

    this.orderStore = OrderStore.create();
  }

  componentDidMount() {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props;

    this.orderStore.fetch({ id });
  }

  handleSearchSuccess = (form) => {
    const values = form.values();

    this.orderStore.update(values)
      .then(store => this.navigateTo(store));
  }

  handleSearchError = (form) => {
    console.log(form.errors());
  }

  navigateTo = (store) => {
    const { history } = this.props;
    const url = ['/orders', store.data.id].join('/');

    history.replace(url);
  };

  @computed get isFetched() {
    return this.orderStore.isFetched;
  }

  render() {
    return (
      <Provider
        orderStore={this.orderStore}
      >
        <DefaultTemplate
          header={<Header />}
          body={this.isFetched && <ChooseRooms />}
        />
      </Provider>
    );
  }
}

export default RoomType;
