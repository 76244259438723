import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  padding: 4px 0;

  ${({ theme }) => css`
    font-size: ${theme.typography.h3.fontSize}px;
    line-height: ${theme.typography.h3.lineHeight}px;
    font-weight: ${theme.typography.h3.fontWeight};
    color: ${theme.colors.text.primary};
  `}
`;

@withTheme
class HotelTitle extends React.Component {
  static propTypes = {
    children: ReactComponentChildrenPropType,
    className: PropTypes.string
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(HotelTitle)``;
