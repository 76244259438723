import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { useOrder } from 'stores/OrdersStore/hooks';
import useQuery from 'hooks/useQuery';

import { Main } from 'components/ui';

import FiltersBar from 'components/ui/FilterBar';
import { useTranslation } from 'react-i18next';

const Title = styled.div`
  color: #9cb4ca;
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
`;

const Wrapper = styled(Main)`
  width: 960px;
  padding-left: 25px;
  padding-right: 25px;

  ${({ error }) => error && css`
    border-color: #e26161;
  `};
`;

function OrderForm({ searchState, ...rest }) {
  const [error, setError] = useState();
  const { t } = useTranslation();
  const [{ query }, { updateQuery, applyQuery }] = useQuery();
  const [, { createOrder }] = useOrder();

  const handleSubmit = async () => {
    setError(null);

    const { address, hotel_ids } = query;
    const contract = searchState.$('contract').value;

    if (hotel_ids?.length > 0) {
      query.hotel = {
        id: hotel_ids[0],
        address: address,
        name: address.location
      };
    }

    try {
      const { id } = await createOrder({ ...query, contract });

      const path = hotel_ids?.length > 0
        ? `/orders/${id}/edit`
        : `/orders/${id}/search`;

      applyQuery(path);
    } catch (e) {
      setError('error');
    }
  };

  return (
    <Wrapper
      error={error}
      {...rest}
    >
      <Title>
        {t('Orders.NewOrder')}
      </Title>

      <FiltersBar
        field={searchState.$('contract')}
        query={query}
        handleUpdate={updateQuery}
        hasBackButton={false}
        hasActions
        hasPrice={false}
        addressFieldWidth='360px'
        hasTreatments={false}
        onSubmit={handleSubmit}
      />
    </Wrapper>
  );
}

OrderForm.propTypes = {
  className: PropTypes.string,
  hasError: PropTypes.bool,
  orderStore: PropTypes.object.isRequired,
  searchState: PropTypes.object.isRequired,
  t: PropTypes.func
};

export default styled(OrderForm)``;
