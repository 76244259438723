import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css, withTheme } from 'styled-components';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';

import { Top, Unit } from 'pages/Settings/components';
import { NewUserForm, UsersList } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex')}
    flex-direction: column;
    flex-grow: 1;
    width: 880px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;

    ${Unit} {
      margin-top: 16px;
    }
  `}
`;

@withTheme
@inject('settingsStore')
@observer
class Settings extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    settingsStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isFetched() {
    const { settingsStore } = this.props;
    return settingsStore.isFetched;
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Top />

        <NewUserForm />

        {this.isFetched && (
          <UsersList />
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Settings))``;
