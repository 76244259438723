import BaseState from 'forms/BaseState';

import { fields } from '.';

class ContractInnForm extends BaseState {
  setup() {
    return fields;
  }
}

export default ContractInnForm;
