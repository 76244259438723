import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import Cancel from 'pages/Orders/containers/Cancel';
import Tooltip from 'components/v2/ui/Tooltip';
import { QuestionIcon } from 'components/icons';

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const DeclaredWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 56px;
  padding: 8px 16px;
  background: #e4e8ee;
`;

const Text = styled.div`
  color: #6b707b;
  font-size: 13px;
  line-height: 15px;
`;

const Overlay = styled.div`
  width: 180px;
`;

@withRouter
@inject('showState')
@observer
class PrimaryActions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired,
    isDeclared: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleEdit = (e) => {
    e.preventDefault();

    const { showState } = this.props;
    return showState.edit();
  };

  @computed get canEdit() {
    const {
      showState: { permissions }
    } = this.props;
    return permissions.update;
  }

  handleCancel = () => {
    const { showState } = this.props;
    return showState.cancel();
  };

  @computed get canCancel() {
    const {
      showState: { permissions }
    } = this.props;
    return permissions.cancel;
  }

  render() {
    const { t, isDeclared, ...rest } = this.props;
    const overlaytext = (
      <Overlay
        dangerouslySetInnerHTML={{
          __html: t('Order.Declared.Tooltip')
        }}
      />
    );

    return (
      <Wrapper {...rest}>
        {isDeclared ? (
          <DeclaredWrapper>
            <Text>{t('Order.Declared.Title')}</Text>
            <Tooltip placement='bottom' overlay={overlaytext}>
              <QuestionIcon />
            </Tooltip>
          </DeclaredWrapper>
        ) : (
          <>
            <Button
              rounded
              outline
              disabled={!this.canEdit}
              onClick={this.handleEdit}
            >
              {t('Order.AddRoom')}
            </Button>

            <Cancel
              title={t('Order.Remove.Caption')}
              acceptText={t('Order.Remove.Accept')}
              declineText={t('Order.Remove.Cancel')}
              onAccept={this.handleCancel}
              onDecline={() => {}}
              disabled={!this.canCancel}
            >
              <Button
                rounded
                outline
                disabled={!this.canCancel}
                variant='danger'
              >
                {t('Order.Cancel')}
              </Button>
            </Cancel>
          </>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PrimaryActions))``;
