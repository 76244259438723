import styled, { css } from 'styled-components';

import { Spinner as SpinnerComponent } from 'components/ui';
import Popover from './Popover';
import Switch from './Switch';

const Spinner = styled(SpinnerComponent)`
  ${({ dimension, t }) => {
    if (dimension === 'small') {
      return css`
        width: 12px;
        height: 12px;
        border-width: 2px;
      `;
    }

    if (dimension === 'medium') {
      return css`
        width: 13px;
        height: 13px;
        border-width: 2px;
      `;
    }

    if (dimension === 'big') {
      return css`
        width: 16px;
        height: 16px;
        border-width: 2px;
      `;
    }

    if (dimension === 'large') {
      return css`
        width: 19px;
        height: 19px;
        border-width: 2px;
      `;
    }
  }}
`;

const Action = styled.div`
  cursor: pointer;

  ${({ dimension, t }) => {
    if (dimension === 'small') {
      return css`
        font-size: 12px;
      `;
    }

    if (dimension === 'medium') {
      return css`
        font-size: 13px;
      `;
    }

    if (dimension === 'big') {
      return css`
        font-size: 16px;
      `;
    }

    if (dimension === 'large') {
      return css`
        font-size: 19px;
      `;
    }
  }}
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  min-width: 6em;
  overflow: hidden;
  text-overflow: ellipsis;

  ::placeholder {
    color: #979BA0;
    font-weight: normal;
  }

  ${({ dimension, t }) => {
    if (dimension === 'small') {
      return css`
        font-size: 13px;
        font-weight: normal;
        margin-right: 13px;
      `;
    }

    if (dimension === 'medium') {
      return css`
        font-size: 14px;
        font-weight: normal;
        margin-right: 14px;
      `;
    }

    if (dimension === 'big') {
      return css`
        font-size: 16px;
        font-weight: bold;
        margin-right: 14px;
        text-transform: uppercase;
        white-space: nowrap;
      `;
    }

    if (dimension === 'large') {
      return css`
        font-size: 22px;
        font-weight: bold;
        text-transform: uppercase;
        white-space: nowrap;
        margin-right: 22px;
      `;
    }
  }}

  ${({ variant }) => {
    if (variant === 'primary') {
      return css`
        color: #4A515C;
      `;
    }

    if (variant === 'secondary') {
      return css`
        color: #7892AA;
      `;
    }

    if (variant === 'danger') {
      return css`
        color: #E26161;
      `;
    }

    if (variant === 'success') {
      return css`
        color: #62CB94;
      `;
    }
  }}
`;

const InputBuffer = styled(Input)`
  position: absolute;
  display: block;
  width: auto !important;
  visibility: hidden;
  flex-grow: 0 !important;
  white-space: nowrap;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;

  ${Input} {
    flex-grow: 1;
  }

  ${Action} {
    flex-grow: 0;
  }

  ${Popover} {
    position: absolute;
    left: 0;
    bottom: -6px;
    min-width: 100%;
    transform: translateY(100%);
  }

  ${Switch} {
    position: absolute;
    left: 0;
    bottom: -6px;
    min-width: 100%;
    transform: translateY(100%);
  }

  ${({ switched }) => switched && css`
    margin-bottom: 24px;
  `}

  ${({ dimension }) => {
    if (dimension === 'small') {
      return css`
        width: 100%;
        height: 36px;
        border-width: 1px;
        border-style: solid;
      `;
    }

    if (dimension === 'medium') {
      return css`
        width: 100%;
        height: 42px;
        border-width: 1px;
        border-style: solid;
      `;
    }

    if (dimension === 'big') {
      return css`
        width: 100%;
        height: 38px;
        border-width: 1px;
        border-style: solid;
      `;
    }

    if (dimension === 'large') {
      return css`
        width: 100%;
        height: 46px;
        border-width: 2px;
        border-style: solid;
      `;
    }
  }}

  ${({ bordered, dimension }) => {
    if (dimension === 'small') {
      return bordered
        ? css`padding: 0 14px;`
        : css`padding: 0 14px 0 0;`;
    }

    if (dimension === 'medium') {
      return bordered
        ? css`padding: 0 20px;`
        : css`padding: 0 20px 0 0;`;
    }

    if (dimension === 'big') {
      return bordered
        ? css`padding: 0 18px;`
        : css`padding: 0 18px 0 0;`;
    }

    if (dimension === 'large') {
      return bordered
        ? css`padding: 0 22px;`
        : css`padding: 0 22px 0 0;`;
    }
  }}

  ${({ variant, bordered }) => {
    if (variant === 'primary') {
      return css`
        border-style: solid;
        border-color: #D1D5DB;
      `;
    }

    if (variant === 'secondary') {
      return css`
        border-style: solid;
        border-color: #7892AA;
      `;
    }

    if (variant === 'danger') {
      return css`
        border-style: solid;
        border-color: #E26161;
      `;
    }

    if (variant === 'success') {
      return css`
        border-style: solid;
        border-color: #62CB94;
      `;
    }
  }}

  ${({ bordered }) => {
    if (!bordered) {
      return css`
        border-color: transparent;
      `;
    }
  }}

  ${({ transparent }) => {
    if (!transparent) {
      return css`
        background-color: #FFF;
      `;
    }
  }}

  ${({ hasError }) => {
    if (hasError) {
      return css`
        border-style: solid;
        border-color: #E26161;
      `;
    }
  }}
`;

export { default as Popover } from './Popover';
export { default as Switch } from './Switch';

export { Spinner, Input, InputBuffer, Action, Wrapper };
