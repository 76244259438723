import React from 'react';
import { computed, when } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import { ViewOrder } from '.';
import HotelsInfo from '../HotelsInfo';

import OrderStore from 'stores/OrdersStore/One';
import HotelsInfoStore from 'stores/HotelsStore/Info';
import BookingForm from 'forms/Orders/BookingForm';

@withRouter
@observer
class Confirm extends React.Component {
  static propTypes = {
    match: ReactRouterPropTypes.match.isRequired
  };

  constructor(props) {
    super(props);

    this.bookingForm = new BookingForm();
    this.hotelsInfo = HotelsInfoStore.create();
    this.orderStore = OrderStore.create();
  }

  componentDidMount() {
    const {
      match: {
        params: {
          id
        }
      }
    } = this.props;

    this.orderStore.fetch({ id });

    this.hotelsInfoHandler = when(
      () => this.orderStore.isFetched,
      () => this.fetchHotelsInfo()
    );
  }

  componentWillUnmount() {
    this.hotelsInfoHandler();
  }

  fetchHotelsInfo = () => {
    const params = this.orderStore.data.hotel.toJSON();

    this.hotelsInfo.fetch(params);
  }

  @computed get hasOrder() {
    return !!this.orderStore.data;
  }

  render() {
    return (
      <Provider
        bookingForm={this.bookingForm}
        hotelsInfo={this.hotelsInfo}
        orderStore={this.orderStore}
      >
        <>
          <DefaultTemplate
            header={<Header />}
            body={this.hasOrder && <ViewOrder />}
          />

          <HotelsInfo />
        </>
      </Provider>
    );
  }
}

export default Confirm;
