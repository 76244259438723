import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'components/molecules';

import { Dots as DotsIcon } from 'components/icons';

const Menu = styled.div`
  box-sizing: border-box;
  border: 1px solid #5AB2D6;
  border-radius: 5px;
  background: transparent;
  position: relative;

  &:after {
    content: ''; 
    position: absolute;
    margin: auto;
    background: #fff;
    top: -3px;
    right: 16px;
    z-index: 1;
    width: 4px;
    height: 4px;
    transform: rotate(-135deg);
    border-right: 1px solid #5AB2D6;
    border-bottom: 1px solid #5AB2D6;
  }
`;

const MenuItem = styled.div`
  padding: 8px 20px;
  box-sizing: border-box;
  color: #4A515C;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;
  cursor: pointer;

  &:hover {
    background-color: #C5D2E2;
  }

  &:nth-child(1) {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:nth-last-child(1) {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const Control = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Wrapper = styled(Dropdown)`
  position: relative;
  padding: 0 !important;
  margin-left: 27px !important;
`;

function DropdownMenu({ className, onChange, menu, ...rest }) {
  const [isOpened, setIsOpened] = useState();

  const handleClose = () => {
    if (isOpened) {
      setIsOpened(false);
    }
  };

  const handleChange = (item) => {
    setIsOpened(false);

    onChange && onChange(item);
  };

  const handleToggleOpened = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Wrapper
      className={className}
      isOpened={isOpened}
      onClose={handleClose}
      contentPointX='right'
      anchor={(
        <Control
          {...rest}
          onClick={handleToggleOpened}
        >
          <DotsIcon />
        </Control>
      )}
    >
      <Menu
        onClick={handleChange}
        onEscKeyDown={handleClose}
      >
        {menu.map(({ label, onClick }, index) => (
          <MenuItem
            key={index}
            onClick={onClick}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  );
}

DropdownMenu.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  menu: PropTypes.array
};

DropdownMenu.defaultProps = {
  className: '',
  menu: []
};

export default styled(DropdownMenu)``;
