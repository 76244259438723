import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Edit, Delete, QuestionIcon } from 'components/icons';
import { Cancel } from 'pages/Orders/containers';
import Tooltip from '../../../../../../../../../components/v2/ui/Tooltip/Tooltip';

const Description = styled.div`
  color: #3aa6d2;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
`;

const PlatformState = styled.div`
  color: #979ba0;
  font-size: 13px;
  line-height: 15px;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

const Control = styled.div`
  cursor: pointer;

  & + & {
    margin-left: 12px;
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Room = styled.div`
  color: #4a515c;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

const Amount = styled.div`
  color: #4a515c;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  white-space: nowrap;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const DeclaredWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  border-radius: 56px;
  padding: 4px;
  background: #f2f4f6;
  position: absolute;
  right: 0;
  bottom: 24px;
`;

const Text = styled.div`
  color: #979ba0;
  font-size: 13px;
  line-height: 15px;
`;

const Overlay = styled.div`
  width: 180px;
`;

@inject('showState')
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    disabled: PropTypes.bool.isRequired,
    showState: PropTypes.object.isRequired,
    reservation: PropTypes.object.isRequired,
    isDeclared: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: '',
    disabled: false
  };

  handleEdit = (e) => {
    const { reservation, showState } = this.props;
    showState.editReservation(reservation);
  };

  handleCancel = (e) => {
    const { reservation, showState } = this.props;
    return showState.cancelReservation(reservation);
  };

  @computed get showControl() {
    const {
      reservation: { state },
      disabled,
      isDeclared
    } = this.props;
    return !isDeclared && !disabled && state !== 'cancelled';
  }

  render() {
    const { reservation, disabled, t, isDeclared, ...rest } = this.props;

    const { price, tariff, room_type, dates_of_stay, seats_count, duration } =
      reservation;
    const overlaytext = (
      <Overlay
        dangerouslySetInnerHTML={{
          __html: t('Order.Declared.Tooltip')
        }}
      />
    );

    return (
      <Wrapper {...rest}>
        <Top>
          <Description>
            {dates_of_stay}
            &nbsp;&nbsp;•&nbsp;&nbsp;
            {t('Orders.Guests', { count: seats_count })}
          </Description>

          <Actions>
            {false && <PlatformState>Синхронизирован</PlatformState>}

            {this.showControl && (
              <Controls>
                <Control onClick={this.handleEdit}>
                  <Edit />
                </Control>

                <Control>
                  <Cancel
                    title={t('Order.Reservation.Remove.Caption')}
                    acceptText={t('Order.Reservation.Remove.Accept')}
                    declineText={t('Order.Reservation.Remove.Cancel')}
                    onAccept={this.handleCancel}
                    onDecline={() => {}}
                  >
                    <Delete />
                  </Cancel>
                </Control>
              </Controls>
            )}
            {isDeclared && (
              <DeclaredWrapper>
                <Text>{t('Order.Declared.Title')}</Text>
                <Tooltip placement='bottom' overlay={overlaytext}>
                  <QuestionIcon />
                </Tooltip>
              </DeclaredWrapper>
            )}
          </Actions>
        </Top>

        <Bottom>
          <Room>
            {tariff.name}
            <br />
            {room_type.name}
          </Room>

          <Amount>
            {t('UI.Price', { price })}
            &nbsp;/&nbsp;
            {t('UI.Duration', { ...duration })}
          </Amount>
        </Bottom>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Header))``;
