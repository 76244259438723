import { toJS } from 'mobx';
import _omit from 'lodash/omit';

function pricesExcludingLastDay(prices) {
  const rest = prices.slice(0, -1);

  const lastDay = prices.slice(-1)[0];
  const prevDay = rest.slice(-1)[0];

  const coercedDay = { ...lastDay, ..._omit(prevDay, 'day') };

  return [...rest, coercedDay];
}

function coerceDailyPrices({ billing_hour, prices }) {
  const values = toJS(prices, { recurseEverything: true });

  if (billing_hour === 'day') {
    return values;
  }

  return pricesExcludingLastDay(values);
}

export default coerceDailyPrices;
