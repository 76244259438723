import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Reservation from './Reservation';

const Wrapper = styled.div`
  ${Reservation} + ${Reservation} {
    margin-top: 15px;
  }
`;

@inject('showState')
@observer
class Reservations extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { showState, t, ...rest } = this.props;
    const { selectedGroup: { reservations } } = showState;

    const listItems = reservations.map(reservation => (
      <Reservation
        key={reservation.id}
        reservation={reservation}
      />
    ));

    return (
      <Wrapper {...rest}>
        {listItems}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reservations))``;
