import { types } from 'mobx-state-tree';

const Hint = types.model('Hint', {
  type: types.string,
  payload: types.frozen()
});

const PricePeriod = types.model('PricePeriod', {
  value: types.number,
  unit: types.enumeration(['day', 'night'])
});

const AgePeriod = types.model('AgePeriod', {
  from: types.number,
  to: types.number
});

const AvailablePeriod = types.model('AvailablePeriod', {
  from: types.string,
  to: types.string
});

const StayPeriod = types.model('StayPeriod', {
  min_days: types.number
});

const Service = types.model('Service', {
  id: types.identifier,
  name: types.string,
  description: types.string,
  pace: types.string,
  pace_name: types.string,
  quantity: types.number
});

const Rate = types.model('Rate', {
  type: types.string,
  description: types.string,
  age_group: types.enumeration(['adult', 'child']),
  age_period: types.maybeNull(AgePeriod),
  occupation: types.enumeration(['main', 'extra']),
  count: types.number
});

export const Tariff = types.model('Tariff', {
  id: types.identifier,
  name: types.string,
  type: types.enumeration(['base', 'package', 'tour']),
  description: types.string,
  hints: types.optional(types.array(Hint), []),
  discount: types.optional(types.number, 0),
  discount_price: types.optional(types.number, 0),
  discount_percent: types.optional(types.number, 0),
  price: types.number,
  price_period: PricePeriod,
  available_period: AvailablePeriod,
  stay_period: StayPeriod,
  rates: types.array(Rate),
  services: types.array(Service),
  billing_hour: types.enumeration(['day', 'night']),
  price_calculation_rule: types.enumeration(['float', 'fixed'])
});
