import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import Qs from 'qs';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Image } from 'components/ui';
import { Block, BlockShadow, Text, Line, Link } from 'components/atoms';
import { Stub } from '.';

import { typography } from 'theme/mixins';

const Wrapper = styled(BlockShadow)`
  ${({ theme }) => css`
    padding: 0;

  ${Text}.title {
    color: ${theme.colors.text.primary};
    ${typography(16, 20, 700)};
  }

  ${Text}.address {
    margin: 16px 0;
  }

  ${Line} {
    margin: 12px 0;
  }

  ${Block} {
    ${Text}.name {
      ${typography(14, 16, 500)};
    }

    ${Text} + ${Text} {
      margin-top: 8px;
    }
  }

  ${Block} + ${Block} {
    margin-top: 16px;
  }

  ${Link} {
    margin-top: 20px;
    ${typography(14, 16)};
    display: inline-block;
  }

  ${Image} {
    border-radius: 4px 4px 0 0;
  }
  `}
`;

const Details = styled.div`
  padding: 20px 16px;
`;

@withRouter
@inject('hotelsInfo')
@observer
class HotelInfo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotelsInfo: PropTypes.object,
    t: PropTypes.func,
    history: ReactRouterPropTypes.history,
    location: ReactRouterPropTypes.location
  };

  static defaultProps = {
    className: ''
  };

  @computed get isFetched() {
    const { hotelsInfo } = this.props;
    return hotelsInfo.data;
  }

  @computed get hotel() {
    const { hotelsInfo } = this.props;
    return hotelsInfo.data.toJSON();
  }

  @computed get hotelPhone() {
    const { hotelsInfo } = this.props;
    const { contacts } = hotelsInfo.data;

    const phone = contacts.toJSON().find(contact => contact.type === 'phone');
    return phone ? phone.value : null;
  }

  @computed get hotelId() {
    const { location } = this.props;
    const { hotel } = Qs.parse(location.search, { ignoreQueryPrefix: true });
    return hotel || null;
  }

  @computed get hotelUrl() {
    const { location: { pathname } } = this.props;
    const queryString = Qs.stringify({ hotel_id: this.hotel.id });

    return [pathname, queryString].join('');
  }

  render() {
    const { location, t, ...rest } = this.props;

    if (this.isFetched) {
      return (
        <Wrapper {...rest}>
          <Image
            src={this.hotel.images[0].landscape_small.url}
            width='256'
            height='128'
            alt={this.hotel.name}
          />

          <Details>
            <Text className='title'>
              {this.hotel.name}
            </Text>

            <Text className='address'>
              {this.hotel.address.location}
            </Text>

            <Text>
              {this.hotelPhone}
            </Text>

            <Link to={`${location.pathname}?hotel_id=${this.hotel.id}`}>
              {t('Hotel.One.More')}
            </Link>
          </Details>
        </Wrapper>
      );
    }

    return (
      <Stub {...rest} />
    );
  }
}

export default styled(withTranslation()(HotelInfo))``;
