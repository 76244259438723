import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { display, flexChildActualWidth, transition } from 'theme/mixins';
import getImage from 'utils/getImage';

import { Rating } from 'components/atoms';
import {
  HotelAddress,
  HotelPhoto,
  HotelPrice,
  HotelTitle
} from './components';

const Wrapper = styled.div.attrs(({ link }) => ({ to: link }))`
  ${display('flex', null, 'space-between')}
  width: 100%;
  min-height: 144px;
  border-radius: 4px;
  cursor: pointer;

  ${({ active, theme }) => css`
    border: 2px solid ${theme.colors.popup.primary};
    background: ${theme.colors.light};

    :hover {
      border-color: ${theme.colors.active.primary};
      box-shadow: 0px 9px 15px rgba(19, 136, 184, 0.4);
    }
  `}

  ${({ active, theme }) => active && css`
    border-color: ${theme.colors.active.primary};
    box-shadow: 0px 9px 15px rgba(19, 136, 184, 0.4);
  `}

  ${transition()};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 8px 16px 16px;
`;

const Visual = styled.div`
  ${flexChildActualWidth(144)}
  ${display('flex', 'flex-end', 'space-between')}
  flex-direction: column;
  padding: 16px 16px 16px 8px;
`;

const Controls = styled.div`
  flex-grow: 1;
  ${display('flex', 'align-items', 'space-between')}
`;

const HotelAddressS = styled(HotelAddress)`
  flex-grow: 1;
`;

@withTheme
class HotelCardPreview extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    link: PropTypes.string,
    hotel: PropTypes.object,
    theme: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  getImgPath(item, size) {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  }

  render() {
    const { hotel, link, ...rest } = this.props;
    const thumbSize = { width: 233, height: 155 };
    const src = this.getImgPath(hotel.images[0], thumbSize);

    return (
      <Wrapper
        {...rest}
        as={link && Link}
      >
        <Details>
          <HotelTitle>
            {hotel.name}
          </HotelTitle>

          <HotelAddressS>
            {hotel.address.location}
          </HotelAddressS>

          <Controls>
            <HotelPrice
              value={hotel.price}
            />
          </Controls>
        </Details>

        <Visual>
          <Rating
            rating={hotel.rating}
          />

          <HotelPhoto
            src={src.src}
          />
        </Visual>
      </Wrapper>
    );
  }
}

export default styled(HotelCardPreview)``;
