import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import { AddTravellers } from '.';

import OrderStore from 'stores/OrdersStore/One';
import SearchState from 'forms/Hotels/SearchState';

@withRouter
@observer
class Travellers extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: ReactRouterPropTypes.match.isRequired
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.orderStore = OrderStore.create();
    this.searchState = new SearchState({}, { hooks });
  }

  componentDidMount() {
    const { match: { params: { id } } } = this.props;

    this.orderStore.fetch({ id })
      .then((store) => {
        const values = store.data.toJSON();
        this.searchState.update(values);
      });
  }

  handleSuccess = (form) => {
    const values = form.values();

    this.orderStore.update(values)
      .then(store => this.navigateTo(store));
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  navigateTo = (store) => {
    const { history, location: { state } } = this.props;

    const return_url = state?.return_url ||
      `/orders/${store.data.id}/choose_room_type`;

    history.replace(return_url);
  };

  @computed get hasOrder() {
    return !!this.orderStore.data;
  }

  render() {
    return (
      <Provider
        orderStore={this.orderStore}
        searchState={this.searchState}
      >
        <DefaultTemplate
          header={<Header />}
          body={this.hasOrder && <AddTravellers />}
        />
      </Provider>
    );
  }
}

export default Travellers;
