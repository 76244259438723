import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Spinner } from 'components/ui';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'center', 'center')}
  padding: 24px 0;
`;

class Loader extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Spinner size="small" />
      </Wrapper>
    );
  }
}

export default styled(Loader)``;
