import React from 'react';
import { AvailableBeds, StopSales, RoomsFreeBeds, DepartureClose, ArrivalClose, RemainingBeds, DepartureArrivalClose } from 'components/atoms';
import PropTypes from 'prop-types';
import { formatPrice } from 'utils';
import { typography } from 'theme/mixins';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import moment from 'utils/moment';

const Date = styled.div`
  ${typography(13, 17, 500)};

  .DayPicker-Day--selected & {
    color: #fff;
  }

  .DayPicker-Day--disabled & {
    color: #a6a6a6;
  }

  .DayPicker-Day--selected.DayPicker-Day--disabled & {
    color: #fff;
  }
`;

const Price = styled.div`
  ${typography(12, 14)};
  color: #4C4C4C;

  .DayPicker-Day--selected & {
    color: rgba(255, 255, 255, 0.7);
  }

  .DayPicker-Day--disabled & {
    color: rgba(76, 76, 76, 0.5);
  }

  .DayPicker-Day--selected.DayPicker-Day--disabled & {
    color: #fff;
  }

  ${props => props.price > 9999 && css`
    margin-left: -3px;
  `};
`;

const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding-left: 6px;
  border-radius: 2px;
  box-sizing: border-box;
`;

const CellEnabled = styled(Cell)`
  background-color: #ebf8fd;

  &:hover {
    background-color: #e4f1f6;
  }

  .DayPicker-Day--selected & {
    background-color: #5ab2d6;
  }
`;

const RestrictionsContainer = styled.div`
  position: absolute;
  top: -3px;
  right: -3px;
  width: 18px;
  height: 18px;
  z-index: 10;
`;

const CellDisabled = styled(Cell)`
  background-color: #e9f0f5;

  .DayPicker-Day--selected & {
    background-color: #E68686;
  }
`;

const CellRestrictionDisabled = styled(Cell)`
  background-color: #F4F8FA;

  .DayPicker-Day--selected & {
    background-color: #FAE2E2;

    div {
      color: #E26161 !important;
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 57px;
  height: 54px;

  ${Cell} {
    flex-grow: 1;
  }

  ${AvailableBeds} {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -3px;
    right: -3px;
    line-height: 15px;
    vertical-align: middle;
    text-align: center;
    border: 2px solid white;
    border-radius: 6px;

    ${props => (props.count < 1
    ? css`
          background-color: #9cb4ca;
        `
    : css`
         background-color: #5ab2d6;
        `
  )};
  }

  ${StopSales} {
    position: absolute;
    width: 18px;
    height: 18px;
    top: -3px;
    right: -3px;
  }

  ${RoomsFreeBeds} {
    position: absolute;
    width: 35px;
    height: 18px;
    top: -3px;
    right: -3px;
  }

  ${RoomsFreeBeds} + ${AvailableBeds} {
    display: none;
  }

  ${StopSales} + ${AvailableBeds} {
    display: none;
  }

  ${RemainingBeds} {
    position: absolute;
    width: 30px;
    height: 18px;
    top: -3px;
    right: -3px;
    display: ${(p) => p.stop_sales && 'none'};
  }

  ${RemainingBeds} + ${AvailableBeds} {
    display: none;
  }
`;

@observer
class Base extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    day: PropTypes.object.isRequired,
    price: PropTypes.number,
    available: PropTypes.number,
    stop_sales: PropTypes.bool,
    beds: PropTypes.number,
    available_beds: PropTypes.number
  }

  static defaultProps = {
    price: -1,
    available: -1,
    stop_sales: false,
    className: ''
  }

  get hasAvailability() {
    const { available_beds } = this.props;
    return available_beds > 0;
  }

  get roomsCount() {
    const { available } = this.props;
    return available;
  }

  get remainingBedsCount() {
    const { available, beds, available_beds } = this.props;
    return available_beds - available * beds;
  }

  get hasStopSales() {
    const { stop_sales } = this.props;
    return stop_sales;
  }

  get hasPrice() {
    const { price } = this.props;
    return price > 0;
  }

  get disabledByRestrictions() {
    const { restrictions } = this.props;

    const {
      closed_by_date_restrictions: {
        closed_to_arrival,
        closed_to_departure
      }
    } = restrictions;

    return closed_to_arrival || closed_to_departure;
  }

  get isDisabled() {
    return !this.hasPrice || !this.hasAvailability || this.hasStopSales;
  }

  get CellComponent() {
    if (this.isDisabled) return CellDisabled;

    if (this.disabledByRestrictions) return CellRestrictionDisabled;

    return CellEnabled;
  }

  render() {
    const { day, price, restrictions, className } = this.props;

    const dayNumber = moment(day).format('D');
    const {
      closed_by_date_restrictions: {
        closed_to_arrival,
        closed_to_departure
      },
      length_of_stay_restrictions: {
        max_length_of_stay,
        min_length_of_stay
      }
    } = restrictions;

    return (
      <Wrapper
        count={this.roomsCount}
        className={className}
        stop_sales={this.hasStopSales}
      >
        {this.hasStopSales && (
          <StopSales />
        )}

        {!this.hasStopSales && this.roomsCount === 0 && this.remainingBedsCount > 0 && (
          <RemainingBeds
            count={this.remainingBedsCount}
          />
        )}

        {!this.hasStopSales && this.disabledByRestrictions && (
          <RestrictionsContainer>
            {closed_to_arrival && !closed_to_departure && <ArrivalClose min={min_length_of_stay} max={max_length_of_stay} />}

            {closed_to_departure && !closed_to_arrival && <DepartureClose min={min_length_of_stay} max={max_length_of_stay} />}

            {closed_to_departure && closed_to_arrival && <DepartureArrivalClose min={min_length_of_stay} max={max_length_of_stay} />}
          </RestrictionsContainer>
        )}

        {!this.hasStopSales && this.roomsCount > 0 && this.remainingBedsCount > 0
          ? <RoomsFreeBeds
              count={this.roomsCount}
              roomsWithFreeBeds={this.remainingBedsCount}
            />

          : <AvailableBeds count={this.roomsCount} />
        }

        <Cell as={this.CellComponent}>
          <Date>{dayNumber}</Date>

          <Price {...{ price }}>
            {this.hasPrice ? formatPrice(price) : '-'}
          </Price>
        </Cell>
      </Wrapper>
    );
  }
}

export default styled(Base)``;
