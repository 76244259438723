import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { SecondaryBlock } from 'components/ui';

import { display, typography } from 'theme/mixins';

import { datesRange } from 'utils/dates';

const Wrapper = styled(SecondaryBlock)``;

const Header = styled.div`
  ${display('flex', 'center', 'space-between')}
`;

const DatesRange = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.secondary};
    ${typography(13, 16)}
  `}
`;

const RoomsCount = styled.div`
  ${({ theme }) => css`
    padding: 2px 8px;
    background: ${theme.colors.button.primary};
    border-radius: 99px;
    color: ${theme.colors.light};
    ${typography(11, 12)}
  `}
`;

const Body = styled.div`
  margin-top: 12px;
  ${typography(14, 18)}
`;

class ReservationGroup extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    reservationGroup: PropTypes.object.isRequired,
    isActive: PropTypes.bool,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleClick = () => {
    const { onClick, reservationGroup } = this.props;
    onClick(reservationGroup);
  }

  render() {
    const {
      reservationGroup: {
        check_in,
        check_out,
        room_type,
        rooms_count,
        tariff
      },
      isActive,
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper
        {...rest}
        onClick={this.handleClick}
        isActive={isActive}
      >
        <Header>
          <DatesRange>
            {datesRange(check_in, check_out, { isShortMonth: true })}
          </DatesRange>

          <RoomsCount>
            {t('Orders.Rooms', { count: rooms_count })}
          </RoomsCount>
        </Header>

        <Body>
          {t('Tariff.Room.Bed', { count: room_type.beds })}
          {' '}
          {room_type.name}
          <br />
          {tariff.name}
        </Body>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(ReservationGroup))``;
