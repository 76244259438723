import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import { Form, Main } from 'components/ui';
import { TextField, SelectField } from '.';

import UserForm from 'forms/Auth/UserForm';

const Wrapper = styled(Main)`
  ${Form} {
    margin-top: 12px;
  }
`;

const Control = styled(Form.RowCell)`
  width: auto;
`;

const Title = styled(Main.Title)``;

@observer
class NewUserForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.userForm = new UserForm({}, { hooks });
  }

  handleSuccess = (form) => {
    const values = form.values();

    console.log(values);
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Title>
          {t('Settings.Users.Add.Title')}
        </Title>

        <Form form={this.userForm}>
          <Form.Row>
            <Form.RowCell>
              <TextField field={this.userForm.$('last_name')} />
            </Form.RowCell>

            <Form.RowCell>
              <TextField field={this.userForm.$('first_name')} />
            </Form.RowCell>

            <Form.RowCell>
              <TextField field={this.userForm.$('middle_name')} />
            </Form.RowCell>
          </Form.Row>

          <Form.Row>
            <Form.RowCell>
              <TextField field={this.userForm.$('email')} />
            </Form.RowCell>

            <Form.RowCell>
              <TextField field={this.userForm.$('phone')} />
            </Form.RowCell>

            <Form.RowCell>
              <SelectField field={this.userForm.$('role')} />
            </Form.RowCell>

            <Control>
              <Button
                rounded
                size="small"
              >
                {t('UI.Button.Add')}
              </Button>
            </Control>
          </Form.Row>
        </Form>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(NewUserForm))``;
