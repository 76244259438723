import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import Base from './Base';
import Package from './Package';

@observer
class Day extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    day: PropTypes.object.isRequired,
    price: PropTypes.number,
    available: PropTypes.number,
    stop_sales: PropTypes.bool
  }

  static defaultProps = {
    price: -1,
    available: -1,
    stop_sales: false,
    className: ''
  }

  render() {
    const { type, ...rest } = this.props;

    switch (type) {
      case 'base':
        return <Base {...rest} />;

      case 'package':
        return <Package {...rest} />;

      default:
        return null;
    }
  }
}

export default styled(Day)``;
