import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.span`
  padding: 6px 0px;
  cursor: pointer;
  color: #6B707B;
  font-family: "Helvetica Neue";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 15px;

  &:hover {
    background: #EDEDED;
  }
`;

function Item({ item, onSelect, className }) {
  const handleClick = (e) => {
    onSelect(item);
  };

  return (
    <Wrapper
      className={className}
      onMouseDown={handleClick}
    >
      {item.short_name}
    </Wrapper>
  );
}

Item.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

Item.defaultProps = {
  onSelect: () => {}
};

export default styled(Item)``;
