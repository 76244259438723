import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed, reaction } from 'mobx';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Edit as EditIcon } from 'components/icons';
import { Input, InputBuffer, Action, Wrapper } from '../components';

@withTranslation()
@inject('form')
@observer
class ViewMode extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    form: PropTypes.object.isRequired,
    prefixed: PropTypes.bool,
    handleEditMode: PropTypes.func.isRequired,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    disabled: false
  };

  constructor(props) {
    super(props);

    this.inputEl = React.createRef();
    this.bufferEl = React.createRef();
  }

  componentDidMount() {
    this._changeValue = reaction(
      () => this.value,
      (value) => this.setInputWidth(),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this._changeValue();
  }

  @computed get value() {
    const { t, prefixed, form } = this.props;
    const contract = form.$('chars').value;

    return prefixed
      ? [t('Contract.Label'), contract].join(' ')
      : contract || t('Contract.Empty');
  }

  setInputWidth = () => {
    const { inputEl, bufferEl } = this;
    const width = bufferEl.current.clientWidth;

    inputEl.current.style.width = `${width}px`;
  }

  render() {
    const { form, handleEditMode, disabled, className, ...rest } = this.props;

    return (
      <Wrapper {...{ className, ...rest }}>
        <Input
          ref={this.inputEl}
          readOnly
          value={this.value}
          title={this.value}
          {...rest}
        />

        <InputBuffer
          as='div'
          ref={this.bufferEl}
          {...rest}
        >
          {this.value}
        </InputBuffer>

        {!disabled && (
          <Action
            {...rest}
            onClick={(e) => handleEditMode('edit')}
          >
            <EditIcon />
          </Action>
        )}
      </Wrapper>
    );
  }
}

export default styled(ViewMode)``;
