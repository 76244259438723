import cleanDeep from 'clean-deep';
import _pick from 'lodash/pick';

const orderAttributes = [
  'id',
  'check_in',
  'check_out',
  'address.location',
  'address.coordinates',
  'hotel.id',
  'hotel.name',
  'hotel.address',
  'hotel.address.location',
  'hotel.address.coordinates',
  'contract'
];

const reservationAttributes = [
  'id',
  'hotel.id',
  'room_type.id',
  'tariff.id',
  'check_in',
  'check_out',
  'contract',
  '_destroy'
];

const slotAttributes = [
  'id',
  'cash',
  '_destroy',
  'rate.type',
  'contract',
  'prices'
];

function mapSlots(slots = []) {
  return slots.map(slot => mapSlot(slot));
}

function mapSlot(slot) {
  let attributes = _pick(slot, slotAttributes);
  attributes = cleanDeep(attributes);

  return attributes;
}

function mapReservation(reservation) {
  let attributes = _pick(reservation, reservationAttributes);
  attributes = cleanDeep(attributes);

  const slots = mapSlots(reservation.slots);

  return { ...attributes, slots };
}

function updateParams({ order, reservation }) {
  let orderAttrs = _pick(order, orderAttributes);
  orderAttrs = cleanDeep(orderAttrs);

  const travellersAttrs = [];

  const { rooms_count } = reservation;
  const reservationAttrs = mapReservation(reservation);
  const reservations = new Array(rooms_count).fill(reservationAttrs);

  const params = {
    ...(orderAttrs),
    travellers: travellersAttrs,
    reservations: reservations
  };

  return params;
}

export { updateParams };
