import { t } from 'utils/localization';

const fields = [
  'contract',
  'contract.label',
  'contract.value'
];

const placeholders = {
  contract: t('Orders.Filters.Contract.Label')
};

const extra = {
  contract: [
    {
      label: t('Orders.TableItem.Contact.Available'),
      value: true
    },
    {
      label: t('Orders.TableItem.Contact.NotAvailable'),
      value: false
    }
  ]
};

export default {
  fields,
  placeholders,
  extra
};
