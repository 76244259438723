import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { Bubble as BaseBubble } from 'components/ui';

const Header = styled.div`
  color: #2c2c2c;
  font-size: 14px;
  line-height: 20px;
`;

const Price = styled.div`
  color: #4A515C;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled(BaseBubble)`
  display: flex;
  flex-direction: column;
  opacity: 1;

  ${Header} + ${Body} {
    margin-top: 10px;
  }
`;

@withTheme
class Bubble extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired,
    theme: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      hotel: {
        name,
        tariff: {
          price
        }
      },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <Header>
          {name}
        </Header>

        <Body>
          <Price>
            {t('UI.Price', { price: price.amount })}
          </Price>
        </Body>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Bubble))``;
