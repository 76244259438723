import BaseState from 'forms/BaseState';

import { fields } from '.';

class SettingsForm extends BaseState {
  setup() {
    return fields;
  }
}

export default SettingsForm;
