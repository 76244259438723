import { t } from 'utils/localization';

const fields = [
  'contract',
  'contract.id',
  'contract.number',
  'contract.price_prefix',
  'contract.insured_name',
  'contract.insured_inn',
  'contract.insured_kpp',
  'contract.begin_date',
  'contract.end_date'
];

const placeholders = {
  'contract.number': t('Form.ContractNumber.Label')
};

const values = {};

const types = {};

const rules = {};

export default {
  fields,
  rules,
  types,
  values,
  placeholders
};
