import {
  CheckRangeFilter, ContractFilter, HotelsFilter, ManagersFilter, OrderCodeFilter, StateFilter, TravellersFilter, InsurerFilter
} from './Filter';

import { t } from 'utils/localization';

const filters = [
  {
    type: 'check_range',
    label: t('Orders.Filters.CheckRangeDate.Label'),
    component: CheckRangeFilter
  },
  {
    type: 'traveller',
    label: t('Orders.Filters.Travellers.Label'),
    component: TravellersFilter
  },
  {
    type: 'hotel',
    label: t('Orders.Filters.Hotels.Label'),
    component: HotelsFilter
  },
  {
    type: 'manager',
    label: t('Orders.Filters.Managers.Label'),
    component: ManagersFilter
  },
  {
    type: 'insurant',
    label: t('Orders.Filters.Insurer.Label'),
    component: InsurerFilter
  },
  {
    type: 'order_code',
    label: t('Orders.Filters.OrderCode.Label'),
    component: OrderCodeFilter
  },
  {
    type: 'state',
    label: t('Orders.Filters.State.Label'),
    component: StateFilter
  },
  {
    type: 'contract',
    label: t('Orders.Filters.Contract.Label'),
    component: ContractFilter
  }
];

export default filters;
