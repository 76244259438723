import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { DoubleTitle } from 'components/molecules';
import { ContractField } from 'components/forms';

const Wrapper = styled.div`
  padding: 0 20px;

  ${ContractField} {
    min-width: 240px;
  }
`;

@withTranslation()
@inject('bookingForm')
@observer
class Insurance extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  handleChange = (value) => {
    const { bookingForm } = this.props;
    bookingForm.updateContract(value);
  }

  render() {
    const { bookingForm, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <DoubleTitle
          title={t('Order.Reservation.Contract.Label')}
          subTitle={t('Order.Reservation.Contract.Restriction')}
        >
          <ContractField
            bordered
            field={bookingForm.$('contract')}
            onChangeHandler={this.handleChange}
          />
        </DoubleTitle>
      </Wrapper>
    );
  }
}

export default styled(Insurance)``;
