import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Places from '../Places';

function QueryWrapper({ onSelect, value, order, ...props }) {
  const chars = value || '';

  const setPlace = (place) => {
    const { scope, place_id } = place;

    const values = scope === 'hotel'
      ? { values: { ...place, scope: null }, hotel_ids: [place_id] }
      : { values: { ...place } };

    return values;
  };

  const unsetPlace = () => {
    return { values: { place: null }, hotel_ids: null };
  };

  const handleSelect = (place) => {
    const { values, hotel_ids } = place
      ? setPlace(place)
      : unsetPlace();

    onSelect(values, hotel_ids);
  };

  return (
    <Places
      {...props}
      chars={chars}
      onSelect={handleSelect}
    />
  );
}

QueryWrapper.propTypes = {
  place: PropTypes.object,
  onSelect: PropTypes.func,
  order: PropTypes.object,
  value: PropTypes.string
};

export default styled(QueryWrapper)``;
