import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';

import { Form } from 'components/ui';
import Insurance from './Insurance';
import Slots from './Slots';
import Submit from './Submit';

const Wrapper = styled(Form)`
  ${Slots} + ${Submit} {
    margin-top: 36px;
  }

  ${Insurance} + ${Slots} {
    margin-top: 32px;
  }
`;

@inject('bookingForm')
@observer
class BookingForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { bookingForm, ...rest } = this.props;

    return (
      <Wrapper form={bookingForm} {...rest}>
        <Insurance />
        <Slots />
        <Submit />
      </Wrapper>
    );
  }
}

export default styled(BookingForm)``;
