import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, Provider } from 'mobx-react';
import { reaction } from 'mobx';

import { Header } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Orders } from '.';

import OrderStore from 'stores/OrdersStore/One';
import OrdersStore from 'stores/OrdersStore/List';
import FiltersState from 'forms/Orders/FiltersState';

@withRouter
@observer
class List extends React.Component {
  static propTypes = {
    header: PropTypes.any,
    location: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.filtersState = new FiltersState();
    this.orderStore = OrderStore.create();
    this.ordersStore = OrdersStore.create();
  }

  componentDidMount() {
    this.fetchOrders();
    this.addHandlers();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSearch = prevProps.location.search;
    const currSearcch = this.props.location.search;

    if (prevSearch !== currSearcch) {
      this.fetchOrders();
    }
  }

  componentWillUnmount() {
    this.clearOrders();
    this.delHandlers();
  }

  addHandlers() {
    this._changeFiltersHandler = reaction(
      () => this.filtersState.values(),
      (values) => this.fetchOrders(),
      { delay: 1000 }
    );
  }

  delHandlers() {
    this._changeFiltersHandler();
  }

  fetchOrders() {
    const filter = this.filtersState.values();
    this.ordersStore.fetch({ filter });
  }

  clearOrders() {
    this.ordersStore.clear();
  }

  render() {
    const { header } = this.props;

    return (
      <Provider
        filtersState={this.filtersState}
        ordersStore={this.ordersStore}
      >
        <DefaultTemplate
          header={<Header showRequestButton {...header} />}
          body={<Orders />}
        />
      </Provider>
    );
  }
}

export default List;
