import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { ContractField } from 'components/forms';

const Text = styled.div`
  font-size: 13px;
  color: #4A515C;
`;

const DocNumber = styled.div`
  font-size: 30px;
  color: #4A515C;
  font-weight: bold;
  line-height: 46px;
`;

const Contract = styled.div`
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: row;

  ${Contract} {
    margin-left: 10px;
  }
`;

const OrderStatus = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

@withRouter
@inject('searchState')
@observer
class OrderNumber extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    order: PropTypes.object.isRequired,
    searchState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { order, searchState, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <OrderInfo>
          <DocNumber>
            {t('Order.Number', { ...order })}
          </DocNumber>

          <Contract>
            <ContractField
              prefixed
              transparent
              dimension='large'
              variant='secondary'
              onChange={this.handleOnChange}
              field={searchState.$('contract')}
            />
          </Contract>
        </OrderInfo>

        {false && (
          <OrderStatus>
            <Text>
              Успешно проведен в 1С
            </Text>
          </OrderStatus>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(OrderNumber))``;
