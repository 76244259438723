import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import DevTools from 'mobx-react-devtools';
import { Switch, Redirect } from 'react-router-dom';

import CustomRoute from 'components/common/CustomRoute';
import {
  Auth,
  Finances,
  Orders,
  Reports,
  Settings,
  Statistics,
  NotFound,
  Notifications,
  Contracts
} from 'pages';

@inject('authStore', 'chatStore')
@observer
class App extends React.Component {
  static propTypes = {
    authStore: PropTypes.object.isRequired,
    chatStore: PropTypes.object.isRequired
  }

  componentDidMount() {
    const { authStore, chatStore } = this.props;

    this._authenticationHandler = reaction(
      () => authStore.isAuthenticated,
      (isAuthenticated) => isAuthenticated ? chatStore.connect() : chatStore.disconnect(),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this._authenticationHandler();
  }

  render() {
    const { authStore } = this.props;

    return (
      <>
        {authStore.isAuthenticated && (
          <Notifications />
        )}

        <Switch>
          <Redirect
            exact
            from='/'
            to='/orders'
          />

          <CustomRoute
            isPrivate
            path='/orders'
            component={Orders}
          />

          <CustomRoute
            isPrivate
            path='/contracts'
            component={Contracts}
          />

          <CustomRoute
            isPrivate
            path='/reports'
            component={Reports}
          />

          <CustomRoute
            isPrivate
            path='/statistics'
            component={Statistics}
          />

          <CustomRoute
            isPrivate
            path='/finances'
            component={Finances}
          />

          <CustomRoute
            isPrivate
            path='/settings'
            component={Settings}
          />

          <Auth />

          <CustomRoute
            path='*'
            component={NotFound}
          />
        </Switch>

        {process.env.NODE_ENV === 'development' && (
          <DevTools position={{ bottom: 5, left: 20 }} />
        )}
      </>
    );
  }
}

export default App;
