import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled, { css } from 'styled-components';
import moment from 'utils/moment';
import { transition } from 'theme/mixins';

const Wrapper = styled.div`
  height: 42px;
  line-height: 42px;
  color: #6B707B;
  font-size: 14px;
  border-radius: 3px;
  background-color: #D9E6EC;
  vertical-align: middle;
  text-align: center;
  user-select: none;
  cursor: pointer;
  ${transition()};

  &:hover {
    color: #FFFFFF;
    background-color: #5AB2D6;
  }

  ${p => p.isHovered && css`
    color: #FFFFFF;
    background-color: #5AB2D6;
  `}

  ${p => p.isActive && css`
    color: #FFFFFF;
    background-color: #5AB2D6;
  `}

  ${p => p.startPosition && css`
    grid-column-start: ${p.startPosition};
  `}
`;

@observer
class PriceItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    item: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    format: PropTypes.string,
    startPosition: PropTypes.number
  }

  static defaultProps = {
    className: '',
    format: 'D MMM',
    startPosition: undefined
  };

  @computed get isActive() {
    const { item, form } = this.props;
    return form.isSelected(item);
  }

  handleSelect = (e) => {
    const { item, form } = this.props;
    form.select(item);
  }

  @computed get isHovered() {
    const { item, form } = this.props;
    return form.isHovered(item);
  }

  handleMousEnter = (e) => {
    const { item, form } = this.props;
    form.enter(item);
  }

  handleMouseLeave = (e) => {
    const { item, form } = this.props;
    form.leave(item);
  }

  render() {
    const { item, format, ...rest } = this.props;

    const {
      isActive,
      isHovered,
      handleSelect,
      handleMousEnter,
      handleMouseLeave
    } = this;

    return (
      <Wrapper
        {...rest}
        isActive={isActive}
        isHovered={isHovered}
        onClick={handleSelect}
        onMouseEnter={handleMousEnter}
        onMouseLeave={handleMouseLeave}
      >
        {moment(item.day).format(format)}
      </Wrapper>
    );
  }
}

export default styled(PriceItem)``;
