import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Block } from 'components/atoms';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const cornerVariant = (angle) => {
  switch (angle) {
    case 'bottom-left':
      return css`
        left: 0;
        bottom: 0;

        > * {
          border-bottom-left-radius: inherit;
        }
      `;

    case 'bottom-right':
      return css`
        right: 0;
        bottom: 0;

        > * {
          border-bottom-right-radius: inherit;
        }
      `;

    case 'top-left':
      return css`
        top: 0;
        left: 0;

        > * {
          border-top-left-radius: inherit;
        }
      `;

    case 'top-right':
      return css`
        top: 0;
        right: 0;

        > * {
          border-top-right-radius: inherit;
        }
      `;

    default:
      return '';
  }
};

const Wrapper = styled(Block)`
  ${({ hasHover, isActive, theme }) => css`
    position: relative;
    padding: 16px;
    background: ${theme.colors.popup.secondary};
    border: 1px solid #bfc6d3;
    border-radius: 4px;

    ${isActive && css`
      background-color: ${theme.colors.button.secondary};
      border-color: ${theme.colors.button.primary};
    `}

    ${(!isActive && hasHover) && css`
      cursor: pointer;
      transition:
        background-color 0.25s,
        border-color 0.25s;

      :hover {
        border-color: ${theme.colors.button.primary};
      }
    `}
  `}
`;

const Corner = styled.div`
  ${({ corner }) => css`
    position: absolute;
    border-radius: inherit;
    ${cornerVariant(corner)}
  `}
`;

class BlockShadow extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    corners: PropTypes.shape({
      'bottom-left': ReactComponentChildrenPropType,
      'bottom-right': ReactComponentChildrenPropType,
      'top-left': ReactComponentChildrenPropType,
      'top-right': ReactComponentChildrenPropType
    }),
    isActive: PropTypes.bool,
    onClick: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  handleClick = () => {
    const { onClick } = this.props;
    if (onClick) onClick();
  }

  renderCorner = (corner) => {
    const { corners } = this.props;

    return (
      <Corner corner={corner}>
        {corners[corner]}
      </Corner>
    );
  }

  get hasHover() {
    const { onClick } = this.props;
    return !!onClick;
  }

  render() {
    const { children, corners, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        hasHover={this.hasHover}
      >
        {corners && Object.keys(corners).map(corner => (
          <React.Fragment key={corner}>
            {this.renderCorner(corner)}
          </React.Fragment>
        ))}

        {children}
      </Wrapper>
    );
  }
}

export default styled(BlockShadow)``;
