import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import Cancel from 'pages/Orders/containers/Cancel';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${Button} + ${Button},
  ${Button} + ${Cancel} {
    margin-left: 20px;
  }
`;

@withRouter
@inject('showState')
@observer
class PrimaryActions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get isDisable() {
    const { showState: { order } } = this.props;
    return order.provider_state.code === 'cancelled';
  }

  handleEdit = (e) => {
    e.preventDefault();

    const { showState } = this.props;
    return showState.edit();
  }

  handleCancel = () => {
    const { showState } = this.props;
    return showState.cancel();
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Button
          rounded
          outline
          disabled={this.isDisable}
          onClick={this.handleEdit}
        >
          {t('Order.AddRoom')}
        </Button>

        <Cancel
          title={t('Order.Remove.Caption')}
          acceptText={t('Order.Remove.Accept')}
          declineText={t('Order.Remove.Cancel')}
          onAccept={this.handleCancel}
          onDecline={() => {}}
        >
          <Button
            rounded
            outline
            variant='danger'
            disabled={this.isDisable}
          >
            {t('Order.Cancel')}
          </Button>
        </Cancel>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PrimaryActions))``;
