import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css, withTheme } from 'styled-components';
import { inject } from 'mobx-react';

import { BlockShadow } from 'components/atoms';
import { Button } from 'components/forms';
import { Top, Unit } from 'pages/Settings/components';
import { AddControl, Form, SubTitle } from 'components/ui';
import { TextField } from '.';

import { display, flexChildActualWidth } from 'theme/mixins';

const Wrapper = styled(Form)`
  ${({ theme }) => css`
    ${display('flex')}
    flex-direction: column;
    flex-grow: 1;
    width: 880px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;

    ${Unit} {
      margin-top: 16px;
    }

    ${AddControl} {
      margin-top: 16px;
    }
  `}
`;

const Control = styled(Unit.Control)`
  ${flexChildActualWidth('25%')}
`;

const Controls = styled.div`
  ${display('flex', 'center', 'flex-end')}
  margin: 16px 0 48px;
`;

const Section = styled(BlockShadow)`
  :not(:first-child) {
    margin-top: 10px;
  }

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

@withTheme
@inject('settingsForm')
class Settings extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    settingsForm: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  handleContactAdd = (id) => {
    console.log(`${id} added`);
  }

  render() {
    const { settingsForm, t, ...rest } = this.props;
    const contacts = [
      {
        id: 'base',
        field: settingsForm.$('contacts.base_methods'),
        label: t('Settings.Contacts.Base.Title'),
        description: t('Settings.Contacts.Base.Description')
      },
      {
        id: 'fincances',
        field: settingsForm.$('contacts.finance_methods'),
        label: t('Settings.Contacts.Finances.Title'),
        description: t('Settings.Contacts.Finances.Description')
      },
      {
        id: 'technical',
        field: settingsForm.$('contacts.technical_methods'),
        label: t('Settings.Contacts.Technical.Title'),
        description: t('Settings.Contacts.Technical.Description')
      },
      {
        id: 'common',
        field: settingsForm.$('contacts.common_methods'),
        label: t('Settings.Contacts.Common.Title'),
        description: t('Settings.Contacts.Common.Description')
      }
    ];

    return (
      <Wrapper
        {...rest}
        form={settingsForm}
      >
        <Top />

        <Section>
          <SubTitle>
            {t('Settings.Contacts.Title')}
          </SubTitle>

          {contacts.map(({ id, field, label, description }) => (
            <Unit key={id}>
              <Unit.Title>
                {label}
              </Unit.Title>

              <Unit.Content>
                <Control>
                  <TextField field={field} />
                </Control>

                <Unit.Description>
                  {description}
                </Unit.Description>
              </Unit.Content>

              <AddControl onClick={() => this.handleContactAdd(id)}>
                {t('Settings.Contacts.Add')}
              </AddControl>
            </Unit>
          ))}
        </Section>

        <Controls>
          <Button
            rounded
            size="small"
          >
            {t('UI.Button.Save')}
          </Button>
        </Controls>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Settings))``;
