import React from 'react';
import styled from 'styled-components';

import SideBar from './components/SideBar';
import FiltersBar from 'components/ui/FilterBar';

const FilterContainer = styled.div`
  padding: 0 30px;
  background: #fff;
`;

const Wrapper = styled.div`
  height: calc(100% - 72px);

  ${SideBar} {
    height: calc(100vh - 164px);
    position: relative;
  }
`;

function Hotels({ ...rest }) {
  return (
    <Wrapper {...rest}>
      <FilterContainer>
        <FiltersBar
          hasContract={false}
          hasTreatments={false}
          hasSort
          {...rest}
        />
      </FilterContainer>

      <SideBar {...rest} />
    </Wrapper>
  );
}

export default styled(Hotels)``;
