import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';

import { Actions, Loader, Reservations } from '.';

import OrderStore from 'stores/OrdersStore/One';

const Body = styled(Reservations)``;

const Footer = styled(Actions)``;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.colors.active.primary};
  `}

  ${Body} + ${Footer} {
    margin-top: 12px;
  }
`;

@withRouter
@observer
class Details extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.orderStore = OrderStore.create();
  }

  componentDidMount() {
    const { order: { id } } = this.props;
    this.orderStore.fetch({ id });
  }

  componentWillUnmount() {
    this.orderStore.clear();
  }

  @computed get isFetched() {
    return this.orderStore.isFetched;
  }

  render() {
    const { order, ...rest } = this.props;
    const { data } = this.orderStore;

    return (
      <Wrapper {...rest}>
        {!this.isFetched && (
          <Loader />
        )}

        {(this.isFetched && data) && (
          <>
            <Body
              reservations={data.reservations.toJSON()}
            />

            {data.provider_state.code !== 'cancelled' && (
              <Footer
                order={data.toJSON()}
              />
            )}
          </>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Details))``;
