import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';

import { Error as ErrorIcon, Success as SuccessIcon } from 'components/icons';

const types = {
  success: css`
    color: #9cb4ca;
  `,
  error: css`
    color: #E26161;
  `
};

const aligns = {
  left: css`
    justify-content: flex-start;
  `,
  right: css`
    justify-content: flex-end;
  `,
  center: css`
    justify-content: center;
  `
};

const Icon = styled.div`
`;

const Text = styled.div`

`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;

  ${({ type }) => types[type]}

  ${({ align }) => aligns[align]}

  ${Icon} + ${Text} {
    margin-left: 8px;
  }
`;

class Message extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(types)),
    align: PropTypes.oneOf(['center', 'left', 'right']),
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired
  };

  static defaultProps = {
    className: '',
    type: 'success',
    align: 'left'
  }

  get Icon() {
    const { type } = this.props;

    switch (type) {
      case 'error':
        return ErrorIcon;

      case 'success':
        return SuccessIcon;

      default:
        return null;
    }
  }

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Icon as={this.Icon} />

        <Text>
          {children}
        </Text>
      </Wrapper>
    );
  }
}

export default styled(Message)``;
