import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { display, transition } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', null, 'space-between')}
  width: 100%;
  height: 240px;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.colors.light};
  `}

  ${transition()};
`;

const Details = styled.div`
  flex-grow: 1;
  ${display('flex', null, 'space-between')}
  flex-direction: column;
  padding: 16px 20px;
`;

const PhotoStub = styled.div`
  width: 264px;
  border-radius: 4px 0 0 4px;

  ${({ theme }) => css`
    background: ${theme.colors.passive.primary};
  `}
`;

const TitleStub = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.passive.primary};

    ::before,
    ::after {
      content: "";
      display: block;
      height: ${theme.typography.h2.fontSize}px;
      margin: calc(${theme.typography.h2.lineHeight}px - ${theme.typography.h2.fontSize}px / 2) 0;
      background: currentColor;
    }

    ::before {
      width: 83%;
    }

    ::after {
      width: 71%;
    }
  `}
`;

const CategoriesStub = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'align-items')}
    color: ${theme.colors.passive.primary};

    ::before,
    ::after {
      content: "";
      display: block;
      height: 14px;
      margin: 5px 0;
      background: currentColor;
    }

    ::before {
      width: 20%;
      margin-right: 4px;
    }

    ::after {
      width: 24%;
      margin-left: 4px;
    }
  `}
`;

const SpecializationsStub = styled.div`
  padding: 12px 0;

  ${({ theme }) => css`
    color: ${theme.colors.passive.primary};

    ::before,
    ::after {
      content: "";
      display: block;
      height: 14px;
      margin: 5px 0;
      background: currentColor;
    }

    ::before {
      width: 68%;
    }

    ::after {
      width: 64%;
    }
  `}
`;

@withTheme
class HotelCardStubListItem extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    theme: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { theme, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <PhotoStub />
        <Details>
          <TitleStub />
          <CategoriesStub />
          <SpecializationsStub />
        </Details>
      </Wrapper>
    );
  }
}

export default styled(HotelCardStubListItem)``;
