import _some from 'lodash/some';
import { t } from 'utils/localization';

const unavailableMessage = {
  type: 'alert',
  message: t('BookingForm.Messages.DatesUnavailable')
};

const withoutRatesMessage = {
  type: 'alert',
  message: t('BookingForm.Messages.WithoutRates')
};

function hasAvailability(price) {
  return price.available_beds <= 0;
}

function withoutRates(price) {
  return price.rates.length <= 0;
}

function pricesAlertMessage({ tariff, prices }) {
  const { billing_hour } = tariff;

  const items = billing_hour === 'night'
    ? prices.slice(0, -1)
    : prices;

  const hasUnavailable = _some(items, (p) => hasAvailability(p));
  if (hasUnavailable) return unavailableMessage;

  const hasWithoutRates = _some(items, (p) => withoutRates(p));
  if (hasWithoutRates) return withoutRatesMessage;

  return undefined;
}

export default pricesAlertMessage;
