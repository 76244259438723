import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { display } from 'theme/mixins';
import { LinearLoader, BackLink } from 'components/ui';

import { Button, ContractField } from 'components/forms';

import {
  AddressField, DatesField, PriceField, TreatmentField, SortField
} from './components';
import { useTranslation } from 'react-i18next';

const FormS = styled.div`
  width: 100%;
`;

const InnerS = styled.div`
  ${display('flex', 'center', 'flex-start')};
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const ButtonsContainer = styled.div`
  margin-left: 8px;
  width: 100px;
`;

const Actions = styled(Button)`
  width: 100%;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;

  ${BackLink} + ${FormS} {
    margin-left: 28px;
  }

  ${AddressField} {
    width: ${({ addressFieldWidth }) => addressFieldWidth};
  }

  ${DatesField} {
    width: 211px;
  }

  ${PriceField}{
    width: 250px;
  }

  ${TreatmentField}{
    width: 250px;
  }

  ${ContractField} {
    width: 200px;
  }

  ${DatesField},
  ${ContractField},
  ${PriceField},
  ${TreatmentField},
  ${SortField} {
    margin-left: 8px;
  }

  ${LinearLoader} {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 9;
  }
`;

function FilterBar({
  addressFieldWidth,
  hasContract,
  hasSort,
  onSubmit,
  isPending,
  hasActions,
  hasBackButton,
  hasPrice,
  hasTreatments,
  field,
  ...rest
}) {
  const { t } = useTranslation();

  const handleSubmit = (slug) => {
    onSubmit && onSubmit();
  };

  return (
    <Wrapper {...rest} addressFieldWidth={addressFieldWidth}>
      {isPending && (
        <LinearLoader />
      )}

      <InnerS>
        {hasBackButton && <BackLink />}

        <FormS>
          <InputsWrapper>
            <AddressField
              {...rest}
            />

            <DatesField
              {...rest}
            />

            {hasContract && (
              <ContractField
                field={field}
                mode='edit'
                dimension='medium'
                bordered
              />
            )}

            {hasPrice && (
              <PriceField
                {...rest}
              />)}

            {hasSort && (
              <SortField {...rest} />
            )}

            {hasTreatments && (
              <TreatmentField
                {...rest}
              />)}

            {hasActions && (
              <ButtonsContainer>
                <Actions onClick={handleSubmit}>
                  {t('Orders.OrderForm.SubmitButton')}
                </Actions>
              </ButtonsContainer>
            )}
          </InputsWrapper>
        </FormS>
      </InnerS>
    </Wrapper>
  );
}

FilterBar.propTypes = {
  className: PropTypes.string,
  isPending: PropTypes.bool,
  onSubmit: PropTypes.func,
  hasPrice: PropTypes.bool,
  hasTreatments: PropTypes.bool,
  addressFieldWidth: PropTypes.string,
  hasActions: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  hasContract: PropTypes.bool,
  hasSort: PropTypes.bool,
  field: PropTypes.object
};

FilterBar.defaultProps = {
  className: '',
  hasSort: false,
  hasBackButton: true,
  hasContract: true,
  hasPrice: true,
  hasTreatments: true,
  addressFieldWidth: '280px'
};

export default styled(FilterBar)``;
