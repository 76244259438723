import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

const Cell = styled.div`
  height: 36px;
  color: #6B707B;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 36px;
  vertical-align: middle;
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #D1D5DB;
`;

@withTranslation()
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired
  }

  render() {
    const { t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Cell>
          {t('Contracts.TableHead.Name')}
        </Cell>

        <Cell>
          {t('Contracts.TableHead.INN.KPP')}
        </Cell>

        <Cell>
          {t('Contracts.TableHead.Number')}
        </Cell>

        <Cell>
          {t('Contracts.TableHead.Date')}
        </Cell>

        <Cell>
          {t('Contracts.TableHead.Type')}
        </Cell>

        <Cell>
          {t('Contracts.TableHead.Count')}
        </Cell>

        <Cell />
      </Wrapper>
    );
  }
}

export default styled(Header)``;
