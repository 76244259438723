import {
  ContractNameFilter,
  ContractInnFilter,
  ContractKppFilter,
  ContractNumberFilter,
} from './Filter';

import { t } from 'utils/localization';

const filters = [
  {
    type: 'lpu_name',
    label: t('Contracts.Filters.ContractName.Label'),
    component: ContractNameFilter,
  },
  {
    type: 'lpu_inn',
    label: t('Contracts.Filters.Inn.Label'),
    component: ContractInnFilter,
  },
  {
    type: 'lpu_kpp',
    label: t('Contracts.Filters.Kpp.Label'),
    component: ContractKppFilter,
  },
  {
    type: 'lpu_dog_number',
    label: t('Contracts.Filters.ContractNumber.Label'),
    component: ContractNumberFilter,
  },
];

export default filters;
