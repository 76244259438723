import { observable, computed, action, toJS } from 'mobx';

import { orderSerializer } from 'forms/Orders/ShowState/serializers';

class BookingCart {
  constructor(props) {
    const { history } = props;
    this.history = history || window.history;
  }

  // States
  @observable state = undefined;

  @computed get isFetched() {
    return !!this.order;
  }

  @computed get isPending() {
    return false;
  }

  @action
  setState(state) {
    this.state = state;
  }

  @action
  unsetState(state) {
    this.state = undefined;
  }

  // Order
  @observable.ref order = undefined;

  @action setOrder(order) {
    this.order = toJS(order);
    this.onSetOrder(order);
  }

  @action unsetOrder() {
    this.order = undefined;
  }

  @action onSetOrder(order) {
    this.setValues(order);
  }

  // Values
  @observable.ref values = undefined;

  @action
  setValues(order) {
    const data = orderSerializer(order);
    this.values = data;
  }

  // Handlers
  @computed get canSubmit() {
    const hasReservations = this.order.reservations.length > 0;
    return hasReservations;
  }

  @action
  handleSubmit = () => {
    const { order: { id }, history } = this;
    history.push(`/orders/${id}`);
  }

  @computed get canMore() {
    const hasReservations = this.order.reservations.length > 0;
    return hasReservations;
  }

  @action
  handleMore = () => {
    const { order: { id }, history } = this;
    history.push(`/orders/${id}/details`);
  }
}

export default BookingCart;
