import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css, withTheme } from 'styled-components';
import { inject } from 'mobx-react';

import { BlockShadow } from 'components/atoms';
import { Button } from 'components/forms';
import { Top, Unit } from 'pages/Settings/components';
import { Form, SubTitle } from 'components/ui';
import { AutofillField, ExtraChargeField } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled(Form)`
  ${({ theme }) => css`
    ${display('flex')}
    flex-direction: column;
    flex-grow: 1;
    width: 880px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;

    ${Unit} {
      margin-top: 16px;
    }

    ${ExtraChargeField} {
      width: 68px;
    }

    ${AutofillField} {
      width: 68px;
    }
  `}
`;

const Controls = styled.div`
  ${display('flex', 'center', 'flex-end')}
  margin: 16px 0 48px;
`;

const Section = styled(BlockShadow)`
  :not(:first-child) {
    margin-top: 10px;
  }

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

@withTheme
@inject('settingsForm')
class Settings extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    settingsForm: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { settingsForm, t, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        form={settingsForm}
      >
        <Top />

        <Section>
          <SubTitle>
            {t('Settings.General.Comission.Title')}
          </SubTitle>

          <Unit>
            <Unit.Title>
              {t('Settings.General.Comission.ExtraCharge.Title')}
            </Unit.Title>

            <Unit.Content>
              <Unit.Control>
                <ExtraChargeField field={settingsForm.$('general')} />
              </Unit.Control>

              <Unit.Description>
                {t('Settings.General.Comission.ExtraCharge.Description')}
              </Unit.Description>
            </Unit.Content>
          </Unit>

          <Unit>
            <Unit.Title>
              {t('Settings.General.Comission.Autofill.Title')}
            </Unit.Title>

            <Unit.Content>
              <Unit.Control>
                <AutofillField field={settingsForm.$('general')} />
              </Unit.Control>

              <Unit.Description>
                {t('Settings.General.Comission.Autofill.Description')}
              </Unit.Description>
            </Unit.Content>
          </Unit>
        </Section>

        <Controls>
          <Button
            rounded
            size="small"
          >
            {t('UI.Button.Save')}
          </Button>
        </Controls>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Settings))``;
