import _intersection from 'lodash/intersection';
import _without from 'lodash/without';

function ruleAnd(items, filters) {
  return _intersection(items, filters).length === filters.length;
}

function ruleOr(items, filters) {
  return _intersection(items, filters).length > 0;
}

function filterByAccommodationFeatures(data, values) {
  const filter = (item) => {
    const tags = item.accommodation_features.map(f => f.tag);
    return ruleAnd(tags, values);
  };

  return data.filter(filter);
}

function filterByAmenities(data, values) {
  const filter = (item) => {
    const tags = item.amenities.map(f => f.tag);
    return ruleAnd(tags, values);
  };

  return data.filter(filter);
}

function filterByLocationTypes(data, values) {
  const filter = (item) => {
    const tags = item.location_types.map(t => t.tag);
    return ruleOr(tags, values);
  };

  return data.filter(filter);
}

function filterByMealOptions(data, values) {
  const filter = (item) => {
    const tags = [...item.feed_types, ...item.meal_options].map(i => i.tag);
    return ruleOr(tags, values);
  };

  return data.filter(filter);
}

function filterByTreatmentFactors(data, values) {
  const filter = (item) => {
    const tags = item.treatment_factors.map(f => f.tag);
    return ruleAnd(tags, values);
  };

  return data.filter(filter);
}

function filterByTreatmentProfiles(data, values) {
  const ignores = ['lyuboe-lechenie'];

  const _values = _without(values, ...ignores);

  if (_values.length < 1) {
    return data;
  }

  const filter = (item) => {
    const tags = item.specializations.map(s => s.tag);
    return ruleAnd(tags, _values);
  };

  return data.filter(filter);
}

function filterByHotelTypes(data, values) {
  const filter = (item) => {
    const tags = item.type_option
      ? [item.type_option.tag]
      : [];

    return ruleOr(tags, values);
  };

  return data.filter(filter);
}

function filterByPrice(data, { min, max }) {
  const filter = (item) => {
    const value = item.tariff?.price?.amount || 0;
    let isSuitable = true;

    if (min > 0 && value < min) {
      isSuitable = false;
    }

    if (max > 0 && value > max) {
      isSuitable = false;
    }

    return isSuitable;
  };

  return data.filter(filter);
}

function filterByRating(data, rating) {
  return data.filter(item => item.rating > rating);
}

function applyFiltersToData({ data, filterBy }) {
  let result = [...data];

  const {
    accommodation_features,
    amenities,
    location_types,
    meal_options,
    treatment_factors,
    treatment_profiles,
    hotel_types,
    price,
    rating
  } = filterBy;

  if (accommodation_features.length > 0) {
    result = filterByAccommodationFeatures(result, accommodation_features);
  }

  if (amenities.length > 0) {
    result = filterByAmenities(result, amenities);
  }

  if (location_types.length > 0) {
    result = filterByLocationTypes(result, location_types);
  }

  if (meal_options.length > 0) {
    result = filterByMealOptions(result, meal_options);
  }

  if (treatment_factors.length > 0) {
    result = filterByTreatmentFactors(result, treatment_factors);
  }

  if (treatment_profiles.length > 0) {
    result = filterByTreatmentProfiles(result, treatment_profiles);
  }

  if (hotel_types.length > 0) {
    result = filterByHotelTypes(result, hotel_types);
  }

  if (price) {
    result = filterByPrice(result, price);
  }

  if (rating > 0) {
    result = filterByRating(result, rating);
  }

  return result;
}

export default applyFiltersToData;
