import { types } from 'mobx-state-tree';

const PlatformState = types.model('PlatformState', {
  name: types.string,
  short_name: types.string,
  inn: types.string,
  kpp: types.string
});

export default PlatformState;
