import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';

import { ContractField } from 'components/forms';
import { ContractForm } from 'forms/Orders';

const Details = styled.div`
  color: #4A515C;
  font-size: 13px;
  line-height: 15px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 220px;
  max-width: 220px;

  ${ContractField} + ${Details} {
    margin-top: 2px;
  }
`;

@withRouter
@inject('showState')
@observer
class Contract extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    order: PropTypes.object.isRequired,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    //
    const { onSuccess, onError } = this;
    const hooks = { onSuccess, onError };

    const { order: { contract } } = props;
    const values = { contract };

    this.contractForm = new ContractForm({ values }, { hooks });
  }

  handleOnChange = (contract) => {
    const { contractForm } = this;
    contractForm.submit();
  }

  onSuccess = (form) => {
    const { showState } = this.props;
    const { contract } = form.values();

    showState.updateContract(contract);
  }

  onError = (form) => {
    console.log('onError: ', form.values());
  }

  @computed get canEdit() {
    const { showState: { permissions } } = this.props;
    return permissions.update;
  }

  render() {
    const { order, order: { contract }, t, ...rest } = this.props;

    const { contractForm } = this;

    const contract_details = contract
      ? contract.insured_name || contract.number
      : t('Contract.Empty');

    return (
      <Wrapper {...rest}>
        <ContractField
          prefixed
          fluid
          dimension='big'
          variant='secondary'
          disabled={!this.canEdit}
          onChangeHandler={this.handleOnChange}
          field={contractForm.$('contract')}
        />

        <Details>
          {contract_details}
        </Details>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Contract))``;
