import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { inject, observer } from 'mobx-react';

import { Text } from 'components/atoms';
import { ReservationGroup } from 'pages/Orders/components';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${Text} {
      color: ${theme.colors.text.disabled};
      ${typography(14, 18, 700)};
    }

    ${ReservationGroup} {
      margin: 12px 0;
    }
  `}
`;

@inject('bookingCart')
@observer
class ReservationsInfo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingCart: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  handleReservationGroupSelect = (reservationGroup) => {
    const { bookingCart } = this.props;
    bookingCart.setReservationGroup(reservationGroup);
  }

  render() {
    const {
      bookingCart: {
        reservationGroups,
        reservationGroup: selectedReservationGroup
      },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <Text>
          {t('Order.OrderInfo.Reservations.Title')}
        </Text>

        {reservationGroups.map((reservationGroup, index) => (
          <ReservationGroup
            key={index}
            isActive={reservationGroup.id === selectedReservationGroup.id}
            onClick={this.handleReservationGroupSelect}
            reservationGroup={reservationGroup}
          />
        ))}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(ReservationsInfo))``;
