import { datesRange } from 'utils/dates';
import _flatten from 'lodash/flatten';
import _cloneDeep from 'lodash/cloneDeep';

import { groupsByTariff, groupsByDates, reservationsMapper } from './utils';

const VIEWS = {
  tariff: groupsByTariff,
  dates: groupsByDates
};

class OrderSerializer {
  constructor(order, options) {
    this.setOrder(order);
    this.setView(options);
  }

  // View
  view = undefined;

  setView({ view } = { view: undefined }) {
    const defaultView = 'tariff';

    this.view = Object.keys(VIEWS).includes(view)
      ? VIEWS[view]
      : VIEWS[defaultView];
  }

  // Order
  order = undefined;

  setOrder(order) {
    this.order = order;
  }

  get reservations() {
    const { order: { reservations } } = this;

    return reservations
      .filter(reservation => this.stateFilter(reservation))
      .map(reservation => {
        const slots = reservation.slots
          .filter(slot => this.stateFilter(slot));

        return { ...reservation, slots };
      });
  }

  stateFilter(item) {
    const { provider_state: { code: orderCode } } = this.order;
    const { state: itemCode } = item;

    return orderCode === 'cancelled' ? true : (itemCode !== 'cancelled');
  }

  get rooms_count() {
    const { reservations } = this;
    return reservations.length;
  }

  get seats_count() {
    const { reservations } = this;

    let seats = reservations
      .map(item => item.slots);

    seats = _flatten(seats);

    return seats.length;
  }

  get dates_of_stay() {
    const { check_in, check_out } = this.order;
    return datesRange(check_in, check_out);
  }

  get contract() {
    const { contract } = this.order;
    return contract;
  }

  get groups() {
    const { view } = this;

    let reservations = _cloneDeep(this.reservations);
    reservations = reservationsMapper(reservations);

    return view(reservations);
  }

  execute() {
    const {
      order: { check_in, check_out, contract },
      groups: { payment, groups },
      dates_of_stay, rooms_count, seats_count
    } = this;

    return { check_in, check_out, dates_of_stay, contract, rooms_count, seats_count, payment, groups };
  }
}

export default OrderSerializer;
