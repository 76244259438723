import { useEffect, useState } from 'react';
import instance from 'connection/instance';
import cleanDeep from 'clean-deep';

function useOrder({ id } = { id: null }) {
  const [order, setOrder] = useState();

  useEffect(() => {
    const fetch = async () => {
      const { data: { data } } = await instance.get(`/api/orders/${id}`);

      setOrder(data);
    };

    id && fetch();
  }, [id]);

  const updateOrder = async hotel => {
    let params = {
      ...order,
      hotel
    };
    params = cleanDeep(params, { emptyArrays: false });

    const { data: { data } } = await instance.put(`/api/orders/${id}`, { data: params });
    const url = ['/orders', order.id, 'edit'].join('/');
    setOrder(data);
    window.location = url;
  };

  const createOrder = async (values) => {
    const data = cleanDeep(values, { emptyArrays: false });
    const { data: { data: order } } = await instance.post('/api/orders', { data });

    return order;
  };

  return [order, { updateOrder, createOrder }];
}

export default useOrder;
