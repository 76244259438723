import { t } from 'utils/localization';

function isSameInsurant(left, right) {
  const values = [left, right]
    .map(({ inn, kpp }) => [inn, kpp].join('_'));

  return values[0] === values[1];
}

function hasTariffGroup({ insurant, groups }) {
  const values = groups.filter(({ insurant: userInsurant }) => (
    isSameInsurant(userInsurant, insurant)
  ));

  return values.length > 0;
}

function permissionAlertMessage({ tariff, currentUser }) {
  const insurant = tariff.insurant;
  if (!insurant) return undefined;

  const { role, groups_by_tariff: groups } = currentUser;
  if (role === 'admin') return undefined;

  const isAllow = hasTariffGroup({ insurant, groups });
  if (isAllow) return undefined;

  const message = {
    type: 'alert',
    message: t('BookingForm.Messages.DisallowTariff', insurant)
  };

  return message;
}

export default permissionAlertMessage;
