import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { Groups, OrderInfo } from 'pages/Orders/components';

const Wrapper = styled.div`
  ${OrderInfo} + ${Groups} {
    margin-top: 30px;
  }
`;

@inject('showState')
@observer
class Sidebar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { showState, ...rest } = this.props;

    const {
      values: { groups },
      groupBy
    } = showState;

    return (
      <Wrapper {...rest}>
        <OrderInfo />

        <Groups
          view={groupBy}
          groups={groups}
        />
      </Wrapper>
    );
  }
}

export default styled(Sidebar)``;
