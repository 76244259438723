import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Tooltip } from 'components/ui';
import { WarnQuestion as WarnIcon } from 'components/icons';
import { Block, Text } from 'components/atoms';

import { display, typography } from 'theme/mixins';

const Wrapper = styled(Block)`
  padding: 6px 0;
`;

const Info = styled(Block)``;

const Traveller = styled(Text)`
  ${typography(14, 20)}
`;

const Room = styled.div``;

const Category = styled(Text)`
  ${display('flex', null, 'space-between')}
  ${typography(14, 20)}
`;

const Description = styled(Text)`
  margin: 4px 0;
`;

const Contract = styled(Text)`
  ${({ theme }) => css`
    margin: 4px 0;
    color: ${theme.colors.text.disabled};
  `}
`;

const Price = styled(Text)`
  margin: 4px 0;
  ${typography(14, 18)}
  white-space: nowrap;
`;

const Surcharge = styled(Text)`
  ${({ theme }) => css`
    ${display('flex', null, 'space-between')}
    margin: 4px 0;
    color: ${theme.colors.error.primary};
  `}
`;

const SurchargeLabel = styled.div`
  ${display('flex', 'center')}
`;

const SurchargeValue = styled.div``;

const SurchargeIcon = styled(WarnIcon)`
  width: 16px;
  height: 16px;
`;

const SurchargeTooltip = styled(Tooltip)`
  margin-left: 6px;
`;

class Slot extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    slot: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      slot: {
        contract,
        price,
        traveller,
        rate
      },
      t,
      ...rest
    } = this.props;

    // TOTO: Add actual surcharge
    const surcharge = undefined;

    return (
      <Wrapper {...rest}>
        <Info>
          {traveller && (
            <Traveller>
              {traveller.first_name} {traveller.last_name}
            </Traveller>
          )}

          <Room>
            <Category>
              <Description>
                {rate.description}
              </Description>

              <Price>
                {t('UI.Price', { price })}
              </Price>
            </Category>

            {contract && (
              <Contract>
                {t('Order.OrderInfo.Contract')} {contract.number}
              </Contract>
            )}

            {surcharge && (
              <Surcharge>
                <SurchargeLabel>
                  {t('Order.OrderInfo.CashSurcharge')}

                  <SurchargeTooltip
                    title={surcharge.title}
                    content={surcharge.description}
                  >
                    <SurchargeIcon />
                  </SurchargeTooltip>
                </SurchargeLabel>

                <SurchargeValue>
                  {t('UI.Price', { price: surcharge })}
                </SurchargeValue>
              </Surcharge>
            )}
          </Room>
        </Info>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Slot))``;
