import React from 'react';
import styled from 'styled-components';
import Lightbox, { Modal, ModalGateway } from 'react-images';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import getImage from 'utils/getImage';

import { display } from 'theme/mixins';
import { Photo, Previews } from '.';

const PHOTO_PREVIEWS_COUNT = 3;

const Wrapper = styled.div`
  ${display('flex', 'center', 'center')}
`;

@observer
class Photos extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @observable isOpened = false;

  @observable currentIndex = 0;

  handleImageView = (index) => {
    this.currentIndex = index;
    this.isOpened = true;
  }

  handleNext = () => {
    this.currentIndex = this.currentIndex + 1;
  }

  handlePrev = () => {
    this.currentIndex = this.currentIndex - 1;
  }

  handleThumbnailClick = (index) => {
    this.currentIndex = index;
  }

  handleClose = () => {
    this.isOpened = false;
    this.currentIndex = 0;
  }

  getImgPath(item, size) {
    if (!item) return '';
    return getImage(item.encoded_url, size);
  }

  render() {
    const { images, t, ...rest } = this.props;
    const mainImageSize = { width: 960, height: 456 };

    const paths = images.map((image, _index) => {
      const { src } = this.getImgPath(image, mainImageSize);
      return src;
    });

    return (
      <Wrapper {...rest}>
        {paths
          .slice(0, PHOTO_PREVIEWS_COUNT - 1)
          .map((path, index) => (
            <Photo
              key={index}
              hasMagnifier
              onClick={() => this.handleImageView(index)}
              src={path}
            />
          ))}

        {paths
          .slice(PHOTO_PREVIEWS_COUNT - 1, PHOTO_PREVIEWS_COUNT)
          .map((path, index) => (
            <Photo
              key={index}
              overlayCaption={t('Tariff.Room.MoreImages', { count: paths.slice(PHOTO_PREVIEWS_COUNT - 1).length })}
              src={path}
              onClick={() => this.handleImageView(2)}
            />
          ))}

        <ModalGateway>
          {this.isOpened && (
            <Modal
              allowFullscreen={false}
              onClose={this.handleClose}
            >
              <Lightbox
                components={{ Footer: Previews }}
                currentIndex={this.currentIndex}
                views={paths.map(path => ({ src: path }))}
                onClickNext={this.handleNext}
                onClickPrev={this.handlePrev}
                onClickThumbnail={this.handleThumbnailClick}
                onClose={this.handleToggleClose}
              />
            </Modal>
          )}
        </ModalGateway>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Photos))``;
