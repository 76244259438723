import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import Notification from './Notification';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 310px;
  top: 84px;
  right: 36px;
  z-index: 9999;

  ${Notification} + ${Notification} {
    margin-top: 10px;
  }
`;

@inject('notificationsStore')
@observer
class Notifications extends Component {
  static propTypes = {
    className: PropTypes.string,
    notificationsStore: PropTypes.object.isRequired
  }

  handleRemove = (notification) => {
    const { notificationsStore } = this.props;
    notificationsStore.remove(notification);
  }

  render() {
    const {
      notificationsStore: { notifications },
      className
    } = this.props;

    const listItems = notifications.map((item, i) => (
      <Notification
        key={i}
        type={item.type}
        notification={item}
        onRemove={this.handleRemove}
      />
    ));

    return (
      <Wrapper className={className}>
        {listItems}
      </Wrapper>
    );
  }
}

export default Notifications;
