import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { SubTitle } from 'components/ui';
import { BlockShadow } from 'components/atoms';
import { AccountTable } from 'pages/Settings/components';

const Wrapper = styled(BlockShadow)`
  ${SubTitle} {
    margin-top: 8px;
    text-transform: uppercase;
  }

  ${AccountTable} {
    margin-top: 24px;
  }

  ${AccountTable.Cell} {
    width: 25%;
  }
`;

class LegalEntity extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    legal_entity: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      legal_entity: {
        accounting_code,
        bank,
        bank_code,
        correspondent_account,
        legal_address,
        name,
        payment_account,
        phone,
        position,
        real_address,
        reg_number,
        signatory,
        tin
      },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        <SubTitle>
          {name}
        </SubTitle>

        <AccountTable>
          <AccountTable.Row>
            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.Signatory')}
              value={signatory}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.Position')}
              value={position}
            />

            <AccountTable.Cell
              label={t('Profile.Phone.Label')}
              value={phone}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.RealAddress')}
              value={real_address.location}
            />
          </AccountTable.Row>

          <AccountTable.Row isHighlighted>
            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.Bank')}
              value={bank}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.PaymentAccount')}
              value={payment_account}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.CorrespondentAccount')}
              value={correspondent_account}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.TIN')}
              value={tin}
            />
          </AccountTable.Row>

          <AccountTable.Row isHighlighted>
            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.RegNumber')}
              value={reg_number}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.AccountingCode')}
              value={accounting_code}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.BankCode')}
              value={bank_code}
            />

            <AccountTable.Cell
              label={t('Settings.Contracts.Legal.LegalAddress')}
              value={legal_address.location}
            />
          </AccountTable.Row>
        </AccountTable>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(LegalEntity))``;
