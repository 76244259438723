import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import theme from './ProviderState.theme';

const Wrapper = styled.div`
  ${props => theme(props)};
`;

@observer
class ProviderState extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success', 'warning']),
    code: PropTypes.string.isRequired
  };

  static defaultProps = {
    className: '',
    variant: 'success',
    code: 'confirmed'
  };

  render() {
    const { code, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {t('ProviderState.Value', { code })}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(ProviderState))``;
