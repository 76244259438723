import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Hotels from './Hotels';

const FiltersContainer = styled.div`
  width: 280px;
  height: 100%;
`;

const HotelsContainer = styled.div`
  width: 512px;
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  height: calc(100vh - 163px);
  padding-top: 20px;
  padding-left: 20px;
  box-sizing: border-box;

  ${FiltersContainer} + ${HotelsContainer} {
    margin-left: 20px;
  }
`;

function SideBar({ ...rest }) {
  return (
    <Wrapper {...rest}>
      <HotelsContainer>
        <Hotels {...rest} />
      </HotelsContainer>
    </Wrapper>
  );
}

SideBar.propTypes = {
  className: PropTypes.string,
  hotels: PropTypes.array.isRequired
};

export default styled(SideBar)``;
