import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { EventsContainer } from 'hooks/useEvents';

import Hotels from './components/Hotels';
import { useOrder } from 'stores/OrdersStore/hooks';

import { FluidTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import HotelsInfo from '../HotelsInfo';

import useQuery from 'hooks/useQuery';
import { useSearch } from 'stores/SearchStore';

import Map from 'components/ui/Map';

// Moscow
const defaultCoordinates = [37.622504, 55.753215];

const MapContainer = styled.div`
  position: relative;
  height: calc(100vh - 72px);
`;

const Wrapper = styled.div`
  ${Map} {
    position: absolute;
    width: 100vw;
    height: calc(100vh - 144px);
    top: 72px;
    left: 0;
  }
`;

function SearchNew({ className, header, match: { params } }) {
  const [order, { updateOrder }] = useOrder(params);
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [needUpdatePath, setNeedUpdatePath] = useState(false);

  // Search query
  const [{ query }, { updateQuery, applyQuery }] = useQuery();
  const [{ data, isPending }, doFetch] = useSearch({ initialData: [] });

  const center = useMemo(() => {
    const [lng, lat] = query.address
      ? query.address.coordinates.map(c => Number(c))
      : defaultCoordinates;
    return ({ lat, lng });
  }, [query.address]);

  useEffect(() => {
    if (!query.address && order) {
      updateQuery({
        address: { ...order.address },
        hotel_ids: order.hotel?.id ? [order.hotel?.id] : []
      });

      setShouldUpdate(false);
    }
  }, [order]);

  // Fetch hotels
  useEffect(() => {
    (order || query.address) && doFetch(query);
  }, [query]);

  // Handlers
  const handleUpdate = (values) => {
    !needUpdatePath && setNeedUpdatePath(true);

    updateQuery(values);
  };

  useEffect(() => {
    const { hotel_ids, address } = query;

    if (hotel_ids && address) {
      const hotel = {
        address: address,
        id: hotel_ids[0],
        name: address.location
      };

      if (!shouldUpdate) {
        setShouldUpdate(true);

        return;
      }

      updateOrder(hotel);
    } else {
      needUpdatePath && applyQuery();
    }
  }, [query]);

  return (
    <EventsContainer>
      <Wrapper className={className}>
        <FluidTemplate
          header={<Header {...header} />}
          body={
            <MapContainer>
              <Hotels
                order={order}
                isPending={isPending}
                hotels={data}
                place={query.address}
                query={query}
                updateOrder={updateOrder}
                handleUpdate={handleUpdate}
              />

              <Map
                isPending={isPending}
                defaultCenter={center}
                hotels={data}
                query={query}
              />
            </MapContainer>
          }
        />

        <HotelsInfo updateOrder={updateOrder} showHotelButton />
      </Wrapper>
    </EventsContainer>
  );
}

SearchNew.propTypes = {
  className: PropTypes.string,
  header: PropTypes.object,
  match: PropTypes.object
};

export default styled(SearchNew)``;
