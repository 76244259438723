import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import RangeForm from '../forms/RangeForm';

const Item = styled.div`
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  color: #5AB2D6;

  ${p => p.cash && css`
    color: #E68686;
  `}

  ${p => p.bank_transfer && css`
    color: #5AB2D6;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 36px;
`;

@observer
class PaymentInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    tariff: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isActive: PropTypes.bool
  }

  static defaultProps = {
    className: '',
    isActive: false,
    tariff: {
      billing_hour: 'night',
      price_calculation_rule: 'float'
    }
  };

  constructor(props) {
    super(props);

    const { field, tariff } = props;
    const prices = field.$('prices').value;

    this.rangeForm = new RangeForm({ prices, tariff });
  }

  componentDidMount() {
    const { field } = this.props;

    this._changePriceHandler = reaction(
      () => field.$('prices').value,
      (prices) => this.rangeForm.setPrices(prices)
    );
  }

  componentWillUnmount() {
    this._changePriceHandler();
  }

  render() {
    const { t, field, ...rest } = this.props;

    const cash = this.rangeForm.selectedValues;
    const bankTransfer = this.rangeForm.unselectedValues;

    return (
      <Wrapper {...rest}>
        {bankTransfer.price > 0 && (
          <Item bank_transfer>
            {t('UI.Price', { price: bankTransfer.price })}
          </Item>
        )}

        {cash.price > 0 && (
          <Item cash>
            {t('UI.Price', { price: cash.price })}
          </Item>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentInput))``;
