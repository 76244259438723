import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { BlockShadow, Block } from 'components/atoms';
import { Form } from 'components/ui';
import { Button } from 'components/forms';
import { display } from 'theme/mixins';
import { General, Personal } from '.';
import { computed } from 'mobx';

const Wrapper = styled.div`
  width: 620px;
  height: 100%;
  margin: 128px auto;
`;

const Title = styled.div`
  ${({ theme }) => css`
    margin: 40px 0;
    font-size: ${theme.typography.h1.fontSize}px;
    font-weight: ${theme.typography.h1.fontWeight};
    line-height: ${theme.typography.h1.lineHeight}px;
    font-family: ${theme.typography.h1.fontFamily};
    color: ${theme.typography.h1.color};
  `}
`;

const SubTitle = styled.div`
  ${({ theme }) => css`
    margin-bottom: 8px;
    font-size: ${theme.typography.h2.fontSize}px;
    font-weight: ${theme.typography.h2.fontWeight};
    line-height: ${theme.typography.h2.lineHeight}px;
    font-family: ${theme.typography.h2.fontFamily};
    color: ${theme.typography.h2.color};
  `}
`;

const Section = styled(BlockShadow)`
  margin-top: 20px;

  :not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Controls = styled.div`
  ${display('flex', 'center', 'flex-end')}
`;

@withTheme
@inject('profileForm', 'profileStore')
@observer
class FormSections extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    profileForm: PropTypes.object,
    profileStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isPending() {
    return false;
    // const { profileStore } = this.props;
    // return profileStore.isPending;
  }

  render() {
    const { profileForm, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {!this.isPending
          ? (
            <Form form={profileForm}>
              <Title>
                {t('Profile.Title')}
              </Title>

              <Section>
                <SubTitle>
                  {t('Profile.General.Title')}
                </SubTitle>

                <Block>
                  <General />
                </Block>
              </Section>

              <Section>
                <SubTitle>
                  {t('Profile.Personal.Title')}
                </SubTitle>

                <Block>
                  <Personal />
                </Block>
              </Section>

              <Controls>
                <Button rounded>
                  {t('UI.Button.Save')}
                </Button>
              </Controls>
            </Form>
          )
          : (
            <div>
              Fetching...
            </div>
          )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(FormSections))``;
