import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/forms';

const Description = styled.div`
  color: #4A515C;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const Action = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;

  ${Description} + ${Action} {
    margin-left: 20px;
  }
`;

@inject('showState')
@observer
class Total extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get isLoading() {
    const { showState } = this.props;
    return showState.isPending;
  }

  @computed get isConfirmable() {
    const { showState: { order: { provider_state: { code } } } } = this.props;
    return code === 'booked';
  }

  handleConfirm = (e) => {
    e.preventDefault();

    const { showState } = this.props;
    showState.confirm();
  }

  render() {
    const { showState: { values: { payment } }, t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Description>
          {t('Orders.Total', payment.total)}
        </Description>

        {this.isConfirmable && (
          <Action>
            <Button
              onClick={this.handleConfirm}
              loading={this.isLoading}
            >
              {t('Order.Confirm')}
            </Button>
          </Action>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Total))``;
