import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Flex from 'styled-flex-component';
import RamblerCalendar from 'rambler-ui/Calendar';

import { typography, ruiClass } from 'theme/mixins';
import theme from 'theme';
import moment from 'utils/moment';

const StyledCalendar = styled(RamblerCalendar)`
  ${({ theme }) => css`
    ${ruiClass('rui-Calendar-month')} {
      color: ${theme.colors.text.disabled};
      ${typography(12, 15, 500)}
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    ${ruiClass('rui-Calendar-weekDay')} {
      ${typography(10, 12)}
      text-transform: uppercase;
    }

    ${ruiClass('rui-Calendar-isActive')} {
      background-color: ${theme.colors.active.primary};
    }

    ${ruiClass('rui-Input-icon')} {
      font-size: 20px !important;
    }

    ${ruiClass('rui-Calendar-isUnavailable')} {
      opacity: 0;
      user-select: none;
      cursor: default !important;
    }
  `}
`;

class Calendar extends React.PureComponent {
  static propTypes = {
    visibleMonths: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    variation: PropTypes.oneOf(['service', 'media']),
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.arrayOf(Date)
    ]),
    initDate: PropTypes.instanceOf(Date),
    today: PropTypes.instanceOf(Date),
    range: PropTypes.bool,
    minYear: PropTypes.number,
    maxYear: PropTypes.number,
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    showYear: PropTypes.bool,
    showMonthSwitch: PropTypes.bool,
    highlightWeekend: PropTypes.bool,
    onChange: PropTypes.func
  };

  static defaultProps = {
    visibleMonths: 1,
    className: '',
    variation: 'service',
    range: false,
    minYear: 1900,
    maxYear: 2200,
    showYear: true,
    showMonthSwitch: true,
    highlightWeekend: false,
    onChange: () => null
  };

  constructor(props) {
    super(props);

    const { value } = props;
    this.initialDate = moment(value[0]).add(1, 'month').toDate();
  }

  render() {
    const { value, ...rest } = this.props;

    return (
      <Flex>
        <StyledCalendar
          {...rest}
          value={value}
          initDate={this.initialDate}
          theme={theme} // pass theme from the file as alternative for unreachable theme provider
        />
      </Flex>
    );
  }
}

export default styled(Calendar)``;
