import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PropTypes } from 'prop-types';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  color: #3aa6d2;
  ${typography(20, 24, 700)};
`;

@withTranslation()
class HotelPrice extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { value, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {t('Hotel.PriceFrom')} {t('UI.Price', { price: value })}
      </Wrapper>
    );
  }
}

export default styled(HotelPrice)``;
