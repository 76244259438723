import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OrderInfo, ReservationsInfo } from 'pages/Orders/components';

const Wrapper = styled.div`
  ${ReservationsInfo} {
    margin-top: 32px;
  }
`;

class Sidebar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <OrderInfo />

        <ReservationsInfo />
      </Wrapper>
    );
  }
}

export default styled(Sidebar)``;
