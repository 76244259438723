import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { Breadcrumbs } from 'components/ui';
import { Center, Sidebar, Summary } from '.';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
`;

const Wrapper = styled.div`
  width: 960px;
  margin: 48px auto;

  ${Breadcrumbs} {
    margin-bottom: 16px;
  }

  ${Summary} {
    margin-bottom: 20px;
  }

  ${Sidebar} {
    flex-grow: 1;
    width: 256px;
  }

  ${Center} {
    flex-grow: 0;
    width: 620px;
    margin-left: 20px;
  }
`;

@inject('showState')
@observer
class ViewOrder extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    showState: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { showState: { order: { id, order_code } }, t, ...rest } = this.props;

    const links = [
      {
        label: t('Orders.Title'),
        url: '/orders'
      },
      {
        label: t('Order.Number', { order_code }),
        url: `/orders/${id}`
      }
    ];

    return (
      <Wrapper {...rest}>
        <Breadcrumbs
          items={links}
        />

        <Summary />

        <Content>
          <Sidebar />
          <Center />
        </Content>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(ViewOrder))``;
