import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Field, Input } from 'components/forms';

const Wrapper = styled.div``;

@observer
class FullName extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { field, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Field
          field={field}
          variation="transparent"
          component={Input}
        />
      </Wrapper>
    );
  }
}

export default styled(FullName)``;
