import { Form } from 'mobx-react-form';
import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';
import { action, computed } from 'mobx';

import fields from './fields';

const VIRTUAL_NUMBER = 'virtual';

class ContractState extends Form {
  options() {
    return {
      showErrorsOnClear: false,
      showErrorsOnChange: true,
      showErrorsOnSubmit: true,
      validateOnChange: false
    };
  }

  plugins() {
    return {
      dvr: dvr(validatorjs)
    };
  }

  setup() {
    return fields;
  }

  @action
  setContract(contract) {
    const chars = contract.number === VIRTUAL_NUMBER
      ? contract.insured_name
      : contract.number;

    this.update({ contract, chars });
  }

  @action
  unsetContract() {
    this.update({ contract: undefined });
  }

  @computed get contractIsValid() {
    return this.isValid;
  }

  @computed get isVirtual() {
    return this.has('contract.number') &&
      this.$('contract.number').value === VIRTUAL_NUMBER;
  }
}

export default ContractState;
