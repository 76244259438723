import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { BlockShadow } from 'components/atoms';
import { Reservation, Slot } from 'pages/Orders/components';

import { display } from 'theme/mixins';

const Traveller = styled.div`
  ${display('flex', 'center', 'space-between')};

  & + & {
    margin-top: 8px;
  }
`;

const Slots = styled.div`
  :not(:first-child) {
    margin-top: 12px;
  }

  :not(:last-child) {
    margin-bottom: 12px;
  }
`;

const Wrapper = styled(BlockShadow)`
  ${({ theme }) => css`
    padding: 20px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.popup.primary};
  `}

  ${Traveller} + ${Reservation.Room} {
    margin-top: 12px;
  }
`;

@withTheme
class ReservationGroup extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    reservation: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      reservation: {
        check_in,
        check_out,
        room_type,
        slots
      },
      reservation,
      ...rest
    } = this.props;

    const freeSeatsCount = room_type.beds + room_type.extra_beds - slots.length;

    return (
      <Wrapper {...rest}>
        <Traveller>
          <Reservation.Traveller
            check_in={check_in}
            check_out={check_out}
            freeSeatsCount={freeSeatsCount}
          />
        </Traveller>

        <Reservation.Room
          reservation={reservation}
        />

        <Slots>
          {slots.map(slot => (
            <Slot
              key={slot.id}
              slot={slot}
            />
          ))}
        </Slots>
      </Wrapper>
    );
  }
}

export default styled(ReservationGroup)``;
