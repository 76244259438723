import React from 'react';
import { PropTypes } from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Breadcrumbs } from 'components/ui';

import { typography } from 'theme/mixins';

const Wrapper = styled.div``;

const Title = styled.div`
  margin: 16px 0 8px;
  ${typography(20, 24, 700)}
`;

@withTheme
class Header extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    mode: PropTypes.oneOf([
      'travellers',
      'room_type'
    ]).isRequired,
    order: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      mode,
      order: {
        id,
        order_code,
        travellers
      },
      t,
      ...rest
    } = this.props;
    let title;

    if (mode === 'travellers') {
      title = t('Order.Travellers.NewTravellers', { count: travellers.length });
    }

    if (mode === 'room_type') {
      title = t('Order.Travellers.RoomType', { count: travellers.length });
    }

    return (
      <Wrapper {...rest}>
        <Breadcrumbs
          items={[
            {
              id: '1',
              name: t('Order.Travellers.OrderNumber', { number: order_code }),
              url: `/orders/${id}`
            },
            {
              id: '2',
              name: t('Order.Travellers.AddTraveler'),
              url: `/orders/${id}/add_travellers`
            },
            ...(mode === 'room_type' ? [{
              id: '3',
              name: t('Order.Travellers.ChooseRoom'),
              url: `/orders/${id}/choose_room_type`
            }] : [])
          ]}
        />

        <Title>
          {title}
        </Title>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Header))``;
