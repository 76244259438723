import BaseState from 'forms/BaseState';

import { fields } from '.';

class ContractForm extends BaseState {
  setup() {
    return fields;
  }
}

export default ContractForm;
