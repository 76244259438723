import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import noPhotoSvgPath from 'assets/icons/no-photo.svg';

const Image = styled.img.attrs(({ src }) => ({
  src,
  width: 100,
  height: 100
}))`
  width: 100px;
  height: 100px;
  border-radius: inherit;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.25s;

  ${({ isDisplay }) => isDisplay && css`
    opacity: 1;
  `}
`;

const Empty = styled.div`
  width: 100px;
  height: 100px;
  background: url(${noPhotoSvgPath}) no-repeat center center;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.25s;

  ${({ isDisplay }) => isDisplay && css`
    opacity: 1;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: auto;
  height: 100px;
  border-radius: 4px;

  ${({ theme }) => css`
    background: ${theme.colors.passive.primary};
  `}
`;

@observer
class HotelPhoto extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    children: PropTypes.any
  };

  static defaultProps = {
    className: ''
  }

  @observable isImageDisplay = false;
  @observable hasError = false;

  handleImageLoad = () => {
    this.isImageDisplay = true;
  }

  handleImageError = () => {
    this.isImageDisplay = true;
    this.hasError = true;
  }

  render() {
    const { src, children, className } = this.props;

    return (
      <Wrapper className={className}>
        {this.hasError
          ? (
            <Empty isDisplay={this.isImageDisplay} />
          )
          : (
            <Image
              isDisplay={this.isImageDisplay}
              src={src}
              onError={this.handleImageError}
              onLoad={this.handleImageLoad}
            />
          )}

        {children && children}
      </Wrapper>
    );
  }
}

export default styled(HotelPhoto)``;
