import React from 'react';
import reset from 'styled-reset';
import { Provider } from 'mobx-react';
import { ApplyTheme } from 'rambler-ui/theme';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createGlobalStyle } from 'styled-components';

import theme from 'theme';
import textCSS from 'theme/textCSS';
import globalCss from 'theme/css/global.css';
import AuthStore from 'stores/AuthStore';
import ChatStore from 'stores/ChatStore';
import NotificationsStore from 'stores/Notifications/List';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const GlobalStyle = createGlobalStyle`
  ${reset};
  ${globalCss};
  ${textCSS}
`;

const authStore = AuthStore.create();
const chatStore = new ChatStore();
const notificationsStore = NotificationsStore.create();

const MainProvider = ({ children }) => (
  <BrowserRouter>
    <Provider {...{ authStore, chatStore, notificationsStore }}>
      <ThemeProvider theme={theme}>
        <ApplyTheme theme={theme}>
          <>
            <GlobalStyle />
            {children}
          </>
        </ApplyTheme>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);

MainProvider.propTypes = {
  children: ReactComponentChildrenPropType.isRequired
};

export default MainProvider;
