import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import arrayToTree from 'array-to-tree';

const Wrapper = styled.article``;

@inject('hotelsInfo')
@observer
class Amenities extends React.Component {
  @computed get amenities() {
    const { hotelsInfo } = this.props;
    const { amenities } = hotelsInfo && hotelsInfo.data && hotelsInfo.data.toJSON();
    const nestedAmenities = arrayToTree(amenities, { parentProperty: 'parent' });

    return nestedAmenities;
  }

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {this.amenities.map(({ id, children, name }) => (
          <section key={id}>
            <h4>
              {name}
            </h4>
            {children && (
              <ul>
                {children.map(({ id, name }) => (
                  <li key={id}>
                    {name}
                  </li>
                ))}
              </ul>
            )}
          </section>
        ))}
      </Wrapper>
    );
  }
}

Amenities.propTypes = {
  className: PropTypes.string,
  hotelsInfo: PropTypes.object
};

Amenities.defaultProps = {
  className: ''
};

export default styled(Amenities)``;
