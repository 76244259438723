import { t } from 'utils/localization';

const fields = ['lpu_name'];

const placeholders = {
  lpu_name: t('Contracts.Filters.ContractName.Label'),
};

export default {
  fields,
  placeholders,
};
