import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Restrictions from '../Restrictions';

import { Button, RoomsCounter } from 'components/forms';
import PaymentInfo from '../PaymentInfo';
import { Attention } from 'pages/Orders/components/Attention/Attention';

const Submit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px 36px;

  ${RoomsCounter} + ${Button} {
    margin-left: 16px;
  }
`;

const LeftContainer = styled.div``;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  align-items: flex-start;
`;

@withTranslation()
@inject('bookingForm', 'bookingState')
@observer
class OnCreate extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    bookingState: PropTypes.object.isRequired,
    bookingForm: PropTypes.object.isRequired,
    restrictionsData: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingForm, bookingState, restrictionsData, t, ...rest } =
      this.props;

    const [minValue, maxValue] = bookingForm.roomsBoundary;
    const count = bookingForm.$('rooms_count').values() + bookingState.order.reservations.length;

    return (
      <Wrapper {...rest}>
        <LeftContainer>
          {restrictionsData?.restrictions?.length > 0 && (
            <Restrictions restrictionsData={restrictionsData} />
          )}
          <Attention count={count} />
        </LeftContainer>

        <RightContainer>
          <PaymentInfo />

          <Submit>
            <RoomsCounter
              field={bookingForm.$('rooms_count')}
              minValue={minValue}
              maxValue={maxValue}
            />

            <Button
              outline
              rounded
              type='submit'
              disabled={
                bookingForm.isDisabled || restrictionsData?.hasActiveRestriction
              }
              loading={bookingState.orderIsPending}
            >
              Добавить в заказ
            </Button>
          </Submit>
        </RightContainer>
      </Wrapper>
    );
  }
}

export default styled(OnCreate)``;
