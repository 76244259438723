import React from 'react';
import styled, { css } from 'styled-components';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

const Input = styled(NumberFormat)`
  border-radius: 0;
  width: 100%;
  height: 40px;
  border: 0px;
  padding-left: 14px;
`;

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: white;
  border-radius: 0;
  border: 1px solid #DDDDE6;
  width: 100%;
  height: 40px;
  overflow: hidden;
  
  ${({ position }) => position === 'left' && css`
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  `}

  ${({ position }) => position === 'right' && css`
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
`;

function FormattedInput({ prefix, onChange, position, value, className }) {
  return (
    <Wrapper position={position} className={className}>
      <Input
        prefix={prefix}
        suffix=' ₽'
        thousandSeparator={' '}
        allowNegative={false}
        color='#4C4C4C'
        isNumericString
        decimalPrecision={2}
        defaultValue={value}
        onValueChange={onChange}
        value={value}
      />
    </Wrapper>
  );
}

FormattedInput.propTypes = {
  prefix: PropTypes.string,
  onChange: PropTypes.func,
  position: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string
};

export default styled(FormattedInput)``;
