import { t } from 'utils/localization';

const fields = ['lpu_kpp'];

const placeholders = {
  lpu_kpp: t('Contracts.Filters.Kpp.Label'),
};

export default {
  fields,
  placeholders,
};
