import { observer } from 'mobx-react';
import React from 'react';
import { withRouter } from 'react-router-dom';
import CustomRoute from 'components/common/CustomRoute';

import { Login, Profile } from './containers';

@withRouter
@observer
class Auth extends React.Component {
  render() {
    return (
      <>
        <CustomRoute
          exact
          path="/profile"
          component={Profile}
        />

        <CustomRoute
          exact
          path="/login"
          component={Login}
        />
      </>
    );
  }
}

export default Auth;
