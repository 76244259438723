import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Field, Input } from 'components/forms';
import { Form } from 'components/ui';

import { ContractNameForm } from './forms';

const Wrapper = styled(Form)``;

@observer
class ContractNameFilter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError,
    };

    this.form = new ContractNameForm({}, { hooks });
  }

  handleSuccess = (form) => {
    const {
      filter: { label, type },
      onSubmit,
    } = this.props;
    const { value } = form.$(type);

    onSubmit({
      type,
      value,
      label: `${label}: ${value}`,
    });
  };

  handleKeyDown = (e) => {
    const { onCancel } = this.props;
    const {
      keyCode,
      target: { value },
    } = e;

    // Submit on Enter
    if (keyCode === 13 && value.length > 0) {
      this.form.submit();
    }

    // Blur on Esc
    if (keyCode === 27) {
      onCancel();
    }
  };

  handleError = (form) => {
    console.log(form.errors());
  };

  handleCancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
  };

  render() {
    const { filter, ...rest } = this.props;

    return (
      <Wrapper {...rest} form={this.form}>
        <Field
          field={this.form.$(filter.type)}
          component={Input}
          autoFocus
          onBlur={this.handleCancel}
          onKeyDown={this.handleKeyDown}
        />
      </Wrapper>
    );
  }
}

export default styled(ContractNameFilter)``;
