const DEFAULT_TRAVELLER = {
  full_name: null,
  first_name: null,
  last_name: null,
  middle_name: null,
  gender: null,
  age: 18
};

const DEFAULT_RATE = {
  age_group: 'adult',
  age_period: null
};

function assignTravellers({ slots }) {
  return slots.map((slot) => {
    // Assign slots straveller
    const traveller = slot.traveller || DEFAULT_TRAVELLER;

    // Assign slots rate
    const rate = slot.rate || DEFAULT_RATE;

    return { ...slot, rate, traveller };
  });
}

export default assignTravellers;
