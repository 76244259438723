import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import Reservations from './Reservations';
import Total from './Total';
import { Cancel } from 'pages/Orders/containers';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  color: #4a515c;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const Body = styled.div`
  padding: 0 30px;
`;

const Footer = styled.div`
  position: fixed;
  width: inherit;
  height: 72px;
  bottom: 0;
  padding: 0 30px;
  background-color: #ffffff;
  border-top: 1px solid #dcdfe4;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 336px);
  padding: 26px 0 98px;
  margin-bottom: -48px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
  display: flex;
  flex-direction: column;
gap: 14px;
`;

@inject('showState')
@observer
class Center extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get canCancel() {
    const { showState } = this.props;
    const { order } = showState;
    const isDeclared = order.is_declared;
    const {
      order: {
        provider_state: { code }
      },
      permissions: { cancel: canCancel }
    } = showState;

    return !isDeclared && code !== 'cancelled' && canCancel;
  }

  handleCancel = (e) => {
    const { showState } = this.props;

    const {
      selectedGroup: { reservations }
    } = showState;

    const ids = reservations.map((r) => r.id);

    return showState.cancelReservations(ids);
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Header>
          {t('Order.OrderInfo.Reservations.Title')}

          {this.canCancel && (
            <Cancel
              title={t('Order.OrderInfo.Reservations.Remove.Caption')}
              acceptText={t('Order.OrderInfo.Reservations.Remove.Accept')}
              declineText={t('Order.OrderInfo.Reservations.Remove.Cancel')}
              onAccept={this.handleCancel}
              onDecline={() => {}}
            >
              <Button rounded outline variant='danger' size='small'>
                {t('Order.OrderInfo.Reservations.Remove.Action')}
              </Button>
            </Cancel>
          )}
        </Header>

        <Body>
          <Reservations />
        </Body>

        <Footer>
          <Total />
        </Footer>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Center))``;
