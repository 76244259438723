import React from 'react';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import { ReactComponentChildrenPropType } from 'types/customPropTypes';

// TODO: Add top sticking support

const Wrapper = styled.div`
  ${({ isSticky, stickedCSS }) => css`
    position: relative;

    ${isSticky && css`
      position: fixed;

      &&& {
        ${stickedCSS}
      }
    `}
  `}
`;

@observer
class Sticky extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType.isRequired,
    stickedCSS: PropTypes.array
  };

  static defaultProps = {
    className: ''
  };

  @observable isSticky = false;

  initialTop = null;

  get bound() {
    return this.sticky.current.getBoundingClientRect();
  }

  sticky = React.createRef();

  componentDidMount() {
    this.initialTop = this.bound.top;
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.isSticky = this.initialTop > window.innerHeight + window.scrollY;
  }

  render() {
    const { children, stickedCSS, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        ref={this.sticky}
        isSticky={this.isSticky}
        stickedCSS={this.isSticky && stickedCSS}
      >
        {children}
      </Wrapper>
    );
  }
}

export default styled(Sticky)``;
