import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

import Hotel from './Hotel';

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  overflow: hidden;

  ${Hotel} + ${Hotel} {
    margin-top: 12px;
  }
`;

const Wrapper = styled.div`
  width: 512px;
  height: 100% !important;
  z-index: 1;
`;

function Hotels({ className, hotels, ...rest }) {
  const scrollEl = useRef();

  if (hotels.length === 0) return null;

  const hotelElements = hotels
    .map(hotel => <Hotel key={hotel.id} hotel={hotel} {...rest} />);

  return (
    <Wrapper className={className}>
      <Scrollbars
        ref={scrollEl}
      >
        <InnerWrapper>
          {hotelElements}
        </InnerWrapper>
      </Scrollbars>
    </Wrapper>
  );
}

Hotels.propTypes = {
  className: PropTypes.string,
  hotels: PropTypes.array.isRequired
};

export default styled(Hotels)``;
