import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tooltip from 'components/v2/ui/Tooltip';

import { StopSalesIcon } from 'components/icons';
import { useTranslation } from 'react-i18next';

const Overlay = styled.div``;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  font-size: 10px;
  border: 2px solid #FFFFFF;
  border-radius: 4px;
  background-color: #E26161;
`;

function StopSales({ className }) {
  const { t } = useTranslation();

  const overlaytext = (
    <Overlay dangerouslySetInnerHTML={{ __html: t('Order.Calendar.Tooltip.NotAvailable') }} />
  );

  return (
    <Tooltip
      placement='bottom'
      overlay={overlaytext}
    >
      <Wrapper className={className}>
        <StopSalesIcon />
      </Wrapper>
    </Tooltip>
  );
}

StopSales.propTypes = {
  className: PropTypes.string
};

export default styled(StopSales)``;
