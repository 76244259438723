import { t } from 'utils/localization';

const fields = [
  'insurant',
  'insurant.id',
  'insurant.insured_name'
];

const placeholders = {
  traveller: t('Orders.Filters.Travellers.Label')
};

export default {
  fields,
  placeholders
};
