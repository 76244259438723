import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/forms';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${Button} + ${Button} {
    margin-left: 35px;
  }
`;

@withRouter
@observer
class SecondaryActions extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Button
          compact
        >
          {t('Order.SyncWithOneC')}
        </Button>

        <Button
          compact
        >
          {t('Order.UploadXLS')}
        </Button>

        <Button
          compact
        >
          {t('Order.History')}
        </Button>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(SecondaryActions))``;
