import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer, Provider } from 'mobx-react';
import { reaction } from 'mobx';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';

import BookingCart from 'forms/Orders/BookingCart';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #BFC6D3;
  border-radius: 4px;
  background-color: #E5E8ED;
  box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);

  ${Header} {
    flex-grow: 0;
  }

  ${Body} {
    flex-grow: 1;
  }

  ${Footer} {
    flex-grow: 0;
  }
`;

@withRouter
@inject('bookingState')
@observer
class OrderAndChat extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    const { history } = props;
    this.bookingCart = new BookingCart({ history });
  }

  componentDidMount() {
    const { bookingState } = this.props;

    this._changeOrderHandler = reaction(
      () => bookingState.order,
      (order) => this.bookingCart.setOrder(order),
      { fireImmediately: true }
    );
  }

  componentWillUnmount() {
    this._changeOrderHandler();
  }

  render() {
    const { bookingState, history, ...rest } = this.props;
    const { bookingCart } = this;

    if (!bookingCart.isFetched) return null;

    return (
      <Provider bookingCart={bookingCart}>
        <Wrapper {...rest}>
          <Header />
          <Body />
          <Footer />
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(OrderAndChat)``;
