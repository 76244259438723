import React from 'react';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { ContractField, Button } from 'components/forms';
import { ContractForm } from 'forms/Orders';
import { Cancel } from 'pages/Orders/containers';

const OrderCode = styled.div`
  font-size: 32px;
  color: #9CB4CA;
  font-weight: bold;
`;

const Contract = styled.div`
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Item = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${Item} + ${Item} {
    margin-left: 21px;
  }

  ${Action} {
    flex-grow: 1;
  }
`;

@withRouter
@inject('bookingState')
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    history: PropTypes.object.isRequired,
    order: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    // Initialize form
    const { onSuccess, onError } = this;
    const hooks = { onSuccess, onError };

    const { order: { contract } } = props;
    const values = { contract };

    this.contractForm = new ContractForm({ values }, { hooks });
  }

  onSuccess = (form) => {
    const { bookingState } = this.props;
    const { contract } = form.values();

    bookingState.orderContractUpdate(contract);
  }

  onError = (form) => {
    console.log('onError: ', form.values());
  }

  handleOnChange = (contract) => {
    const { contractForm } = this;
    contractForm.submit();
  }

  handleCancel = () => {
    const { bookingState } = this.props;

    return bookingState.orderCancel()
      .then(_ => this.navigateTo());
  }

  navigateTo = () => {
    const { history } = this.props;
    history.replace('/orders');
  }

  render() {
    const { order: { order_code }, t, ...rest } = this.props;

    const { contractForm } = this;

    return (
      <Wrapper {...rest}>
        <OrderCode as={Item}>
          {t('Order.Number', { order_code })}
        </OrderCode>

        <Contract as={Item}>
          <ContractField
            prefixed
            transparent
            dimension='large'
            variant='secondary'
            field={contractForm.$('contract')}
            onChangeHandler={this.handleOnChange}
          />
        </Contract>

        <Action as={Item}>
          <Cancel
            title={t('Order.Remove.Caption')}
            acceptText={t('Order.Remove.Accept')}
            declineText={t('Order.Remove.Cancel')}
            onAccept={this.handleCancel}
            onDecline={() => {}}
          >
            <Button
              size='big'
              outline
              variant='secondary'
            >
              Отменить заказ
            </Button>
          </Cancel>
        </Action>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Header))``;
