import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Item from './Item';

const Header = styled.div`
  padding: 0 15px;
  color: #4A515C;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
`;

const Body = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Header} + ${Body} {
    margin-top: 8px;
  }
`;

function Group({ group, onSelect, className }) {
  const { title, data } = group;

  const handleSelect = (place) => {
    const mappedPlace = place;

    if (!place.coordinates) {
      mappedPlace.coordinates = place.location;
      mappedPlace.location = place.label;
    }

    onSelect(mappedPlace);
  };

  return (
    <Wrapper className={className}>
      <Header>
        {title}
      </Header>
      <Body>
        {data.map((item) => (
          <Item
            key={item.place_id}
            item={item}
            onSelect={handleSelect}
          />
        ))}
      </Body>
    </Wrapper>
  );
}

Group.propTypes = {
  className: PropTypes.string,
  group: PropTypes.shape({
    title: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
  }),
  onSelect: PropTypes.func
};

Group.defaultProps = {
  onSelect: () => {}
};

export default styled(Group)``;
