import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TreatmentForm from 'components/forms/TreatmentForm';

function TreatmentField({ query, handleUpdate, className }) {
  return (
    <TreatmentForm
      className={className}
      value={query.filter.treatment_profiles[0]}
      onSelect={(value) => (
        handleUpdate({ filter: { treatment_profiles: [value] } })
      )}
    />
  );
}

TreatmentField.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func
};

export default styled(TreatmentField)``;
