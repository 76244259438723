export default {
  translation: {
    'Checkboxes.Diseases.Title': 'Оздоровление',
    'Checkboxes.Diseases.EmptyMessage': 'Такого заболевания не найдено, выберите пункт из списка',
    'Checkboxes.Diseases.Placeholder': 'Введите заболевание',
    'Checkboxes.Diseases.MoreButtonText': 'Все категории заболеваний',
    'Checkboxes.HotelTypes.Title': 'Объект размещения',
    'Checkboxes.MealOptions.Title': 'Питание',
    'Checkboxes.Amenities.Title': 'Удобства',
    'Checkboxes.MealOptions.MoreButtonText': 'Все типы питания',
    'Checkboxes.TreatmentFactors.Title': 'Лечебные факторы',
    'Checkboxes.LocationTypes.Title': 'Расположение',
    'Checkboxes.AccommodationFeatures.Title': 'Особенности размещения',
    'Filters.Places.Placeholder': 'Введите город',
    'Filter.Places.RecentQueries.Title': 'Недавние поисковые запросы',
    'Filter.Places.PopularQueries.Title': 'Популярные запросы',
    'Filters.Places.Hotels.Title': 'Санатории',
    'Filters.Places.Regions.Title': 'Регионы',
    'Filters.Disease.Placeholder': 'Введите заболевание',
    'Price.Range': '{from} ₽ - {to} ₽',
    'Common.Day': '{count, plural, one {{count} день} few {{count} дня} many {{count} дней}}',
    'Common.Adults': 'Взрослых',
    'Common.From': 'От',
    'Common.Clear': 'Очистить',
    'Common.Apply': 'Применить',
    'Common.To': 'До',
    'Common.Budget': 'Бюджет',
    'Common.Childs': 'Детей',
    'Common.ShowRooms': 'Показать номера',
    'Common.HideList': 'Скрыть список',
    'Common.SelectChildAge': 'Укажите возраст детей',
    'PaymentPicker.ByCash': 'Наличными {price, number, RUB}',
    'PaymentPicker.SelectDaysByCash': 'Выберите дни, которые будут оплачены наличными',
    'Auth.LogOut': 'Выйти',
    'Contract.Label': 'Договор',
    'Contract.Placeholder': 'Договор',
    'Contract.Empty': 'Нет договора',
    'Contract.Value': 'Договор {number}',
    'Contract.Switch.Label': 'Указать по',
    'Contract.Switch.Mode': `{mode, select,
      edit {по договору}
      contract {по договору}
      insurant {по страхователю}
    }`,
    'Finances.Title': 'Финансы',
    'Profile.Title': 'Мой профиль',
    'Profile.General.Title': 'Общее',
    'Profile.Personal.Title': 'Личные данные',
    'Profile.Email.Label': 'Почта',
    'Profile.Email.Placeholder': 'Введите почту',
    'Profile.FullName.Label': 'Имя пользователя',
    'Profile.FullName.Placeholder': 'Имя пользователя',
    'Profile.FullNameAcronym.Label': 'ФИО',
    'Profile.FullNameAcronym.Placeholder': 'ФИО',
    'Profile.FirstName.Label': 'Имя',
    'Profile.FirstName.Placeholder': 'Имя',
    'Profile.MiddleName.Label': 'Отчество',
    'Profile.MiddleName.Placeholder': 'Отчество',
    'Profile.LastName.Label': 'Фамилия',
    'Profile.LastName.Placeholder': 'Фамилия',
    'Profile.Phone.Label': 'Телефон',
    'Profile.Phone.Placeholder': 'Телефон',
    'Profile.ExtraPhone.Label': 'доб. телефон',
    'Profile.ExtraPhone.Placeholder': 'доб. телефон',
    'Profile.1CLogin.Label': 'Логин в 1С',
    'Profile.1CLogin.Placeholder': 'Логин в 1С',
    'Profile.UplaodPhoto.New': 'Новое фото',
    'Profile.UplaodPhoto.Remove': 'Удалить фото',
    'Profile.Department': 'Филиал',
    'Profile.Rules': 'Права',
    'Profile.Role.Label': 'Роль',
    'Traveller.FullName': '{last_name} {first_name}',
    'Form.Age.Label': 'возраст',
    'Form.Age.Value': `{age} {age, plural,
      one {год}
      few {лет}
      many {лет}
    }`,
    'Traveller.PolisNumber': 'полис №{number}',
    'Conversation.Text.Placeholder': 'Ваше сообщение...',
    'Conversation.Header.Position': 'Менеджер по бронированию',
    'Form.ContractNumber.Label': 'Номер договора',
    'Form.Gender.Label': 'пол',
    'Form.Gender.Male': 'мужской',
    'Form.Gender.Female': 'женский',
    'Form.Gender.Value': `{gender, select,
      male {мужской}
      female {женский}
      other {не определен}
    }`,
    'Form.GenderAndAge.Value': `{gender, select,
      male {{age, select,
        0 {младенец}
        1 {мальчик, {age} год}
        2 {мальчик, {age} года}
        3 {мальчик, {age} года}
        4 {мальчик, {age} года}
        5 {мальчик, {age} лет}
        6 {мальчик, {age} лет}
        7 {мальчик, {age} лет}
        8 {мальчик, {age} лет}
        9 {мальчик, {age} лет}
        10 {мальчик, {age} лет}
        11 {мальчик, {age} лет}
        12 {мальчик, {age} лет}
        13 {мальчик, {age} лет}
        14 {мальчик, {age} лет}
        15 {мальчик, {age} лет}
        16 {мальчик, {age} лет}
        17 {мальчик, {age} лет}
        18 {мальчик, {age} лет}
        other {мужчина, {age} лет}
      }}
      female {{age, select,
        0 {младенец}
        1 {девочка, {age} год}
        2 {девочка, {age} года}
        3 {девочка, {age} года}
        4 {девочка, {age} года}
        5 {девочка, {age} лет}
        6 {девочка, {age} лет}
        7 {девочка, {age} лет}
        8 {девочка, {age} лет}
        9 {девочка, {age} лет}
        10 {девочка, {age} лет}
        11 {девочка, {age} лет}
        12 {девочка, {age} лет}
        13 {девочка, {age} лет}
        14 {девочка, {age} лет}
        15 {девочка, {age} лет}
        16 {девочка, {age} лет}
        17 {девочка, {age} лет}
        18 {девочка, {age} лет}
        other {женщина, {age} лет}
      }}
      other {не определен}
    }`,
    'Form.FirstName.Label': 'Имя',
    'Form.LastName.Label': 'Фамилия',
    'Form.MiddleName.Label': 'Отчество',
    'Form.FullName.Label': 'Фамилия Имя Отчество',
    'Form.AddressSearch': 'Поиск по адресу',
    'Form.HotelSearch': 'Поиск по названию санатория',
    'Form.RoomsCounter.Label': 'Таких номеров',
    'Hotel.NotAvailable': 'К сожалению, на все даты нет доступных номеров.',
    'Hotel.ChooseOther': 'Выбрать другой санаторий',
    'Hotel.AgeRestrictions': 'Возрастные ограничения',
    'Hotel.AgeRestrictionsFrom': `{count, plural,
      =0 {без ограничений}
      one {дети от {count} года}
      few {дети от {count} лет}
      many {дети от {count} лет}
    }`,
    'Hotel.CheckIn': 'Заезд',
    'Hotel.CheckInTime': 'после {time}',
    'Hotel.CheckOut': 'Выезд',
    'Hotel.CheckOutTime': 'до {time}',
    'Hotel.Details.Tabs.Description': 'Описание',
    'Hotel.Details.Tabs.Specializations': 'Специализации',
    'Hotel.Details.Tabs.FeedingPrograms': 'Питание',
    'Hotel.Details.Tabs.Amenities': 'Удобства',
    'Hotel.Details.Tabs.TreatmentPrograms': 'Программы лечения',
    'Hotel.Details.Tabs.Map': 'На карте',
    'Hotel.Details.Tabs.Comments': 'Отзывы',
    'Hotel.Details.Specializations.Caption': 'Главные показания к лечению в санатории:',
    'Hotel.PriceFrom': 'от',
    'Hotel.Search.Rating.Title': 'По рейтингу',
    'Hotel.Search.Sort': 'сортировать',
    'Hotel.Search.Rating': 'рейтинг',
    'Hotel.Search.PriceAsc': 'Цена по возрастанию',
    'Hotel.Search.PriceDesc': 'Цена по убыванию',
    'Hotel.One.CheckIn': 'Заезд с {time}',
    'Hotel.One.CheckOut': 'Выезд до {time}',
    'Hotel.One.More': 'Подробное описание',
    'Order.Notifications.EditSubmit.Success': `Бронь успешно {mode, select,
      update {изменена}
      add {добавлена}
    }`,
    'Order.Notifications.EditSubmit.Error': `Бронь не {mode, select,
      update {изменена}
      add {добавлена}
    }`,
    'Order.Calendar.Tooltip.AvailableBeds': `По выбранному тарифу<br />{count, plural,
      one {доступен {count} номер}
      few {доступно {count} номера}
      many {доступно {count} номеров}
    }`,
    'Order.OrderForm.Attention1': '<b>Удобно:</b> можно не указывать город и найти все санатории по профилю лечения',
    'Order.OrderForm.Attention2': '<b>Важно:</b> укажите количество туристов в 1 номере. Купить сразу несколько номеров и добавить больше туристов можно будет на странице санатория.',
    'Order.Empty': 'В заказе не выбрано ни одного номера. Добавьте номер или удалите пустой заказ.',
    'Order.Calendar.Tooltip.ClosedArrival': 'Выезд на дату запрещен',
    'Order.Calendar.Tooltip.ClosedDeparture': 'Въезд на дату запрещен',
    'Order.Calendar.Tooltip.ClosedDepartureArrival': 'Въезд и выезд на дату запрещен',
    'Tariff.Restrictions.ClosedArrival': 'На выбранную дату заезда тариф недоступен',
    'Tariff.Restrictions.ClosedDeparture': 'На выбранную дату выезда тариф недоступен',
    'Tariff.Restrictions.MinDaysBeforeCheckIn': 'Минимальное количество {unit, select, night {ночей} day {дней}} до заезда – {unit, select, night {{value, plural, one {# ночь} few {# ночи} many {# ночей}}} day {{value, plural, one {# день} few {# дня} many {# дней}}}}',
    'Tariff.Restrictions.MaxDaysBeforeCheckIn': 'Максимальное количество {unit, select, night {ночей} day {дней}} до заезда – {unit, select, night {{value, plural, one {# ночь} few {# ночи} many {# ночей}}} day {{value, plural, one {# день} few {# дня} many {# дней}}}}',
    'Tariff.Restrictions.MaxLengthOfStay': `Максимальный срок пребывания – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Tariff.Restrictions.MinLengthOfStay': `Минимальный срок пребывания – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Tariff.Restrictions.MaxLengthOfStayArrival': `Максимальный срок пребывания, начиная с выбранной даты – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Tariff.Restrictions.MinLengthOfStayArival': `Минимальный срок пребывания, начиная с выбранной даты – {billing_hour, select,
      day {{count, plural,
        one {# день}
        few {# дня}
        many {# дней}
      }}
      night {{count, plural,
        one {# ночь}
        few {# ночи}
        many {# ночей}
      }}
    }`,
    'Order.PriceBlock.Restrictions.Title': 'Ограничения по тарифу',
    'Order.PriceBlock.Restrictions.Header': 'Невозможно выбрать эти даты',
    'Order.PriceBlock.ActiveRestrictions.Title': 'Ограничения на выбранную дату заезда',
    'Order.Calendar.Tooltip.CloseDeparture': 'Запрещен заезд на дату',
    'Order.Calendar.Tooltip.LengthOfStay': `Выберите период:{min, plural,
      =0 { }
      one {<br />min – # день}
      few {<br />min – # дня}
      many {<br />min – # дней}
    }{max, plural,
      =0 { }
      one {<br />max – # день}
      few {<br />max – # дня}
      many {<br />max – # дней}
    }`,
    'Order.Calendar.Tooltip.StopSales': 'Этот день недоступен<br />т.к входит в период<br />стоп продаж',
    'Order.Calendar.Tooltip.OneFreeBed': 'По выбранному тарифу<br />доступно 1 место<br />(подселение)',
    'Order.Calendar.Tooltip.RoomsFreeBeds': `По выбранному тарифу<br/>{count, plural,
      one {доступен {count} номер}
      few {доступно {count} номера}
      many {доступно {count} номеров}
    }<br />целиком и {countBeds} {countBeds, plural,
      one {место}
      few {места}
      many {мест}
    } в<br/>другом номере<br/>(подселение)`,
    'Order.Calendar.Tooltip.NotAvailable': 'По выбранному тарифу нет доступных номеров на эти даты',
    'Order.PaymentInfo.Title': 'Информация об оплате',
    'Order.PaymentInfo.PayTill': 'Оплатить до',
    'Order.PaymentInfo.InitialCost': 'Начальная стоимость',
    'Order.PaymentInfo.TotalCost': 'Итоговая стоимость',
    'Order.PaymentInfo.Fee': 'Комиссия',
    'Order.UserOrder.Title': 'Мой заказ',
    'Order.Confirm': 'Подтвердить',
    'Order.Next': 'Далее',
    'Order.Cancel': 'Отменить заказ',
    'Order.SyncWithOneC': 'Синхронизировать с 1С',
    'Order.UploadXLS': 'Выгрузить XLS',
    'Order.History': 'История',
    'Order.Recalc': 'Пересчитать заказ',
    'Order.Title': 'Заказ',
    'Order.Number': '№{order_code}',
    'Order.AddTraveler': 'Добавить гостя',
    'Order.AddRoom': 'Добавить номер',
    'Order.RoomType.Count.1': 'Одномеснтный номер',
    'Order.RoomType.Count.2': 'Двухместный номер',
    'Order.RoomType.Count.3': 'Трёхместный номер',
    'Order.RoomType.Count.4': 'Четырёхместный номер',
    'Order.Remove.Caption': 'Вы уверены, что хотите отменить заказ?',
    'Order.Remove.Accept': 'Отменить заказ',
    'Order.Remove.Cancel': 'Отмена',
    'Order.Declared.Tooltip': 'Брони с таким статусом нельзя редактировать или удалять',
    'Order.Declared.Title': 'Проведены убытки',
    'Order.Reservation.Remove.Caption': 'Вы уверены, что хотите отменить бронирование?',
    'Order.Reservation.Remove.Accept': 'Да, отменить',
    'Order.Reservation.Remove.Cancel': 'Не отменять',
    'Order.Reservation.AddRoom': 'Дополнительный номер',
    'Order.Reservation.FreeSeats': `{count} {count, plural,
      one {место}
      few {места}
      many {мест}
    } сводобно`,
    'Order.Reservation.Contract.Label': 'Договор страхования',
    'Order.Reservation.Contract.Restriction': 'действующий для этого номера',
    'Order.Travellers.OrderNumber': 'Заказ {number}',
    'Order.Travellers.AddTraveler': 'Добавить гостя',
    'Order.Travellers.ChooseRoom': 'Выбор номера',
    'Order.Travellers.NewTravellers': `Данные {count, plural,
      one {нового гостя}
      few {новых гостей}
      many {новых гостей}
    }`,
    'Order.Travellers.RoomType': `Выберите номер для {count, plural,
      one {гостя}
      few {гостей}
      many {гостей}
    }`,
    'Order.RoomDescription.Title': 'Описание номера и тарифа',
    'Order.Traveller.NoTraveller': 'Нет',
    'Order.OrderInfo.DatesRange': 'Заезд – выезд',
    'Order.OrderInfo.Contract': 'Договор',
    'Order.OrderInfo.Insurance': 'По страховке',
    'Order.OrderInfo.Cash': 'За наличные',
    'Order.OrderInfo.Reservations.Title': 'Номера в заказе',
    'Order.OrderInfo.Reservations.Remove.Action': 'Отменить номера',
    'Order.OrderInfo.Reservations.Remove.Caption': 'Вы уверены, что хотите отменить номера?',
    'Order.OrderInfo.Reservations.Remove.Accept': 'Да, отменить',
    'Order.OrderInfo.Reservations.Remove.Cancel': 'Не отменять',
    'Order.OrderInfo.Amount': 'Стоимость',
    'Order.OrderInfo.CashSurcharge': 'Доплата наличными',
    'Hotel.One.FreeCancel': 'Бесплатная отмена',
    'Orders.Title': 'Заказы',
    'Orders.SearchOrder': 'Поиск заказа',
    'Orders.NewOrder': 'Новый заказ',
    'Orders.Total': 'Итого: {price, number, RUB}',
    'Orders.Guests': `{count, plural,
      =0 {нет гостей}
      one {{count} гость}
      few {{count} гостя}
      many {{count} гостей}
    }`,
    'Orders.Rooms': `{count, plural,
      =0 {нет номеров}
      one {{count} номер}
      few {{count} номера}
      many {{count} номеров}
    }`,
    'Orders.Status': `{code, select,
      created {Новый}
      booked {Забронирован}
      confirmed {Подтверждён}
      cancelled {Отменен}
      archived {Архивный}
    }`,
    'Order.Actions.NotAvailable': 'Любые изменения невозможны, потому что заказ был ранее отменен.',
    'Order.Actions.Archive': 'Архивировать',
    'Order.Actions.CreateOrder': 'Создать заявку',
    'Order.Groups.Views': `{view, select,
      tariff {По номерам}
      dates {По датам}
    }`,
    'ProviderState.Label': 'Статус',
    'ProviderState.Value': `{code, select,
      created {Новый}
      booked {Забронирован}
      confirmed {Подтверждён}
      cancelled {Отменен}
      archived {Архивный}
    }`,
    'ProviderState.Booked.Message': 'Подтвердите заказ',
    'ProviderState.Prefix': 'Статус:',
    'Contracts.TableHead.Name': 'Название ЛПУ',
    'Contracts.TableHead.INN.KPP': 'ИНН/КПП',
    'Contracts.TableHead.Number': '№ с ЛПУ',
    'Contracts.TableHead.Date': 'Дата заключения',
    'Contracts.TableHead.Type': 'Тип расчета',
    'Contracts.TableHead.Count': 'Количество услуг',
    'Contracts.SearchContract': 'Поиск договора',
    'Contracts.Day': 'День',
    'Contracts.Night': 'Ночь',
    'Contracts.Delete': 'Очистить услуги',
    'Contracts.Filters.ContractName.Label': 'Название ЛПУ',
    'Contracts.Filters.Inn.Label': 'ИНН ЛПУ',
    'Contracts.Filters.Kpp.Label': 'КПП ЛПУ',
    'Contracts.Filters.ContractNumber.Label': 'Номер ЛПУ',
    'Contracts.Remove.Caption': 'Вы уверены, что хотите очистить услуги?',



    'Orders.TableHead.Code': 'ID Заказа',
    'Orders.TableHead.Dates': 'Даты',
    'Orders.TableHead.CheckIn': 'Заезд',
    'Orders.TableHead.CheckOut': 'Выезд',
    'Orders.TableHead.Travellers': 'Гости',
    'Orders.TableHead.Hotel': 'Санаторий',
    'Orders.TableHead.Manager': 'Менеджер',
    'Orders.TableHead.Changed': 'Изменен',
    'Orders.TableHead.State': 'Статус',
    'Orders.TableItem.OrderID': 'ID заказа',
    'Orders.TableItem.CreatedAt': 'Дата создания',
    'Orders.TableItem.PayTill': 'Оплатить до',
    'Orders.TableItem.PaymentType': 'Тип оплаты',
    'Orders.TableItem.Price': 'Начальная стоимость',
    'Orders.TableItem.Fee': 'Комиссия',
    'Orders.TableItem.TotalAmount': 'К оплате',
    'Orders.TableItem.FreeCancelation': 'Бесплатная отмена',
    'Orders.TableItem.RoomType': 'Тип номера',
    'Orders.TableItem.Tariff': 'Тариф',
    'Orders.TableItem.Seats': 'Число мест',
    'Orders.TableItem.Seats.Main': `{count, plural,
      =0 {без основных}
      one {{count} основное}
      few {{count} основных}
      many {{count} основных}
    }`,
    'Orders.TableItem.Seats.Extra': `{count, plural,
      =0 {без доп.}
      one {{count} доп.}
      few {{count} доп.}
      many {{count} доп.}
    }`,
    'Orders.TableItem.AdultsMain': 'Взрос. на осн. месте',
    'Orders.TableItem.ChildMain': 'Дети на осн. месте',
    'Orders.TableItem.AdultsExtra': 'Взрос. на доп. месте',
    'Orders.TableItem.ChildExtra': 'Дети на доп. месте',
    'Orders.TableItem.Status.Created': 'Новый',
    'Orders.TableItem.Status.Booked': 'Забронирован',
    'Orders.TableItem.Status.Confirmed': 'Подтверждён',
    'Orders.TableItem.Status.Cancelled': 'Отменён',
    'Orders.TableItem.Status.Error': 'Ошибка',
    'Orders.TravellersList.Expand': `ещё {count} {count, plural,
      one {гость}
      few {гостя}
      many {гостей}
    }`,
    'Orders.TableItem.Contact.Available': 'Есть',
    'Orders.TableItem.Contact.NotAvailable': 'Нет',
    'Orders.Categories.All': 'Все',
    'Orders.Categories.Coming': 'Будущие',
    'Orders.Categories.Completed': 'Завершенные',
    'Orders.Categories.Canceled': 'Отмененные',
    'Orders.Categories.ComingCanceled': 'Скоро отмена',
    'Orders.Categories.Archive': 'Архив',
    'Orders.FilterButton': 'Фильтр',
    'Orders.Filters.CheckInDate.Label': 'Даты заезда',
    'Orders.Filters.CheckOutDate.Label': 'Даты выезда',
    'Orders.Filters.CheckRangeDate.Label': 'Заезд — выезд',
    'Orders.Filters.CheckRangeDate.Value': 'с {from} по {to}',
    'Orders.Filters.Travellers.Label': 'Имя гостя',
    'Orders.Filters.Hotels.Label': 'Санаторий',
    'Orders.Filters.Managers.Label': 'Менеджер',
    'Orders.Filters.Insurer.Label': 'Страхователь',
    'Orders.Filters.OrderCode.Label': 'Номер заказа',
    'Orders.Filters.State.Label': 'Статус',
    'Orders.Filters.Contract.Label': 'Договор',
    'Orders.Filters.Clear': 'Очистить фильтры',
    'Orders.DownloadReportButton': 'Скачать отчет',
    'Orders.Hotels.FavoriteHotels': 'Избранные санатории',
    'Orders.OrderForm.SubmitButton': 'Найти',
    'Orders.OrderForm.WithTreatment': 'С лечением',
    'Orders.OrderForm.WithoutTreatment': 'Без лечения',
    'Orders.OrderForm.AddTraveler': 'Добавить гостя',
    'Orders.OrderForm.Error': 'Пожалуйста, проверьте корректность введённых данных',
    'Orders.OrderForm.AddGuest': 'Добавить гостя',
    'Orders.OrderForm.ContractExpired': 'Срок действия договора страхователя выходит за рамки дат бронирования!',
    'Orders.Show.OrderAndCaht.Seats': `{count} {count, plural,
      one {гость}
      few {гостя}
      many {гостей}
    }`,
    'Orders.Show.OrderAndCaht.Rooms': `{count} {count, plural,
      one {номер}
      few {номера}
      many {номеров}
    }`,
    'Orders.Reservations.Duration': `{value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'Orders.Reservations.ForDuration': `за {value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'Orders.Reservations.TotalForDuration': `Итого за {value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'Orders.EmptyOrders0': 'Здесь отображается таблица с заказами.',
    'Orders.EmptyOrders1': 'Чтобы создать первый заказ, нажмите «Новый заказ».',
    'Orders.Submit.OnChange': 'Сохранить изменения',
    'Orders.Submit.OnCreate': 'Добавить в заказ',
    'Orders.PaymentMethod': `{type, select,
      bank_transfer {Страховая}
      cash {Наличные}
    }`,
    'Pages.Orders': 'Заказы',
    'Pages.Reports': 'Отчётность',
    'Pages.Statistics': 'Статистика',
    'Pages.Finances': 'Финансы',
    'Pages.Settings': 'Настройки',
    'Reports.Title': 'Отчетность',
    'Pages.Contacts': 'Договора',
    'Reports.Empty': 'Отчётов пока не создано',
    'Reports.TableHead.TransactionType': 'Операция',
    'Reports.TableHead.TransactionId': 'ID операции',
    'Reports.TableHead.CreatedAt': 'Создан',
    'Reports.TableHead.PaymentType': 'Тип платежа',
    'Reports.TableHead.Amount': 'Сумма',
    'Reports.TableHead.AccountCode': 'Номер счёта',
    'Settings.Title': 'Настройки',
    'Settings.General.Title': 'Основные настройки',
    'Settings.General.Comission.Title': 'Комиссия',
    'Settings.General.Comission.ExtraCharge.Title': 'Ваша наценка',
    'Settings.General.Comission.ExtraCharge.Description': 'Процент на который увеличивается исходная цена для демонстрации клиенту или автоматического расчета.',
    'Settings.General.Comission.Autofill.Title': 'Автозаполнение',
    'Settings.General.Comission.Autofill.Description': 'Позволяет настроить автоматическое заполнение цены перепродажи, которая будет попадать в закрывающие документы.',
    'Settings.General.Emails.Title': 'Настройка почтовых рассылок',
    'Settings.General.Emails.TransactionalMailing.Title': 'Транзакционные рассылки',
    'Settings.General.Emails.TransactionalMailing.Description': 'Включает автоматическое формирование счёта и отправку его на email при создании бронирования.',
    'Settings.General.Emails.PartnerLogo.Title': 'Логотип партнера',
    'Settings.General.Emails.PartnerLogo.DisplaySiteLogo.Label': 'Показывать логотип на сайте.',
    'Settings.General.Emails.PartnerLogo.Description': 'Ваш логотип будет интегрирован на сайт и во все письма клиенту (подтверждениях бронирования, инциденты и др.).',
    'Settings.Contacts.Title': 'Контактные данные',
    'Settings.Contacts.Add': 'Добавить e-mail',
    'Settings.Contacts.Base.Title': 'Основные способы связи',
    'Settings.Contacts.Base.Description': 'E-mail адреса контракта, на которые будут приходить важные уведомления, и по которым с вами свяжутся в случае срочных вопросов',
    'Settings.Contacts.Finances.Title': 'Финансовые вопросы',
    'Settings.Contacts.Finances.Description': 'E-mail бухгалтерии, для уведомления о вопросах оплаты и решения финансовых вопросов',
    'Settings.Contacts.Technical.Title': 'Технические вопросы',
    'Settings.Contacts.Technical.Description': 'E-mail ответственного за техническую сторону сотрудничества',
    'Settings.Contacts.Common.Title': 'Общая поддержка',
    'Settings.Contacts.Common.Description': 'E-mail специалиста технической поддержки для решения общих вопросов',
    'Settings.Contracts.Title': 'Договоры компании',
    'Settings.Contracts.Legal.Title': 'Юр. лица',
    'Settings.Contracts.Legal.Empty': 'Юридических лиц пока нет',
    'Settings.Contracts.Legal.AccountingCode': 'КПП',
    'Settings.Contracts.Legal.Bank': 'Банк',
    'Settings.Contracts.Legal.BankCode': 'БИК',
    'Settings.Contracts.Legal.CorrespondentAccount': 'Корр. счет',
    'Settings.Contracts.Legal.LegalAddress': 'Юр. адрес',
    'Settings.Contracts.Legal.PaymentAccount': 'Рассчетный счет',
    'Settings.Contracts.Legal.Position': 'Должность',
    'Settings.Contracts.Legal.RealAddress': 'Физ. адрес',
    'Settings.Contracts.Legal.RegNumber': 'ОГРН',
    'Settings.Contracts.Legal.Signatory': 'Подписант',
    'Settings.Contracts.Legal.TIN': 'ИНН',
    'Settings.Contracts.List.Title': 'Договоры',
    'Settings.Contracts.List.Empty': 'Договоров пока нет',
    'Settings.Contracts.List.SignedAt': 'Дата подписания',
    'Settings.Contracts.List.EndAt': 'Дата окончания действия',
    'Settings.Contracts.List.StartAt': 'Дата начала действия',
    'Settings.Contracts.List.ContractType': 'Тип договора',
    'Settings.Contracts.List.ContractFormat': 'Форма договора',
    'Settings.Users.Title': 'Пользователи',
    'Settings.Users.Add.Title': 'Новый пользователь',
    'Settings.Users.AddedUsers.Title': 'Добавленные пользователи',
    'Statistics.Title': 'Статистика',
    'Tariff.Package.Duration.Label': 'Длительность курса',
    'Tariff.Services.Title': 'Услуги входящие в тариф',
    'Tariff.Booking': 'Бронирование',
    'Tariff.Price': 'Цена',
    'Tariff.PriceFrom': 'от {price, number, RUB}',
    'Tariff.Seats.Primary': `{count} {count, plural,
      one {основное место}
      few {основных места}
      many {основных мест}
    }`,
    'Tariff.Seats.Secondary': `{count} {count, plural,
      one {доп. место}
      few {доп. места}
      many {доп. мест}
    }`,
    'Tariff.Rooms.Count': `{count} {count, plural,
      one {комната}
      few {комнаты}
      many {комнат}
    }`,
    'Tariff.Room.Area': '{area} м<sup>2</sup>',
    'Tariff.Room.MoreImages': 'ещё {count}',
    'Tariff.Room.Bed': '{count}-мест.',
    'Tariff.Features.Title': 'Особенности',
    'Tariff.Features.Sharing': 'Возможность подселения',
    'Traveler.Age': `{count} {count, plural,
      one {год}
      few {года}
      many {лет}
    }`,
    'Tariff.ReservationFromDays': `Бронирование от {value} {unit, select,
      day {{value, plural,
        one {дня}
        few {дней}
        many {дней}
      }}
      night {{value, plural,
        one {ночи}
        few {ночей}
        many {ночей}
      }}
    }`,
    'Tariffs.FromDays': `от {value} {unit, select,
      day {{value, plural,
        one {дня}
        few {дней}
        many {дней}
      }}
      night {{value, plural,
        one {ночи}
        few {ночей}
        many {ночей}
      }}
    }`,
    
    'Tariffs.Restriction.MinStayPeriod': 'Min срок проживания - {unit, select, night {{value} нч.} day {{value} дн.}}',
    'Tariffs.CourseDuration': `Длительность курса {value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'UI.PriceFrom': 'от {price, number, RUB}',
    'UI.Button.Add': 'Добавить',
    'UI.Button.Back': 'Назад',
    'UI.Button.Save': 'Сохранить',
    'UI.Button.Confirm': 'Подтвердить',
    'UI.Button.Edit': 'Редактировать',
    'UI.Button.EditCancel': 'Отменить редактирование',
    'UI.Button.More': 'Подробнее',
    'UI.Button.Cancel': 'Отменить',
    'UI.Button.Clear': 'Очистить',
    'UI.Price': '{price, number, RUB}',
    'UI.Duration': `{value} {unit, select,
      day {{value, plural,
        one {день}
        few {дня}
        many {дней}
      }}
      night {{value, plural,
        one {ночь}
        few {ночи}
        many {ночей}
      }}
    }`,
    'UI.Guests': `{count} {count, plural,
      one {гость}
      few {гостя}
      many {гостей}
    }`,
    'UI.PerWeekQuantity': `{count} {count, plural,
      one {раз}
      few {раза}
      many {раз}
    } в неделю`,
    'UI.Fetching': 'Загружается...',
    'UI.DemoLimitations': 'Данный функционал не работает в демо-версии',
    'UI.InTime': 'в {time}',
    'UI.DateInTime': '{date} в {time}',
    'User.Roles.Administrator': 'Администратор',
    'User.Roles.Manager': 'Менеджер',
    'User.Roles.Analyst': 'Аналитик',
    'User.Action.Change': 'Изменить',
    'User.Action.Block': 'Заблокировать',
    'User.Action.Remove': 'Удалить',
    'BookingForm.Messages.DatesUnavailable': 'Нельзя забронировать номер, так как выбраны недоступные даты!',
    'BookingForm.Messages.WithoutRates': 'Нельзя забронировать номер, так на выбранные даты нет цен!',
    'BookingForm.Messages.TravellersRestrictions': 'Количество выбранных мест больше чем гостей!',
    'BookingForm.Messages.DisallowTariff': 'Невозможно забронировать номер, потому что у вас нет доступа к тарифам {short_name}',
    'BookingForm.Messages.StopSales': 'Нельзя забронировать номер, так на выбранные даты по данному тарифу остановлены продажи!',
    'SyncWithOneC.RequestInfo.Title': 'Запрос направлен',
    'SyncWithOneC.RequestInfo.Action': 'Понятно',
    'SyncWithOneC.RequestInfo.Text': `
      Запрос на синхронизацию успешно отправлен и будет выполнен в течении 1-2 минут.
    `,
    'Header.GoForm': 'Бронирование для физических лиц'
  }
};
