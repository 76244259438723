import moment from 'utils/moment';
import { t } from 'utils/localization';

const fields = [
  'id',
  'address',
  'address.location',
  'address.coordinates',
  'hotel',
  'hotel.id',
  'hotel.name',
  'hotel.address',
  'hotel.address.location',
  'hotel.address.coordinates',
  'contract',
  'contract.id',
  'contract.number',
  'contract.price_prefix',
  'contract.insured_name',
  'contract.insured_inn',
  'contract.insured_kpp',
  'contract.begin_date',
  'contract.end_date',
  'check_in',
  'check_out',
  'sort',
  'sort.sort_by',
  'sort.direction',
  'sort.value',
  'sort.label',
  'filter',
  'filter.rating',
  'filter.rating.value',
  'filter.rating.label'
];

const placeholders = {
  address: t('Form.AddressSearch'),
  'contract.number': t('Form.ContractNumber.Label'),
  hotel: t('Form.HotelSearch'),
  sort: t('Hotel.Search.Sort'),
  'filter.rating': t('Hotel.Search.Rating')
};

const labels = {};

const sortItems = [
  {
    value: 'asc',
    direction: 1,
    sort_by: 'pricing.min',
    label: t('Hotel.Search.PriceAsc')
  },
  {
    value: 'desc',
    direction: -1,
    sort_by: 'pricing.min',
    label: t('Hotel.Search.PriceDesc')
  }
];

const extra = {
  sort: sortItems
};

const rules = {
  address: 'required',
  'address.location': 'required|string',
  'address.coordinates': 'required|array',
  check_in: 'required|string',
  check_out: 'required|string'
};

const values = {
  check_in: moment().add(7, 'days').format(),
  check_out: moment().add(14, 'days').format(),
  hotel: {},
  sort: sortItems[0],
  'filter.rating': 1
};

const output = {
  check_in: date => date ? moment(date).format() : date,
  check_out: date => date ? moment(date).format() : date,
  'filter.rating': ({ value }) => value
};

export default {
  fields,
  labels,
  placeholders,
  rules,
  values,
  extra,
  output
};
