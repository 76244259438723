import React from 'react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { PropTypes } from 'prop-types';

import { display, typography } from 'theme/mixins';
import { CornerDown } from 'components/icons';

const Wrapper = styled.div`
  ${display('flex', 'center', 'center')}
  padding: 0 20px;
  border-radius: 100px;

  ${({ theme }) => css`
    border: 2px solid;
    color: ${theme.colors.button.primary};
  `}

  ${typography(13, 16, 700)};
`;

const Arrow = styled(CornerDown)`
  position: relative;
  top: 1px;
  margin-left: 4px;
  fill: currentColor;
  transform: rotate(-90deg);
`;

@withTranslation()
class HotelPrice extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.number.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { value, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {t('Hotel.PriceFrom')} {t('UI.Price', { price: value })}

        <Arrow />
      </Wrapper>
    );
  }
}

export default styled(HotelPrice)``;
