import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { Button } from 'components/forms';
import { Error as ErrorIcon } from 'components/icons';

const Text = styled.div`
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #E26161;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
  vertical-align: middle;

  ${Text} {
    margin-left: 8px;
  }
`;

const Action = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 28px 0;
  background-color: #FDF1F1;

  ${Message} + ${Action} {
    margin-left: 60px;
  }
`;

@observer
class Alert extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    message: PropTypes.shape({
      type: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired
    }).isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { message, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Message>
          <ErrorIcon />
          <Text>
            {message.message}
          </Text>
        </Message>

        {false && (
          <Action>
            <Button
              type='button'
              rounded
              size='small'
              variant='outline-critical'
            >
              Очистить даты
            </Button>
          </Action>
        )}
      </Wrapper>
    );
  }
}

export default styled(Alert)``;
