import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'components/forms';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CornerDown } from 'components/icons';

const DescIcon = styled(CornerDown)`
  margin-right: 10px;
  font-size: 10px;
`;

const AscIcon = styled(CornerDown)`
  transform: rotate(180deg);
  font-size: 10px;
`;

const StyledSelect = styled(Select)`
  width: inherit;
  height: 42px;

  input {
    height: 42px !important;
  }
`;

function SortField({ query, handleUpdate, ...rest }) {
  const { t } = useTranslation();
  const getIcon = () => {
    if (Number(query.sort.direction) === 1) {
      return <AscIcon />;
    }

    if (Number(query.sort.direction) === -1) {
      return <DescIcon />;
    }

    return null;
  };

  const handleChange = sort => {
    handleUpdate({ sort });
  };

  const sortItems = [
    {
      value: 'asc',
      direction: 1,
      sort_by: 'pricing.min',
      label: t('Hotel.Search.PriceAsc')
    },
    {
      value: 'desc',
      direction: -1,
      sort_by: 'pricing.min',
      label: t('Hotel.Search.PriceDesc')
    },
    {
      value: 'desc_rating',
      direction: -1,
      sort_by: 'rating',
      label: t('Hotel.Search.Rating.Title')
    }
  ];

  const sortValue = sortItems.find(s => (s.sort_by === query.sort.sort_by) && (s.direction === Number(query.sort.direction)));

  return (
    <StyledSelect
      {...rest}
      onChange={handleChange}
      value={sortValue}
      arrowIcon={getIcon()}
      items={sortItems}
      labelKey='label'
      valueKey='direction'
    />
  );
}

SortField.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func
};

export default styled(SortField)``;
