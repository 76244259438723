import pricesPayment, { mergePayments, oldPricesPayment } from 'utils/pricesPayment';
import { datesRange } from 'utils/dates';
import { duration as getDuration } from 'utils/helpers';

function reservationMapper(reservation) {
  let { tariff: { billing_hour }, check_in, check_out, slots } = reservation;
  slots = slotsMapper({ reservation, slots });

  const dates_of_stay = datesRange(check_in, check_out, 'D MMMM YYYY');
  const rooms_count = 1;
  const seats_count = slots.length;
  const duration = getDuration({ check_in, check_out, billing_hour });

  const payment = mergePayments(slots.map(item => item.payment));

  return { ...reservation, dates_of_stay, rooms_count, seats_count, duration, payment, slots };
}

function slotsMapper({ reservation, slots }) {
  return slots.map(slot => slotMapper({ reservation, slot }));
}

function slotMapper({ reservation, slot }) {
  const { tariff } = reservation;
  const { prices } = slot;

  const payment = slot.prices.length > 0
    ? pricesPayment({ tariff, prices })
    : oldPricesPayment({ reservation, slot });

  return { ...slot, payment };
}

function reservationsMapper(reservations) {
  return reservations.map(r => reservationMapper(r));
}

export default reservationsMapper;
