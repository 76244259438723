import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import PriceItem from './PriceItem';

const Grid = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 6px;
`;

const DayItem = styled.div`
  color: #4A515C;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;

  &:nth-child(-n+5) {
    color: #979BA0;
  }
`;

const Wrapper = styled.div`
`;

@inject('rangeForm')
@observer
class Body extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    format: PropTypes.string,
    rangeForm: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: '',
    format: 'D MMM'
  };

  render() {
    const {
      rangeForm,
      rangeForm: { startDay, days, prices },
      field,
      format,
      ...rest
    } = this.props;

    const listDays = days.map((item, idx) => (
      <DayItem key={idx}>
        {item}
      </DayItem>
    ));

    const listItems = prices.map((item, idx) => (
      <PriceItem
        key={idx}
        item={item}
        form={rangeForm}
        startPosition={idx === 0 ? startDay : undefined}
      />
    ));

    return (
      <Wrapper {...rest}>
        <Grid>
          {listDays}
          {listItems}
        </Grid>
      </Wrapper>
    );
  }
}

export default styled(Body)``;
