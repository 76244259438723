import React from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { css } from 'styled-components';
import { getNameInitials } from 'utils/localization';

const Label = styled.div`
  height: 14px;
  color: #6B707B;
  font-size: 12px;
  line-height: 14px;
`;

const Value = styled.div`
  color: #4A515C;
  font-size: 14px;
  line-height: 16px;

  ${p => p.empty && css`
    color: #979BA0;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${Label} + ${Value} {
    margin-top: 6px;
  }
`;

const Img = styled.div`
  width: 50px;
`;

const Cell = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px 0 14px;
  border-bottom: 1px solid #D1D5DB;

  ${Content} {
    flex-grow: 1;
  }

  ${Img} + ${Content} {
    margin-left: 16px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: 284px 160px 124px repeat(4, 1fr);
`;

@observer
class Reservation extends React.Component {
  static propTypes = {
    reservation: PropTypes.object.isRequired,
    className: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      reservation: { room_type, tariff, slots },
      t,
      ...rest
    } = this.props;

    // Main seats
    const mainSlots = slots.filter(s => s.rate.occupation === 'main');
    const mainSlotsCount = mainSlots.length;

    const adultsMain = mainSlots
      .filter(s => s.rate.age_group === 'adult')
      .filter(s => s.traveller)
      .map(s => getNameInitials(s.traveller))
      .map(item => <Value key={item}>{item}</Value>);

    const hasAdultsMain = adultsMain.length > 0;

    const childMain = mainSlots
      .filter(s => s.rate.age_group === 'child')
      .filter(s => s.traveller)
      .map(s => getNameInitials(s.traveller))
      .map(item => <Value key={item}>{item}</Value>);

    const hasChildMain = childMain.length > 0;

    // Extra seats
    const extraSlots = slots.filter(s => s.rate.occupation === 'extra');
    const extraSlotsCount = extraSlots.length;

    const adultsExtra = extraSlots
      .filter(s => s.rate.age_group === 'adult')
      .filter(s => s.traveller)
      .map(s => getNameInitials(s.traveller))
      .map(item => <Value key={item}>{item}</Value>);

    const hasAdultsExtra = adultsExtra.length > 0;

    const childExtra = extraSlots
      .filter(s => s.rate.age_group === 'child')
      .filter(s => s.traveller)
      .map(s => getNameInitials(s.traveller))
      .map(item => <Value key={item}>{item}</Value>);

    const hasChildExtra = childExtra.length > 0;

    return (
      <Wrapper {...rest}>
        <Cell>
          <Content>
            <Label>
              Тип номера
            </Label>
            <Value>
              {room_type.name}
            </Value>
          </Content>
        </Cell>
        <Cell>
          <Content>
            <Label>
              Тариф
            </Label>
            <Value>
              {tariff.name}
            </Value>
          </Content>
        </Cell>
        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.Seats')}
            </Label>
            <Value>
              {t('Orders.TableItem.Seats.Main', { count: mainSlotsCount })}
            </Value>
            <Value>
              {t('Orders.TableItem.Seats.Extra', { count: extraSlotsCount })}
            </Value>
          </Content>
        </Cell>
        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.AdultsMain')}
            </Label>
            {hasAdultsMain
              ? adultsMain
              : <Value empty>{t('Order.Traveller.NoTraveller')}</Value>
            }
          </Content>
        </Cell>
        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.ChildMain')}
            </Label>
            {hasChildMain
              ? childMain
              : <Value empty>{t('Order.Traveller.NoTraveller')}</Value>
            }
          </Content>
        </Cell>
        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.AdultsExtra')}
            </Label>
            {hasAdultsExtra
              ? adultsExtra
              : <Value empty>{t('Order.Traveller.NoTraveller')}</Value>
            }
          </Content>
        </Cell>
        <Cell>
          <Content>
            <Label>
              {t('Orders.TableItem.ChildExtra')}
            </Label>
            {hasChildExtra
              ? childExtra
              : <Value empty>{t('Order.Traveller.NoTraveller')}</Value>
            }
          </Content>
        </Cell>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reservation))``;
