import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.article`
  max-width: 672px;
`;

@inject('hotelsInfo')
@observer
class Description extends React.Component {
  @computed get description() {
    const { hotelsInfo } = this.props;
    const { description } = hotelsInfo && hotelsInfo.data && hotelsInfo.data.toJSON();
    return description;
  }

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <p>
          {this.description}
        </p>
      </Wrapper>
    );
  }
}

Description.propTypes = {
  className: PropTypes.string,
  hotelsInfo: PropTypes.object
};

Description.defaultProps = {
  className: ''
};

export default styled(Description)``;
