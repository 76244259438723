import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';

import OrderNumber from './OrderNumber';
import Status from './Status';
import Contract from './Contract';
import Creator from './Creator';
import PrimaryActions from './PrimaryActions';
import SecondaryActions from './SecondaryActions';
import NotAvailableActions from './NotAvailableActions';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:first-child {
    margin-bottom: 14px;
  }

  &:last-child {
    margin-top: 30px;
  }

  ${Status} {
    max-width: 396px;
    flex-grow: 1;
  }

  ${Creator} {
    flex-grow: 1;
  }

  ${OrderNumber} + ${Status} {
    margin-left: 24px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 960px;
  padding: 20px 26px 26px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);

  ${SecondaryActions} {
    flex-grow: 1;
    align-self: flex-end;
  }
`;

@inject('showState')
@withRouter
@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get isCancelled() {
    const {
      showState: { order }
    } = this.props;
    return order.provider_state.code === 'cancelled';
  }

  handleCreatorUpdate = (creator) => {
    const { showState } = this.props;
    showState.updateCreator(creator);
  };

  render() {
    const {
      showState: { order, permissions, isPending },
      t,
      ...rest
    } = this.props;
    const isDeclared = order.is_declared;

    return (
      <Wrapper {...rest}>
        <Row>
          <OrderNumber order={order} />

          <Status order={order} />

          <Creator
            order={order}
            isLoading={isPending}
            isDisabled={!permissions.update_creator}
            onChange={this.handleCreatorUpdate}
          />
        </Row>

        <Row>
          <Contract order={order} />
        </Row>

        <Row>
          {this.isCancelled ? (
            <NotAvailableActions />
          ) : (
            <PrimaryActions isDeclared={isDeclared} />
          )}

          <SecondaryActions />
        </Row>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
