import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import OrderNumber from './OrderNumber';
import Status from './Status';
import PrimaryActions from './PrimaryActions';
import SecondaryActions from './SecondaryActions';

const Cell = styled.div`
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & + & {
    margin-top: 30px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 960px;
  padding: 20px 26px 26px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(36,95,119,0.21);
`;

@inject('showState')
@withRouter
@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { showState: { order }, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Row>
          <Cell>
            <OrderNumber
              order={order}
            />
          </Cell>
          <Cell>
            <Status
              order={order}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>
            <PrimaryActions />
          </Cell>
          <Cell>
            <SecondaryActions />
          </Cell>
        </Row>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
