import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

const Wrapper = styled.div`
  font-size: 30px;
  color: #4A515C;
  font-weight: bold;
  line-height: 46px;
  white-space: nowrap;
`;

@withRouter
@observer
class OrderNumber extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    order: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { order, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {t('Order.Number', { ...order })}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(OrderNumber))``;
