import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { fullName } from 'utils/personName';

const Name = styled.div`
  color: #4A515C;
  font-size: 13px;
  line-height: 22px;
  font-weight: bold;
`;

const Document = styled.div`
  color: #979BA0;
  font-size: 13px;
  line-height: 22px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: middle;

  ${Name} + ${Document} {
    &:before {
      content: '/';
      margin: 0 10px;
    }
  }
`;

@observer
class Traveller extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    traveller: PropTypes.object.isRequired
  };

  render() {
    const { traveller, t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Name>
          {fullName(traveller)}
        </Name>

        <Document>
          {t('Traveller.PolisNumber', { ...traveller.document })}
        </Document>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Traveller))``;
