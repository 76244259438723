import { types } from 'mobx-state-tree';

import { Price, PriceFormation, Insurant } from 'stores/models';

const PricePeriod = types.model('PricePeriod', {
  value: types.number,
  unit: types.enumeration(['day', 'night'])
});

const AgePeriod = types.model('AgePeriod', {
  from: types.number,
  to: types.number
});

const AvailablePeriod = types.model('AvailablePeriod', {
  from: types.string,
  to: types.string
});

const StayPeriod = types.model('StayPeriod', {
  min_days: types.number
});

const Service = types.model('Service', {
  id: types.identifier,
  name: types.string,
  description: types.string,
  pace: types.string,
  pace_name: types.string,
  quantity: types.number
});

const Traveller = types.model('Traveller', {
  age_group: types.string,
  age: types.maybeNull(types.number)
});

const Rate = types.model('Rate', {
  type: types.string,
  description: types.string,
  age_group: types.enumeration(['adult', 'child']),
  age_period: types.maybeNull(AgePeriod),
  occupation: types.enumeration(['main', 'extra', 'no']),
  count: types.number
});

const Slot = types.model('Slot', {
  traveller: Traveller,
  rate: Rate
});

const Tariff = types.model('Tariff', {
  id: types.identifier,
  tariff_id: types.string,
  name: types.string,
  type: types.enumeration(['base', 'package', 'tour']),
  description: types.string,
  multi_seat: types.boolean,
  price: Price,
  discount: Price,
  discount_price: Price,
  discount_percent: types.optional(types.number, 0),
  price_period: PricePeriod,
  price_formation: PriceFormation,
  available_period: AvailablePeriod,
  restrictions: types.frozen(),
  stay_period: types.optional(StayPeriod, { min_days: 0 }),
  rates: types.array(Rate),
  services: types.array(Service),
  billing_hour: types.enumeration(['day', 'night']),
  price_calculation_rule: types.enumeration(['float', 'fixed']),
  insurant: types.maybeNull(Insurant),
  slots: types.array(Slot)
});

export default Tariff;
