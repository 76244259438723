import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled, { css } from 'styled-components';

import {
  Check as CheckIcon,
  Close as CloseIcon
} from 'components/icons';

const Icons = {
  success: <CheckIcon />,
  error: <CloseIcon />
};

const Icon = styled.div`
  font-size: 10px;
  color: inherit;
`;

const Message = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: inherit;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 24px;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.17);

  ${({ type }) => type === 'success' && css`
    background-color: #5AB2D6;
    color: #FFFFFF;
  `}

  ${({ type }) => type === 'error' && css`
    background-color: #FFB3B3;
    color: #4A515C;
  `}
`;

@observer
class Notification extends Component {
  static propTypes = {
    className: PropTypes.string,
    notification: PropTypes.object.isRequired,
    onRemove: PropTypes.func
  }

  static defaultProps = {
    onRemove: () => {}
  }

  componentDidMount() {
    this.startTimer();
  }

  startTimer() {
    const { notification: { time } } = this.props;

    this._timer = setTimeout(this.hideNotification, time * 1000);
  }

  stopTimer() {
    if (!this._timer) return;

    clearTimeout(this._timer);
  }

  hideNotification = () => {
    const { notification, onRemove } = this.props;
    onRemove(notification);
  }

  handleClick = (e) => {
    e.preventDefault();
    this.hideNotification();
  }

  render() {
    const { notification: { type, message }, className } = this.props;

    return (
      <Wrapper
        type={type}
        onClick={this.handleClick}
        className={className}
      >
        <Message>
          {message}
        </Message>

        <Icon>
          {Icons[type]}
        </Icon>
      </Wrapper>
    );
  }
}

export default styled(Notification)``;
