import React from 'react';
import PropTypes from 'prop-types';
import { observer, Provider } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';
import Order from './components/Order';
import HotelsInfo from '../HotelsInfo';

import BookingState from 'forms/Orders/BookingState';

@withRouter
@observer
class Edit extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    // BookingState
    const { match: { params } } = this.props;
    this.bookingState = new BookingState(params);
  }

  render() {
    return (
      <Provider
        bookingState={this.bookingState}
      >
        <>
          <DefaultTemplate
            header={<Header />}
            body={<Order />}
          />

          <HotelsInfo />
        </>
      </Provider>
    );
  }
}

export default Edit;
