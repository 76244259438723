import instance from 'connection/instance';
import Queue from './Queue';
import normalizeFetchParams from './utils/normalizeFetchParams';
import normalizeFiltersParams from './utils/normalizeFiltersParams';
import applyFiltersToData from './utils/applyFiltersToData';
import applySortToData from './utils/applySortToData';
import getPricesRange from './utils/getPricesRange';
import _isEqual from 'lodash/isEqual';

const SEARCH_URL = '/api/hotels/search';

class QueueStore {
  constructor(options = {}) {
    this.lazy = false;
    this.queue = new Queue();
    this.data = [];
    this.currentData = [];
    this.fetchedData = [];
    this.currentFetchParams = {};
  }

  // Hooks
  onSetStatus = () => { };
  onSetTotal = () => { };
  onSetData = () => { };
  onSetAvailable = () => { };

  setHooks(options = {}) {
    const defaultCb = () => { };

    this.onSetStatus = options.onSetStatus || defaultCb;
    this.onSetTotal = options.onSetTotal || defaultCb;
    this.onSetData = options.onSetData || defaultCb;
    this.onSetSeo = options.onSetSeo || defaultCb;
    this.onSetAvailable = options.onSetAvailable || defaultCb;
    this.onSetPricesRange = options.onSetPricesRange || defaultCb;
  }

  incFetchedData(value) {
    this.fetchedData = [...this.fetchedData, ...value];
  }

  setFetchedData(value) {
    this.fetchedData = value;
  }

  setCurrentData(value) {
    this.currentData = value;
    this.onSetData(this.currentData);
  }

  // Pagination
  currentPage = 1

  perPage = 50

  totalPages = 0

  // Total
  total = 0

  setTotal(value) {
    this.total = value;
    this.onSetTotal(this.total);
  }

  // SEO
  seo = {}

  setSeo(value) {
    this.seo = value;
    this.onSetSeo(this.seo);
  }

  // Prices
  pricesRange = [0, 0];

  setPricesRange(value) {
    this.pricesRange = value;
    this.onSetPricesRange(this.pricesRange);
  }

  // Available
  available = 0

  setAvailable(value) {
    this.available = Number(value);
    this.onSetAvailable(this.available);
  }

  incAvailable(value) {
    const newValue = this.available + Number(value);
    this.setAvailable(newValue);
  }

  // Store state
  status = undefined

  setStatus(value) {
    this.status = value;
    this.onSetStatus(this.status);
  }

  async fetch(params) {
    // Fetch data if needed
    const fetchParams = normalizeFetchParams(params);

    let isServerFetch;

    if (!_isEqual(this.currentFetchParams, fetchParams)) {
      isServerFetch = true;
      this.currentFetchParams = fetchParams;
    }

    if (this.fetchedData.length < 1) {
      isServerFetch = true;
    }

    if (isServerFetch) {
      await this.cancelTasks();
      await this.fetchData(fetchParams);
    }

    // Apply filters
    const rawData = this.fetchedData;
    const filteredData = this.applyFilters(rawData, params);

    this.setAvailable(filteredData.length);
    this.setCurrentData(filteredData);

    // Set PricesRange
    const range = getPricesRange(rawData);
    this.setPricesRange(range);
  }

  applyFilters(items, params) {
    const { filterBy, sortBy } = normalizeFiltersParams(params);

    let data = applyFiltersToData({ data: items, filterBy });
    data = applySortToData({ data, sortBy });

    return data;
  }

  async fetchData(query) {
    const params = {
      data: {
        ...query,
        paginate: {
          page: 1,
          limit: this.perPage
        }
      }
    };

    try {
      this.setStatus('pending');

      const { status, data: response } = await instance.get(SEARCH_URL, { params });

      if (status !== 200) {
        this.setStatus('error');
        return;
      }

      this.setFetchedData([]);
      this.setAvailable(0);
      this.parseMeta(response);
      this.parseChunk(response);

      await this.buildTasks(query);

      this.setStatus('done');
    } catch (e) {
      this.setStatus('error');
    }
  }

  parseMeta = ({ meta }) => {
    const { total, paginate, seo } = meta;

    this.currentPage = Number(paginate.page);
    this.perPage = Number(paginate.limit);
    this.totalPages = Number(paginate.total_pages);
    this.setTotal(total);
    this.setSeo(seo);
  }

  parseChunk = ({ meta, data }) => {
    this.incFetchedData(data);
    this.incAvailable(data.length);
    this.setCurrentData(this.fetchedData);
  }

  async buildTasks(query) {
    while (this.currentPage < this.totalPages) {
      this.currentPage++;
      const params = { ...query, paginate: { page: this.currentPage, limit: this.perPage } };

      const exec = (data) => {
        const params = { data };
        return () => instance.get(SEARCH_URL, { params });
      };

      const callback = () => {
        return (response) => this.parseChunk(response);
      };

      this.queue.addTask(exec(params), callback());
    }

    return this.queue.execute();
  }

  async cancelTasks() {
    await this.queue.cancel();
    this.currentPage = 1;
  }
}

export default QueueStore;
