import { computed, observable, action, toJS } from 'mobx';
import moment from 'utils/moment';
import _sortBy from 'lodash/sortBy';
import { t } from 'utils/localization';
import pricesPayment from 'utils/pricesPayment';

import _findIndex from 'lodash/findIndex';
import _first from 'lodash/first';
import _last from 'lodash/last';

const CASH = {
  type: 'cash'
};

const BANK_TRANSFER = {
  type: 'bank_transfer'
};

const DEFAULT_VALUE = {
  check_in: null,
  check_out: null,
  price: 0,
  duration: { value: 0, unit: 'night' }
};

class RangeForm {
  constructor(props) {
    const { prices, tariff } = props;

    this.setPrices(prices);
    this.setTariff(tariff);
  }

  @computed get byNight() {
    const { billing_hour } = this.tariff;
    return billing_hour === 'night';
  }

  @computed get byDay() {
    const { billing_hour } = this.tariff;
    return billing_hour === 'day';
  }

  @computed get dailyShift() {
    const shift = this.byDay ? 0 : 1;
    return shift;
  }

  @computed get isFullSelected() {
    const { type } = this.tariff;

    switch (type) {
      case 'base':
        return false;

      case 'package':
        return true;

      case 'tour':
        return true;

      default:
        return false;
    }
  }

  @computed get selectedRange() {
    const items = toJS(this.prices);

    let selected = toJS(this.selected);
    selected = Object.values(selected);
    selected = _sortBy(selected, i => i.day);

    const firstSelected = _first(selected);
    const firstIdx = _findIndex(items, ({ day }) => day === firstSelected?.day);

    const lastSelected = _last(selected);
    const lastIdx = _findIndex(items, ({ day }) => day === lastSelected?.day);

    return [firstIdx, lastIdx, items.length - 1];
  }

  @computed get isDisabled() {
    const [firstIdx, lastIdx, count] = this.selectedRange;
    if (this.selected.size === 0) return false;

    const byBoundary = firstIdx === 0 || lastIdx === count;
    if (!byBoundary) return true;

    const selectedCount = this.byDay ? 0 : 1;
    const byCount = this.selected.size > selectedCount;
    if (!byCount) return true;

    return false;
  }

  @computed get payment() {
    const [firstIdx, lastIdx] = this.selectedRange;
    const prices = toJS(this.prices);

    const selected = firstIdx < 0
      ? []

      : (
        prices.splice(firstIdx, lastIdx - firstIdx + 1)
          .map(item => ({ ...item, payment_method: CASH }))
      );

    const values = _sortBy([...prices, ...selected], 'day');

    return pricesPayment({ tariff: this.tariff, prices: values });
  }

  @computed get selectedValues() {
    const value = this.payment.cash || DEFAULT_VALUE;

    let description = [
      t('Orders.PaymentMethod', CASH),
      t('UI.Duration', value.duration)
    ];

    description = description.join(' – ');

    return { ...value, description };
  }

  @computed get unselectedValues() {
    const value = this.payment.bank_transfer || DEFAULT_VALUE;

    let description = [
      t('Orders.PaymentMethod', BANK_TRANSFER),
      t('UI.Duration', value.duration)
    ];

    description = description.join(' – ');

    return { ...value, description };
  }

  @observable.ref tariff = undefined

  @action
  setTariff(value) {
    this.tariff = value;
  }

  @action
  unsetTariff() {
    this.tariff = undefined;
  }

  @observable prices = [];

  @computed get pricesValue() {
    const payment_method = CASH;

    const items = toJS(this.prices)
      .map(item => (
        this.isSelected(item)
          ? { ...item, payment_method }
          : item
      ));

    return items;
  }

  @action
  setPrices(prices) {
    this.clearSelected();

    const payment_method = BANK_TRANSFER;

    prices
      .filter(item => item.payment_method.type === 'cash')
      .map(item => this.addSelected(item));

    const items = prices
      .map(item => ({ ...item, payment_method }));

    this.prices = items;
  }

  @action
  unsetPrices() {
    this.prices = [];
  }

  @computed get startDay() {
    const days = [7, 1, 2, 3, 4, 5, 6];

    const startDay = this.prices[0].day;
    const idx = moment(startDay).day();

    return days[idx];
  }

  @computed get days() {
    return moment.weekdaysMin(true);
  }

  @observable selected = new Map();

  @action
  addSelected(item) {
    this.selected.set(item.day, item);
  }

  @action
  removeSelected(item) {
    this.selected.delete(item.day);
  }

  @action
  deselect(item) {
    this.selected.delete(item.day, item);
  }

  @action
  selectAfter(selectedIdx) {
    const items = toJS(this.prices);
    items.slice(selectedIdx, items.length).map(i => this.addSelected(i));
  }

  @action
  selectBefore(selectedIdx) {
    const items = toJS(this.prices);
    items.slice(0, selectedIdx).map(i => this.addSelected(i));
  }

  @action
  clearSelected() {
    this.selected.clear();
  }

  @computed get firstSelected() {
    const items = toJS(this.selected);
    return Object.values(items)[0];
  }

  isSelected(item) {
    return this.selected.get(item.day);
  }

  @observable hovered = new Map();

  @action
  addHovered(item) {
    this.hovered.set(item.day, item);
  }

  @action
  removeHovered(item) {
    this.hovered.delete(item.day);
  }

  hoverAfter(selectedIdx) {
    const items = toJS(this.prices);
    items.slice(selectedIdx, items.length).map(i => this.addHovered(i));
  }

  hoverBefore(selectedIdx) {
    const items = toJS(this.prices);
    items.slice(0, selectedIdx).map(i => this.addHovered(i));
  }

  @action
  leave(item) {
    this.clearHovered();
  }

  @action
  clearHovered() {
    this.hovered.clear();
  }

  isHovered(item) {
    return this.hovered.get(item.day);
  }

  @action
  fullSelect(item) {
    const { selected } = this;

    if (selected.size === 1) {
      this.prices.forEach((p) => {
        this.selected.set(p.day, p);
      });
    }

    this.selected.set(item.day, item);
  }

  @action
  partialSelect(item) {
    const { selected, dailyShift } = this;

    if (selected.size === 1) {
      const firstIdx = this.prices
        .findIndex(i => i.day === this.firstSelected.day);

      const lastIdx = this.prices
        .findIndex(i => i.day === item.day);

      if (firstIdx === 0 && firstIdx === lastIdx) {
        this.selectBefore(lastIdx + 1 + dailyShift);
        return;
      }

      if (lastIdx === this.prices.length - 1 && firstIdx === lastIdx) {
        this.selectAfter(lastIdx - dailyShift);
        return;
      }

      if (firstIdx === 0) {
        this.selectBefore(lastIdx + 1);
        return;
      }

      if (firstIdx === this.prices.length - 1) {
        this.selectAfter(lastIdx);
        return;
      }

      if (lastIdx > firstIdx) {
        this.selectAfter(firstIdx);
        return;
      }

      if (lastIdx < firstIdx) {
        this.selectBefore(firstIdx);
        return;
      }
    }

    if (selected.size >= 2) {
      this.clearSelected();
      this.clearHovered();
    }

    this.selected.set(item.day, item);
  }

  @action
  select(item) {
    const { isFullSelected } = this;

    isFullSelected
      ? this.fullSelect(item)
      : this.partialSelect(item);
  }

  @action
  enter(item) {
    if (this.selected.size !== 1) return;

    const selectedIdx = this.prices
      .findIndex(i => i.day === this.firstSelected.day);

    const hoveredIdx = this.prices
      .findIndex(i => i.day === item.day);

    if (selectedIdx === 0) {
      this.hoverBefore(hoveredIdx + 1);
      return;
    }

    if (selectedIdx === this.prices.length - 1) {
      this.hoverAfter(hoveredIdx);
      return;
    }

    if (hoveredIdx > selectedIdx) {
      this.hoverAfter(selectedIdx);
      return;
    }

    if (hoveredIdx < selectedIdx) {
      this.hoverBefore(selectedIdx);
      return;
    }

    this.clearHovered();
  }
}

export default RangeForm;
