import { types } from 'mobx-state-tree';
import { Contract, PaymentMethod } from 'stores/models';

const Hotel = types.model('Hotel', {
  id: types.string,
  name: types.string
});

const RoomType = types.model('RoomType', {
  id: types.string,
  name: types.string,
  beds: types.number,
  extra_beds: types.number
});

const StayPeriod = types.model('StayPeriod', {
  min_days: types.number
});

const Tariff = types.model('Tariff', {
  id: types.string,
  name: types.string,
  type: types.enumeration(['base', 'package', 'tour']),
  description: types.string,
  billing_hour: types.enumeration(['night', 'day']),
  price_calculation_rule: types.enumeration(['float', 'fixed']),
  stay_period: StayPeriod
});

const Rate = types.model('Rate', {
  type: types.string,
  description: types.string,
  count: types.number,
  age_group: types.string,
  occupation: types.string
});

const Document = types.model('Document', {
  number: types.string
});

const Traveller = types.model('Traveller', {
  id: types.identifier,
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybeNull(types.string),
  age_group: types.enumeration(['adult', 'child']),
  gender: types.enumeration(['male', 'female', 'not_defined']),
  document: Document
});

const DailyPrice = types.model('DailyPrice', {
  day: types.string,
  price: types.number,
  payment_method: PaymentMethod
});

const Slot = types.model('Slot', {
  id: types.identifier,
  count: types.number,
  price: types.number,
  cash: types.boolean,
  rate: Rate,
  prices: types.optional(types.array(DailyPrice), []),
  traveller: types.maybeNull(Traveller),
  state: types.enumeration(['booked', 'confirmed', 'cancelled']),
  contract: types.maybeNull(Contract)
});

const Reservation = types.model('Reservation', {
  id: types.identifier,
  price: types.number,
  state: types.enumeration(['booked', 'confirmed', 'cancelled']),
  check_in: types.string,
  check_out: types.string,
  hotel: Hotel,
  room_type: RoomType,
  tariff: Tariff,
  contract: types.maybeNull(Contract),
  slots: types.array(Slot)
});

export { Reservation };
