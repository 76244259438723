import moment from 'utils/moment';
import { t } from 'utils/localization';

const genderItems = [{
  value: 'male',
  label: t('Form.Gender.Male')
}, {
  value: 'female',
  label: t('Form.Gender.Female')
}];

const fields = [
  'id',
  'rooms_count',
  'updatedAt',
  '_destroy',
  'hotel',
  'hotel.id',
  'hotel.name',
  'hotel.address',
  'hotel.address.location',
  'hotel.address.coordinates',
  'room_type',
  'room_type.id',
  'tariff',
  'tariff.id',
  'check_in',
  'check_out',
  'price',
  'duration',
  'duration.value',
  'duration.unit',
  'payment.total',
  'payment.total.price',
  'payment.bank_transfer',
  'payment.bank_transfer.price',
  'payment.cash',
  'payment.cash.price',
  'contract',
  'contract.id',
  'contract.number',
  'contract.price_prefix',
  'contract.insured_name',
  'contract.insured_inn',
  'contract.insured_kpp',
  'contract.begin_date',
  'contract.end_date',
  'slots[]',
  'slots[].id',
  'slots[]._destroy',
  'slots[].price',
  'slots[].cash',
  'slots[].rate',
  'slots[].rate.type',
  'slots[].rate.description',
  'slots[].prices[]',
  'slots[].prices[].day',
  'slots[].prices[].price',
  'slots[].prices[].payment_method',
  'slots[].prices[].payment_method.type',
  'slots[].prices[].payment_method.description',
  'slots[].traveller',
  'slots[].traveller.id',
  'slots[].traveller.full_name',
  'slots[].traveller.first_name',
  'slots[].traveller.last_name',
  'slots[].traveller.middle_name',
  'slots[].traveller.gender',
  'slots[].traveller.document',
  'slots[].traveller.document.number',
  'slots[].contract',
  'slots[].contract.id',
  'slots[].contract.number',
  'slots[].contract.price_prefix',
  'slots[].contract.insured_name',
  'slots[].contract.insured_inn',
  'slots[].contract.insured_kpp',
  'slots[].contract.begin_date',
  'slots[].contract.end_date',
  'slots[].prices[]',
  'slots[].prices[].day',
  'slots[].prices[].price',
  'slots[].prices[].payment_method',
  'slots[].prices[].payment_method.type'
];

const placeholders = {
  address: 'Поиск по адресу',
  hotel: 'Поиск по названию санаторию'
};

const labels = {
};

const extra = {
  'slots[].traveller.gender': genderItems
};

const rules = {
  check_in: 'required|string',
  check_out: 'required|string',
  hotel: 'required',
  'hotel.id': 'required|string',
  'hotel.name': 'required|string',
  room_type: 'required',
  'room_type.id': 'required|string',
  'room_type.name': 'required|string',
  tariff: 'required',
  'tariff.id': 'required|string',
  'tariff.name': 'required|string',
  slots: 'required|array|min:1',
  'slots[].rate': 'required',
  'slots[].rate.type': 'required|string'
  // 'slots[].contract': 'required',
  // 'slots[].contract.number': 'required',
  // 'slots[].contract.platform_id': 'required',
  // 'slots[].contract.price_prefix': 'required',
  // 'slots[].contract.insured_name': 'required',
  // 'slots[].contract.insured_inn': 'required',
  // 'slots[].contract.insured_kpp': 'required',
  // 'slots[].contract.begin_date': 'required',
  // 'slots[].contract.end_date': 'required'
  // 'slots[].traveller': 'required',
  // 'slots[].traveller.first_name': 'required|min:2',
  // 'slots[].traveller.last_name': 'required|min:2',
  // 'slots[].traveller.age': 'required',
  // 'slots[].traveller.gender': 'required'
};

const types = {
};

const values = {
  duration: {
    value: 1,
    unit: 'night'
  },
  slots: []
};

const input = {
  'slots[].traveller.gender': (value) => {
    return value ? genderItems.find(item => item.value === value) : value;
  }
};

const output = {
  'slots[].traveller.gender': (gender) => gender ? gender.value : gender,
  check_in: date => moment(date).format(),
  check_out: date => moment(date).format()
};

export default {
  fields,
  labels,
  placeholders,
  rules,
  types,
  values,
  extra,
  output,
  input
};
