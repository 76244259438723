import { types, applySnapshot, resolveIdentifier } from 'mobx-state-tree';
import { toJS } from 'mobx';
import instance from 'connection/instance';
import instanceBlob from 'connection/instanceBlob';
import cleanDeep from 'clean-deep';

import { destroyParams } from './paramsMapper';

import { Order, Meta } from './Order';
import { Reservation } from './Reservation';

const OrderStore = types
  .model('OrderStore', {
    meta: types.maybeNull(Meta),
    data: types.maybeNull(Order),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error']))
  })
  .views(self => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    }
  }))
  .actions(self => ({
    fetch(params = {}) {
      self.setState('pending');

      const { id, ...options } = params;

      return instance.get(`/api/orders/${id}`, { options })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    create(values = {}) {
      self.setState('pending');

      const data = cleanDeep(values, { emptyArrays: false });

      return instance.post('/api/orders', { data })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    update(values = {}) {
      self.setState('pending');

      const { id, ...data } = cleanDeep(values, { emptyArrays: false });

      return instance.put(`/api/orders/${id}`, { data })
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    confirm(params = {}) {
      self.setState('pending');

      const { id } = params;

      return instance.post(`/api/orders/${id}/confirm`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    cancel() {
      self.setState('pending');

      const { id } = self.data;

      return instance.post(`/api/orders/${id}/cancel`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    cancelReservations(ids) {
      const reservations = ids
        .map(id => resolveIdentifier(Reservation, self, id));

      const values = destroyParams({
        order: toJS(self.data),
        reservations: toJS(reservations)
      });

      return self.update(values);
    },

    syncOnPlatform() {
      self.setState('pending');

      const { id } = self.data;

      return instance.post(`/api/orders/${id}/sync_on_platform`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    downloadReport() {
      self.setState('pending');

      const { id } = self.data;

      return instanceBlob.get(`/api/orders/${id}/download_report`)
        .then((response) => self.loadFile(response.data))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    recalc() {
      self.setState('pending');

      const { id } = self.data;

      return instance.post(`/api/orders/${id}/recalc`)
        .then(response => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch(error => self.errorHandler(error));
    },

    loadFile(data) {
      const { order_code } = self.data;
      const blob = new Blob(
        [data],
        { type: 'application/vnd.ms-excel;charset=UTF-8' }
      );

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      const filename = ['order_', order_code, '.xlsx'].join('');

      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) {
        applySnapshot(self, data);
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    clear() {
      applySnapshot(self, {});
    }
  }));

export default OrderStore;
