import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { display, transition } from 'theme/mixins';

import { BlockShadow } from 'components/atoms';
import { Edit, Delete } from 'components/icons';
import { Reservation, Slot } from 'pages/Orders/components';

const Controls = styled.div`
  ${display('flex', 'center')};
  ${transition()}
  flex-wrap: nowrap;
`;

const Control = styled.div`
  cursor: pointer;

  & + & {
    margin-left: 12px;
  }
`;

const Traveller = styled.div`
  ${display('flex', 'center', 'space-between')};

  & + & {
    margin-top: 8px;
  }
`;

const Slots = styled.div``;

const Wrapper = styled(BlockShadow)`
  padding: 20px;

  ${Traveller} + ${Reservation.Room} {
    margin-top: 12px;
  }

  ${Slots} {
    :not(:first-child) {
      margin-top: 4px;
    }

    :not(:last-child) {
      margin-bottom: 4px;
    }
  }
`;

@observer
class ReservationListItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    reservation: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: '',
    onEdit: () => undefined,
    onRemove: () => undefined
  };

  render() {
    const { reservation, onEdit, onRemove, ...rest } = this.props;

    const { slots } = reservation;

    const listItems = slots.map(slot => (
      <Slot
        key={slot.id}
        slot={slot}
        reservation={reservation}
      />
    ));

    return (
      <Wrapper {...rest}>
        <Traveller>
          <Reservation.Traveller reservation={reservation} />

          <Controls>
            <Control onClick={() => onEdit(reservation)}>
              <Edit />
            </Control>

            <Control onClick={() => onRemove(reservation)}>
              <Delete />
            </Control>
          </Controls>
        </Traveller>

        <Reservation.Room reservation={reservation} />

        <Slots>
          {listItems}
        </Slots>
      </Wrapper>
    );
  }
}

export default styled(ReservationListItem)``;
