import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { BlockShadow } from 'components/atoms';
import { UserActions } from '.';

import { display, typography } from 'theme/mixins';

const Wrapper = styled(BlockShadow)`
  ${display('flex')}
`;

const Section = styled.div`
  ${({ theme }) => css`
    :not(:first-child) {
      padding-left: 12px;
    }

    :not(:last-child) {
      padding-right: 12px;
      border-right: 1px solid ${theme.colors.popup.primary};
    }
  `}
`;

const Label = styled.div`
  ${({ theme }) => css`
    margin: 4px 0;
    color: ${theme.colors.text.secondary};
    ${typography(12, 16)}
  `}
`;

const Value = styled.div`
  margin: 4px 0;
  ${typography(14, 16)}
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FullNameSection = styled(Section)`
  flex-grow: 1;
`;

const EmailSection = styled(Section)`
  width: 22%;
`;

const PhoneSection = styled(Section)`
  width: 18%;
`;

const RoleSection = styled(Section)`
  width: 20%;
`;

const ActionsSection = styled(Section)`
  ${display('flex', 'center')}
  width: 48px;
`;

@withTheme
class UserCard extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    user: PropTypes.object.isRequired,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  handleUserAction = (item) => {
    console.log(item);
  }

  render() {
    const { user, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <FullNameSection>
          <Label>
            {t('Profile.FullNameAcronym.Label')}
          </Label>

          <Value>
            {user.last_name} {user.first_name} {user.middle_name}
          </Value>
        </FullNameSection>

        <EmailSection>
          <Label>
            {t('Profile.Email.Label')}
          </Label>

          <Value>
            {user.email}
          </Value>
        </EmailSection>

        <PhoneSection>
          <Label>
            {t('Profile.Phone.Label')}
          </Label>

          <Value>
            {user.phone}
          </Value>
        </PhoneSection>

        <RoleSection>
          <Label>
            {t('Profile.Role.Label')}
          </Label>

          <Value>
            {user.role}
          </Value>
        </RoleSection>

        <ActionsSection>
          <UserActions onChange={this.handleUserAction} />
        </ActionsSection>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(UserCard))``;
