import { types } from 'mobx-state-tree';

import { Address, Contract, ProviderState, PlatformState } from 'stores/models';

const Guests = types.model('Guests', {
  count: types.integer
});

const Rooms = types.model('Rooms', {
  count: types.integer
});

const Hotel = types.model('Hotel', {
  id: types.string,
  name: types.string,
  address: Address
});

const Creator = types.model('Creator', {
  id: types.string,
  email: types.string,
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybeNull(types.string)
});

export const OrderItem = types.model('OrderItem', {
  id: types.identifier,
  order_code: types.string,
  updated_at: types.string,
  created_at: types.string,
  check_in: types.string,
  check_out: types.string,
  creator: Creator,
  hotel: types.maybeNull(Hotel),
  guests: Guests,
  rooms: Rooms,
  provider_state: ProviderState,
  platform_state: types.maybeNull(PlatformState),
  contract: types.maybeNull(Contract)
});
