import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

import { OrderOptions } from 'pages/Orders/components';
import { AddRoom, ReservationGroup } from '.';

import BookingCart from 'forms/Orders/BookingCart';

const Wrapper = styled.div`
  width: 528px;
  margin: 32px auto;

  ${ReservationGroup} {
    cursor: pointer;
  }
`;

@withRouter
@inject('orderStore')
@observer
class ChooseRooms extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history.isRequired,
    isOpened: PropTypes.bool,
    match: ReactRouterPropTypes.match.isRequired,
    onAccept: PropTypes.func,
    onClose: PropTypes.func,
    orderStore: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    const order = props.orderStore.data;
    this.bookingCart = new BookingCart({ order });
  }

  handleAddRoom = (reservation) => {
    const { history } = this.props;
    const { order: { id } } = this.bookingCart;

    reservation
      ? history.push(`/orders/${id}/edit/${reservation.id}`)
      : history.push(`/orders/${id}/edit`);
  }

  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  render() {
    const { isOpened, t, ...rest } = this.props;

    const { order, reservations } = this.bookingCart;

    const listItems = reservations.map(r => (
      <ReservationGroup
        key={r.id}
        onClick={() => this.handleAddRoom(r)}
        reservation={r}
      />
    ));

    return (
      <Wrapper {...rest}>
        <OrderOptions>
          <OrderOptions.Header
            order={order}
            mode='room_type'
          />

          <OrderOptions.Content>
            <AddRoom
              onClick={() => this.handleAddRoom()}
            />

            {listItems}
          </OrderOptions.Content>
        </OrderOptions>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(ChooseRooms))``;
