import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import { typography } from 'theme/mixins';

const Title = styled.div`
  color: #4a515c;
  ${typography(20, 32, 700)};
`;

const Hint = styled.div`
  display: flex-inline;
  width: auto;
  height: 34px;
  padding: 0 12px;
  color: #E68686;
  font-size: 13px;
  line-height: 34px;
  border-radius: 17px;
  background-color: #FDF1F1;
  white-space: nowrap;
`;

const Attention = styled.div`
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  ${Title} {
    flex-grow: 0;
  }

  ${Title} + ${Attention} {
    margin-left: 28px;
  }
`;

@inject('bookingState', 'bookingForm')
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingState: PropTypes.object.isRequired,
    bookingForm: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingState, bookingForm, ...rest } = this.props;

    const { room_type, tariff } = bookingState;
    const title = [room_type.name, tariff.name].join(' / ');

    const { hasHint, hint } = bookingForm;

    return (
      <Wrapper {...rest}>
        <Title>
          {title}
        </Title>

        {hasHint && (
          <Attention>
            <Hint type={hint.type}>
              {hint.message}
            </Hint>
          </Attention>
        )}
      </Wrapper>
    );
  }
}

export default styled(Header)``;
