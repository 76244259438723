import React from 'react';
import PropTypes from 'prop-types';
import { Provider, observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Body from './Body';
import Footer from './Footer';
import { Close as CloseIcon } from 'components/icons';

import { RangeForm } from '../forms';

const CloseBtn = styled.a`
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  flex-direcction: row;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  color: #3AA6D2;
`;

const Wrapper = styled.div`
  padding: 20px 154px 20px 154px;
  background-color: #F4F7FA;

  ${Header} + ${Body} {
    margin-top: 24px;
  }

  ${Body} + ${Footer} {
    margin-top: 24px;
  }
`;

@withTranslation()
@observer
class PaymentPicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    field: PropTypes.object.isRequired,
    tariff: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: '',
    tariff: {
      billing_hour: 'night',
      price_calculation_rule: 'float'
    }
  };

  constructor(props) {
    super(props);

    const { field, tariff } = props;
    const prices = field.$('prices').value;

    this.rangeForm = new RangeForm({ prices, tariff });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const { field } = this.props;

    const prices = this.rangeForm.pricesValue;
    field.update({ prices });

    field.unsetAsEdit();
  }

  handleCancel = (e) => {
    e.preventDefault();

    const { field } = this.props;
    field.unsetAsEdit();
  }

  handleClear = (e) => {
    e.preventDefault();
    this.rangeForm.clearSelected();
  }

  render() {
    const { field, t, ...rest } = this.props;

    return (
      <Provider rangeForm={this.rangeForm}>
        <Wrapper {...rest}>
          <Header>
            {t('PaymentPicker.SelectDaysByCash')}

            <CloseBtn
              onClick={this.handleCancel}
            >
              <CloseIcon />
            </CloseBtn>
          </Header>

          <Body
            field={field}
          />

          <Footer
            field={field}
            onSubmit={this.handleSubmit}
            onClear={this.handleClear}
          />
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(PaymentPicker)``;
