import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer, Provider } from 'mobx-react';
import { reaction } from 'mobx';

import { Header } from 'components/organisms';
import { DefaultTemplate } from 'components/templates';
import { Contacts } from '.';

import ContractsStore from 'stores/ContractsStore/List';
import ContactFiltersState from 'forms/Contracts/ContractFiltersState';
import AuthStore from 'stores/AuthStore';

@withRouter
@observer
class List extends React.Component {
  static propTypes = {
    header: PropTypes.any,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.filtersState = new ContactFiltersState();
    this.contractsStore = ContractsStore.create();
    this.authStore = AuthStore.create();
  }

  componentDidMount() {
    this.fetchContracts();
    this.addHandlers();
    this.authStore.fetchProfile();
  }

  componentDidUpdate(prevProps, prevState) {
    const prevSearch = prevProps.location.search;
    const currSearcch = this.props.location.search;

    if (prevSearch !== currSearcch) {
      this.fetchContracts();
    }
  }

  componentWillUnmount() {
    this.clearContracts();
    this.delHandlers();
  }

  addHandlers() {
    this._changeFiltersHandler = reaction(
      () => this.filtersState.values(),
      (values) => this.fetchContracts(),
      { delay: 1000 }
    );
  }

  delHandlers() {
    this._changeFiltersHandler();
  }

  fetchContracts() {
    const filter = this.filtersState.values();
    this.contractsStore.fetch({ filter });
  }

  clearContracts() {
    this.contractsStore.clear();
  }

  render() {
    const { header } = this.props;

    return (
      <Provider
        filtersState={this.filtersState}
        contractsStore={this.contractsStore}
        authStore={this.authStore}
      >
        <DefaultTemplate
          header={<Header showRequestButton {...header} />}
          body={<Contacts />}
        />
      </Provider>
    );
  }
}

export default List;
