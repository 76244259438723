import { t } from 'utils/localization';

const fields = ['lpu_inn'];

const placeholders = {
  lpu_inn: t('Contracts.Filters.Inn.Label'),
};

export default {
  fields,
  placeholders,
};
