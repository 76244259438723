import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CornerDown } from 'components/icons';
import styled, { css, withTheme } from 'styled-components';
import { observable, computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import withClickOutside from 'react-click-outside';
import { display, typography, flexCenterBetween } from 'theme/mixins';

import { Avatar, Bubble } from 'components/ui';

const Wrapper = styled.div`
  ${flexCenterBetween()};
  position: relative;

  ${Avatar} {
    cursor: pointer !important;
    margin: 10px !important;
  }

  ${CornerDown} {
    margin: 0 6px;
    font-size: 8px;
    fill: currentColor;
  }

  ${Bubble} {
    position: absolute;
    top: calc(100% + 8px);
    left: 50%;
    transform: translate(-50%);
  }
`;

const UserName = styled.div`
  ${display('flex', 'center')}
  cursor: pointer;
  ${typography(14, 17, 500)};
  user-select: none;
`;

const UserNameInner = styled.span`
  white-space: nowrap;
`;

const LogOutControl = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.active.primary};
    cursor: pointer;
  `}
`;

@inject('authStore')
@withClickOutside
@withTheme
@observer
class UserBar extends React.Component {
  @observable isOpened = false

  static propTypes = {
    authStore: PropTypes.object,
    className: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: '',
    avatar: '',
    name: ''
  };

  @computed get user() {
    const { authStore } = this.props;
    const { avatar, email, first_name, last_name } = authStore.toJSON().data;

    return {
      avatar,
      email,
      first_name,
      last_name,
      name: `${first_name} ${last_name}`
    };
  }

  handleClick = () => {
    this.isOpened = !this.isOpened;
  }

  handleClickOutside = () => {
    this.isOpened = false;
  }

  handleLogOut = () => {
    const { authStore } = this.props;
    this.isOpened = false;
    authStore.logout();
  }

  render() {
    const { t, ...rest } = this.props;
    const { avatar, name } = this.user;

    return (
      <Wrapper {...rest}>
        {avatar && (
          <Avatar
            src={avatar}
            size={25}
          />
        )}
        <UserName onClick={this.handleClick}>
          <UserNameInner>
            {name}
          </UserNameInner>
          <CornerDown />
        </UserName>
        <Bubble isOpened={this.isOpened}>
          <LogOutControl onClick={this.handleLogOut}>
            {t('Auth.LogOut')}
          </LogOutControl>
        </Bubble>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(UserBar))``;
