import React from 'react';
import styled, { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Filter } from 'pages/Contracts/components';

const SelectedFilter = styled(Filter)`
  margin: 4px;
`;

@withTheme
@inject('filtersState')
@observer
class SelectedFilters extends React.Component {
  static propTypes = {
    filtersState: PropTypes.object.isRequired,
  };

  @computed get filters() {
    const { filtersState } = this.props;
    return Array.from(filtersState.$('filters').fields.values());
  }

  handleFilterRemove = (filter) => {
    const { filtersState } = this.props;
    filtersState.removeFilter(filter);
  };

  render() {
    if (this.filters.length > 0) {
      return this.filters.map((filter) => (
        <SelectedFilter
          key={filter.id}
          filter={filter}
          onRemove={this.handleFilterRemove}
        />
      ));
    }

    return null;
  }
}

export default SelectedFilters;
