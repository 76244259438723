import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { DateTime } from 'components/ui';
import Cancel from 'pages/Contracts/containers/Cancel';
import { Button } from 'components/forms';

const Text = styled.div`
  color: #4a515c;
  font-size: 13px;
  line-height: 18px;
`;

const Cell = styled.div`
  padding-bottom: 14px;
  color: #4a515c;
  font-size: 13px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

@withTheme
class Caption extends React.PureComponent {
  static propTypes = {
    contract: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    t: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    onSelect: () => {},
  };

  handleCancel = async () => {
    const { contract, contractsStore } = this.props;
    await contractsStore.cleanService(contract);
  };

  render() {
    const {
      t,
      className,
      contract: {
        lpu_name,
        lpu_inn,
        lpu_kpp,
        lpu_dog_number,
        lpu_dog_date,
        lpu_dog_days_nights,
        services_count,
      },
      user: { role },
    } = this.props;

    const priceType = lpu_dog_days_nights === 0 ? 'Ночь' : 'День';
    const inn = !lpu_inn ? '-' : lpu_inn;
    const kpp = !lpu_kpp ? '-' : lpu_kpp;

    const servicesCount = services_count > 0 ? services_count : 0;
    const disableRemoveBtn = services_count === 0 && role === 'admin';

    return (
      <>
        <Cell className={className}>
          <Text>{lpu_name}</Text>
        </Cell>
        <Cell className={className}>
          <Text>{`${inn} / ${kpp}`}</Text>
        </Cell>
        <Cell className={className}>
          <Text>{lpu_dog_number}</Text>
        </Cell>
        <Cell className={className}>
          <Text as={DateTime} value={lpu_dog_date} />
        </Cell>
        <Cell className={className}>
          <Text>{priceType}</Text>
        </Cell>
        <Cell className={className}>
          <Text>{servicesCount}</Text>
        </Cell>
        <ButtonWrapper>
          <Cancel
            title={t('Contracts.Remove.Caption')}
            acceptText={t('Contracts.Delete')}
            declineText={t('Order.Remove.Cancel')}
            onAccept={this.handleCancel}
            onDecline={() => {}}
            disabled={disableRemoveBtn}
          >
            <Button
              size={'small'}
              rounded
              outline
              disabled={disableRemoveBtn}
              variant="danger"
            >
              {t('Contracts.Delete')}
            </Button>
          </Cancel>
        </ButtonWrapper>
      </>
    );
  }
}

export default styled(withTranslation()(Caption))``;
