import React from 'react';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Tabs } from 'components/molecules';

import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  padding: 36px 0 24px 0;

  ${Tabs} {
    margin-top: 42px;
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.active.secondary};
    ${typography(40, 48, 700)};
  `}
`;

@withTheme
@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Title>
          {t('Settings.Title')}
        </Title>

        <Tabs
          tabs={[
            {
              to: '/settings',
              label: t('Settings.General.Title')
            },
            {
              to: '/settings/contracts',
              label: t('Settings.Contracts.Title')
            },
            {
              to: '/settings/contacts',
              label: t('Settings.Contacts.Title')
            },
            {
              to: '/settings/users',
              label: t('Settings.Users.Title')
            }
          ]}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Top))``;
