import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

import Group from './Group';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 420px;
  background-color: #FFFFFF;
  box-sizing: border-box;
  overflow: hidden;

  ${Group} + ${Group} {
    margin-top: 12px;
  }
`;

function Groups({ groups, onSelect, className }) {
  const items = groups.filter(({ data }) => data.length > 0);

  const handleSelect = (place) => {
    onSelect(place);
  };

  return (
    <Wrapper className={className}>
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={372}
      >
        {items.map((group, idx) => (
          <Group
            key={idx}
            group={group}
            onSelect={handleSelect}
          />
        ))}
      </Scrollbars>
    </Wrapper>
  );
}

Groups.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired
    })
  ),
  onSelect: PropTypes.func
};

Groups.defaultProps = {
  onSelect: () => { }
};

export default styled(Groups)``;
