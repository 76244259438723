import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { setDisplayName } from 'recompose';
import RamblerInput from 'rambler-ui/Input';
import styled, { css, withTheme } from 'styled-components';

import { typography } from 'theme/mixins';
import colors from 'theme/colors';

export const variations = {
  regular: {
    default: {
      color: colors.text.primary,
      background: colors.light,
      borderColor: colors.popup.primary,
      placeholderColor: colors.text.disabled
    },
    focused: {
      color: colors.text.primary,
      borderColor: colors.active.secondary,
      placeholderColor: colors.text.disabled
    },
    disabled: {
      color: colors.text.primary,
      background: colors.light,
      borderColor: colors.popup.primary,
      placeholderColor: colors.text.disabled
    },
    success: {
      color: colors.text.primary,
      background: colors.light,
      borderColor: colors.success.primary,
      placeholderColor: colors.text.disabled
    },
    warning: {
      color: colors.text.primary,
      background: colors.light,
      borderColor: colors.error.secondary,
      placeholderColor: colors.text.disabled
    },
    error: {
      color: colors.text.primary,
      background: colors.light,
      borderColor: colors.error.primary,
      placeholderColor: colors.text.disabled
    }
  },
  transparent: {
    default: {
      color: colors.text.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    focused: {
      color: colors.text.primary,
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    disabled: {
      color: colors.text.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    success: {
      color: colors.text.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.text.disabled
    },
    warning: {
      color: colors.error.secondary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.error.secondary
    },
    error: {
      color: colors.error.primary,
      background: 'transparent',
      borderColor: 'transparent',
      placeholderColor: colors.error.primary
    }
  }
};

const variation = state => ({ variation }) => {
  const values = variations[variation] && variations[variation][state]
    ? variations[variation][state]
    : null;

  return values && css`
    color: ${values.color};
    background: ${values.background};
    border: 1px solid ${values.borderColor};

    ${variation === 'transparent' && css`
      /* Paddings compensation for 'custom' variation */
      padding-left: 14px;
      padding-right: 14px;

      border-radius: 0;
    `}

    ::placeholder {
      color: ${values.placeholderColor};
    }
  `;
};

const Wrapper = styled(RamblerInput)`
  ${({ disabled, size, status }) => css`
    &&& {
      svg {
        font-size: 20px;
      }

      & {
        border: none;
        border-radius: 4px;
      }

      .rui-Input-input {
        height: 36px;

        ${typography(13, 15, 400)};

        ${(!disabled && status) && variation(status)}
        ${disabled && variation('disabled')}
        ${(!disabled && !status) && variation('default')}

        :focus {
          ${variation('focused')};
        }

        ${size === 'large' && css`
          height: 44px;
          padding-left: 20px;
          padding-right: 20px;
          border-width: 2px;
          border-radius: 4px;
          font-size: 22px;

          :focus {
            border-width: 2px;
          }
        `}
      }

      .rui-Input-activeBorder {
        ${size === 'large' && css`
          border-width: 2px;
          border-radius: 4px;
        `}
      }

      .rui-Input-input:focus + .rui-Input-activeBorder {
        border-color: #abb3bf;
      }
    }
  `}
`;

@withTheme
@setDisplayName('Input')
@observer
class Input extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf([
      'email',
      'number',
      'password',
      'tel',
      'text',
      'url',
      'time',
      'date'
    ]),
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variation: PropTypes.oneOf(['regular', 'awesome', 'promo', 'transparent']),
    name: PropTypes.string,
    status: PropTypes.oneOf(['error', 'warning', 'success', null]),
    isFocused: PropTypes.bool,
    inputClassName: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyUp: PropTypes.func,
    onKeyDown: PropTypes.func,
    iconLeft: PropTypes.node,
    iconRight: PropTypes.node,
    field: PropTypes.object,
    theme: PropTypes.object
  };

  static defaultProps = {
    className: '',
    size: 'medium',
    variation: 'regular',
    inputClassName: '',
    iconLeft: null,
    iconRight: null
  };

  @computed get hasError() {
    const { field } = this.props;
    return field && !!field.error;
  }

  render() {
    const { field, theme, ...rest } = this.props;

    return (
      <Wrapper
        theme={theme}
        error={this.hasError ? 1 : 0}
        {...field && field.bind()}
        {...rest}
      />
    );
  }
}

export default styled(Input)``;
