import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Item = styled.div`
  width: auto;
  height: 28px;
  padding: 0 14px;
  line-height: 28px;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
  color: #4A515C;
  font-size: 13px;
  transition: all 0.1s ease-in;
  white-space: nowrap;

  &:hover {
    background-color: #C5D2E2;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6px 0;
  overflow: visible;
`;

const Wrapper = styled.div`
  position: relative;
  max-height: 480px;
  border: 1px solid #ABB3BF;
  border-radius: 5px;
  background-color: #FFFFFF;
  z-index: 2;
  overflow: auto;
`;

function Popover({ insurants, onSelect, className }) {
  const handleSelect = (insurant) => (e) => {
    onSelect(insurant);
  };

  return (
    <Wrapper className={className}>
      <List>
        {insurants.map((insurant) => (
          <Item
            key={insurant.id}
            onClick={handleSelect(insurant)}
          >
            {insurant.insured_name}
          </Item>
        ))}
      </List>
    </Wrapper>
  );
}

Popover.defaultProps = {
  onSelect: () => {}
};

Popover.propTypes = {
  className: PropTypes.string,
  insurants: PropTypes.array,
  onSelect: PropTypes.func
};

export default styled(Popover)``;
