import { t } from 'utils/localization';

const fields = ['chars', 'filters[]', 'filters[].type'];

const placeholders = {
  chars: t('Contracts.SearchContract'),
};

const values = {
  chars: '',
  filters: [],
};

const types = {
  'filters[].type': 'string',
};

const rules = {
  chars: 'string',
  filters: 'array|min:1',
};

export default {
  fields,
  rules,
  types,
  values,
  placeholders,
};
