import moment from 'utils/moment';
import _some from 'lodash/some';

function dailyPrice({ priceItem, rates }) {
  const priceRates = rates
    .map(type => priceItem.rates.find(r => r.type === type));

  const errPriceRates = _some(priceRates, (item) => !item);
  if (errPriceRates) return -1;

  return priceRates
    .reduce((sum, r) => sum + r.price, 0);
}

function dailyAvailable({ priceItem, rates }) {
  return priceItem.available;
}

function convertPricesItem({ priceItem, rates }) {
  const price = dailyPrice({ priceItem, rates });
  const available = dailyAvailable({ priceItem, rates });
  const day = moment(priceItem.day).format('Y-MM-DD');
  const { restrictions, stop_sales, available_beds } = priceItem;

  return { day, price, available, stop_sales, available_beds, restrictions };
}

function pricesPerDayForRates({ prices, rates }) {
  const pricesList = prices
    .map(priceItem => convertPricesItem({ priceItem, rates }));

  let pricesMap = pricesList.map(priceItem => [priceItem.day, priceItem]);
  pricesMap = new Map(pricesMap);

  const pricesDisabled = pricesList
    .filter(({
      price,
      available,
      stop_sales,
      restrictions: {
        closed_by_date_restrictions: {
          closed_to_departure,
          closed_to_arrival
        }
      }
    }) => price <= 0 || available <= 0 || stop_sales || closed_to_departure || closed_to_arrival);

  return { pricesList, pricesMap, pricesDisabled };
}

export default pricesPerDayForRates;
