import React from 'react';
import { Text } from 'components/atoms';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { typography } from 'theme/mixins';

const Wrapper = styled.div`
  ${Text} {
    ${typography(14, 16)};
  }

  ${Text}.name {
    ${typography(12, 16)};

    ${({ theme }) => css`
      color: ${theme.colors.text.disabled};
    `}
  }

  ${Text}.value {
    ${typography(14, 16)};
    margin: 4px 0;

    ${({ theme }) => css`
      color: ${theme.colors.text.primary};
    `}
  }
`;

@withTheme
class Info extends React.PureComponent {
  render() {
    const { name, value, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Text className="name">
          {name}
        </Text>
        <Text className="value">
          {value}
        </Text>
      </Wrapper>
    );
  }
}

Info.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  theme: PropTypes.object
};

Info.defaultProps = {
  className: ''
};

export default styled(Info)``;
