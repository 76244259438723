import { css } from 'styled-components';

const size = ({ size }) => {
  return css`
    width: auto;
    height: 28px;
    font-size: 13px;
    font-weight: 500;
  `;
};

const variants = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      color: #FFFFFF;
      background-color: #5AB2D6;
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #FFFFFF;
      background-color: #CCD5E1;
    `;
  }

  if (variant === 'success') {
    return css`
      color: #FFFFFF;
      background-color: #29C875;
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #FFFFFF;
      background-color: #E26161;
    `;
  }

  if (variant === 'warning') {
    return css`
      color: #FFFFFF;
      background-color: #EEB868;
    `;
  }

  return css``;
};

const defaults = (props) => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: lowercase;
  color: #6B707B;
  padding: 0 20px;
  border-radius: 9999px;
  background-color: transparent;
  user-select: none;

  ::before {
    content: 'Статус:';
    margin-right: 0.4em;
    opacity: 0.7;
    text-transform: capitalize;
  }
`;

const theme = (props) => ([
  defaults(props),
  size(props),
  variants(props)
]);

export default theme;
