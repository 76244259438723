import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Add } from 'components/icons';
import { ContentPlaceholder } from 'components/ui';

import { display, typography } from 'theme/mixins';

const Wrapper = styled(ContentPlaceholder)`
  ${({ theme }) => css`
    color: ${theme.colors.button.primaryHover};
    cursor: pointer;
    vertical-align: center;
    ${typography(14, 16, 500)};
    ${display('inline-flex', 'center')};

    ${Add} {
      margin-right: 6px;
    }
  `}
`;

@withTheme
class AddRoom extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Add />

        {t('Order.Reservation.AddRoom')}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(AddRoom))``;
