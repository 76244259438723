import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';

import Tariff from './Tariff';

const Wrapper = styled.div`
  ${Tariff} + ${Tariff} {
    margin-top: 15px;
  }
`;

@inject('bookingState')
@observer
class Tariffs extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object.isRequired,
    room_type: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    bookingState: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  handleSelect = (availability) => {
    const { bookingState } = this.props;
    bookingState.toggleAvailability({ availability });
  }

  render() {
    const { hotel, room_type, ...rest } = this.props;

    const listItems = room_type.tariffs.map(tariff => (
      <Tariff
        key={tariff.id}
        hotel={hotel}
        room_type={room_type}
        tariff={tariff}
        onClick={this.handleSelect}
      />
    ));

    return (
      <Wrapper {...rest}>
        {listItems}
      </Wrapper>
    );
  }
}

export default styled(Tariffs)``;
