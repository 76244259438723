import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';

import { InfiniteScroll, LinearLoader } from 'components/ui';
import Header from './Header';
import Row from './Row';

const Grid = styled.div`
  display: grid;
  grid-gap: 4px;
  grid-template-areas:
  "number dates guests hotel creator updated_at status additional"
  grid-auto-flow: row;
  grid-template-columns: repeat(7, 1fr);
`;

const Wrapper = styled.div`
  position: relative;
  padding: 10px 20px 0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);

  ${LinearLoader} {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
  }
`;

@inject('contractsStore')
@observer
class Body extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    contracts: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    onLoadMore: PropTypes.func.isRequired,
    contractsStore: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  @computed get isPending() {
    const {
      contractsStore: { isPending }
    } = this.props;
    return isPending;
  }

  handleLoadMore = (page) => {
    const { onLoadMore } = this.props;
    !this.isPending && onLoadMore(page);
  };

  render() {
    const { contracts, pagination, className, contractsStore, user } =
      this.props;

    const listItems = contracts.map((item) => {
      const isActive = this.selected?.id === item.id;

      return (
        <Grid
          as={Row}
          key={item.id}
          contract={item}
          isActive={isActive}
          contractsStore={contractsStore}
          user={user}
        />
      );
    });

    return (
      <Wrapper className={className}>
        {this.isPending && <LinearLoader />}

        <Grid as={Header} />

        <InfiniteScroll
          pagination={pagination}
          onLoadMore={this.handleLoadMore}
        >
          {listItems}
        </InfiniteScroll>
      </Wrapper>
    );
  }
}

export default styled(Body)``;
