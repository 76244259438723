const fields = [
  'general.autofill',
  'general.extra_charge',
  'contacts.base_methods',
  'contacts.finance_methods',
  'contacts.technical_methods',
  'contacts.common_methods'
];

const types = {
  'general.autofill': 'number',
  'general.extra_charge': 'number'
};

const rules = {
  'general.autofill': 'required',
  'general.extra_charge': 'required'
};

const placeholders = {};

const values = {
  'general.autofill': 0,
  'general.extra_charge': 0
};

export default {
  fields,
  rules,
  types,
  placeholders,
  values
};
