import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';

import Navigation from './Navigation';
import Header from './Header';

const Wrapper = styled.div`
  padding: 36px 0 24px 0;
`;

@withTranslation()
@observer
class Top extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    backUrl: PropTypes.string,
    t: PropTypes.func,
    order: PropTypes.object.isRequired
  };

  static defaultProps = {
    backUrl: '',
    className: ''
  };

  render() {
    const { order, backUrl, t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {false && <Navigation />}
        <Header order={order} />
      </Wrapper>
    );
  }
}

export default styled(Top)``;
