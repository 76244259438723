import { types } from 'mobx-state-tree';

export const PriceComposition = types.model('PriceComposition', {
  min: types.number,
  max: types.number,
  avg: types.number
});

export const Pricing = types.model('Pricing', {
  price: PriceComposition,
  discount_price: types.maybeNull(PriceComposition),
  discount_percent: types.optional(types.integer, 0)
});
