import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { computed } from 'mobx';
import { observer, Provider } from 'mobx-react';
import ReactRouterPropTypes from 'react-router-prop-types';

import { Block, Rating, Text } from 'components/atoms';
import { display, typography } from 'theme/mixins';
import { CarouselGallery } from 'components/ui';
import { Info, Tabs, Stub } from './components';

import HotelsInfo from 'stores/HotelsStore/Info';

const InfoWrapper = styled.div`
  ${display('flex', 'center')};
`;

const Wrapper = styled.div`
  width: 732px;
  height: 90vh;
  overflow: scroll;
  box-sizing: border-box;
  padding: 26px 30px 16px 30px;

  > ${Block}.flex-between {
    ${display('flex', 'center', 'space-between')};

    > ${Text}.name {
      color: #4a515c;
      margin-bottom: 20px;
      ${typography(20, 24, 700)};
    }
  }

  > ${Text}.address {
    color: #6b707b;
    ${typography(14, 16)};
  }

  ${CarouselGallery} {
    margin: 16px 0 34px;
  }

  ${Info} + ${Info} {
    margin-left: 36px;
  }

  ${Tabs} {
    margin: 34px 0;
  }
`;

const GalleryS = styled(CarouselGallery)`
  margin: 24px 0 8px;
`;

@withTranslation()
@withRouter
@observer
class DialogHotel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    location: ReactRouterPropTypes.location,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.hotelsInfo = HotelsInfo.create();
  }

  componentDidMount() {
    const { id } = this.props;
    this.hotelsInfo.fetch({ id });
  }

  @computed get hotel() {
    if (!this.isFetched) return undefined;

    return this.hotelsInfo.data.toJSON();
  }

  @computed get isFetched() {
    return !!this.hotelsInfo.data;
  }

  render() {
    const { t, ...rest } = this.props;

    if (!this.isFetched) {
      return (
        <Wrapper>
          <Stub />
        </Wrapper>
      );
    }

    const {
      address, check_in_time, check_out_time, children_from, name, rating, images
    } = this.hotel;

    return (
      <Provider hotelsInfo={this.hotelsInfo}>
        <Wrapper {...rest} onClick={e => e.stopPropagation()}>
          <Block className='flex-between'>
            <Text className='name'>
              {name}
            </Text>

            <Rating rating={rating} />
          </Block>

          <Text className='address'>
            {address.location}
          </Text>

          <GalleryS items={images} />

          <InfoWrapper>
            <Info
              name={t('Hotel.CheckIn')}
              value={t('Hotel.CheckInTime', { time: check_in_time })}
            />

            <Info
              name={t('Hotel.CheckOut')}
              value={t('Hotel.CheckOutTime', { time: check_out_time })}
            />

            <Info
              name={t('Hotel.AgeRestrictions')}
              value={t('Hotel.AgeRestrictionsFrom', { count: children_from })}
            />
          </InfoWrapper>

          <Tabs />
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(DialogHotel)``;
