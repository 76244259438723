import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { HotelCardStubPreview, HotelCardStubListItem } from './components';

class HotelCardStub extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    kind: PropTypes.oneOf([
      'list-item',
      'preview'
    ])
  };

  static defaultProps = {
    className: '',
    kind: 'preview'
  };

  render() {
    const { kind, ...rest } = this.props;

    if (kind === 'preview') {
      return (
        <HotelCardStubPreview {...rest} />
      );
    }

    if (kind === 'list-item') {
      return (
        <HotelCardStubListItem {...rest} />
      );
    }

    return null;
  }
}

export default styled(HotelCardStub)``;
