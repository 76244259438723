import { css } from 'styled-components';

const transition = (props) => css`
  transition: all .2s ease-in-out;
`;

const size = ({ size }) => {
  if (size === 'small') {
    return css`
      width: auto;
      height: 34px;
      padding: 0 14px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 4px;
    `;
  }

  if (size === 'medium') {
    return css`
      width: auto;
      height: 42px;
      padding: 0 22px;
      font-size: 13px;
      font-weight: 500;
      border-radius: 4px;
    `;
  }

  if (size === 'big') {
    return css`
      width: auto;
      height: 46px;
      padding: 0 24px;
      font-size: 13px;
      font-weight: 500;
      border-radius: 4px;
    `;
  }

  return css``;
};

const variant = ({ variant, loading }) => {
  if (variant === 'primary') {
    return css`
      color: #FFFFFF;
      background-color: #5AB2D6;
      border: 2px solid transparent;

      &:hover {
        background-color: #3AA6D2;
      }

      &:active {
        background-color: #2E96C1;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #979BA0;
      background-color: #E4E8EE;
      border: 2px solid transparent;

      &:hover {
        background-color: #D1D8E3;
      }

      &:active {
        background-color: #C0C9D6;
      }
    `;
  }

  if (variant === 'whiteground') {
    return css`
      background-color: #fff;
      border: 2px solid transparent;
      color: #3C6F9D;
      font-size: 14px;
      line-height: 17px;
      padding: 0 20px;

      &:hover {
        background-color: #D1D8E3;
      }

      &:active {
        background-color: #C0C9D6;
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #FFFFFF;
      background-color: #E26161;
      border: 2px solid transparent;

      &:hover {
        background-color: #BE4040;
      }

      &:active {
        background-color: #9D2F2F;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      color: #FFFFFF;
      background-color: #62CB94;
      border: 2px solid transparent;

      &:hover {
        background-color: #359965;
      }

      &:active {
        background-color: #257F50;
      }
    `;
  }

  return css``;
};

const outline = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      color: #5AB2D6;
      border: 2px solid currentColor;

      &:hover {
        color: #FFFFFF;
        background-color: #3AA6D2;
        border-color: #3AA6D2;
      }

      &:active {
        color: #FFFFFF;
        background-color: #2E96C1;
        border-color: #2E96C1;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      color: #9CB4CA;
      border: 2px solid currentColor;

      &:hover {
        color: #6A8197;
      }

      &:active {
        color: #556B80;
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      color: #E26161;
      border: 2px solid currentColor;

      &:hover {
        color: #BE4040;
      }

      &:active {
        color: #9D2F2F;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      color: #62CB94;
      border: 2px solid currentColor;

      &:hover {
        color: #359965;
      }

      &:active {
        color: #257F50;
      }
    `;
  }

  return css``;
};

const compact = ({ variant }) => {
  if (variant === 'primary') {
    return css`
      padding: 0;
      color: #3C6F9D;

      &:hover {
        color: #3C6F9D;
      }

      &:active {
        color: #3C6F9D;
      }
    `;
  }

  if (variant === 'secondary') {
    return css`
      padding: 0;
      color: #9CB4CA;

      &:hover {
        color: #6A8197;
      }

      &:active {
        color: #556B80;
      }
    `;
  }

  if (variant === 'danger') {
    return css`
      padding: 0;
      color: #E26161;

      &:hover {
        color: #BE4040;
      }

      &:active {
        color: #9D2F2F;
      }
    `;
  }

  if (variant === 'success') {
    return css`
      padding: 0;
      color: #62CB94;

      &:hover {
        color: #359965;
      }

      &:active {
        color: #257F50;
      }
    `;
  }

  return css``;
};

const disabled = (props) => css`
  pointer-events: none;
  opacity: 0.5;
`;

const rounded = ({ size }) => {
  if (size === 'small') {
    return css`
      padding: 0 22px;
      border-radius: 9999px;
    `;
  }

  if (size === 'medium') {
    return css`
      padding: 0 26px;
      border-radius: 9999px;
    `;
  }

  if (size === 'big') {
    return css`
      padding: 0 32px;
      border-radius: 9999px;
    `;
  }

  return css``;
};

const loading = ({ loading }) => css`
  pointer-events: none;

  & > * {
    opacity: 0;
  }
`;

const defaults = (props) => css`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  outline: none;
  box-sizing: border-box;
  background-color: transparent;
`;

const theme = (props) => ([
  defaults(props),
  transition(props),
  size(props),
  (props.outline ? outline(props) : css``),
  (props.compact ? compact(props) : css``),
  (props.disabled ? disabled(props) : css``),
  (props.rounded ? rounded(props) : css``),
  (props.loading ? loading(props) : css``),
  (!props.compact && !props.outline ? variant(props) : css``)
]);

export default theme;
