import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Header from './Header';
import Slots from './Slots';

const Wrapper = styled.div`
  padding: 19px;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  box-sizing: border-box;

  ${Header} + ${Slots} {
    margin-top: 16px;
  }
`;

@inject('showState')
@observer
class Reservation extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    showState: PropTypes.object.isRequired,
    disabled: PropTypes.bool.isRequired,
    reservation: PropTypes.object.isRequired,
    isDeclared: PropTypes.bool.isRequired
  };

  static defaultProps = {
    className: '',
    disabled: false
  };

  render() {
    const { reservation, t, disabled, isDeclared, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Header
          disabled={disabled}
          reservation={reservation}
          isDeclared={isDeclared}
        />

        <Slots
          reservation={reservation}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Reservation))``;
