import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';

const Wrapper = styled.div`
  padding: 4px 0;

  ${({ theme }) => css`
    font-size: ${theme.typography.description.fontSize}px;
    line-height: ${theme.typography.description.lineHeight}px;
    color: ${theme.colors.text.secondary};
  `}
`;

@withTheme
class HotelAddress extends React.Component {
  static propTypes = {
    children: ReactComponentChildrenPropType,
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  }

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(HotelAddress)``;
