import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { computed } from 'mobx';
import moment from 'utils/moment';

import Alert from './Alert';
import OnUpdate from './OnUpdate';
import OnCreate from './OnCreate';

const Wrapper = styled.div`
  overflow: hidden;
`;

@inject('bookingForm', 'bookingState')
@observer
class Submit extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingForm: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  @computed get from() {
    const { bookingForm } = this.props;
    const { value } = bookingForm.$('check_in');

    return value ? moment(value).toDate() : null;
  }

  @computed get to() {
    const { bookingForm } = this.props;
    const { value } = bookingForm.$('check_out');

    return value ? moment(value).toDate() : null;
  }

  @computed get rates() {
    const { bookingForm: { slots } } = this.props;

    const rates = slots
      .map(slot => slot.$('rate.type').value);

    return rates;
  }

  @computed get restrictionsData() {
    const { rates, props: { bookingState } } = this;

    return bookingState.getRestrictions({ rates, range: [this.from, this.to] });
  }

  render() {
    const {
      className,
      bookingForm: { isEditableMode, hasAlert, alert },
      ...rest
    } = this.props;

    return (
      <Wrapper className={className}>
        {hasAlert
          ? <Alert message={alert} />
          : isEditableMode
              ? <OnUpdate restrictionsData={this.restrictionsData} {...rest} />
              : <OnCreate restrictionsData={this.restrictionsData} {...rest} />
        }
      </Wrapper>
    );
  }
}

export default styled(Submit)``;
