import { types } from 'mobx-state-tree';

const Version = types.model('Version', {
  url: types.string
});

const Image = types.model('Image', {
  url: types.string,
  thumb: Version,
  landscape_big: Version,
  landscape_small: Version
});

export default Image;
