import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.article``;

@inject('hotelsInfo')
@observer
class TreatmentPrograms extends React.Component {
  @computed get treatmentPrograms() {
    const { hotelsInfo } = this.props;
    const { treatment_programs } = hotelsInfo && hotelsInfo.data && hotelsInfo.data.toJSON();
    return treatment_programs;
  }

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <div>
          {this.treatmentPrograms.map(({ id, title, content }) => (
            <section key={id}>
              <h4>
                {title}
              </h4>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </section>
          ))}
        </div>
      </Wrapper>
    );
  }
}

TreatmentPrograms.propTypes = {
  className: PropTypes.string,
  hotelsInfo: PropTypes.object
};

TreatmentPrograms.defaultProps = {
  className: ''
};

export default styled(TreatmentPrograms)``;
