import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { withTranslation } from 'react-i18next';
import moment from 'utils/moment';

import { typography, flexCenterBetween } from 'theme/mixins';
import { tariffsRestriction } from 'utils/helpers';

const Badge = styled.div`
  position: absolute;
  max-width: 100%;
  height: 22px;
  left: -4px;
  padding-left: 18px;
  padding-right: 12px;
  box-sizing: border-box;
  color: #FFFFFF;
  font-size: 12px;
  line-height: 22px;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0 4px 4px 0;
  background-color: #5AB2D6;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Insurant = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 22px;
  margin: 8px 0px -6px -16px;
`;

const Title = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    ${typography(14, 17, 500)};
    margin-bottom: 7px;
  `}
`;

const FlexContainer = styled.div`
  ${flexCenterBetween()};

  & + & {
    padding-top: 7px;
  }
`;

const Label = styled.div`
  ${typography(13, 15)};
`;

const PriceLabel = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.text.secondary};
  `}
`;

const Price = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    ${typography(13, 16, 500)};
  `}
`;

const DaysLabel = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.text.disabled};
  `}
`;

const Days = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    opacity: 0.6;
  `}
`;

const Wrapper = styled.div`
  margin-top: 15px;
`;

@withTheme
@observer
class Base extends React.Component {
  static propTypes = {
    tariff: PropTypes.object.isRequired,
    t: PropTypes.func
  }

  @computed get minDays() {
    const { t, tariff } = this.props;

    const restriction = tariffsRestriction(tariff);
    if (!restriction) return null;

    return t('UI.Duration', restriction);
  }

  render() {
    const { tariff: { name, price, insurant, available_period }, t } = this.props;
    const from = moment(available_period.from).format('D MMM YYYY');
    const to = moment(available_period.to).format('D MMM YYYY');

    return (
      <>
        <Title>
          {name}
        </Title>

        <PriceLabel>
          {from} - {to}
        </PriceLabel>

        <Wrapper>
          <FlexContainer>
            <PriceLabel>
              {t('Tariff.Price')}
            </PriceLabel>

            <Price>
              {t('Tariff.PriceFrom', { price: price.amount })}
            </Price>
          </FlexContainer>

          {!!this.minDays && (
            <FlexContainer>
              <DaysLabel>
                {t('Tariff.Package.Duration.Label')}
              </DaysLabel>

              <Days>
                {this.minDays}
              </Days>
            </FlexContainer>
          )}

          {insurant && (
            <Insurant>
              <Badge>
                {insurant.short_name}
              </Badge>
            </Insurant>
          )}
        </Wrapper>
      </>
    );
  }
}

export default styled(withTranslation()(Base))``;
