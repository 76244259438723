import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import Reservations from './Reservations';
import Total from './Total';

const Header = styled.div`
  padding: 0 30px;
  color: #4A515C;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
`;

const Body = styled.div`
  padding: 0 30px;
`;

const Footer = styled.div`
  position: fixed;
  width: inherit;
  height: 72px;
  bottom: 0;
  padding: 0 30px;
  background-color: #FFFFFF;
  border-top: 1px solid #DCDFE4;
  box-sizing: border-box;
`;

const Wrapper = styled.div.attrs(props => ({
  topOffset: props.topOffset || '152px'
}))`
  width: 100%;
  min-height: calc(100vh - ${props => props.topOffset});
  padding: 26px 0 98px;
  margin-bottom: -48px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);

  ${Header} + ${Body} {
    margin-top: 14px;
  }
`;

@inject('showState')
@observer
class Center extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { t, className } = this.props;

    return (
      <Wrapper className={className}>
        <Header>
          {t('Order.OrderInfo.Reservations.Title')}
        </Header>

        <Body>
          <Reservations />
        </Body>

        <Footer>
          <Total />
        </Footer>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Center))``;
