import React from 'react';
import { Provider } from 'mobx-react';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';

import { Settings } from '.';

import SettingsForm from 'forms/Settings/SettingsForm';

class General extends React.Component {
  constructor(props) {
    super(props);

    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError
    };

    this.settingsForm = new SettingsForm({}, { hooks });
  }

  handleSuccess = (form) => {
    const values = form.values();

    console.log(values);
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  render() {
    return (
      <Provider settingsForm={this.settingsForm}>
        <DefaultTemplate
          header={<Header />}
          body={<Settings />}
        />
      </Provider>
    );
  }
}

export default General;
