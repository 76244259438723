import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Sticky from 'react-stickynode';

const HeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 72px;
  top: 0;
  background: #9cb4ca;
  z-index: 100;
`;

const Header = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const ConversationWrapper = styled.div`
  position: relative;
  min-width: 280px;
  max-width: 372px;
  height: calc(100vh - 72px);
`;

const Conversation = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 72px);
  right: -20px;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  padding: 72px 20px 0;

  ${({ centered }) => centered && css`
    margin: 0 auto;
  `}

  ${ConversationWrapper} {
    flex-grow: 1;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 960px;
  max-width: 1280px;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1280px;
  background: #f6f8fb;
  overflow: auto;
  min-height: 100vh;
`;

class DefaultTemplate extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    header: PropTypes.node,
    body: PropTypes.node,
    conversation: PropTypes.node
  };

  static defaultProps = {
    className: '',
    header: null,
    body: null
  };

  render() {
    const { header, body, conversation, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <HeaderWrapper>
          <Header>{header}</Header>
        </HeaderWrapper>

        <BodyWrapper centered={!conversation}>
          <Body>{body}</Body>

          {conversation && (
            <ConversationWrapper as={Sticky} top={72}>
              <Conversation>{conversation}</Conversation>
            </ConversationWrapper>
          )}
        </BodyWrapper>
      </Wrapper>
    );
  }
}

export default DefaultTemplate;
