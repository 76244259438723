import { types, applySnapshot, getSnapshot, applyPatch } from 'mobx-state-tree';
import cleanDeep from 'clean-deep';
import _cloneDeep from 'lodash/cloneDeep';

import instance from 'connection/instance';
import { ContractItem } from './contract';

const Paginate = types.model('Paginate', {
  limit: types.number,
  page: types.number,
  total: types.number,
});

const ContractsListMeta = types.model('ContractsListMeta', {
  paginate: Paginate,
});

const ContractsStore = types
  .model('ContractsStore', {
    data: types.optional(types.array(ContractItem), []),
    meta: types.maybeNull(ContractsListMeta),
    state: types.maybeNull(types.enumeration(['pending', 'done', 'error'])),
  })
  .views((self) => ({
    get isFetched() {
      return self.state === 'done';
    },

    get isPending() {
      return self.state === 'pending';
    },

    get isError() {
      return self.state === 'error';
    },

    get selectOptions() {
      return getSnapshot(self.data);
    },
  }))
  .actions((self) => ({
    fetch(options = {}) {
      self.setState('pending');

      const { page = 1 } = options;

      const paginate = {
        page,
        limit: 100,
      };

      const params = { ...options, paginate };

      return instance
        .get('/api/platform_prices', { params })
        .then((response) => self.resetStore(response))
        .then(() => self.setState('done'))
        .catch((error) => self.errorHandler(error));
    },

    updateService({ data }, id) {
      const clonedData = _cloneDeep(self.data);
      const index = clonedData.findIndex((el) => el.id === id);
      index >= 0 && (clonedData[index] = data.data);
      return clonedData;
    },

    cleanService(values = {}) {
      self.setState('pending');

      const { id } = cleanDeep(values, { emptyArrays: false });

      return instance
        .delete(`/api/platform_prices/${id}`)
        .then((response) => self.updateService(response, id))
        .then((response) => self.resetStoreData(response))
        .then(() => self.setState('done'))
        .catch((error) => self.errorHandler(error));
    },

    fetchMore(options = {}) {
      self.setState('pending');

      const { page } = options;

      const paginate = {
        page,
        limit: 100,
      };

      const params = { ...options, paginate };

      return instance
        .get('/api/platform_prices', { params })
        .then((response) => self.updateStore(response))
        .then(() => self.setState('done'))
        .catch((error) => self.errorHandler(error));
    },

    setState(state) {
      self.state = state;
      return self;
    },

    resetStoreData(data) {
      applySnapshot(self.data, data);

      return self;
    },

    resetStore(response) {
      const { status, data } = response;

      if (status === 200) applySnapshot(self, data);

      return self;
    },

    updateStore(response) {
      const { status } = response;

      if (status === 200) {
        const { data, meta } = response.data;

        const metaPatch = [
          {
            op: 'replace',
            path: '/meta',
            value: meta,
          },
        ];

        const contractsPatch = data.map((order) => ({
          op: 'add',
          path: '/data/-',
          value: order,
        }));

        applyPatch(self, metaPatch.concat(contractsPatch));
      }

      return self;
    },

    errorHandler(error) {
      self.setState('error');
      return Promise.reject(error);
    },

    clear() {
      applySnapshot(self, {});
    },
  }));

export default ContractsStore;
