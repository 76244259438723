import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled, { css } from 'styled-components';
import { typography } from 'theme/mixins';

import { Badge, Text } from 'components/atoms';

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  text-align: center;
  border-bottom: 1px solid #BFC6D3;

  ${Text} {
    position: relative;
    ${typography(16, 20, 600)};
    color: #6B707B;

    ${Badge} {
      position: absolute;
      transform: translate(2px, -50%);
      background-color: #6B707B;
    }
  }

  & + & {
    border-left: 1px solid #BFC6D3;
  }

  ${p => p.active && css`
    border-top: 3px #5AB2D6 solid;
    border-bottom: none;

    ${Text} {
      color: #4A515C;

      ${Badge} {
        background-color: #5AB2D6;
      }
    }
  `}
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;

  ${Tab} {
    max-width: 50%;
    flex-grow: 1;
  }
`;

const Wrapper = styled.div`
  width: inherit;
  height: 64px;
`;

@inject('bookingCart')
@observer
class Header extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingCart: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    const { bookingCart, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Tabs>
          <Tab active>
            <Text>
              Мой заказ
            </Text>
          </Tab>

          <Tab>
            {false && (
              <Text>
                Чат-онлайн
              </Text>
            )}
          </Tab>
        </Tabs>
      </Wrapper>
    );
  }
}

export default styled(Header)``;
