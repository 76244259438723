import _clone from 'lodash/clone';
import _findIndex from 'lodash/findIndex';

import moment from 'utils/moment';

function getPricesForPeriod({ tariff, prices, check_in, check_out }) {
  const { billing_hour } = tariff;
  const billingShift = billing_hour === 'day' ? 1 : 0;

  const data = _clone(prices);
  const defaultValue = data.slice(0, 1);

  if (!check_in || !check_out) return defaultValue;

  const firstDate = moment(check_in).format('Y-MM-DD');
  const firstIndex = _findIndex(data, price => price.day === firstDate);
  if (firstIndex < 0) return defaultValue;

  const lastDate = moment(check_out).format('Y-MM-DD');
  const lastIndex = _findIndex(data, price => price.day === lastDate);
  if (lastIndex < 0) return defaultValue;

  return data.slice(firstIndex, lastIndex + billingShift);
}

export default getPricesForPeriod;
