import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Photos from './Photos';

import { typography, flexCenterBetween } from 'theme/mixins';

const Wrapper = styled.div`
  width: inherit;
  height: auto;
  padding: 26px 30px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
`;

const Title = styled.div`
  color: #4a515c;
  padding-bottom: 4px;
  ${typography(16, 19, 700)};
`;

const SubTitle = styled.div`
  ${typography(14, 19, 500)};
`;

const FlexContainer = styled.div`
  ${flexCenterBetween()};
  align-items: flex-start;

  & + & {
    padding-top: 4px;
  }
`;

const InformationWrapper = styled.div`
  padding-bottom: 35px;
  ${flexCenterBetween()};
`;

const Information = styled.div`
  width: 230px;
  color: #4c4c4c;
  padding-top: 7px;
  ${typography(13, 19)};
`;

const Services = styled.div`
  padding: 8px 0 16px;
`;

const Special = styled.div`
  padding-top: 12px;
`;

const Label = styled.div`
  color: #4c4c4c;
  width: 330px;
  ${typography(13, 16)};
`;

const Value = styled.div`
  color: #979ba0;
  ${typography(13, 15)};
`;

class Description extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hotel: PropTypes.object,
    room_type: PropTypes.object,
    tariff: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { hotel, room_type, tariff, t, ...rest } = this.props;

    const { beds, extra_beds } = room_type;

    return (
      <Wrapper {...rest}>
        <Title>
          {t('Order.RoomDescription.Title')}
        </Title>

        <InformationWrapper>
          <Information>
            <div>
              {t('Tariff.Seats.Primary', { count: beds })}
              {extra_beds > 0 && (
                <>
                  {' ・ '}
                  {t('Tariff.Seats.Secondary', { count: extra_beds })}
                </>
              )}
            </div>

            <div>
              {t('Tariff.Rooms.Count', { count: room_type.rm_count })}
              {', '}
              <span dangerouslySetInnerHTML={{ __html: t('Tariff.Room.Area', { area: room_type.area }) }} />
            </div>
          </Information>

          <Photos images={hotel.images} />
        </InformationWrapper>

        {tariff.services.length > 0 && (
          <>
            <SubTitle>
              {t('Tariff.Services.Title')}
            </SubTitle>

            <Services>
              {tariff.services.map(service => (
                <FlexContainer key={service.id}>
                  <Label>
                    {service.name}
                  </Label>

                  {false && (
                    <Value>
                      {t('UI.PerWeekQuantity', { count: service.quantity })}
                    </Value>
                  )}
                </FlexContainer>
              ))}
            </Services>
          </>
        )}

        {tariff.rates.filter(rate => rate.occupation === 'main' && rate.count === 0).length > 0 && (
          <>
            <SubTitle>
              {t('Tariff.Features.Title')}
            </SubTitle>

            <Special>
              <Label>
                {t('Tariff.Features.Sharing')}
              </Label>
            </Special>
          </>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Description))``;
