import { types } from 'mobx-state-tree';

export const Traveller = types.model('Traveller', {
  id: types.identifier,
  date_of_birth: types.maybeNull(types.string),
  age_group: types.string,
  gender: types.string,
  first_name: types.string,
  last_name: types.string,
  middle_name: types.maybeNull(types.string),
  full_name: types.maybeNull(types.string)
});
