import { types } from 'mobx-state-tree';

export const ContractItem = types.model('ContractItem', {
  id: types.maybeNull(types.string),
  lpu_id: types.maybeNull(types.string),
  lpu_dog_id: types.maybeNull(types.string),
  connection_string: types.maybeNull(types.string),
  price_date: types.maybeNull(types.string),
  lpu_name: types.maybeNull(types.string),
  lpu_dog_number: types.maybeNull(types.string),
  lpu_dog_date: types.maybeNull(types.string),
  price_id: types.maybeNull(types.string),
  lpu_dog_days_nights: types.maybeNull(types.number),
  lpu_inn: types.maybeNull(types.string),
  lpu_kpp: types.maybeNull(types.string),
  services_count: types.maybeNull(types.number),
  created_at: types.maybeNull(types.string),
  updated_at: types.maybeNull(types.string)
});
