import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import { Navigation, Top } from 'pages/Settings/components';
import { PagePlaceholder } from 'components/ui';
import { LegalEntity } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${({ theme }) => css`
    ${display('flex')}
    flex-direction: column;
    flex-grow: 1;
    width: 880px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;
  `}
`;

const LegalEntities = styled.div`
  padding: 12px 0;

  ${LegalEntity} {
    margin: 20px 0;
  }
`;

@withTheme
@inject('settingsStore')
@observer
class Settings extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    settingsStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isFetched() {
    const { settingsStore } = this.props;
    return settingsStore.isFetched;
  }

  @computed get legalEntities() {
    const { settingsStore } = this.props;
    return settingsStore.data && settingsStore.data.toJSON().contracts.legal_entities;
  }

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Top />

        <Navigation />

        {this.isFetched && this.legalEntities.length > 0 && (
          <LegalEntities>
            {this.legalEntities.map(legal_entity => (
              <LegalEntity
                key={legal_entity.id}
                legal_entity={legal_entity}
              />
            ))}
          </LegalEntities>
        )}

        {this.isFetched && this.legalEntities.length === 0 && (
          <PagePlaceholder.Container>
            <PagePlaceholder>
              <PagePlaceholder.Image />

              <PagePlaceholder.Content>
                {t('Settings.Contracts.Legal.Empty')}
              </PagePlaceholder.Content>
            </PagePlaceholder>
          </PagePlaceholder.Container>
        )}

        {!this.isFetched && (
          <PagePlaceholder.Container>
            <PagePlaceholder>
              <PagePlaceholder.Fetch label={t('UI.Fetching')} />
            </PagePlaceholder>
          </PagePlaceholder.Container>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Settings))``;
