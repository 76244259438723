import _findIndex from 'lodash/findIndex';
import _pick from 'lodash/pick';
import _isEqual from 'lodash/isEqual';
import _sortBy from 'lodash/sortBy';
import _some from 'lodash/some';

function rateCompareAttributes(rate) {
  const attributes = [
    'age_group',
    'age_period.from',
    'age_period.to'
  ];

  return _pick(rate, attributes);
}

function detectOccupancy({ lRate, rRate }) {
  const lAttrs = rateCompareAttributes(lRate);
  const rAttrs = rateCompareAttributes(rRate);

  return _isEqual(lAttrs, rAttrs);
}

function getSlotsRates({ occupation, slots }) {
  const result = [];

  occupation.forEach((ocp, o_idx) => {
    result[o_idx] = new Array(ocp.length);

    slots.forEach((slot, s_idx) => {
      const rRight = slot.rate;

      const rateIdx = _findIndex(
        ocp, lRate => detectOccupancy({ lRate, rRight })
      );

      if (rateIdx) {
        const removed = ocp.splice(rateIdx, 1);
        result[o_idx][s_idx] = removed[0];
      }
    });
  });

  return result;
}

function ratesCompareAttributes(rates) {
  let value = rates.map(rate => rate.type);
  value = _sortBy(value).join('_');

  return value;
}

function isCoerced({ rates, slots }) {
  let rRates = slots.map(slot => slot.rate);
  rRates = ratesCompareAttributes(rRates);

  const lRates = ratesCompareAttributes(rates);

  return rRates === lRates;
}

function coerceSlotsRates(props) {
  const { occupation, slots } = props;

  let variants = getSlotsRates({ slots, occupation });
  variants = variants.reverse();

  // Check if coerce needed?
  const isCoerce = variants
    .find(rates => isCoerced({ rates, slots }));
  if (isCoerce) return slots;

  // Find best variant for current choose
  const changedRate = _sortBy(slots, 'updatedAt').reverse()[0].rate;

  let rates = (
    !!changedRate.type &&
      variants.find(rates => _some(rates, r => r.type === changedRate.type))
  ) || variants[0];

  rates = rates.reverse();

  // Assign cerced rates
  return slots.map((slot, idx) => (
    { ...slot, rate: rates[idx] }
  ));
}

export default coerceSlotsRates;
