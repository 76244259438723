import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import withClickOutside from 'react-click-outside';

import { Menu, MenuItem } from 'rambler-ui/Menu';
import { Plus as PlusIcon } from 'components/icons';
import { Dropdown } from 'components/molecules';

import { display, typography } from 'theme/mixins';

import { filters } from 'pages/Contracts/containers/List/Contracts/FilterBar';

const Wrapper = styled(Dropdown)`
  width: 152px;
`;

const Control = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    ${display('flex', 'center', 'center')}
    height: 36px;
    padding: 0 8px;
    border: 1px solid;
    border-radius: 4px;
    color: ${theme.colors.active.primary};
    cursor: pointer;
    user-select: none;
    transition: padding-left 0.25s, padding-right 0.25s;

    :not(:first-child) {
      margin-left: 4px;
    }

    :not(:last-child) {
      margin-right: 4px;
    }

    ${PlusIcon} {
      width: 12px;
      height: 12px;
      margin: 0 6px;
    }
  `}
`;

const Label = styled.div`
  margin: 0 6px;
  ${typography(12, 12)};
  white-space: nowrap;
`;

@withTheme
@observer
class FilterPicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    selectedFilters: PropTypes.arrayOf(PropTypes.object),
    onChange: PropTypes.func.isRequired,
    t: PropTypes.func,
  };

  static defaultProps = {
    className: '',
  };

  @observable isOpened = false;

  handleClickOutside() {
    this.isOpened = false;
  }

  handleChange = ({ value }) => {
    const { onChange } = this.props;
    const filter = filters.find(({ type }) => type === value);

    this.isOpened = false;

    if (filter) {
      onChange(filter);
    }
  };

  handleToggleOpened = () => {
    this.isOpened = !this.isOpened;
  };

  handleClose = () => {
    this.isOpened = false;
  };

  render() {
    const { selectedFilters, t, ...rest } = this.props;

    return (
      <Wrapper
        isOpened={this.isOpened}
        anchor={
          <Control {...rest} onClick={this.handleToggleOpened}>
            <PlusIcon />

            <Label>{t('Orders.FilterButton')}</Label>
          </Control>
        }
      >
        <Menu onChange={this.handleChange} onEscKeyDown={this.handleClose}>
          {filters.map(({ label, type: value }) => (
            <MenuItem key={value} value={{ label, value }}>
              {label}
            </MenuItem>
          ))}
        </Menu>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(withClickOutside(FilterPicker)))``;
