const VIRTUAL_NUMBER = 'virtual';

function contractNumber(contract) {
  if (!contract) {
    return { mode: null, value: null };
  }

  const { number, insured_name } = contract;

  if (number === VIRTUAL_NUMBER) {
    return { mode: 'insurant', value: insured_name };
  }

  return { mode: 'contract', value: `№${number}, ${insured_name}` };
}

export default contractNumber;
