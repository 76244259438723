import moment from 'utils/moment';

export function datesRange(date1, date2, format = 'D MMM') {
  const from = moment(date1);
  const to = moment(date2);

  const delimiter = ' – ';

  if (from.isSame(to)) {
    return from.format(format);
  }

  if (from.month() === to.month()) {
    return [from.format('D'), to.format(format)].join(delimiter);
  }

  if (from.year() === to.year()) {
    const formatTo = format;
    const formatFrom = format.replace(/Y/g, '').trim();

    return [from.format(formatFrom), to.format(formatTo)].join(delimiter);
  }

  return [from.format(format), to.format(format)].join(delimiter);
}

export function formatDate(date, params = {}) {
  const { isShortMonth } = params;

  const monthFormat = isShortMonth ? 'MMM' : 'MMMM';
  const isCurrentYear = moment(date).format('YYYY') === moment(new Date()).format('YYYY');

  const format = isCurrentYear
    ? `D ${monthFormat}`
    : `D ${monthFormat} YYYY`;

  return moment(date).format(format);
}
