import _sortBy from 'lodash/sortBy';

function applySortToData({ data, sortBy }) {
  const result = [...data];

  const { sort_by, direction } = sortBy;

  if (sort_by === 'rating') {
    return _sortBy(
      result,
      (item) => (item.rating || 0) * direction
    );
  }

  if (sort_by === 'pricing.min') {
    return _sortBy(
      result,
      (item) => (item.tariff.price.amount || 0) * Number(direction)
    );
  }

  return result;
}

export default applySortToData;
