import { useState } from 'react';

import instance from 'connection/instance';

function useManagersFilter() {
  const [managers, setManagers] = useState([]);
  const [pending, setPending] = useState(false);

  const fetch = async (value) => {
    setPending(true);
    const params = { data: value };

    const { data: { data } } = await instance.get('/api/filters/managers', { params });
    setManagers(data);

    setPending(false);
  };

  return [{ managers, pending }, { fetch }];
}

export default useManagersFilter;
