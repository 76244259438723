import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';

import { Button } from 'components/forms';
import { PagePlaceholder } from 'components/ui';

@withRouter
@inject('ordersStore')
@observer
class Orders extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history,
    ordersStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get isFetched() {
    const { ordersStore } = this.props;
    return ordersStore.isFetched;
  }

  handleOrderCreate = (event) => {
    event.preventDefault();

    const { history } = this.props;
    history.push('/orders/new');
  };

  render() {
    const { t, className } = this.props;

    return this.isFetched
      ? (
        <PagePlaceholder.Container className={className}>
          <PagePlaceholder>
            <PagePlaceholder.Image />

            <PagePlaceholder.Content>
              {t('Orders.EmptyOrders0')}
              <br />
              {t('Orders.EmptyOrders1')}
            </PagePlaceholder.Content>

            <PagePlaceholder.Control>
              <Button onClick={this.handleOrderCreate}>
                {t('Orders.NewOrder')}
              </Button>
            </PagePlaceholder.Control>
          </PagePlaceholder>
        </PagePlaceholder.Container>
      )

      : (
        <PagePlaceholder.Container className={className}>
          <PagePlaceholder>
            <PagePlaceholder.Fetch
              label={t('UI.Fetching')}
            />
          </PagePlaceholder>
        </PagePlaceholder.Container>
      );
  }
}

export default styled(withTranslation()(Orders))``;
