import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Text } from 'components/atoms';
import { Button } from 'components/forms';
import { Sticky } from 'components/ui';

import { display, typography } from 'theme/mixins';

const stickedCSS = css`
  justify-content: center;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
  background: #e5e8ed;
`;

const Wrapper = styled(Sticky)`
  ${({ theme }) => css`
    ${display('flex', 'center', 'flex-end')};

    ${Text} {
      color: ${theme.colors.text.primary};
      ${typography(16, 20, 700)};

      :not(:last-child) {
        margin-right: 20px;
      }
    }
  `}
`;

class Total extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
    total: PropTypes.number,
    hasSubmitButton: PropTypes.bool,
    loading: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func
  };

  static defaultProps = {
    className: '',
    loading: false,
    hasSubmitButton: false,
    onSubmit: () => null
  };

  render() {
    const { hasSubmitButton, onSubmit, total, loading, t, ...rest } = this.props;

    return (
      <Wrapper
        {...rest}
        delay={500}
        stickedCSS={stickedCSS}
      >
        <Text>
          {`${t('Orders.Total', { price: total })}`}
        </Text>

        {hasSubmitButton && (
          <Button
            onClick={onSubmit}
            loading={loading}
          >
            {t('Order.Confirm')}
          </Button>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Total))``;
