import _pick from 'lodash/pick';

import { UNKNOWN_RATE_ERROR } from 'utils/errors';

const DEFAULT_PAYMENT_METHOD = {
  type: 'bank_transfer'
};

function dailyRatePrice({ rateType, dailyPrice }) {
  const { day, rates } = dailyPrice;

  const rate = rates
    .find(r => r.type === rateType);

  if (!rate) {
    throw Error(UNKNOWN_RATE_ERROR);
  }

  return { day, price: rate.price };
}

function dailyRatePrices({ rateType, prices }) {
  return prices.map(dailyPrice => (
    dailyRatePrice({ rateType, dailyPrice })
  ));
}

function assignPaymentMethod({ previousPrices, currentPrices }) {
  const previousPricesMap = new Map(previousPrices.map(item => (
    [item.day, _pick(item, 'payment_method')]
  )));

  const currentPricesMap = new Map(currentPrices.map(item => (
    [item.day, item]
  )));

  const result = currentPrices.map(({ day }) => {
    const currentItem = currentPricesMap.get(day);
    const previousItem = previousPricesMap.get(day) || { payment_method: DEFAULT_PAYMENT_METHOD };

    return { ...currentItem, ...previousItem };
  });

  return result;
}

function assignBaseSlotPrices({ slot, billing_hour, prices }) {
  const { prices: previousPrices, rate: { type: rateType } } = slot;

  let currentPrices = dailyRatePrices({ rateType, prices });
  currentPrices = assignPaymentMethod({ previousPrices, currentPrices });

  const shift = billing_hour === 'night' ? 1 : 0;
  const sliceBy = [0, prices.length - shift];

  const price = currentPrices
    .slice(...sliceBy)
    .reduce((sum, p) => sum + p.price, 0);

  return { ...slot, price, prices: currentPrices };
}

function assignPackageSlotPrices({ slot, billing_hour, prices }) {
  const { prices: previousPrices, rate: { type: rateType } } = slot;

  let currentPrices = dailyRatePrices({ rateType, prices });
  currentPrices = assignPaymentMethod({ previousPrices, currentPrices });

  const price = currentPrices[0].price;

  return { ...slot, price, prices: currentPrices };
}

function assignSlotsPrices({ slots, billing_hour, type, prices }) {
  const data = slots.map(slot => {
    try {
      switch (type) {
        case 'base':
          return assignBaseSlotPrices({ slot, billing_hour, prices });
        case 'package':
          return assignPackageSlotPrices({ slot, billing_hour, prices });
        default:
          return { ...slot, price: 0, prices: [] };
      }
    } catch (error) {
      switch (error.message) {
        case UNKNOWN_RATE_ERROR:
          return { ...slot, price: 0, prices: [] };
        default:
          throw error;
      }
    }
  });

  return data;
}

export default assignSlotsPrices;
