import { types } from 'mobx-state-tree';

const Permissions = types.model('Permissions', {
  show: types.boolean,
  create: types.boolean,
  update: types.boolean,
  confirm: types.boolean,
  cancel: types.boolean,
  sync: types.boolean,
  sync_on_platform: types.boolean,
  sync_on_provider: types.boolean,
  upload_xls: types.boolean,
  history: types.boolean,
  update_creator: types.boolean
});

const Meta = types.model('Meta', {
  permissions: Permissions
});

export default Meta;
