import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tabs as BaseTabs } from 'components/organisms';

import {
  Description,
  Specializations,
  FeedingPrograms,
  Amenities,
  TreatmentPrograms,
  Map,
  Comments
} from './components';
import { withTranslation } from 'react-i18next';

const TabsS = styled(BaseTabs)`
  &&& {
    a {
      font-weight: 400;

      + a {
        margin-left: 22px;
      }

      .active {
        font-weight: 700;
      }
    }
  }

  ${Description},
  ${Specializations},
  ${FeedingPrograms},
  ${Amenities},
  ${TreatmentPrograms},
  ${Map},
  ${Comments} {
    padding-top: 24px;
  }
`;

@withTranslation()
class Tabs extends React.PureComponent {
  constructor(props) {
    super(props);
    const { t } = props;

    this.tabs = [
      {
        label: t('Hotel.Details.Tabs.Description'),
        value: 'description',
        component: Description
      },
      {
        label: t('Hotel.Details.Tabs.Specializations'),
        value: 'specializations',
        component: Specializations
      },
      {
        label: t('Hotel.Details.Tabs.FeedingPrograms'),
        value: 'food',
        component: FeedingPrograms
      },
      {
        label: t('Hotel.Details.Tabs.Amenities'),
        value: 'comfort',
        component: Amenities
      },
      {
        label: t('Hotel.Details.Tabs.TreatmentPrograms'),
        value: 'programs',
        component: TreatmentPrograms
      }
      // {
      //   label: t('Hotel.Details.Tabs.Map'),
      //   value: 'map',
      //   component: Map
      // },
      // {
      //   label: t('Hotel.Details.Tabs.Comments'),
      //   value: 'comments',
      //   component: Comments
      // }
    ];
  }

  render() {
    const { tabs } = this;
    const { ...rest } = this.props;

    return (
      <TabsS
        {...rest}
        tabs={tabs}
      />
    );
  }
}

Tabs.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func
};

Tabs.defaultProps = {
  className: ''
};

export default styled(Tabs)``;
