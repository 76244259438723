import { DateTime, Interval } from 'luxon';
import _compact from 'lodash/compact';

const format = 'yyyy-MM-dd';

function getRestrictionsData({ range, pricesPerDay, billing_hour, tariff }) {
  const modifiers = {};

  const selectedOneDay = range.length === 1;
  const value = selectedOneDay ? [...range, ...range] : range;

  if (pricesPerDay.length === 0 || _compact(range).length === 0) {
    return { mappedRestrictions: [] };
  }

  const from = DateTime.fromJSDate(value[0]).startOf('day');
  const to = DateTime.fromJSDate(value[1]).plus({ days: 1 }).startOf('day');

  const days = Interval.fromDateTimes(from, to)
    .splitBy({ days: 1 })
    .map(d => pricesPerDay.find(p => p.day === d.start.toFormat(format)))
    .filter(d => d);

  const billingShift = billing_hour === 'night' ? 1 : 0;
  const daysCount = days.length - billingShift;

  const mappedRestrictions = {};

  if (days.length > 0) {
    const {
      length_of_stay_arrival_based_restrictions: {
        max_length_of_stay: max_length_of_stay_arrival_based,
        min_length_of_stay: min_length_of_stay_arrival_based
      },
      closed_by_date_restrictions: {
        closed_to_arrival: closed_to_arrival_by_date
      },
      advance_booking_restrictions: {
        max_days_before_check_in,
        min_days_before_check_in
      }
    } = days[0].restrictions;

    switch (tariff.type) {
      case 'base': {
        const max_length_of_stay = Math.min(...days.map(d => d.restrictions.length_of_stay_restrictions.max_length_of_stay));
        const min_length_of_stay = Math.max(...days.map(d => d.restrictions.length_of_stay_restrictions.min_length_of_stay));

        const maxLength = max_length_of_stay_arrival_based
          ? Math.min(max_length_of_stay, max_length_of_stay_arrival_based)
          : max_length_of_stay;

        const minLength = min_length_of_stay_arrival_based
          ? Math.max(min_length_of_stay, min_length_of_stay_arrival_based)
          : min_length_of_stay;

        mappedRestrictions.max_length_of_stay = {
          value: maxLength,
          active: maxLength !== 0 && (maxLength < daysCount)
        };

        mappedRestrictions.min_length_of_stay = {
          value: minLength,
          active: minLength !== 0 && (minLength > daysCount)
        };

        break;
      }

      case 'package': {
        mappedRestrictions.package_attention = {
          value: tariff.stay_period.min_days,
          active: tariff.stay_period.min_days !== daysCount
        };

        break;
      }

      case 'tour': {
        mappedRestrictions.tour_attention = {
          value: {
            from: tariff.available_period.from,
            to: tariff.available_period.to
          },
          active: value[0] !== tariff.available_period.from || value[1] !== tariff.available_period.to
        };

        break;
      }

      default:
        break;
    }

    const { closed_by_date_restrictions: { closed_to_departure: closed_to_departure_by_date } } = days[days.length - 1].restrictions;

    const daysBeforeCheckIn = Math.ceil(from.diff(DateTime.now(), 'days').days) + 1 - billingShift;

    mappedRestrictions.max_days_before_check_in = {
      value: max_days_before_check_in,
      active: !!max_days_before_check_in && (daysBeforeCheckIn > max_days_before_check_in)
    };

    mappedRestrictions.min_days_before_check_in = {
      value: min_days_before_check_in,
      active: !!min_days_before_check_in && (daysBeforeCheckIn < min_days_before_check_in)
    };

    mappedRestrictions.closed_to_arrival_by_date = {
      value: closed_to_arrival_by_date,
      active: closed_to_arrival_by_date
    };

    mappedRestrictions.closed_to_departure_by_date = {
      value: closed_to_departure_by_date,
      active: closed_to_departure_by_date
    };
  }

  return {
    mappedRestrictions: range.length === 2 ? mappedRestrictions : [],
    modifiers
  };
}

export default getRestrictionsData;
