import { observable, action, computed, toJS } from 'mobx';
import _pick from 'lodash/pick';
import { orderSerializer } from './serializers';

import OrderStore from 'stores/OrdersStore/One';

const GROUP_BY = ['tariff', 'dates'];

class ShowState {
  constructor(props = {}) {
    this.history = props.history || window.history;
    this.orderStore = OrderStore.create();
  }

  @observable orderState = undefined;

  @computed get isFetched() {
    return !!this.order;
  }

  @computed get isPending() {
    return this.orderState === 'pending';
  }

  @action
  setOrderState(state) {
    this.orderState = state;
  }

  @action
  unsetOrderState() {
    this.orderState = undefined;
  }

  async fetch(params) {
    this.setOrderState('pending');
    await this.orderStore.fetch(params);
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async updateCreator(creator) {
    let params = _pick(
      this.order,
      ['id', 'check_in', 'check_out', 'address', 'hotel']
    );

    params = { ...params, creator };

    this.setOrderState('pending');
    await this.orderStore.update(params);
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async updateContract(contract) {
    let params = _pick(
      this.order,
      ['id', 'check_in', 'check_out', 'address', 'hotel']
    );

    params = { contract, ...params };

    this.setOrderState('pending');
    await this.orderStore.update(params);
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async cancel() {
    this.setOrderState('pending');
    await this.orderStore.cancel();
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async recalc() {
    this.setOrderState('pending');

    await this.orderStore.recalc();
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async cancelReservation({ id }) {
    this.setOrderState('pending');
    await this.orderStore.cancelReservations([id]);
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async cancelReservations(ids) {
    this.setOrderState('pending');
    await this.orderStore.cancelReservations(ids);
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async syncOnPlatform() {
    this.setOrderState('pending');
    await this.orderStore.syncOnPlatform();
    const store = toJS(this.orderStore);
    this.onFetch(store);
    this.setOrderState('done');
  }

  async edit() {
    const { order: { id }, history } = this;
    history.push(`/orders/${id}/edit`);

    return Promise.resolve();
  }

  async editReservation({ id }) {
    const { order: { id: order_id }, history } = this;
    history.push(`/orders/${order_id}/edit/${id}`);

    return Promise.resolve();
  }

  async confirm() {
    const { order: { id }, history, orderStore } = this;

    this.setOrderState('pending');
    await orderStore.confirm({ id });
    this.setOrderState('done');
    history.push('/orders');
  }

  async downloadReport() {
    const { order: { id }, orderStore } = this;

    this.setOrderState('pending');
    await orderStore.downloadReport({ id });
    this.setOrderState('done');
  }

  @action
  onFetch({ meta, data }) {
    this.setOrder(data);
    this.setPermissions(meta);
  }

  // Order
  @observable.ref order = undefined;

  @action
  setOrder(order) {
    this.order = order;
    this.onSetOrder(order);
  }

  @action
  unsetOrder() {
    this.order = undefined;
  }

  @action
  onSetOrder(order) {
    this.setValues(order);
  }

  // Permissions
  @observable.ref permissions = undefined;

  setPermissions({ permissions }) {
    this.permissions = permissions;
  }

  unsetPermissions({ permissions }) {
    this.permissions = undefined;
  }

  // GroupBy
  @observable groupBy = GROUP_BY[0];

  @computed get groupViews() {
    return GROUP_BY;
  }

  @action
  setGroupBy(view) {
    const value = GROUP_BY.includes(view) ? view : GROUP_BY[0];
    this.groupBy = value;

    this.onSetGroupBy(value);
  }

  @action
  unsetGroupBy() {
    this.groupBy = undefined;
  }

  @action
  onSetGroupBy(value) {
    const { order } = this;
    this.setValues(order);
  }

  // View values
  @observable.ref values = undefined;

  @action
  setValues(order) {
    const { groupBy } = this;

    const data = orderSerializer(order, { view: groupBy });
    this.values = data;

    this.onSetValues(data);
  }

  @action
  onSetValues(data) {
    const { groups } = data;
    this.selectGroup(groups[0]);
  }

  // Groups selected
  @observable.ref selectedGroup = undefined;

  @action
  selectGroup(group) {
    this.selectedGroup = group;
  }

  @action
  deselectGroup() {
    this.selectedGroup = undefined;
  }

  @action
  groupIsSelected(group) {
    return this.selectedGroup?.id === group.id;
  }
}

export default ShowState;
