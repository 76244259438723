import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _debounce from 'lodash/debounce';

import { useTranslation } from 'react-i18next';

import Select from 'components/v2/ui/Select';

import useManagersFilter from './hooks/useManagersFilter';

const Wrapper = styled.div``;

function Creator({ onConfirm, onClose, ...rest }) {
  const { t } = useTranslation();
  const [{ managers, pending }, { fetch }] = useManagersFilter();

  const fetchData = (chars = '') => {
    if (chars.length >= 3) {
      const data = { chars };

      fetch(data);
    }
  };

  const handleSearch = _debounce(fetchData, 200);

  const handleChange = (val) => {
    onConfirm(val);
    onClose();
  };

  return (
    <Wrapper {...rest}>
      <Select
        isLoading={pending}
        valueKey='id'
        autoComplete='off'
        autoFocus
        labelKey='name'
        placeholder={t('Orders.Filters.Managers.Label')}
        onChange={handleChange}
        items={managers}
        onSearch={handleSearch}
      />
    </Wrapper>
  );
}

Creator.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};

Creator.defaultProps = {
  className: ''
};

export default styled(Creator)``;
