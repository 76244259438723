import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  width: auto;
  height: 19px;
  border-radius: 3px;
  font-size: 13px;
  font-weight: bold;
  color: #FFFFFF;

  ${({ variant }) => variant === 'danger' && css`
    background-color: #E68686;
  `}

  ${({ variant }) => variant === 'primary' && css`
    background-color: #5AB2D6;
  `}

  ${({ variant }) => variant === 'light' && css`
    background-color: #FFEFEF;
    color: #E68686;
  `}
`;

class Badge extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    variant: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success'])
  }

  static defaultProps = {
    className: '',
    variant: 'danger'
  }

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(Badge)``;
