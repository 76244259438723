import React from 'react';
import { PropTypes } from 'prop-types';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import Caption from './Caption';

const Wrapper = styled.div`
  ${({ theme, isActive }) => css`
    padding: 12px 20px 18px 20px;
    margin-left: -20px;
    margin-right: -20px;

    &:nth-child(even) {
      background-color: ${theme.colors.passive.secondary};
    }

    &:hover {
      background-color: #e8ecf1;
    }

    ${isActive &&
    css`
      outline: 1px solid ${theme.colors.active.primary};
      box-shadow: 0 2px 6px 0 rgba(36, 95, 119, 0.21);
    `}
  `}
`;

@observer
class Row extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    contract: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
    t: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    contractsStore: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { contract, t, isActive, contractsStore, user, ...rest } =
      this.props;

    return (
      <Wrapper {...rest}>
        <Caption
          contract={contract}
          contractsStore={contractsStore}
          user={user}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Row))``;
