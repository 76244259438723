import React from 'react';
import { withTranslation } from 'react-i18next';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import Qs from 'qs';
import { typography } from 'theme/mixins';
import { NavLink, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  border-bottom: 1px solid #d1d5db;
`;

const TabS = styled(NavLink)`
  display: inline-block;
  position: relative;
  padding-bottom: 14px;
  color: #6b707b;
  cursor: pointer;
  text-decoration: none;
  ${typography(14, 17, 400)};

  :not(:first-child) {
    margin-left: 20px;
  }

  :not(:last-child) {
    margin-right: 20px;
  }

  ${({ active }) => active && css`
    color: #3aa6d2;
    cursor: default;
    text-shadow: 0 0 1px currentColor;

    &::after {
      content: "";
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 3px;
      background: #5ab2d6;
      border-radius: 1.5px;
    }
  `};
`;

@withRouter
@withTranslation()
class Tabs extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    location: ReactRouterPropTypes.location,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = { category: null };
  }

  componentDidMount() {
    const { location } = this.props;
    const { category = null } = Qs.parse(location.search);
    this.setState({ category });
  }

  selectCategory = (category) => {
    this.setState({ category });
  }

  render() {
    const { t, ...rest } = this.props;
    const { category } = this.state;

    return (
      <Wrapper {...rest}>
        <TabS
          to="#"
          active={!category ? 1 : 0}
          onClick={() => this.selectCategory(null)}
        >
          {t('Orders.Categories.All')}
        </TabS>

        <TabS
          to="?category=coming"
          active={category === 'coming' ? 1 : 0}
          onClick={() => this.selectCategory('coming')}
        >
          {t('Orders.Categories.Coming')}
        </TabS>

        <TabS
          to="?category=completed"
          active={category === 'completed' ? 1 : 0}
          onClick={() => this.selectCategory('completed')}
        >
          {t('Orders.Categories.Completed')}
        </TabS>

        <TabS
          to="?category=canceled"
          active={category === 'canceled' ? 1 : 0}
          onClick={() => this.selectCategory('canceled')}
        >
          {t('Orders.Categories.Canceled')}
        </TabS>

        <TabS
          to="?category=coming_canceled"
          active={category === 'coming_canceled' ? 1 : 0}
          onClick={() => this.selectCategory('coming_canceled')}
        >
          {t('Orders.Categories.ComingCanceled')}
        </TabS>

        <TabS
          to="?category=archive"
          active={category === 'archive' ? 1 : 0}
          onClick={() => this.selectCategory('archive')}
        >
          {t('Orders.Categories.Archive')}
        </TabS>
      </Wrapper>
    );
  }
}

export default styled(Tabs)``;
