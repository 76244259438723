import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled, { css, withTheme } from 'styled-components';

import { Button } from 'components/forms';
import { FiltersControls, SelectedFilters } from '.';

import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'center', 'space-between')};
  min-height: 52px;
`;

const Filters = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    ${display('flex', 'center')};
    flex-wrap: wrap;
    position: relative;
    padding-right: 48px;
    border: 1px solid ${theme.colors.popup.primary};
    border-radius: 4px;
    background-color: #fbfbfb;
  `}
`;

const DownloadButton = styled(Button).attrs({
  rounded: true,
  variant: 'outline',
})``;

@withTheme
class FilterBar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func,
  };

  static defaultProps = {
    className: '',
  };

  componentDidMount() {}

  render() {
    const { t, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Filters>
          <SelectedFilters />

          <FiltersControls />
        </Filters>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(FilterBar))``;
