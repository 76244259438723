import React from 'react';
import { withTranslation } from 'react-i18next';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactRouterPropTypes from 'react-router-prop-types';
import { withRouter } from 'react-router-dom';

import { NavigationTabs } from 'components/ui';

const Wrapper = styled.div``;

@withRouter
@inject('settingsStore')
@observer
class Navigation extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    settingsStore: PropTypes.object,
    t: PropTypes.func,
    location: ReactRouterPropTypes.location
  };

  static defaultProps = {
    className: ''
  };

  @computed get isFetched() {
    const { settingsStore } = this.props;
    return settingsStore.isFetched;
  }

  @computed get contracts() {
    const { settingsStore } = this.props;
    return settingsStore.data && settingsStore.data.toJSON().contracts;
  }

  isActive(url) {
    const { location } = this.props;
    return location.pathname === url;
  }

  render() {
    const { t, ...rest } = this.props;
    const navigation = [
      {
        to: '/settings/contracts',
        label: t('Settings.Contracts.Legal.Title'),
        count: this.isFetched && this.contracts.legal_entities.length
      },
      {
        to: '/settings/contracts/list',
        label: t('Settings.Contracts.List.Title'),
        count: this.isFetched && this.contracts.contracts.length
      }
    ];

    return (
      <Wrapper {...rest}>
        <NavigationTabs
          navigation={navigation.map(item => ({
            ...item,
            active: this.isActive(item.to)
          }))}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Navigation))``;
