import { t } from 'utils/localization';

const fields = ['lpu_dog_number'];

const placeholders = {
  lpu_dog_number: t('Contracts.Filters.ContractNumber.Label'),
};

export default {
  fields,
  placeholders,
};
