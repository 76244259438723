import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import moment from 'utils/moment';

import { Button } from 'components/forms';

const Header = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #4A515C;
  line-height: 20px;
`;

const SubHeader = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: #6B707B;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;

  ${Button} + ${Button} {
    margin-left: 28px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 22px 0 8px;
  border-top: 1px solid #CBE3EB;

  ${Description} {
    flex-grow: 1;
    margin-right: 24px;
  }
`;

@inject('rangeForm')
@observer
class Footer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    rangeForm: PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  };

  render() {
    const { field, onSubmit, onClear, rangeForm, t, ...rest } = this.props;

    const { check_in, check_out, price, duration } = rangeForm.selectedValues;

    return (
      <Wrapper {...rest}>
        {price > 0 && (
          <Description>
            <Header>
              {t('PaymentPicker.ByCash', { price: price })}
            </Header>
            <SubHeader>
              {moment(check_in).format('D MMMM')}
              &nbsp;–&nbsp;
              {moment(check_out).format('D MMMM')}
              &nbsp;/&nbsp;
              {t('UI.Duration', { ...duration })}
            </SubHeader>
          </Description>
        )}

        <Controls>
          <Button
            rounded
            outline
            disabled={rangeForm.isDisabled}
            onClick={onSubmit}
          >
            {t('UI.Button.Save')}
          </Button>

          <Button
            compact
            variant='secondary'
            onClick={onClear}
          >
            {t('UI.Button.Clear')}
          </Button>
        </Controls>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Footer))``;
