import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import contractNumber from 'utils/contractNumber';

const Cell = styled.div`
  color: #4C4C4C;
  font-size: 13px;
  line-height: 16px;
`;

const Label = styled(Cell)`
  color: #6B707B;
`;

const Value = styled(Cell)`
  font-weight: 500;
  text-align: right;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & + & {
    margin-top: 10px;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  color: #3AA6D2;
  font-size: 16px;
  font-height: 20px;
  font-weight: bold;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 16px;
  border: 1px solid #BFC6D3;
  border-radius: 4px;
  background-color: #E5E8ED;
  box-sizing: border-box;

  ${Header} + ${Body} {
    margin-top: 14px;
  }
`;

@inject('showState')
@observer
class OrderInfo extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    showState: PropTypes.object.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { showState, t, ...rest } = this.props;

    const {
      dates_of_stay,
      contract,
      rooms_count,
      seats_count,
      payment: { bank_transfer, cash }
    } = showState.values;

    const { value: contractValue } = contractNumber(contract);

    return (
      <Wrapper {...rest}>
        <Header>
          {t('Orders.Rooms', { count: rooms_count })}
          &nbsp;/&nbsp;
          {t('Orders.Guests', { count: seats_count })}
        </Header>
        <Body>
          <Row>
            <Label>
              {t('Order.OrderInfo.DatesRange')}
            </Label>
            <Value>
              {dates_of_stay}
            </Value>
          </Row>

          <Row>
            <Label>
              {t('Order.OrderInfo.Contract')}
            </Label>
            <Value>
              {contractValue || t('Contract.Empty')}
            </Value>
          </Row>

          {bank_transfer && (
            <Row>
              <Label>
                {t('Order.OrderInfo.Insurance')}
              </Label>
              <Value>
                {t('UI.Price', bank_transfer)}
              </Value>
            </Row>
          )}

          {cash && (
            <Row>
              <Label>
                {t('Order.OrderInfo.Cash')}
              </Label>
              <Value>
                {t('UI.Price', cash)}
              </Value>
            </Row>
          )}
        </Body>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(OrderInfo))``;
