import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Flex from 'styled-flex-component';
import { Provider, inject, observer } from 'mobx-react';

import { BackLink } from 'components/ui';
import { Center, Sidebar } from '.';

import BookingCart from 'forms/Orders/BookingCart';

const Wrapper = styled.div`
  width: 960px;
  margin: 0 auto 48px auto;

  ${BackLink} {
    margin: 36px 0 24px;
    display: inline-block;
  }

  ${Sidebar} {
    width: 256px;
  }

  ${Center} {
    flex-grow: 1;
    margin-left: 20px;
  }
`;

@inject('orderStore')
@observer
class ViewOrder extends React.Component {
  constructor(props) {
    super(props);

    const order = props.orderStore.data;
    this.bookingCart = new BookingCart({ order });
  }

  static propTypes = {
    className: PropTypes.string,
    orderStore: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Provider bookingCart={this.bookingCart}>
        <Wrapper {...rest}>
          <BackLink />

          <Flex justifyBetween alignStart>
            <Sidebar />
            <Center />
          </Flex>
        </Wrapper>
      </Provider>
    );
  }
}

export default styled(ViewOrder)``;
