import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { observer, inject } from 'mobx-react';
import { computed } from 'mobx';

import Content from './Content';
import EmptyContent from './EmptyContent';
import LoadingContent from './LoadingContent';
import Top from './Top';
import RoomTypes from './RoomTypes';
import { HotelCard as Hotel } from 'pages/Orders/components';

const Sidebar = styled.div`
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;

  ${Sidebar} {
    width: 260px;
    padding-bottom: 20px;
  }

  ${Content} {
    width: 960px;
  }

  ${Sidebar} + ${Content} {
    margin-left: 20px;
  }
`;

const Wrapper = styled.div`
`;

@withTheme
@inject('bookingState')
@observer
class Order extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    bookingState: PropTypes.object.isRequired
  }

  @computed get showTop() {
    const { bookingState: { orderState } } = this.props;
    return orderState.code === 'done';
  }

  @computed get showRoomTypes() {
    const { bookingState: { hotelState, availabilitiesState } } = this.props;
    return hotelState.code === 'done' && availabilitiesState.code === 'done';
  }

  @computed get showHotel() {
    const { bookingState: { hotelState } } = this.props;
    return hotelState.code === 'done';
  }

  @computed get showEmptyContent() {
    const { bookingState: { availabilitiesState, hasAvailabilities } } = this.props;
    return availabilitiesState.code === 'done' && !hasAvailabilities;
  }

  @computed get showLoadingContent() {
    const { bookingState: { availabilitiesState } } = this.props;
    return availabilitiesState.code === 'pending';
  }

  @computed get showContent() {
    const { bookingState: { availabilitiesState, hasAvailabilities } } = this.props;
    return availabilitiesState.code === 'done' && hasAvailabilities;
  }

  render() {
    const { bookingState: { order, hotel, room_types }, ...rest } = this.props;

    const { showTop, showRoomTypes, showHotel, showContent, showEmptyContent, showLoadingContent } = this;

    return (
      <Wrapper {...rest}>
        {showTop && (
          <Top order={order} />
        )}

        <InnerWrapper>
          <Sidebar>
            {showHotel && (
              <Hotel hotel={hotel} />
            )}

            {showRoomTypes && (
              <RoomTypes
                hotel={hotel}
                room_types={room_types}
              />
            )}
          </Sidebar>

          {showEmptyContent && (
            <Content
              as={EmptyContent}
              order={order}
            />
          )}

          {showLoadingContent && (
            <Content
              as={LoadingContent}
              order={order}
            />
          )}

          {showContent && (
            <Content
              order={order}
            />
          )}
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default styled(Order)``;
