import React from 'react';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import styled, { withTheme } from 'styled-components';

import { DateTime } from 'components/ui';
import { OrderState } from 'pages/Orders/components';

import { getNameInitials } from 'utils/localization';
import orderState from 'utils/orderState';

const SubText = styled.div`
  color: #979BA0;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Text = styled.div`
  color: #4A515C;
  font-size: 13px;
  line-height: 18px;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Cell = styled.div`
  padding-bottom: 14px;
  color: #4A515C;
  font-size: 13px;
`;

@withTheme
class Caption extends React.PureComponent {
  static propTypes = {
    order: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    t: PropTypes.func,
    className: PropTypes.string
  };

  static defaultProps = {
    className: '',
    onSelect: () => {}
  };

  handleClick = () => {
    const { onSelect, order } = this.props;
    onSelect(order);
  };

  render() {
    const {
      t,
      className,
      order: {
        order_code,
        updated_at,
        check_in,
        check_out,
        creator,
        hotel,
        guests,
        rooms,
        provider_state,
        platform_state,
        contract
      }
    } = this.props;

    const state = orderState({ provider_state, platform_state });

    const contract_details = contract
      ? contract.insured_name || contract.number
      : t('Contract.Empty');

    return (
      <>
        <Cell className={className}>
          <Text>
            {order_code}

            <SubText title={contract_details}>
              {contract_details}
            </SubText>
          </Text>
        </Cell>

        <Cell className={className}>
          с <Text
            as={DateTime}
            value={check_in}
          />
          <br />
          по <Text
            as={DateTime}
            value={check_out}
          />
        </Cell>

        <Cell className={className}>
          <List>
            <Text>
              {t('Orders.Rooms', rooms)}
            </Text>
            <Text>
              {t('Orders.Guests', guests)}
            </Text>
          </List>
        </Cell>

        <Cell className={className}>
          <Text>
            {hotel ? hotel.name : '—'}
          </Text>
        </Cell>

        <Cell className={className}>
          <Text>
            {getNameInitials(creator)}
          </Text>
        </Cell>

        <Cell className={className}>
          <List>
            <Text
              as={DateTime}
              value={updated_at}
            />

            <Text>
              в <DateTime value={updated_at} format='H:mm' />
            </Text>
          </List>
        </Cell>

        <Cell className={className}>
          <OrderState
            state={state}
          />
        </Cell>

        <Cell className={className} />
      </>
    );
  }
}

export default styled(withTranslation()(Caption))``;
