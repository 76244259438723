import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { PropTypes } from 'prop-types';
import { ReactComponentChildrenPropType } from 'types/customPropTypes';
import { display } from 'theme/mixins';

const Wrapper = styled.div`
  ${display('flex', 'align-items', 'space-between')}
  padding: 4px 0;

  ${({ theme }) => css`
    font-size: ${theme.typography.h2.fontSize}px;
    line-height: ${theme.typography.h2.lineHeight}px;
    color: ${theme.colors.text.primary};
  `}
`;

@withTheme
class HotelTitle extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: ReactComponentChildrenPropType,
    theme: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { children, ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {children}
      </Wrapper>
    );
  }
}

export default styled(HotelTitle)``;
