import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import { computed, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import styled, { css, withTheme } from 'styled-components';

import { Top, Body, FilterBar } from '.';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: ${theme.pageContainer.width}px;
    margin: 0 auto;
    padding: 0 ${theme.pageContainer.padding}px;

    ${FilterBar} + ${Body} {
      margin-top: 12px;
    }
  `}
`;

@withRouter
@withTheme
@inject('filtersState', 'contractsStore', 'authStore')
@observer
class Contacts extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: ReactRouterPropTypes.history,
    filtersState: PropTypes.object.isRequired,
    contractsStore: PropTypes.object,
    authStore: PropTypes.object,
    t: PropTypes.func,
  };

  static defaultProps = {
    className: '',
  };

  @computed get pagination() {
    const { contractsStore } = this.props;
    const { page, total } = contractsStore.meta.paginate;

    return { total, current: Number(page) };
  }

  @computed get contracts() {
    const { contractsStore } = this.props;
    return toJS(contractsStore.data);
  }

  @computed get getUser() {
    const { authStore } = this.props;
    return authStore.data;
  }

  handleLoadMore = (page) => {
    const { contractsStore, filtersState } = this.props;
    const filter = filtersState.values();

    contractsStore.fetchMore({ page, filter });
  };

  render() {
    const { contractsStore, filtersState, authStore, t, ...rest } = this.props;
    const user = this.getUser;

    return (
      <Wrapper {...rest}>
        <Top />

        <FilterBar filters={filtersState.$('filters')} />

        {this.contracts.length > 0 && (
          <Body
            contracts={this.contracts}
            pagination={this.pagination}
            onLoadMore={this.handleLoadMore}
            user={user}
          />
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Contacts))``;
