import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Text } from 'components/atoms';

import { display, typography } from 'theme/mixins';

import { datesRange } from 'utils/dates';

const Wrapper = styled(Text)`
  ${({ theme }) => css`
    ${display('flex', 'center')}
    color: ${theme.colors.button.primaryHover};
    ${typography(14, 16, 700)}
  `}
`;

class Traveller extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    reservation: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const {
      reservation: {
        check_in,
        check_out,
        duration
      },
      t,
      ...rest
    } = this.props;

    return (
      <Wrapper {...rest}>
        {datesRange(check_in, check_out)}

        {duration && (
          <>
            {' • '}
            {t('Orders.Reservations.Duration', duration)}
          </>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Traveller))``;
