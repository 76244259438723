import React from 'react';
import { Provider } from 'mobx-react';

import { DefaultTemplate } from 'components/templates';
import { Header } from 'components/organisms';

import { Settings } from '.';

import SettingsStore from 'stores/SettingsStore';

class ContractsLegal extends React.Component {
  constructor(props) {
    super(props);

    this.settingsStore = SettingsStore.create();
  }

  componentDidMount() {
    this.settingsStore.fetch();
  }

  render() {
    return (
      <Provider settingsStore={this.settingsStore}>
        <DefaultTemplate
          header={<Header />}
          body={<Settings />}
        />
      </Provider>
    );
  }
}

export default ContractsLegal;
