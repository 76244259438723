import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Restrictions from '../Restrictions';

import { Button } from 'components/forms';
import PaymentInfo from '../PaymentInfo';

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftContainer = styled.div``;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px 36px;
  box-sizing: border-box;
  justify-content: space-between;

  ${PaymentInfo} + ${Content} {
    margin-left: 16px;
  }
`;

@withTranslation()
@inject('bookingForm', 'bookingState')
@observer
class OnUpdate extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    bookingState: PropTypes.object.isRequired,
    bookingForm: PropTypes.object.isRequired,
    restrictionsData: PropTypes.object
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { bookingForm, bookingState, restrictionsData, t, ...rest } =
      this.props;
    return (
      <Wrapper {...rest}>
        <LeftContainer>
          {restrictionsData?.restrictions?.length > 0 && (
            <Restrictions restrictionsData={restrictionsData} />
          )}
        </LeftContainer>

        <RightContainer>
          <PaymentInfo />

          <Content>
            <Button
              rounded
              type='submit'
              loading={bookingState.orderIsPending}
              disabled={restrictionsData?.hasActiveRestriction}
            >
              {t('Orders.Submit.OnChange')}
            </Button>

            <Button compact type='reset' variant='secondary'>
              {t('UI.Button.EditCancel')}
            </Button>
          </Content>
        </RightContainer>
      </Wrapper>
    );
  }
}

export default styled(OnUpdate)``;
