import { types } from 'mobx-state-tree';

import { Creator } from '../Creator';
import { Hotel } from '../Hotel';
import { Traveller } from '../Traveller';
import { Reservation } from '../Reservation';
import { Conference } from '../Conference';

import { Address, Contract, ProviderState, PlatformState } from 'stores/models';

const Order = types.model('Order', {
  id: types.identifier,
  address: types.maybeNull(Address),
  check_in: types.string,
  check_out: types.string,
  created_at: types.string,
  creator: Creator,
  treatment: types.maybeNull(types.boolean),
  hotel: types.maybeNull(Hotel),
  order_code: types.string,
  is_declared: types.maybeNull(types.boolean),
  price: types.maybeNull(types.number),
  contract: types.maybeNull(Contract),
  provider_state: ProviderState,
  platform_state: PlatformState,
  conference: types.maybeNull(Conference),
  reservations: types.optional(types.array(Reservation), []),
  travellers: types.optional(types.array(Traveller), [])
});

export default Order;
