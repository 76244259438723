import React from 'react';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.article``;

@inject('hotelsInfo')
@observer
class FeedingPrograms extends React.Component {
  @computed get feedingPrograms() {
    const { hotelsInfo } = this.props;
    const { feeding_programs } = hotelsInfo && hotelsInfo.data && hotelsInfo.data.toJSON();
    return feeding_programs;
  }

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        {this.feedingPrograms.map(({ id, title, content }) => (
          <section key={id}>
            <h4>
              {title}
            </h4>
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </section>
        ))}
      </Wrapper>
    );
  }
}

FeedingPrograms.propTypes = {
  className: PropTypes.string,
  hotelsInfo: PropTypes.object
};

FeedingPrograms.defaultProps = {
  className: ''
};

export default styled(FeedingPrograms)``;
