import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { Button } from 'components/forms';
import ReactRouterPropTypes from 'react-router-prop-types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: calc(100% - 60px);
  height: auto;
  margin: 0 30px;
  padding: 24px 0 30px;
  border-top: 1px solid #BFC6D3;

  ${Button} + ${Button} {
    margin-left: 36px;
  }
`;

@withRouter
@inject('bookingCart', 'bookingForm')
@observer
class Footer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    bookingForm: PropTypes.object.isRequired,
    bookingCart: PropTypes.object.isRequired,
    history: ReactRouterPropTypes.history
  }

  static defaultProps = {
    className: ''
  }

  @computed get isDisabled() {
    const {
      bookingCart: { hasReservations },
      bookingForm: { isEditableMode }
    } = this.props;

    return !hasReservations || isEditableMode;
  }

  handleNext = () => {
    const { bookingCart, history } = this.props;
    const { id } = bookingCart.order;

    history.replace(`/orders/${id}`);
  }

  render() {
    const { t, bookingCart, ...rest } = this.props;
    const { canMore, handleMore, canSubmit, handleSubmit } = bookingCart;

    return (
      <Wrapper {...rest}>
        <Button
          compact
          onClick={handleMore}
          disabled={!canMore}
        >
          {t('UI.Button.More')}
        </Button>

        <Button
          onClick={handleSubmit}
          disabled={!canSubmit}
        >
          {t('Order.Confirm')}
        </Button>
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(Footer))``;
