import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { BlockShadow } from 'components/atoms';

const textLines = (lines) => css`
  ${lines.map(([fontSize, lineHeight, width], index) => css`
    ::${index === 0 ? 'before' : 'after'} {
      content: "";
      display: block;
      width: ${width};
      height: ${fontSize}px;
      margin: ${(lineHeight - fontSize) / 2}px 0;
      background: currentColor;
    }
  `)}
`;

const Wrapper = styled(BlockShadow)`
  ${({ theme }) => css`
    padding: 0;
    color: ${theme.colors.passive.secondary};
  `}
`;

const Details = styled.div`
  padding: 20px 16px;
`;

const Image = styled.div`
  width: 256px;
  height: 128px;
  background-color: currentColor;
  border-radius: 4px 4px 0 0;
`;

const Title = styled.div`
  margin: 0 0 16px;

  ${textLines([
    [16, 20, '76%'],
    [16, 20, '88%']
  ])}
`;

const Address = styled.div`
  margin-bottom: 12px;

  ${textLines([
    [14, 18, '86%'],
    [14, 18, '68%']
  ])}
`;

const Phone = styled.div`
  margin-bottom: 8px;

  ${textLines([
    [14, 18, '64%']
  ])}
`;

const HotelPreviewLink = styled.div`
  ${textLines([
    [14, 24, '56%']
  ])}
`;

class HotelInfo extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  render() {
    const { ...rest } = this.props;

    return (
      <Wrapper {...rest}>
        <Image />

        <Details>
          <Title />

          <Address />

          <Phone />

          <HotelPreviewLink />
        </Details>
      </Wrapper>
    );
  }
}

export default styled(HotelInfo)``;
