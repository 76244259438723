import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import {
  Pen as PenIcon,
  Edit as EditIcon
} from 'components/icons';

import RangeForm from '../forms/RangeForm';

const Icon = styled.div`
`;

const PaymentItem = styled.div`
  white-space: nowrap;
  font-size: 13px;

  ${p => p.cash && css`
    color: #E68686;
  `}

  ${p => p.bank_transfer && css`
    color: #5AB2D6;
  `}

  ${p => p.inactive && css`
    color: #979BA0;
  `}

  & + & {
    ::before {
      content: '・'
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: auto;
  height: 36px;
  padding: 0 48px 0 16px;
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  line-height: 32px;
  box-sizing: border-box;

  ${Icon} {
    position: absolute;
    right: 15px;
    top: 7px;
    cursor: pointer;
  }

  ${p => p.isActive && css`
    border: 2px solid #5AB2D6;
  `}
`;

@observer
class PaymentInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    field: PropTypes.object.isRequired,
    tariff: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    isActive: PropTypes.bool
  }

  static defaultProps = {
    className: '',
    isActive: false,
    tariff: {
      billing_hour: 'night',
      price_calculation_rule: 'float',
      type: 'base'
    }
  };

  constructor(props) {
    super(props);

    const { field, tariff } = props;
    const prices = field.$('prices').value;

    this.rangeForm = new RangeForm({ prices, tariff });
  }

  componentDidMount() {
    const { field } = this.props;

    this._changePriceHandler = reaction(
      () => field.$('prices').value,
      (prices) => this.rangeForm.setPrices(prices)
    );
  }

  componentWillUnmount() {
    this._changePriceHandler();
  }

  handleOnEdit = (e) => {
    const { field } = this.props;

    field.isEdit ? field.unsetAsEdit() : field.setAsEdit();
  }

  render() {
    const { t, field, ...rest } = this.props;

    const cash = this.rangeForm.selectedValues;
    const bankTransfer = this.rangeForm.unselectedValues;

    return (
      <Wrapper
        {...rest}
        isActive={field.isEdit}
      >
        <PaymentItem
          bank_transfer
          inactive={bankTransfer.price === 0}
        >
          {bankTransfer.description}
        </PaymentItem>

        <PaymentItem
          cash
          inactive={cash.price === 0}
        >
          {cash.description}
        </PaymentItem>

        <Icon
          as={field.isEdit ? PenIcon : EditIcon}
          onClick={this.handleOnEdit}
        />
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(PaymentInput))``;
