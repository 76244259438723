import React from 'react';
import PropTypes from 'prop-types';
import { computed } from 'mobx';
import { observer, inject } from 'mobx-react';
import styled, { css, withTheme } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { display, flexChildActualWidth, typography } from 'theme/mixins';

import { UserCard } from '.';

const Wrapper = styled.div``;

const User = styled.div`
  ${({ theme }) => css`
    ${display('flex', 'center')}
    margin: 10px 0;

    ::before {
      content: counter(slot) '.';
      counter-increment: slot;
      ${flexChildActualWidth(24)}
      color: ${theme.colors.text.primary};
      ${typography(14, 16, 700)};
    }
  `}
`;

const Users = styled.div`
  counter-reset: slot;
  margin: 32px 0 8px;

  ${UserCard} {
    width: 100%;
  }
`;

const Title = styled.div`
  ${({ theme }) => css`
    margin: 16px 0 8px;
    padding: 24px 0 16px;
    border-bottom: 1px solid ${theme.colors.popup.primary};
    color: ${theme.colors.text.disabled};
    font-family: ${theme.typography.h2.fontFamily};
    font-size: ${theme.typography.h2.fontSize}px;
    font-weight: ${theme.typography.h2.fontWeight};
    line-height: ${theme.typography.h2.lineHeight}px;
  `}
`;

@withTheme
@inject('settingsStore')
@observer
class UsersList extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    settingsStore: PropTypes.object,
    t: PropTypes.func
  };

  static defaultProps = {
    className: ''
  };

  @computed get settings() {
    const { settingsStore } = this.props;
    return settingsStore.data.toJSON();
  }

  render() {
    const { t, ...rest } = this.props;
    const { users } = this.settings;

    return (
      <Wrapper {...rest}>
        <Title>
          {t('Settings.Users.AddedUsers.Title')}
        </Title>

        {users && (
          <Users>
            {users.map(user => (
              <User key={user.id}>
                <UserCard user={user} />
              </User>
            ))}
          </Users>
        )}
      </Wrapper>
    );
  }
}

export default styled(withTranslation()(UsersList))``;
