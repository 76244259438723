import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inject, observer, Provider } from 'mobx-react';
import { reaction } from 'mobx';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Sticky from 'react-stickynode';
import Bottom from '../Bottom';
import Form from '../Form';

import BookingForm from 'forms/Orders/BookingForm';

const Wrapper = styled.div`
  padding-bottom: 20px;

  ${Form} + ${Bottom} {
    margin-top: 20px;
  }
`;

@withTranslation()
@withRouter
@inject('bookingState', 'notificationsStore', 'authStore')
@observer
class Content extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    history: PropTypes.object.isRequired,
    bookingState: PropTypes.object.isRequired,
    authStore: PropTypes.object.isRequired,
    notificationsStore: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.initBookingForm();
  }

  componentDidMount() {
    this.addHandlers();
  }

  componentWillUnmount() {
    this.delHandlers();
    this.disposeBookingForm();
  }

  initBookingForm() {
    const hooks = {
      onSuccess: this.handleSuccess,
      onError: this.handleError,
      onReset: this.handlerReset
    };

    this.bookingForm = new BookingForm({}, { hooks });
  }

  disposeBookingForm() {
    this.bookingForm.dispose();
    this.bookingForm.unsetAvailability();
  }

  populateBookingForm(params) {
    const { authStore: { data } } = this.props;

    this.bookingForm.setAvailability({
      ...params, currentUser: data.toJSON()
    });
  }

  addHandlers() {
    const { bookingState } = this.props;

    this._stateFetchedHandler = reaction(
      () => bookingState.formParams,
      (params) => this.populateBookingForm(params)
    );

    this._datesChangeHandler = reaction(
      () => {
        const check_in = this.bookingForm.$('check_in').value;
        const check_out = this.bookingForm.$('check_out').value;

        return { check_in, check_out };
      },
      ({ check_in, check_out }) => {
        if (!!check_in && !!check_out) this.updateDailyPrices({ check_in, check_out });
      }
    );
  }

  delHandlers = () => {
    this._stateFetchedHandler();
    this._datesChangeHandler();
  }

  updateDailyPrices = ({ check_in, check_out }) => {
    const { bookingState } = this.props;

    const dailyPrices = bookingState.getDailyPricesPerPeriod({ check_in, check_out });

    this.bookingForm.updateDailyPrices(dailyPrices);
  }

  handleSuccess = async (form) => {
    const params = form.values();
    const { bookingState } = this.props;

    try {
      await bookingState.orderUpdate(params);
      this.successNotification();
      if (form.isEditableMode) this.navigateToOrder();
    } catch {
      this.errorNotification();
    }
  }

  handleError = (form) => {
    console.log(form.errors());
  }

  handlerReset = (form) => {
    this.navigateToOrder();
  }

  handleClear = (form) => {
    console.log('handleClear');
  }

  navigateToOrder = () => {
    const {
      history,
      bookingState: { order: { id } }
    } = this.props;

    history.replace(`/orders/${id}/edit`);
  }

  successNotification = () => {
    const { t, notificationsStore } = this.props;

    const mode = this.bookingForm.isEditableMode ? 'update' : 'add';

    const message = {
      type: 'success',
      message: t('Order.Notifications.EditSubmit.Success', { mode })
    };

    notificationsStore.add(message);
  }

  errorNotification = () => {
    const { t, notificationsStore } = this.props;

    const mode = this.bookingForm.isEditableMode ? 'update' : 'add';

    const message = {
      type: 'error',
      message: t('Order.Notifications.EditSubmit.Error', { mode })
    };

    notificationsStore.add(message);
  }

  render() {
    const { className } = this.props;

    return (
      <Provider bookingForm={this.bookingForm}>
        <Sticky top={20} className={className}>
          <Wrapper className={className}>
            <Form />
            <Bottom />
          </Wrapper>
        </Sticky>
      </Provider>
    );
  }
}

export default styled(Content)``;
